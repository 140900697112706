import React from "react";
import BlogSkeletonCard from "./blogSkeletonCard";
const BlogsSkeleton = () => {
  return (
    <div className="blog-list">
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
      <BlogSkeletonCard />
    </div>
  );
};

export default BlogsSkeleton;
