import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import cartEmptyImage from "../../Assets/cart.webp";
import RecentlyViewedProducts from "../LandingPage/RecentlyViewedProducts";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import Toast from "../ReactToastify/Toast";
import CartSkeleton from "./CartSkeleton";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import "./cart.css";
import LazyImage from "../LazyImage/LazyImage";
import { Helmet } from "react-helmet";
const API_HOST = process.env.REACT_APP_API_HOST;
let cartData = [];
const MemoizedRecentlyViewedProducts = React.memo(RecentlyViewedProducts);
const Cart = () => {
  const infra = process.env.REACT_APP_INFRA;
  const { showToast } = Toast();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);
  const [IsRemoveItemClicked, setIsRemoveItemClicked] = useState(false)
  const [cartProducts, setCartProducts] = useState([]);
  const [isResponse, setIsResponse] = useState(false);
  const [cartLoading, setCartLoading] = useState(true);
  const [cartDeletedItem, setCartDeletedItem] = useState(null);
  const [deletedItemModal, setDeletedItemModal] = useState(false);
  const [maintainanceMode, setMaintainanceMode] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState({
    totalProducts: 0,
    subTotalPrice: 0,
  });

  const [showFeatures, setShowFeatures] = useState({
    showWishList: true,
    searchbar: true,
  });

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showWishList: featureFlags.buyer_products_wishlist,
        searchbar: featureFlags.buyer_products_search,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const [changeInSelectedProduct, setChangeInSelectedProduct] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const filterCartData = (productId) => {
    let filteredData = cartProducts;

    filteredData = filteredData.filter((obj) => obj.stock_id !== productId);

    cartData = filteredData.map((item) => {
      return item.stock.id; // Return the stock.id to create a new array
    });
    
    setCartProducts(filteredData);
    let checkoutProducts = contextObj.params.checkoutProducts;
    if (checkoutProducts.length > 0) {
      checkoutProducts = checkoutProducts.filter((id) => id !== productId);
      contextObj.setInput("checkoutProducts", checkoutProducts);
    }
  };
  // Get Cart Data
  const fetchCartData = async () => {
    const isLoggedIn = getDataFromCookies("isLoggedIn");
    if (isLoggedIn == true) {
      const token = getDataFromCookies("7H3K9R5P2Q");
      try {
        const response = await fetch(
          `${API_HOST}/${endpoints.buyerCartDetails}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();

        setCartLoading(false);
        setIsResponse(true);

        if (res.status === 200) {
          // Open deleted item warning
          if (res.data && res.data.deleted_items !== null) {
            setDeletedItemModal(true);
            setCartDeletedItem(res.data.deleted_items);
          }

          // set CartData for checkout
          cartData = res.data.cart.map((item) => item.stock.id);

          // set Cart data
          const cartDataRes = res.data.cart;
          setCartProducts(cartDataRes);
          if (cartDataRes) {
            cartDataRes.forEach((element) => {
              element.isSelected = element.stock.quantity !== 0;
            });
            setCartProducts(cartDataRes);
          }
        } else if (res.status === 404) {
          contextObj.setInput("cartLength", 0);
          setCartProducts([]);

          // Open deleted item warning
          if (res.data && res.data.deleted_items !== null) {
            setDeletedItemModal(true);
            setCartDeletedItem(res.data.deleted_items);
          }
        } else if (res.status === 401) {
          removeDataFromCookie("7H3K9R5P2Q", "/");
          removeDataFromCookie("isLoggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          contextObj.setInput("isLoggedIn", false);
          navigate(`${navigation.navigateToHomepage}`);
        } else if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
      } catch (error) {
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      }
    }
  };

  useEffect(() => {
    fetchCartData();
  }, [contextObj.params.reFetchCart]);

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  const getToolTipForMoveToWishlistBtn = (isInWishlist, isStockAvailable) => {
    let toolTipText = "";
    if (!isStockAvailable) toolTipText = "Product is Out Of Stock";
    else if (isInWishlist) toolTipText = "Already Added To Wishlist";
    else toolTipText = "";
    return toolTipText;
  };

  useEffect(() => {
    setSelectedProducts((prev) => ({
      ...prev,
      totalProducts: cartProducts.filter((item) => item.isSelected).length,
      subTotalPrice: formatNumberWithCommas(calculateTotalAmount(cartProducts)),
    }));
  }, [cartProducts]);

  useEffect(() => {
    setSelectedProducts((prev) => ({
      ...prev,
      totalProducts: cartProducts.filter((item) => item.isSelected).length,
      subTotalPrice: formatNumberWithCommas(calculateTotalAmount(cartProducts)),
    }));
  }, [changeInSelectedProduct]);

  useEffect(() => {
    if (contextObj.params.productAddedToWishlistId) {
      const timeoutId = setTimeout(() => {
        contextObj.setInput("productAddedToWishlistId", "");
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productAddedToWishlistId]);

  const moveToWishlist = async (productId) => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    const removeItem = await fetch(
      `${API_HOST}/${endpoints.moveToWishlist}/${productId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const removeItemResJson = await removeItem.json();
    if (
      removeItemResJson.status == 500 ||
      removeItemResJson.status == 501 ||
      removeItemResJson.status == 502 ||
      removeItemResJson.status == 503 ||
      (removeItemResJson.message &&
        removeItemResJson.message.toLowerCase().includes("server error"))
    ) {
      if (removeItemResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (removeItemResJson.status == 201) {
      // await fetchCartData();
      filterCartData(productId);
      showToast("success", removeItemResJson.message);
      setShowDeleteConfirmation(false);
      contextObj.setInput("productAddedToWishlistId", productId);
      contextObj.setInput("cartLength", contextObj.params.cartLength - 1);
    } else if (removeItemResJson.status == 403) {
      showToast("error", removeItemResJson.message);
    } else if (
      removeItemResJson.status == 500 ||
      removeItemResJson.status == 501 ||
      removeItemResJson.status == 502 ||
      removeItemResJson.status == 503 ||
      (removeItemResJson.message &&
        removeItemResJson.message.toLowerCase().includes("server error"))
    ) {
      showToast("info", "Something went wrong");
      setShowDeleteConfirmation(false);
    }
  };

  const handleRemoveitem = async (cartId, productId) => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.removeItemFromCart}${cartId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      if (res.data.total_items == 0) setCartProducts([]);
      showToast("success", Constants.alertMessages.alertForProductRemove);
      contextObj.setInput("cartLength", contextObj.params.cartLength - 1);
      setShowDeleteConfirmation(false);
       filterCartData(productId);
    } else if (res.status == 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else if (
      res.status == 500 ||
      res.status == 501 ||
      res.status == 502 ||
      res.status == 503 ||
      (res.message && res.message.toLowerCase().includes("server error"))
    ) {
      if (res.status == 503) setMaintainanceMode(true);
      else setMaintainanceMode(false);
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setIsRemoveItemClicked(false);
  };

  const clearedDeletedItems = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.clearedCartDeletedItems}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      contextObj.setInput(
        "cartLength",
        contextObj.params.cartLength - cartDeletedItem
      );
      setShowDeleteConfirmation(false);
    } else if (res.status == 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else if (
      res.status == 500 ||
      res.status == 501 ||
      res.status == 502 ||
      res.status == 503 ||
      (res.message && res.message.toLowerCase().includes("server error"))
    ) {
      if (res.status == 503) setMaintainanceMode(true);
      else setMaintainanceMode(false);
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDeletedItemModal(false);
    await fetchCartData();
  };

  const getSelectedItemsArray = () => {
    if (cartData.length > 0) {
      contextObj.setInput("checkoutProducts", cartData);
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "cartPage");
      navigate("/checkout");
    } else {
      showToast("info", "Select products to checkout");
    }
  };

  useEffect(() => {
    if (showDeleteConfirmation) {
      document.body.style.overflow = "hidden";
      document.body.style.WebkitOverflowScrolling = "hidden";
      return () => {
        document.body.style.overflow = "auto";
        document.body.style.WebkitOverflowScrolling = "scroll";
      };
    }
  }, [showDeleteConfirmation]);

  useEffect(() => {
    const loggedIn = getDataFromCookies("isLoggedIn");
    if (loggedIn !== true) {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }
  }, []);

  function formatNumberWithCommas(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  }

  function calculateOfferPercentage(actualPrice, sellingPrice) {
    if (actualPrice && sellingPrice) {
      if (actualPrice <= 0 || sellingPrice <= 0) {
        throw new Error("Price must be greater than zero.");
      }
      const discount = actualPrice - sellingPrice;
      const offerPercentage = Math.abs(
        Math.round((discount / actualPrice) * 100)
      );

      return offerPercentage;
    }
  }

  function calculateTotalAmount(cartProducts) {
    const totalAmount = cartProducts.reduce((total, item) => {
      if (item.isSelected) {
        return total + item.stock.selling_price;
      }
      return total;
    }, 0);
    return totalAmount;
  }

  const [isWideScreen, setIsWideScreen] = useState(
    window.innerWidth >= 1150 && window.innerWidth <= 1450
  );
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1150 && window.innerWidth <= 1570);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Your Joyful Cart - JOYREJOY</title>
          <meta
            name="description"
            content="Your joyful cart at JOYREJOY is filled with treasures for little ones! Review and proceed to checkout with ease."
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div className="pageParentDiv pageParentDivColoredBackground">
          <div className="allBoxContainer">
            <div className="main-container-div">
              <div className="cart-container">
                {cartLoading ? (
                  <CartSkeleton />
                ) : (
                  <>
                    {cartProducts.length > 0 ? (
                      <>
                        <div className="cartContainerInnerLargeBox">
                          <div className="pageListHeaderContainer">
                            <p className="PageListTitle">
                              My Cart ({cartProducts.length})
                            </p>
                          </div>
                          <div className="cartProductsContainer">
                            {cartProducts.length > 0 ? (
                              <>
                                {cartProducts.map((product, index) => {
                                  return (
                                    <div key={index}>
                                      {product && (
                                        <div>
                                          {product.stock !== null && (
                                            <div
                                              style={{
                                                backgroundColor: "white",
                                              }}
                                            >
                                              {index !== 0 && (
                                                <hr className="hrLineForCartPage" />
                                              )}
                                              <div
                                                key={
                                                  product.stock.id &&
                                                  product.stock.id
                                                }
                                                className="imageAndDetailsMainDiv"
                                              >
                                                <div className="imageAndDetailsMainDivContainer">
                                                  <div className="image-container">
                                                    <span
                                                      onClick={() => {
                                                        
                                                        if (
                                                          product.stock
                                                            .quantity > 0
                                                        ) {
                                                          navigate(
                                                            `${navigation.navigateToProductList}/${product.stock.product.slug}`
                                                          );
                                                        } else {
                                                          setShowDeleteConfirmation(
                                                            true
                                                          );
                                                          setDeleteProductId(
                                                            product.stock.id
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <LazyImage
                                                        id={`cartProductImg_${index}`}
                                                        style={{
                                                          height: "100%",
                                                          width: "100%",

                                                          objectFit: "contain",
                                                        }}
                                                        src={
                                                          imgHost +
                                                          "/" +
                                                          product.stock
                                                            .thumb_image
                                                        }
                                                        alt="image"
                                                      />
                                                    </span>
                                                  </div>
                                                  <div className="cartDetailsDiv">
                                                    <div className="cartPageProductNameDivForMobile">
                                                      <p
                                                        id={`cartPageProductName_${index}`}
                                                        className={`cartPageProductName ${
                                                          isWideScreen
                                                            ? "truncate"
                                                            : ""
                                                        }`}
                                                      >
                                                        {isWideScreen
                                                          ? product.stock
                                                              .product.name
                                                              .length > 20
                                                            ? `${product.stock.product.name.substring(
                                                                0,
                                                                30
                                                              )}`
                                                            : product.stock
                                                                .product.name
                                                          : product.stock
                                                              .product.name}
                                                      </p>
                                                      <p className="cartPageProductNameForMobile">
                                                        {product.stock.product
                                                          .name.length > 60 ? (
                                                          <span className="productName">
                                                            {product.stock.product.name.substring(
                                                              0,
                                                              60
                                                            )}
                                                            ...
                                                          </span>
                                                        ) : (
                                                          product.stock.product
                                                            .name
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div
                                                      className=""
                                                      style={{
                                                        paddingTop: "10px",
                                                      }}
                                                    >
                                                      <div className="cartPagePriceDiv">
                                                        <p
                                                          className="cartPageProductPrice"
                                                          id={`cartProductPrice_${index}`}
                                                        >
                                                          <span className="cartPageProductPriceSymbol">
                                                            ₹{" "}
                                                          </span>
                                                          {product.stock
                                                            .selling_price &&
                                                            formatNumberWithCommas(
                                                              product.stock
                                                                .selling_price
                                                            )}
                                                        </p>
                                                        <p className="cartPageDiscountPercentage">
                                                          {" "}
                                                          {product.stock
                                                            .current_market_price &&
                                                            product.stock
                                                              .selling_price &&
                                                            calculateOfferPercentage(
                                                              product.stock
                                                                .current_market_price,
                                                              product.stock
                                                                .selling_price
                                                            )}
                                                          % off
                                                        </p>
                                                      </div>
                                                      <div
                                                        className="estimatedPriceDiv"
                                                        id={`estimatedPriceDiv_${index}`}
                                                      >
                                                        <p>
                                                          (Est. Original Price:{" "}
                                                          <span className="estPriceSpanTag">
                                                            ₹{" "}
                                                            {product.stock
                                                              .current_market_price &&
                                                              formatNumberWithCommas(
                                                                product.stock
                                                                  .current_market_price
                                                              )}
                                                          </span>
                                                          )
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="cartPageDeleteButton">
                                                  <button
                                                    className="cart-delete-buttonTab-index"
                                                    id={`cartPageRemoveFromCartBtnForIndex_${index}`}
                                                    onClick={() => {
                                                      setShowDeleteConfirmation(
                                                        true
                                                      );
                                                      setDeleteProductId(
                                                        product.stock_id
                                                      );
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faTrashCan}
                                                      className="trash-icon"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="cartPageOutOfStockMainDiv">
                                                {product.stock.product
                                                  .is_stock_available == 0 && (
                                                  <div className="cartPageOutOfStockLabel">
                                                    Out of Stock
                                                  </div>
                                                )}
                                              </div>
                                              {showDeleteConfirmation &&
                                                deleteProductId ===
                                                  product.stock.id && (
                                                  <div className="ModalContentForCart">
                                                    <div
                                                      className="modal-container"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <div>
                                                          <h5 className="headingMessageOfAlert">
                                                            Remove Item?
                                                          </h5>
                                                        </div>
                                                        <div
                                                          className="crossbuttonForCart"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          <button
                                                            className="fA-cross-icons-button"
                                                            onClick={
                                                              handleDeleteCancel
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faXmark}
                                                              id={
                                                                "cartPageCloseRemoveProductModal"
                                                              }
                                                              className="CartPage_CrossMarkButton"
                                                            />
                                                          </button>
                                                        </div>
                                                      </div>

                                                      <div className="popupContainer-RemoveFromList">
                                                        <div className="cartPageProductNameHeadingContainer">
                                                          <p className="cartPageProductNameHeading">
                                                            Are you sure you
                                                            want to remove{" "}
                                                            <span className="cartPageProductNameHeadingSpanTag">
                                                              {
                                                                product.stock
                                                                  .product.name
                                                              }
                                                              ?
                                                            </span>
                                                          </p>
                                                        </div>
                                                        <div className="imageAndDetailsMainDivForDeleteButton">
                                                          <div
                                                            onClick={() => {
                                                              if (
                                                                product.stock
                                                                  .product
                                                                  .is_stock_available ==
                                                                1
                                                              ) {
                                                                navigate(
                                                                  `${navigation.navigateToProductList}/${product.stock.product.slug}`
                                                                );
                                                              } else {
                                                                setShowDeleteConfirmation(
                                                                  true
                                                                );
                                                                setDeleteProductId(
                                                                  product.stock
                                                                    .id
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <LazyImage
                                                              className="imageContainerForRemove"
                                                              src={
                                                                imgHost +
                                                                "/" +
                                                                product.stock.thumb_image
                                                              }
                                                              alt="image"
                                                            />
                                                          </div>
                                                          <div className="deleteModalForMobileDiv">
                                                            <div>
                                                              <p
                                                                className={`cartPageProductName ${
                                                                  isWideScreen
                                                                    ? "truncate"
                                                                    : ""
                                                                }`}
                                                              >
                                                                {isWideScreen
                                                                  ? product
                                                                      .stock
                                                                      .product
                                                                      .length >
                                                                    20
                                                                    ? `${product.stock.product.name.substring(
                                                                        0,
                                                                        30
                                                                      )}`
                                                                    : product
                                                                        .stock
                                                                        .product
                                                                        .name
                                                                  : product
                                                                      .stock
                                                                      .product
                                                                      .name}
                                                              </p>
                                                              <p className="cartPageProductNameForMobileDelete">
                                                                {product.stock
                                                                  .product.name
                                                                  .length > 20
                                                                  ? `${product.stock.product.name.substring(
                                                                      0,
                                                                      33
                                                                    )}`
                                                                  : product
                                                                      .stock
                                                                      .product
                                                                      .name}
                                                              </p>
                                                            </div>

                                                            <div className="cartPagePriceDiv">
                                                              <p className="cartPageProductPrice">
                                                                <span className="cartPageProductPriceSymbol">
                                                                  ₹
                                                                </span>
                                                                {product.stock
                                                                  .selling_price &&
                                                                  formatNumberWithCommas(
                                                                    product
                                                                      .stock
                                                                      .selling_price
                                                                  )}
                                                              </p>
                                                              <p className="cartPageDiscountPercentage">
                                                                {" "}
                                                                {product.stock
                                                                  .current_market_price &&
                                                                  product.stock
                                                                    .selling_price &&
                                                                  calculateOfferPercentage(
                                                                    product
                                                                      .stock
                                                                      .current_market_price,
                                                                    product
                                                                      .stock
                                                                      .selling_price
                                                                  )}
                                                                % off
                                                              </p>
                                                            </div>
                                                            <div className="estimatedPriceDivForDeleteButton">
                                                              <p>
                                                                (Est. Original
                                                                Price:{" "}
                                                                <span className="estPriceSpanTag">
                                                                  ₹{" "}
                                                                  {product.stock
                                                                    .current_market_price &&
                                                                    formatNumberWithCommas(
                                                                      product
                                                                        .stock
                                                                        .current_market_price
                                                                    )}
                                                                </span>
                                                                )
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <hr className="lineInDeleteBox" />
                                                        </div>
                                                        <hr className="lineInDeleteBox" />
                                                      </div>
                                                      <div className="alertModalButtonDiv">
                                                        <button
                                                          className="buttonForRemove"
                                                          disabled={IsRemoveItemClicked}
                                                          id="cartPageRemoveProductModelDeleteBtn"
                                                          onClick={() => {
                                                            handleRemoveitem(
                                                              product.id,
                                                              product.stock_id
                                                            );
                                                            setIsRemoveItemClicked(true);
                                                          }}
                                                        >
                                                          Remove
                                                        </button>
                                                        {showFeatures.showWishList && (
                                                          <button
                                                            className={
                                                              product.is_in_wishlist ===
                                                                false &&
                                                              product.stock
                                                                .quantity > 0
                                                                ? "buttonForWishlisht"
                                                                : "buttonForWishlisht disabledBtn"
                                                            }
                                                            id="cartPageRemoveProductModelMoveTOWishListBtn"
                                                            onClick={(e) => {
                                                              if (
                                                                !product.is_in_wishlist &&
                                                                product.stock
                                                                  .quantity > 0
                                                              ) {
                                                                moveToWishlist(
                                                                  product.stock
                                                                    .id
                                                                );
                                                              }
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                            }}
                                                            title={getToolTipForMoveToWishlistBtn(
                                                              product.stock
                                                                .is_in_wishlist,
                                                              product.stock
                                                                .quantity
                                                            )}
                                                          >
                                                            Move to Wishlist
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="NoItemsInCart">
                                No Items In Cart
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="cartContainerInnerSmallBox">
                          <div className="priceAndSubTotalDiv">
                            <div className="priceHeadingTag">
                              <p style={{ margin: 0 }}>Price Details</p>
                            </div>
                            <div className="cartPageSubTotalDiv">
                              Subtotal ({selectedProducts.totalProducts}{" "}
                              {selectedProducts.totalProducts === 1
                                ? "Item"
                                : "Items"}
                              )
                              <p>
                                ₹{" "}
                                {formatNumberWithCommas(
                                  calculateTotalAmount(cartProducts)
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="checkoutButtonDiv">
                            <div className="checkoutText">
                              <p>
                                Clicking on "Checkout" will not deduct any money
                              </p>
                            </div>
                            <div>
                              <button
                                id="cartPageCheckoutBtn"
                                onClick={() => {
                                  if (selectedProducts.totalProducts > 0) {
                                    getSelectedItemsArray();
                                  }
                                }}
                                style={{
                                  opacity:
                                    selectedProducts.totalProducts > 0
                                      ? 1
                                      : 0.5,
                                  cursor:
                                    selectedProducts.totalProducts > 0
                                      ? "pointer"
                                      : "default",
                                }}
                                className={
                                  selectedProducts.totalProducts > 0
                                    ? "sidebarCheckoutButtonForCart"
                                    : "disabledSidebarCheckoutButtonForCart"
                                }
                              >
                                Checkout
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {isResponse && (
                          <div
                            className="NoItemsInCart"
                            id="Cart_NoItemsContainer"
                          >
                            <img
                              className=""
                              src={cartEmptyImage}
                              alt="Wishlist.webp"
                            />
                            <h4
                              className="wishlistHeadingElement"
                              id="EmptyCartHeading"
                            >
                              Your cart is empty!
                            </h4>
                            <li className="wishlistEmptyline">
                              Add items to it now.
                            </li>
                            <button
                              onClick={() => {
                                navigate(navigation.navigateToHomepage);
                              }}
                              className="ShopNowButtonWishlist"
                            >
                              Shop Now
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!cartLoading && (
        <div
          className="bg-white "
          style={{
            padding: "0 5%",
          }}
        >
          <MemoizedRecentlyViewedProducts />
        </div>
      )}
      {/* Deleted Item Modal */}
      {deletedItemModal && (
        <div className="warning-modal ModalContentForCart">
          <div className="modal-container warning-modal-container">
            <h1>Warning</h1>
            <p className="message">
              Oops, {cartDeletedItem} {cartDeletedItem == 1 ? "item" : "items"}{" "}
              in your cart no longer available.
            </p>

            <div className="modal-buttons">
              <button
                type="button"
                className="warning-rm-btn"
                onClick={() => {
                  clearedDeletedItems();
                }}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Cart;
