import React, { useState, useEffect, useContext, useRef } from "react";
import BlogCard from "../Blogs/BlogCard";
import { useNavigate } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import Endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import "./home.css";
import SkeletonComponent from "../skeleton/Skeleton";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
const API_HOST = process.env.REACT_APP_API_HOST;

const BlogsAndNews = (props) => {
  const BlogsAndNewsRef = useRef(null);
  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  const [blogs, setBlogs] = useState([]);

  const openBlogDetails = (slug, id) => {
    navigate(`/blogs/${slug}`);
    sessionStorage.setItem("blogDetailsId", id);
  };

  const getBlogsAndNews = async () => {
    try {
      const response = await fetch(
        `${API_HOST}/${Endpoints.getBlogList}?per_page=4`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (
        res.status >= 500 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        setLoadingImg(false);
        contextObj.setInput("serviceUnavailable", true);
      } else if (res.status == 200) {
        setBlogs(res.data.data);
      } else {
        setLoadingImg(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };
  let [loadingImg, setLoadingImg] = useState(true);
  useEffect(() => {
    getBlogsAndNews();
  }, []);

  return (
    <div ref={BlogsAndNewsRef}>
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div className="blog-and-news-container" id="blogsContainer">
          {loadingImg && blogs.length < 1 && (
            <SkeletonComponent className={"blogsAndNewsSkeleton "} />
          )}
          {blogs.length > 0 && (
            <>
              <h2 className="blogs-heading">
                Stay Updated With Our{" "}
                <span style={{ color: "#EA5E3D" }}>Blog And News</span>
              </h2>

              <div className="blogs-line">
                {blogs.map((blog, index) => (
                  <BlogCard
                    key={index}
                    blog={blog}
                    navigateToDetails={openBlogDetails}
                    loadingImg={setLoadingImg}
                  />
                ))}
              </div>
              <div style={{ marginBottom: "50px" }}>
                <button
                  type="button"
                  className="all-blogs-btn"
                  onClick={() => {
                    navigate(navigation.navigateToBlogs);
                  }}
                  onMouseDown={(e) => {
                    if (e.button == 1) {
                      e.preventDefault();
                      window.scrollTo(0, 0);
                      window.open(`${navigation.navigateToBlogs}`, "_blank");
                    }
                  }}
                >
                  See All Blogs & News
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogsAndNews;
