import React, { useState, useEffect, useContext } from "react";
import "./serviceUnavailable.css";
import Constants from "../../config/ProjectConstants.json";
import ParamsContext from "../useContext/paramContext";
import websiteUnderMaintenance from "../../Assets/websiteUnderMaintenance.webp";
import { useNavigate } from "react-router-dom";

import placeHolderImage from "../../Assets/placeHolderImage.webp";
function ServiceUnavailable(props) {
  const contextObj = useContext(ParamsContext);
  const navigate = useNavigate();

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
    } else {
      navigate("/");
    }
  }, [contextObj.params.featureFlags]);

  useContext(() => {
    if (contextObj.params.serviceUnavailable) {
    } else {
      navigate("/");
    }
  }, [contextObj.params.featureFlags]);

  return (
    <>
      <div className="serviceUnavailableDivContainer">
        {props.maintainanceMode ? (
          <div className="serviceUnavailablePageContainer">
            <p className="serviceUnavailableP">
              {Constants.HeadlinesMessages.messageForMaintencePage_first}
            </p>
            <p className="serviceUnavailableP serviceUnavailablePageDescriptionTxt-second">
              {Constants.HeadlinesMessages.messageForMaintencePage_second}
            </p>
            <div className="serviceUnavailablePageImageContainer">
              <img
                className="imgForServiceUnavailablePage"
                src={websiteUnderMaintenance}
                onError={(e) => {
                  e.target.src = placeHolderImage;
                }}
                alt="Website Under Maintenance."
              />
            </div>
          </div>
        ) : (
          <div className="serviceUnavailablePageContainer">
            <p className="serviceUnavailableP">
              {Constants.HeadlinesMessages.messageForServiceUnavailable_First}
            </p>
            <p className="serviceUnavailableP serviceUnavailablePageDescriptionTxt-second">
              {Constants.HeadlinesMessages.messageForServiceUnavailable_Second}
            </p>
            {/* <div
              onClick={() => {
                navigate(`${navigation.navigateToHomepage}`);
              }}
              className="serviceUnavailableNavigateToHomeBtn"
            >
              Go Back To Home Screen
            </div> */}
          </div>
        )}
      </div>
    </>
  );
}
export default ServiceUnavailable;
