import { React, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./login.css";
import ParamsContext from "../useContext/paramContext";
import { handleScrollToTop } from "../../resuableFunctions/resuableFunctions";
function TermsConditionsText() {
  const location = useLocation();
  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  return (
    <span>
      <p className="Terms_Conditions_Text mobile_modal_password-requirements">
        By continuing, I agree to{" "}
        <span
          onClick={() => {
            // navigate("/terms-of-use");
            if (location.pathname === "/terms-of-use") {
              handleScrollToTop();
            } else {
              window.open("/terms-of-use", "_blank");
            }
          }}
          className="openPolicyDocsForLoginModal"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // navigate("/terms-of-use");
              if (location.pathname === "/terms-of-use") {
                handleScrollToTop();
              } else {
                window.open("/terms-of-use", "_blank");
              }
            }
          }}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Terms of Use
        </span>{" "}
        &{" "}
        <span
          onClick={() => {
            // navigate("/privacy-policy");
            if (location.pathname === "/privacy-policy") {
              handleScrollToTop();
            } else {
              window.open("/privacy-policy", "_blank");
            }
          }}
          className="openPolicyDocsForLoginModal"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // navigate("/privacy-policy");
              if (location.pathname === "/privacy-policy") {
                handleScrollToTop();
              } else {
                window.open("/privacy-policy", "_blank");
              }
            }
          }}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Privacy Policy
        </span>
      </p>
    </span>
  );
}

export default TermsConditionsText;
