import React from "react";
import "./description.css";

const Description = ({ htmlContent }) => {
  return (
    <div
      className="description"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default Description;
