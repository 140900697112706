import React from "react";
import { useLocation } from "react-router-dom";
import PolicyComponent from "./policy/privacypolicy";
import { useState, useEffect, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import { Helmet } from "react-helmet";

const APP_NAME = process.env.REACT_APP_NAME;
const SELLER_URL = process.env.REACT_APP_SELLER_DOMAIN;
const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;
const infra = process.env.REACT_APP_INFRA;

const SellersGuideLine = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
    buyerPoliciesRetrievedviaApi: false,
  });
  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
        buyerPoliciesRetrievedviaApi:
          featureFlags.buyer_policies_retrieved_via_api,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;

  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const Name = "Seller's GuideLine";
  const PolicyId = 6;

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Guide to Joyful Selling - JOYREJOY</title>
          <meta
            name="description"
            content="Our seller guidelines at JOYREJOY are your roadmap to joyful selling! Explore our guidelines with a playful spirit and embark on a journey to success with us. Let's make selling on JOYREJOY a joyful and rewarding experience!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {showFeatures.buyerPoliciesRetrievedviaApi ? (
        <PolicyComponent PolicyName={Name} policyId={PolicyId} />
      ) : (
        <div
          className="pageParentDiv"
          style={{
            marginTop: contextObj.params.topNavbarHeight,
          }}
        >
          <div className="body-container">
            <div className="policy-container" style={conditionalStyle}>
              <ResourcesSidebar activeResource={"Seller's Guideline"} />

              <div className="policy-content">
                <h1
                  className={`policy-content-heading ${
                    isSticky ? "sticky-heading" : ""
                  }`}
                >
                  Seller's Guideline
                </h1>
                <p>
                  <span>
                    {APP_NAME} provides an opportunity to every individual to
                    sell from anywhere in India. With {APP_NAME}, you can now
                    make money with unused toys or baby products and encourage
                    reusability by sharing joy.
                  </span>
                </p>
                <p>Become a Seller with {APP_NAME}</p>
                <p>
                  <span>
                    To become a seller, first you need to have a Sellers
                    Account.
                  </span>
                </p>
                <ol>
                  <li>
                    Click{" "}
                    <a href={SELLER_URL} target="_blank">
                      Become a Seller
                    </a>{" "}
                    from the top header to navigate to the {APP_NAME} Seller
                    console.
                  </li>
                  <li>
                    SignUp as a seller and complete your profile information.
                  </li>
                  <li>
                    Your Profile will then be verified against the details
                    filled in for correctness and authenticity.
                  </li>
                </ol>

                <p>Publish your product</p>
                <ol>
                  <li>
                    Once your profile is approved, you can add your product(s)
                    by uploading attractive images and a short and catchy
                    description.
                  </li>
                  <li>
                    Your Product will then go through initial verification in
                    terms of image clarity, price and description.
                  </li>
                  <li>
                    Once approved, your product will then be available at{" "}
                    {APP_NAME}
                    's Buyer Portal for everyone to buy.
                  </li>
                  <li>
                    As soon as your product receives an interest, the team at{" "}
                    {APP_NAME} will get in touch with you for the availability
                    of the product.
                  </li>
                  <li>
                    Once they receive your confirmation, {APP_NAME} will pick up
                    the product from your end for further processing.
                  </li>
                  <li>
                    You will receive your payment within {3} to {7} working days
                    post the shipment has been completed.
                  </li>
                </ol>

                <p>Tips To Improve Your Product Listing</p>
                <ol>
                  <li>
                    Captivating Captures: Images are the most effective and
                    interactive way of showing the product. Better the image,
                    higher the chances of it getting sold. Images of {500} x{" "}
                    {500} or {1000} x {1000} pixels are recommended to increase
                    the picture quality.
                  </li>
                  <li>
                    Crafting Price Perfection: {APP_NAME} accepts preloved toys
                    and so it would be a good choice to quote based on how much
                    the toy has been used. Below are some of the suggestion,
                  </li>
                  <ul>
                    <li>New (Sealed): ~90% of the current market price</li>
                    <li>Almost Like New: ~70% of the current market price</li>
                    <li>Gently Loved : ~50% of the current market price</li>
                    <li>Loved: ~40% of the current market price</li>
                    <li style={{ listStyle: "none", marginTop: "10px" }}>
                      <b style={{ fontWeight: 500 }}>Note:</b> Your product will
                      be listed 10-20% higher pricing on our portal."Current
                      Market Price" will be checked online at the instant when
                      Product Request is received. In case if there are multiple
                      vendors selling the same product, then the one with the
                      lowest will be considered as the current market price for
                      computation.
                    </li>
                  </ul>
                  <li>
                    Packaging Guidelines:
                    <ul>
                      <li>
                        Secure Packaging: Use sturdy, durable packaging
                        materials to ensure the item is well-protected during
                        transit.Wrap fragile items with bubble wrap or packing
                        paper to prevent breakage.
                      </li>
                      <li>
                        Proper Labeling: Clearly label the package with the
                        below mentioned address.
                        <br />
                        <span>
                          <b style={{ fontWeight: 500 }}>{ADMIN_ADDRESS}</b>
                        </span>
                      </li>
                      <li>
                        Include your return address in case the package needs to
                        be returned.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Availability for Shipment Pickup: After receiving the order,{" "}
                    {APP_NAME} will initiate the process of collecting the
                    product from your end as soon as possible to meet the
                    expected delivery date. In case, if you are NOT available at
                    the scheduled date, please communicate the same to{" "}
                    {APP_NAME} so that pickup can be rescheduled.
                  </li>
                  <li>
                    Quality Control: Include any necessary documentation, such
                    as user manuals or certificates, if applicable.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SellersGuideLine;
