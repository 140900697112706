import React from "react";

import "./accountDelete.css";
import Wright from "../../Assets/Wright.webp";
import placeHolderImage from "../../Assets/placeHolderImage.webp";

const AccountDelete = () => {
  return (
    <>
      <div>
        {/* <UpdatedNavbar /> */}
        <div className="Account-Container">
          <div className="Account-Delete-Container">
            <div className="Account-Delete-SVG-box">
              <img
                src={Wright}
                alt="wright.webp"
                onError={(e) => {
                  e.target.src = placeHolderImage;
                }}
              />
            </div>
            <div className="">
              <h1 className="Account-Delete-Heading-Popup">
                Account deleted successfully
              </h1>
            </div>
            <div>
              <li className="Account-Delete-List-tag">
                Your account is deleted, you will no longer have access to data.
              </li>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDelete;
