import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faSquareXTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleRight,
  faCopy,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import approve from "../../Assets/approver_logo2.webp";
import EcoFriendly from "../../Assets/eco_friendly_logo.webp";
import CartSidebar from "./CartSidebar";
import { setProductIdsArrayToCookies } from "../Cookies/Cookies";
import RecentlyViewedProducts from "../LandingPage/RecentlyViewedProducts";
import SimilarProductsSection from "../LandingPage/SimilarProducts";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import ProductDetailsSkeleton from "./ProductDetailsSkeleton";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import RichTextComponent from "./richTextComponent";
import Toast from "../ReactToastify/Toast";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import LazyImage from "../LazyImage/LazyImage";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import { Helmet } from "react-helmet";
import "./product-details.css";
import {
  faSortDown,
  faSortUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { capitalizeFirstChar } from "../../resuableFunctions/resuableFunctions";
const MemoizedRecentlyViewedProducts = React.memo(RecentlyViewedProducts);
const MemoizedSimilarProductsSection = React.memo(SimilarProductsSection);
const API_HOST = process.env.REACT_APP_API_HOST;
const infra = process.env.REACT_APP_INFRA;

const imageBase = process.env.REACT_APP_IMG_FOLDER;

function ProductDetail() {
  const { showToast } = Toast();
  const [sellerDetails, setSellerDetails] = useState(null);
  const [showsellerDetails, setShowsellerDetails] = useState(false);
  let [defaultStock, setDefaultStock] = useState(false);

  let [variantsList, setVariantsList] = useState({
    variantName: true,
    variantValue: true,
  });

  const changeSelectedVariant = async (variant) => {
    let updatedSelectStock = await getStockByVariant(
      variant.variantId,
      variant.id
    );

    getParentObjectByVariantId(
      updatedSelectStock.variant_id,
      updatedSelectStock.variant_value_id
    );

    setDefaultStock(updatedSelectStock);
  };

  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const [maintainanceMode, setMaintainanceMode] = useState(false);

  const [fetchDataNow, setFetchDataNow] = useState(false);
  const validIdFormat = (phone) => {
    const regex = /^[0-9]+$/;
    return regex.test(phone);
  };

  const contextObj = useContext(ParamsContext);
  const [showVedio, setShowVedio] = useState(false);
  const navigate = useNavigate();
  let productId;
  const { slug } = useParams();

  const [showFeatures, setShowFeatures] = useState({
    showWishList: true,
    recentlyViewedProducts: true,
    buyItNowButton: true,
    shareIconButton: true,
    showFooter: true,
    cartSidebar: true,
  });
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showWishList: featureFlags.buyer_products_wishlist,
        recentlyViewedProducts: featureFlags.buyer_common_recentlyvisiteditems,
        buyItNowButton: featureFlags.buyer_productdetails_buyitnow,
        shareIconButton: featureFlags.buyer_productdetails_productdetailsshare,
        showFooter: featureFlags.buyer_common_footer,
        cartSidebar: featureFlags.buyer_common_addtocartbar,
      }));
    }
  }, [contextObj.params.featureFlags]);

  function extractProductIdFromSlug(slug) {
    const parts = slug.split("-");
    const productIdIndex = parts.length - 2;
    const productIdTest = parseInt(parts[productIdIndex], 10);
    const validId = validIdFormat(parts[productIdIndex]);
    if (validId) productId = productIdTest;
    else navigate(`${navigation.navigateToNoRoutesMatched}`);
  }
  extractProductIdFromSlug(slug);
  const [product, setProduct] = useState([]);
  const [hasMorePoints, setHasMorePoints] = useState(true);
  const [productImgs, setProductImgs] = useState([]);
  const [focusImg, setfocusImg] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [isLoadingModel, setIsLoadingModel] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenForMobile, setIsDropdownOpenForMobile] = useState(false);
  const dropdownRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleShareIconClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const shareDataForMobile = () => {
    setIsDropdownOpenForMobile(!isDropdownOpenForMobile);
  };

  const [currentProductCondition, setCurrentProductCondition] = useState("");
  const [startIndexForMobile, setStartIndexForMobile] = useState(0);
  const containerRef = useRef(null);
  const touchStartX = useRef(null);
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    if (touchStartX.current === null) return;

    const touchEndX = e.changedTouches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;
    const threshold = 50;
    if (deltaX > threshold) {
      if (startIndexForMobile > 0) {
        setStartIndexForMobile(startIndexForMobile - 1);
      }
    } else if (-deltaX > threshold) {
      if (startIndexForMobile < mobileViewImage.length - 1) {
        setStartIndexForMobile(startIndexForMobile + 1);
      }
    }
    touchStartX.current = null;
  };

  // Function to format a number with commas
  function formatNumberWithCommas(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  }

  function calculateOfferPercentage(actualPrice, sellingPrice) {
    if (actualPrice && sellingPrice) {
      if (actualPrice <= 0 || sellingPrice <= 0) {
        throw new Error("Price must be greater than zero.");
      }
      const discount = actualPrice - sellingPrice;
      const offerPercentage = Math.round((discount / actualPrice) * 100);
      return offerPercentage;
    }
  }

  useEffect(() => {
    contextObj.setInput("showCartSidebar", false);
    setCurrentProductCondition("");
    fetchproduct();
  }, [productId, contextObj.params.isLoggedIn]);

  useEffect(() => {
    if (defaultStock) {
      setIsLoadingModel(true);
      setImageUrl(getImageUrl(defaultStock.thumb_image));
      let imgs = [defaultStock.thumb_image, ...defaultStock.images_url];
      setProductImgs(imgs);
      setMobileViewImage(imgs);
      setfocusImg(imgs[0]);

      setIsLoadingModel(false);
    }
  }, [defaultStock]);
  // add to cart
  const handleAddToCart = async () => {
    const checkLoggedIn = getDataFromCookies("isLoggedIn");
    if (checkLoggedIn !== true) {
      contextObj.setInput("actionAfterLogin", "addToCart");
      contextObj.setInput("productId", defaultStock.id);
      contextObj.setInput("showLoginModel", true);
    } else {
      const payload = {
        stock_id: defaultStock.id,
      };
      if (window.innerWidth > 600) {
        contextObj.setInput("showCartSidebar", true);
      }
      const authenticationToken = getDataFromCookies("7H3K9R5P2Q");
      const response = await fetch(`${API_HOST}/${endpoints.addToCart}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify(payload),
      });
      const res = await response.json();
      if (res.status === 201 || res.status === 409) {
        setFetchDataNow(true);

        showToast("success", Constants.alertMessages.alertForAddingInCart);
        if (res.status === 201) {
          contextObj.setInput("cartLength", contextObj.params.cartLength + 1);
        }

        setDefaultStock((prev) => ({
          ...prev,
          is_in_cart: true,
        }));

        let stocksInProd = product.stocks;
        if (stocksInProd) {
          stocksInProd = stocksInProd.map((stock) => {
            return {
              ...stock, // spread the existing stock properties
              is_in_cart:
                stock.id === defaultStock.id ? true : stock.is_in_cart, // add or update the is_in_cart property
            };
          });

          setProduct((prev) => ({
            ...prev,
            stocks: stocksInProd,
          }));
        }
      } else if (res.status == 404) {
        showToast("info", res.message);
      } else if (
        res.status == 422 &&
        res.message == Constants.alertMessages.alertForMaxLimitOfCart
      ) {
        setFetchDataNow(true);
        showToast("error", Constants.alertMessages.alertForMaxLimitOfCart);
      } else if (res.status === 401) {
        removeDataFromCookie("7H3K9R5P2Q", "/");
        removeDataFromCookie("isLoggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        contextObj.setInput("isLoggedIn", false);
        navigate(`${navigation.navigateToHomepage}`);
      } else if (
        res.status == 500 ||
        res.status == 501 ||
        res.status == 502 ||
        res.status == 503 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        if (res.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
    }
  };

  useEffect(() => {
    if (contextObj.params.productAddedToWishlistId) {
      const timeoutId = setTimeout(() => {
        contextObj.setInput("productAddedToWishlistId", "");
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productAddedToWishlistId]);

  async function handleAddToWishlist() {
    const checkLoggedIn = getDataFromCookies("isLoggedIn");
    if (checkLoggedIn !== true) {
      contextObj.setInput("actionAfterLogin", "addToWishlist");
      contextObj.setInput("productId", defaultStock.id);
      contextObj.setInput("showLoginModel", true);
    } else {
      let payload = {
        stock_id: defaultStock.id,
      };

      payload = JSON.stringify(payload);
      const authenticationToken = getDataFromCookies("7H3K9R5P2Q");
      const addToWishlist = await fetch(
        `${API_HOST}/${endpoints.addToWishList}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticationToken}`,
          },
          body: payload,
        }
      );
      const addToWishlistResJson = await addToWishlist.json();
      if (
        addToWishlistResJson.status == 500 ||
        addToWishlistResJson.status == 501 ||
        addToWishlistResJson.status == 502 ||
        addToWishlistResJson.status == 503 ||
        (addToWishlistResJson.message &&
          addToWishlistResJson.message.toLowerCase().includes("server error"))
      ) {
        if (addToWishlistResJson.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      if (addToWishlistResJson.status === 201) {
        showToast("success", addToWishlistResJson.message);

        contextObj.setInput("productAddedToWishlistId", productId);

        setDefaultStock((prev) => ({
          ...prev,
          is_in_wishlist: true,
        }));

        let stocksInProd = product.stocks;
        if (stocksInProd) {
          stocksInProd = stocksInProd.map((stock) => {
            return {
              ...stock, // spread the existing stock properties
              is_in_wishlist:
                stock.id === defaultStock.id ? true : stock.is_in_wishlist, // add or update the is_in_wishlist property
            };
          });

          setProduct((prev) => ({
            ...prev,
            stocks: stocksInProd,
          }));
        }
      } else if (addToWishlistResJson.status === 409) {
        showToast("info", addToWishlistResJson.message);
      } else if (addToWishlistResJson.status === 401) {
        removeDataFromCookie("7H3K9R5P2Q", "/");
        removeDataFromCookie("isLoggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");

        contextObj.setInput("isLoggedIn", false);
        navigate(`${navigation.navigateToNoRoutesMatched}`);
      } else {
        showToast("error", Constants.alertMessages.alertForWishlistLimit);
      }
    }
  }

  const getImageUrl = (imageName) =>
    `${API_HOST}/${infra === "PROD" ? "public/" : ""}${imageName}`;

  const shareUrl = window.location.href;
  const title = product.name;
  const image = imageBase + "/" + product.thumb_image;
  const condition = product.condition;
  const price = product.selling_price;
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    shareUrl
  )}`;

  const handleWhatsAppShare = () => {
    window.open(whatsappUrl, "_blank");
  };

  const [mobileViewImage, setMobileViewImage] = useState([]);
  function getStockByVariant(variantId, variant_value_id) {
    return product.stocks.find(
      (stock) =>
        stock.variant.id === variantId &&
        stock.variant_value.id === variant_value_id
    );
  }
  function getParentObjectByVariantId(variant_id, variant_value_id) {
    let response = { selectedVariant: null, updatedVariantsList: null };

    response.updatedVariantsList = variantsList.map((parentObj) => {
      // Iterate over variantValues and update the checked status
      const updatedVariantValues = parentObj.variantValues.map(
        (variantValueObj) => {
          const isMatching =
            variantValueObj.variantId === variant_id &&
            variantValueObj.id === variant_value_id;

          if (isMatching) {
            response.selectedVariant = parentObj;
          }
          return {
            ...variantValueObj,
            checked: isMatching,
          };
        }
      );

      return {
        ...parentObj,
        variantValues: updatedVariantValues,
      };
    });

    setVariantsList(response.updatedVariantsList);
  }

  useMemo(() => {
    if (defaultStock && variantsList) {
      getParentObjectByVariantId(
        defaultStock.variant_id,
        defaultStock.variant_value_id
      );
    }
  }, [defaultStock]);

  const fetchproduct = async () => {
    setIsLoadingModel(true);
    let headers;
    if (getDataFromCookies("isLoggedIn") == true) {
      const token = getDataFromCookies("7H3K9R5P2Q");
      headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }
    await fetch(`${API_HOST}/${endpoints.getProducts}/${productId}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status == 404 && res.message == "Invalid product id.") {
          showToast("info", Constants.alertMessages.alertForInvalidID);
          navigate(navigation.navigateToHomepage);
        } else if (
          res.status == 404 &&
          res.message == "Product is not Verified"
        ) {
          showToast("info", Constants.alertMessages.unVerifiedProduct);
          navigate(navigation.navigateToHomepage);
        } else if (res.status == 404) {
          showToast("info", res.message);
        }
        if (res.status == 200) {
          const productDataRes = res.data;
          if (productDataRes.seller) {
            setSellerDetails(productDataRes.seller);
          } else {
            setSellerDetails(null);
          }
          if (getDataFromCookies("isLoggedIn")) {
            await handleRecentlyViewedProductsForLogedinUser();
          } else {
            let guestUserAcceptedCookie = getDataFromCookies(
              "guestUserAcceptedCookie"
            );
            if (guestUserAcceptedCookie) {
              setProductIdsArrayToCookies(productId);
              const existingProductIDs = readCookie("JoyReJoyProductIDs") || [];
              existingProductIDs.push(productDataRes.id);
              writeCookie("JoyReJoyProductIDs", existingProductIDs, 1);

              let productIds = removeDuplicatesFromArray(existingProductIDs);
              productIds = productIds
                .filter((id) => id !== productDataRes.id)
                .reverse();
            }
          }
          if (productDataRes.condition) {
            setCurrentProductCondition(productDataRes.condition);
          }
          if (productDataRes.slug == slug) {
            let defaultVariant = null;
            setProduct(productDataRes);
            if (
              productDataRes.morePoints !== undefined &&
              productDataRes.morePoints !== null &&
              productDataRes.morePoints !== ""
            ) {
              setHasMorePoints(true);
            } else {
              setHasMorePoints(false);
            }

            const uniqueVariants = {};
            productDataRes.stocks.forEach((stock) => {
              if (stock.is_default == 1) {
                setDefaultStock(stock);
              }
              if (stock.variant && stock.variant_value) {
                const variantName = stock.variant.name;
                const variantValue = {
                  id: stock.variant_value.id,
                  variantId: stock.variant.id,
                  variantName: stock.variant.name,
                  variantValue: stock.variant_value.value,
                  thumb_image: stock.thumb_image,
                  checked: stock.is_default == 1 ? true : false,
                };

                // Store unique variant name and values
                if (!uniqueVariants[variantName]) {
                  uniqueVariants[variantName] = new Set(); // Use Set to ensure unique values
                }
                uniqueVariants[variantName].add(variantValue);
              }
            });
            const formattedVariants = Object.keys(uniqueVariants).map(
              (name) => ({
                variantName: name,
                variantValues: Array.from(uniqueVariants[name]), // Convert Set to array
              })
            );
            setVariantsList(formattedVariants);
          } else {
            showToast("success", Constants.alertMessages.alertForInvalidID);
            navigate(navigation.navigateToHomepage);
          }
        }
      });

    setIsLoadingModel(false);
  };

  const handleRecentlyViewedProductsForLogedinUser = async () => {
    const payload = {
      product_id: productId,
    };
    const token = getDataFromCookies("7H3K9R5P2Q");

    await fetch(`${API_HOST}/${endpoints.loggedInAddToRecentlyAddedProducts}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }

        contextObj.setInput("reFetchRecentlyViewProducts", true);
      });
  };

  const handleButtonClick = () => {
    setSelectedImage((prevSelectedImage) => {
      const nextIndex = (prevSelectedImage + 1) % productImgs.length;
      return nextIndex;
    });
  };

  function removeDuplicatesFromArray(array) {
    return [...new Set(array)];
  }

  function writeCookie(cookieName, productIDs, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    // Convert the array to a JSON string and write it to the cookie
    const cookieString = `${cookieName}=${encodeURIComponent(
      JSON.stringify(productIDs)
    )}; expires=${expirationDate.toUTCString()}`;

    document.cookie = cookieString;
  }

  // Function to read a cookie and return the parsed array of product IDs
  function readCookie(cookieName) {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(cookieName + "=")) {
        const cookieValue = decodeURIComponent(
          cookie.substring(cookieName.length + 1)
        );

        try {
          return JSON.parse(cookieValue);
        } catch (error) {
          console.error("Error parsing cookie value:", error);
          return [];
        }
      }
    }

    return [];
  }

  useEffect(() => {
    if (contextObj.params.showCartSidebar && showFeatures.cartSidebar) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [contextObj.params.showCartSidebar, showFeatures.cartSidebar]);

  const [imageUrl, setImageUrl] = useState(null);

  const polishedAsMetaDesc = (htmlString) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlString, "text/html");
    var plainText = doc.body.textContent;
    return plainText.substring(0, 320);
  };

  const getBackgroundColor = (condition) => {
    if (condition) {
      switch (condition.toUpperCase()) {
        case "NEW":
          return "#379D75";
        case "ALMOST LIKE NEW":
          return "#F7BC3A";
        case "GENTLY LOVED":
          return "#9AC2E6";
        case "LOVED":
          return "#F4ABA4";
        default:
          return "";
      }
    }
  };

  const getBorderColor = (condition) => {
    switch (condition.toUpperCase()) {
      case "NEW":
        return "rgba(55, 157, 117, 0.5) transparent rgba(55, 157, 117, 0.5) rgba(55, 157, 117, 1)";
      case "ALMOST LIKE NEW":
        return "rgba(247, 188, 58, 0.5) transparent rgba(247, 188, 58, 0.5) rgba(247, 188, 58, 1)";
      case "GENTLY LOVED":
        return "rgba(154, 194, 230, 0.5) transparent rgba(154, 194, 230, 0.5) rgba(154, 194, 230, 1)";
      case "LOVED":
        return "rgba(244, 171, 164, 0.5) transparent rgba(244, 171, 164, 0.5) rgba(244, 171, 164, 1)";
      default:
        return "";
    }
  };

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>{product.name}</title>
          <meta
            name="description"
            content={
              product.meta_description ||
              polishedAsMetaDesc(product.description)
            }
          />
          <link rel="canonical" href={currentURL} />

          {/* Open Graph Tags */}
          <meta
            property="og:title"
            content={`${product.meta_title || product.name} | JOYREJOY`}
          />
          <meta
            property="og:description"
            content={
              product.meta_description ||
              polishedAsMetaDesc(product.description)
            }
          />
          <meta
            property="og:image"
            content={`${imgHost}/${product.thumb_image}`}
          />
          <meta property="og:url" content={currentURL} />
          <meta property="og:type" content="product" />

          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${product.meta_title || product.name} | JOYREJOY`}
          />
          <meta
            name="twitter:description"
            content={
              product.meta_description ||
              polishedAsMetaDesc(product.description)
            }
          />
          <meta
            name="twitter:image"
            content={`${imgHost}/${product.thumb_image}`}
          />
        </Helmet>
      )}

      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div className="pageParentDiv">
          <div className="body-container">
            {isLoadingModel ? (
              <ProductDetailsSkeleton />
            ) : (
              <>
                {imageUrl && (
                  <>
                    {contextObj.params.showCartSidebar &&
                      showFeatures.cartSidebar && (
                        <div className="WebViewOnly">
                          <CartSidebar
                            fetchDataNow={fetchDataNow}
                            resetFetchDataNow={setFetchDataNow}
                          />
                        </div>
                      )}

                    <div className="ProductDetailsPageMainContainer">
                      <div className="productContainer">
                        <div className="productImgContainer">
                          <div className="prodImageVariantHolder">
                            <div className="prodImagesHolder">
                              <div
                                className={` ${
                                  defaultStock.quantity <= 0 && "disabledBtn"
                                } buyerProductDetails-imgList`}
                              >
                                {productImgs.map((img, index) => (
                                  <div
                                    className={` ${
                                      defaultStock.quantity <= 0 &&
                                      "disabledBtn"
                                    } buyerProductDetails-imgListOptions ${
                                      (selectedImage === img ||
                                        focusImg === img) &&
                                      !showVedio
                                        ? "highlightedImage"
                                        : ""
                                    }`}
                                    key={index}
                                    onMouseEnter={() => {
                                      setfocusImg(img);
                                      setSelectedImage(img);
                                      setShowVedio(false);
                                    }}
                                  >
                                    <LazyImage
                                      className="productsImage"
                                      src={imgHost + "/" + img}
                                      alt="Example image"
                                    />
                                  </div>
                                ))}

                                {product.video_url && (
                                  <div
                                    className={`buyerProductDetails-imgListOptions ${
                                      showVedio ? "highlightedImage" : ""
                                    }`}
                                    onMouseEnter={() => {
                                      setSelectedImage("");
                                      setShowVedio(true);
                                    }}
                                    style={{
                                      backgroundImage: `url('${imgHost}/${productImgs[0]}')`,
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setShowVedio(true);
                                    }}
                                  >
                                    <div className="playVedioImageContainer">
                                      <div className="playVedioPlayerBtn">
                                        <FontAwesomeIcon
                                          icon={faPlay}
                                          style={{
                                            color: "#ffffff",
                                            height: "20px",
                                          }}
                                          fade
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <>
                                  <svg
                                    className="upsideDownArrowButton"
                                    width="80"
                                    height="24"
                                    viewBox="0 0 80 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={handleButtonClick}
                                  >
                                    <path
                                      d="M75 0C77.7614 0 80 2.23858 80 5V24L0 24V5C0 2.23858 2.23858 0 5 0L75 0Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M40 14.3641L46.2225 8L48 9.81795L40 18L32 9.81795L33.7775 8L40 14.3641Z"
                                      fill="#808080"
                                    />
                                  </svg>
                                </>
                              </div>

                              {showVedio ? (
                                <div className="react-player">
                                  <ReactPlayer
                                    className="reactVideoPlayer"
                                    controls={true}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    url={product.video_url}
                                  />
                                  <div className="PlayVedioSpan">
                                    <div
                                      className="playVideoButton"
                                      onClick={() => {
                                        setShowVedio(false);
                                      }}
                                    >
                                      Close
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="buyerProductDetails-imgDiv">
                                  {defaultStock.quantity <= 0 && (
                                    <span className="verified-ribbon">
                                      Out of Stock
                                    </span>
                                  )}
                                  {showFeatures.showWishList &&
                                    defaultStock.quantity >= 1 && (
                                      <div
                                        className="joyrejoyWishlistIcon"
                                        id="ProdDetailsAddToWishList"
                                        onClick={() => {
                                          if (
                                            defaultStock.is_in_wishlist !== 1 &&
                                            defaultStock.quantity >= 1
                                          ) {
                                            handleAddToWishlist();
                                          }
                                        }}
                                      >
                                        {defaultStock.is_in_wishlist == 0 ||
                                        defaultStock.is_in_wishlist ==
                                          undefined ? (
                                          <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              width="40"
                                              height="40"
                                              rx="20"
                                              fill="white"
                                            />
                                            <path
                                              d="M11 16.8231C11 12.8932 14.0227 12 15.75 12C17.9091 12 19.6364 13.7863 20.5 15.1261C21.3636 13.7863 23.0909 12 25.25 12C26.9773 12 30 12.8932 30 16.8231C30 22.7179 20.5 28.0769 20.5 28.0769C20.5 28.0769 11 22.7179 11 16.8231Z"
                                              stroke="#EA5E3D"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              width="40"
                                              height="40"
                                              rx="20"
                                              fill="white"
                                            />
                                            <path
                                              d="M11 16.8231C11 12.8932 14.0227 12 15.75 12C17.9091 12 19.6364 13.7863 20.5 15.1261C21.3636 13.7863 23.0909 12 25.25 12C26.9773 12 30 12.8932 30 16.8231C30 22.7179 20.5 28.0769 20.5 28.0769C20.5 28.0769 11 22.7179 11 16.8231Z"
                                              fill="#EA5E3D"
                                            />
                                          </svg>
                                        )}
                                      </div>
                                    )}
                                  <div
                                    className={` ${
                                      defaultStock.quantity <= 0 &&
                                      "disabledBtn"
                                    } productDetailsPageImgContainer`}
                                    id="productDetailsPageImgContainer"
                                  >
                                    <label
                                      className="prod-condition-ribbon"
                                      style={{
                                        backgroundColor: getBackgroundColor(
                                          product.condition
                                        ),
                                        "--border-color": getBorderColor(
                                          product.condition
                                        ),
                                      }}
                                    >
                                      {product.condition.toUpperCase()}
                                    </label>
                                    <LazyImage
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "contain",
                                        borderRadius: "10px",
                                      }}
                                      src={`${imgHost}/${focusImg}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* for mobile view */}
                        <div className="mobileViewImages">
                          {showVedio === true ? (
                            <div className="react-player">
                              <ReactPlayer
                                className="reactVideoPlayer"
                                controls={true}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                url={product.video_url}
                              />

                              <div className="PlayVedioSpan">
                                <div
                                  className="playVideoButton"
                                  onClick={() => {
                                    setShowVedio(false);
                                  }}
                                >
                                  Close
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {mobileViewImage.length >= 0 && (
                                <>
                                  <div>
                                    {showFeatures.showWishList &&
                                      defaultStock.quantity >= 1 && (
                                        <div className="WishlistAndShareIconDiv">
                                          <div
                                            id="ProdDetailsAddToWishList"
                                            className="joyrejoyWishlistIcon"
                                            onClick={() => {
                                              if (
                                                defaultStock.is_in_wishlist !==
                                                  1 &&
                                                defaultStock.quantity >= 1
                                              ) {
                                                handleAddToWishlist();
                                              }
                                            }}
                                          >
                                            {defaultStock.is_in_wishlist == 0 ||
                                            defaultStock.is_in_wishlist ==
                                              undefined ? (
                                              <svg
                                                width="40"
                                                height="40"
                                                viewBox="0 0 40 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  width="40"
                                                  height="40"
                                                  rx="20"
                                                  fill="white"
                                                />
                                                <path
                                                  d="M11 16.8231C11 12.8932 14.0227 12 15.75 12C17.9091 12 19.6364 13.7863 20.5 15.1261C21.3636 13.7863 23.0909 12 25.25 12C26.9773 12 30 12.8932 30 16.8231C30 22.7179 20.5 28.0769 20.5 28.0769C20.5 28.0769 11 22.7179 11 16.8231Z"
                                                  stroke="#EA5E3D"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                width="40"
                                                height="40"
                                                viewBox="0 0 40 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  width="40"
                                                  height="40"
                                                  rx="20"
                                                  fill="white"
                                                />
                                                <path
                                                  d="M11 16.8231C11 12.8932 14.0227 12 15.75 12C17.9091 12 19.6364 13.7863 20.5 15.1261C21.3636 13.7863 23.0909 12 25.25 12C26.9773 12 30 12.8932 30 16.8231C30 22.7179 20.5 28.0769 20.5 28.0769C20.5 28.0769 11 22.7179 11 16.8231Z"
                                                  fill="#EA5E3D"
                                                />
                                              </svg>
                                            )}
                                          </div>
                                          <>
                                            {showFeatures.shareIconButton && (
                                              <div className="shareIconDivMobile">
                                                <svg
                                                  width="17"
                                                  height="17"
                                                  viewBox="0 0 17 17"
                                                  fill="none"
                                                  className={`OptionsForShare ${
                                                    isDropdownOpenForMobile
                                                      ? "open"
                                                      : ""
                                                  }`}
                                                  onClick={shareDataForMobile}
                                                  ref={dropdownRef}
                                                >
                                                  <path
                                                    d="M5.73656 9.61825C5.90513 9.28175 6 8.902 6 8.5C6 8.098 5.90513 7.71825 5.73656 7.38175M5.73656 9.61825C5.32612 10.4376 4.47874 11 3.5 11C2.11929 11 1 9.88075 1 8.5C1 7.11925 2.11929 6 3.5 6C4.47874 6 5.32612 6.56243 5.73656 7.38175M5.73656 9.61825L11.2634 12.3817M5.73656 7.38175L11.2634 4.61827M11.2634 12.3817C11.0948 12.7182 11 13.098 11 13.5C11 14.8807 12.1192 16 13.5 16C14.8807 16 16 14.8807 16 13.5C16 12.1192 14.8807 11 13.5 11C12.5212 11 11.6739 11.5624 11.2634 12.3817ZM11.2634 4.61827C11.6739 5.43757 12.5212 6 13.5 6C14.8807 6 16 4.88071 16 3.5C16 2.11929 14.8807 1 13.5 1C12.1192 1 11 2.11929 11 3.5C11 3.90197 11.0948 4.28179 11.2634 4.61827Z"
                                                    stroke="#EA5E3D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                            )}
                                          </>
                                        </div>
                                      )}
                                  </div>
                                  {/* mobile view div will appear */}
                                  <div>
                                    {showFeatures.shareIconButton && (
                                      <div className="shareIconForUserForMobileContainer">
                                        <div
                                          className="shareIconForUserForMobile"
                                          onClick={shareDataForMobile}
                                          style={{
                                            visibility: isDropdownOpenForMobile
                                              ? "visible"
                                              : "hidden",
                                          }}
                                          ref={dropdownRef}
                                        >
                                          <div id="SocialMediaButtonDiv">
                                            <FacebookShareButton
                                              className="IconButtonInlineWithText"
                                              url={shareUrl}
                                              quote={title}
                                            >
                                              <FontAwesomeIcon
                                                className="facebookIcon"
                                                icon={faFacebook}
                                              />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                              className="IconButtonInlineWithText"
                                              url={shareUrl}
                                              title={title}
                                            >
                                              <FontAwesomeIcon
                                                className="twitterIcon"
                                                icon={faSquareXTwitter}
                                              />
                                            </TwitterShareButton>

                                            <button
                                              className="IconContainerButton"
                                              onClick={handleWhatsAppShare}
                                            >
                                              <FontAwesomeIcon
                                                className="whatsappIcon"
                                                icon={faWhatsapp}
                                              />
                                            </button>
                                            <EmailShareButton
                                              className="IconButtonInlineWithText"
                                              url={shareUrl}
                                              subject={title}
                                              body={`Check out this product:\n\nTitle: ${title}\nCondition: ${condition}\nImage: ${image}\nPrice: ₹ ${price}`}
                                            >
                                              <FontAwesomeIcon
                                                className="GmailIcon"
                                                icon={faEnvelope}
                                              />
                                            </EmailShareButton>

                                            <CopyToClipboard
                                              className="IconButtonInlineWithText"
                                              text={window.location.href}
                                              style={{
                                                height: "1px",
                                                border: "none",
                                                background: "none",
                                              }}
                                            >
                                              <button className="insideLinkButton">
                                                <FontAwesomeIcon
                                                  className="copyIcon"
                                                  icon={faCopy}
                                                />
                                              </button>
                                            </CopyToClipboard>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    ref={containerRef}
                                    onTouchStart={handleTouchStart}
                                    onTouchEnd={handleTouchEnd}
                                    class="imageSliderContainer"
                                  >
                                    {defaultStock.quantity <= 0 && (
                                      <span className="verified-ribbon">
                                        Out of Stock
                                      </span>
                                    )}
                                    <div
                                      className={` ${
                                        defaultStock.quantity <= 0 &&
                                        "disabledBtn"
                                      } imagesSliderImageContainer`}
                                    >
                                      {mobileViewImage
                                        .slice(
                                          startIndexForMobile,
                                          startIndexForMobile + 1
                                        )
                                        .map((imageUrl, index) => (
                                          <LazyImage
                                            className={
                                              showFeatures.shareIconButton
                                                ? "imageSliderWithTopMargin"
                                                : "imageSliderWithoutTopMargin"
                                            }
                                            key={index}
                                            src={imgHost + "/" + imageUrl}
                                            alt={`Image ${index}`}
                                          />
                                        ))}
                                      {product.video_url && (
                                        <div
                                          className="playVedioImageContainer"
                                          onClick={() => {
                                            setShowVedio(true);
                                          }}
                                        >
                                          <div className="playVedioPlayerBtn">
                                            <FontAwesomeIcon
                                              icon={faPlay}
                                              style={{
                                                color: "#ffffff",
                                                height: "20px",
                                              }}
                                              fade
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="dotContainer">
                                      {mobileViewImage.map((_, index) => (
                                        <div
                                          key={index}
                                          className={`dot ${
                                            index === startIndexForMobile
                                              ? "activeDot"
                                              : ""
                                          }`}
                                        ></div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {/* logic ends above */}

                        <div className="productDetails">
                          <div className="ProductDetailsInnerContainer">
                            <div
                              className={` ${
                                defaultStock.quantity <= 0 && "disabledBtn"
                              } shareIconContainer`}
                            >
                              <>
                                {showFeatures.shareIconButton && (
                                  <div className="shareIconDiv">
                                    <svg
                                      width="17"
                                      height="17"
                                      viewBox="0 0 17 17"
                                      fill="none"
                                      className={` ${
                                        defaultStock.quantity <= 0 &&
                                        "disabledBtn"
                                      } OptionsForShare ${
                                        isDropdownOpen ? "open" : ""
                                      }`}
                                      onClick={handleShareIconClick}
                                      ref={dropdownRef}
                                    >
                                      <path
                                        d="M5.73656 9.61825C5.90513 9.28175 6 8.902 6 8.5C6 8.098 5.90513 7.71825 5.73656 7.38175M5.73656 9.61825C5.32612 10.4376 4.47874 11 3.5 11C2.11929 11 1 9.88075 1 8.5C1 7.11925 2.11929 6 3.5 6C4.47874 6 5.32612 6.56243 5.73656 7.38175M5.73656 9.61825L11.2634 12.3817M5.73656 7.38175L11.2634 4.61827M11.2634 12.3817C11.0948 12.7182 11 13.098 11 13.5C11 14.8807 12.1192 16 13.5 16C14.8807 16 16 14.8807 16 13.5C16 12.1192 14.8807 11 13.5 11C12.5212 11 11.6739 11.5624 11.2634 12.3817ZM11.2634 4.61827C11.6739 5.43757 12.5212 6 13.5 6C14.8807 6 16 4.88071 16 3.5C16 2.11929 14.8807 1 13.5 1C12.1192 1 11 2.11929 11 3.5C11 3.90197 11.0948 4.28179 11.2634 4.61827Z"
                                        stroke="#4A5568"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>
                                )}
                              </>
                              <div className="shareIconForUserContainer">
                                {" "}
                                <div
                                  className="shareIconForUser"
                                  onClick={handleShareIconClick}
                                  style={{
                                    display: isDropdownOpen ? "flex" : "none",
                                  }}
                                  ref={dropdownRef}
                                >
                                  <div id="SocialMediaButtonDiv">
                                    <FacebookShareButton
                                      className="IconButtonInlineWithText"
                                      url={shareUrl}
                                      quote={title}
                                    >
                                      <FontAwesomeIcon
                                        className="facebookIcon"
                                        icon={faFacebook}
                                      />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                      className="IconButtonInlineWithText"
                                      url={shareUrl}
                                      title={title}
                                    >
                                      <FontAwesomeIcon
                                        className="twitterIcon"
                                        icon={faSquareXTwitter}
                                      />
                                    </TwitterShareButton>
                                    <button
                                      className="IconContainerButton"
                                      onClick={handleWhatsAppShare}
                                    >
                                      <FontAwesomeIcon
                                        className="whatsappIcon"
                                        icon={faWhatsapp}
                                      />
                                    </button>

                                    <EmailShareButton
                                      className="IconButtonInlineWithText"
                                      url={shareUrl}
                                      subject={title}
                                      body={`Check out this product:\n\nTitle: ${title}\nCondition: ${condition}\nImage: ${image}\nPrice: ₹ ${price}`}
                                    >
                                      <FontAwesomeIcon
                                        className="GmailIcon"
                                        icon={faEnvelope}
                                      />
                                    </EmailShareButton>

                                    <CopyToClipboard
                                      className="IconButtonInlineWithText"
                                      text={window.location.href}
                                      style={{
                                        height: "1px",
                                        border: "none",
                                        background: "none",
                                      }}
                                    >
                                      <button className="insideLinkButton">
                                        <FontAwesomeIcon
                                          className="copyIcon"
                                          icon={faCopy}
                                        />
                                      </button>
                                    </CopyToClipboard>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="allProductsDescriptionDiv">
                              <div className="product-slug-url">
                                <span
                                  onClick={() => {
                                    navigate(navigation.navigateToHomepage);
                                  }}
                                >
                                  Home
                                </span>
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  style={{
                                    height: "12px",
                                    margin: "0px 7px",
                                  }}
                                />
                                <span
                                  onClick={() => {
                                    navigate(navigation.navigateToProductList);
                                  }}
                                >
                                  Products
                                </span>
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  style={{ height: "12px", margin: "0px 7px" }}
                                />
                                {product.name}
                              </div>
                              <h3 className="productNameHeading" id="title">
                                {product.name}
                              </h3>

                              <p
                                className="ProductBrandName"
                                id="ProductBrandName"
                              >
                                {product.category.name}
                              </p>
                              <div className="" id="">
                                <div className="productDetailsPriceName">
                                  <div>
                                    <p className="sellingPrice" id="title">
                                      <b className="sellingPriceHeading11"></b>{" "}
                                      ₹{" "}
                                      {formatNumberWithCommas(
                                        defaultStock.selling_price
                                      )}{" "}
                                    </p>
                                  </div>
                                  <div>
                                    <div className="sellingPrice">
                                      {calculateOfferPercentage(
                                        defaultStock.current_market_price,
                                        defaultStock.selling_price
                                      ) > 0 && (
                                        <span className="currentPriceSpanTag">
                                          {calculateOfferPercentage(
                                            defaultStock.current_market_price,
                                            defaultStock.selling_price
                                          )}
                                          % off
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p id="taxIncludedMsg">
                                    (Est. Original Price:{" "}
                                    <span className="OriginalPriceSpanTagProductList">
                                      ₹
                                      {formatNumberWithCommas(
                                        defaultStock.current_market_price
                                      )}
                                    </span>
                                    )
                                  </p>
                                </div>
                              </div>
                              {product.applicable_age_group &&
                                product.applicable_age_group.title && (
                                  <div className="ageGroupDiv">
                                    <p className="ageText" id="desc">
                                      Preferable Age Group:{" "}
                                      <span id="sellingPriceHeading3">
                                        {product.applicable_age_group.title}
                                      </span>
                                    </p>
                                  </div>
                                )}

                              <div className="prodVariantsList">
                                {variantsList &&
                                  variantsList.map((variant) => {
                                    return (
                                      <div className="prodVariantContainer">
                                        <p className="variantTitle">
                                          {capitalizeFirstChar(
                                            variant.variantName
                                          )}
                                        </p>
                                        <div className="variantValuesContainer">
                                          {variant.variantValues &&
                                            variant.variantValues.map(
                                              (variantData) => {
                                                return (
                                                  <>
                                                    {variant.variantName ==
                                                      "color" ||
                                                    variant.variantName ==
                                                      "material" ? (
                                                      <div
                                                        className="variantValueImgTxtContainer"
                                                        id={
                                                          variantData.checked ==
                                                            true &&
                                                          "selectedVariant"
                                                        }
                                                        onClick={() => {
                                                          changeSelectedVariant(
                                                            variantData
                                                          );
                                                        }}
                                                      >
                                                        <div className="variantValueImgContainer">
                                                          <img
                                                            className="variantValueImg"
                                                            src={
                                                              imgHost +
                                                              "/" +
                                                              variantData.thumb_image
                                                            }
                                                            alt=""
                                                          />
                                                        </div>

                                                        <p
                                                          className=""
                                                          style={{
                                                            margin: "0px",
                                                            fontSize: "12px",
                                                            // color:
                                                            //   variant.variantName ==
                                                            //     "color" &&
                                                            //   variantData.variantValue.split(
                                                            //     "#"
                                                            //   )[1] &&
                                                            //   `#${
                                                            //     variantData.variantValue.split(
                                                            //       "#"
                                                            //     )[1]
                                                            //   }`,
                                                          }}
                                                        >
                                                          {variant.variantName ==
                                                          "color"
                                                            ? variantData.variantValue.split(
                                                                "#"
                                                              )[0]
                                                            : variantData.variantValue}
                                                        </p>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        <p
                                                          className="variantValueTxt"
                                                          id={
                                                            variantData.checked ==
                                                              true &&
                                                            "selectedVariant"
                                                          }
                                                          onClick={() => {
                                                            changeSelectedVariant(
                                                              variantData
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            variantData.variantValue
                                                          }
                                                        </p>
                                                      </>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>

                              <div className="ButtonDivProductDetails">
                                <span className="wislistButtonSpanTag">
                                  <button
                                    className={` ${
                                      defaultStock.quantity <= 0 &&
                                      "disabledBtn"
                                    } addToWishListButton`}
                                    id="ProdDetailsAddToCart"
                                    onClick={() => {
                                      if (defaultStock.quantity >= 1) {
                                        handleAddToCart();
                                      }
                                    }}
                                  >
                                    ADD TO CART
                                  </button>
                                </span>

                                <span className="buyNowButtonSpanTag">
                                  {showFeatures.buyItNowButton && (
                                    <button
                                      className={` ${
                                        defaultStock.quantity <= 0 &&
                                        "disabledBtn"
                                      } addToCartButton`}
                                      id="ProdDetailsBuyNow"
                                      onClick={() => {
                                        if (defaultStock.quantity >= 1) {
                                          const checkLoggedIn =
                                            getDataFromCookies("isLoggedIn");
                                          if (checkLoggedIn !== true) {
                                            contextObj.setInput(
                                              "actionAfterLogin",
                                              "buyItNow"
                                            );
                                            contextObj.setInput(
                                              "productId",
                                              defaultStock.id
                                            ); // order to be placed for stock id
                                            contextObj.setInput(
                                              "showLoginModel",
                                              true
                                            );
                                          } else {
                                            contextObj.setInput(
                                              "checkoutProducts",
                                              [defaultStock.id]
                                            ); // order to be placed for stock id
                                            contextObj.setInput(
                                              "navigation",
                                              "true"
                                            );
                                            contextObj.setInput(
                                              "navigationFrom",
                                              "productDetails"
                                            );
                                            navigate("/checkout");

                                            // Order can be placed for only those products where quantity of stock is greate than 0
                                          }
                                        }
                                      }}
                                    >
                                      BUY IT NOW
                                    </button>
                                  )}
                                </span>
                              </div>
                              {sellerDetails && (
                                <div className="sellerDetails_Container">
                                  <div className="generalSellerDetails">
                                    <p
                                      className=""
                                      onClick={async () => {
                                        navigate(
                                          `/shop/${sellerDetails.shop_url}`
                                        );
                                      }}
                                    >
                                      Sold By :{" "}
                                      <span className="productDetailsSellerShopName">
                                        {sellerDetails.shop_name}
                                      </span>{" "}
                                    </p>
                                    {/* <p
                                      onClick={() => {
                                        setShowsellerDetails(true);
                                      }}
                                      style={{
                                        
                                        color: "#ea5e3d",
                                      }}
                                    >
                                      Show Seller Details
                                    </p> */}
                                  </div>
                                  {showsellerDetails && (
                                    <div
                                      className="productDetailsSellerDetails_ModalContainer"
                                      onClick={() => {
                                        setShowsellerDetails(false);
                                      }}
                                    >
                                      <div
                                        className="productDetailsSellerDetails_ModalContent"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        <div className="productDetailsSellerDetails ">
                                          <div className="close_productDetailsSellerDetails">
                                            <FontAwesomeIcon
                                              icon={faTimes}
                                              color="#b8b8b8"
                                              style={{
                                                height: "22px",
                                                margin: "10px",
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                              }}
                                              onClick={() => {
                                                setShowsellerDetails(false);
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p className="">
                                              Sold By :{" "}
                                              <span className="productDetailsSellerShopName">
                                                {sellerDetails.shop_name}
                                              </span>{" "}
                                            </p>
                                          </div>

                                          <div className="visitStoreBtn">
                                            <p
                                              onClick={() => {
                                                navigate(
                                                  `/shop/${sellerDetails.shop_url}`
                                                );
                                              }}
                                              style={{
                                                borderBottom:
                                                  "2px solid #ea5e3d",
                                                cursor: "pointer",
                                              }}
                                            >
                                              Visit{" "}
                                              <span className="productDetailsSellerShopName">
                                                {sellerDetails.shop_name}
                                              </span>{" "}
                                              Store
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="joyrejoyLogoDiv">
                                <div className="joyrejoyLogoAndParaDiv">
                                  <img
                                    className="approveLogoImage"
                                    src={approve}
                                    onError={(e) => {
                                      e.target.src = placeHolderImage;
                                    }}
                                    alt="Approved"
                                  />
                                  <p className="joyrejoyLogoParagraph">
                                    JOYREJOY
                                    <br className="bordergap" /> Approved
                                  </p>
                                </div>
                                <div className="joyrejoyLogoAndParaDiv">
                                  <img
                                    className="approveLogoImage"
                                    src={EcoFriendly}
                                    onError={(e) => {
                                      e.target.src = placeHolderImage;
                                    }}
                                    alt="Approved"
                                  />
                                  <p className="joyrejoyLogoParagraph">
                                    Eco-Friendly
                                  </p>
                                </div>
                              </div>
                              <div className="productDescriptionDiv">
                                <p
                                  className="ProductDescriptionHeading"
                                  id="ProductDescriptionHeading"
                                >
                                  Product Description
                                </p>
                                <RichTextComponent
                                  description={product.description}
                                />
                                {/* <ReactQuill
                                  name="description"
                                  value={product.description}
                                  readOnly
                                  theme="snow"
                                  style={{ height: "fitContent" }}
                                  className="custom-react-quill"
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {hasMorePoints && (
                        <div>
                          <div className="productDescriptionDiv">
                            <p className="ProductDescriptionHeading">
                              More Points
                            </p>
                            <p className="ProductNameDescription01">
                              {product.morePoints}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Similar Products */}
                    <div className="bg-white">
                      <MemoizedSimilarProductsSection
                        currentProductCondition={currentProductCondition}
                        filterProductId={productId}
                        resetFetchDataNow={setFetchDataNow}
                      />
                    </div>

                    {/* Recently Products */}
                    <div className="bg-white">
                      <MemoizedRecentlyViewedProducts
                        filterProductId={productId}
                        resetFetchDataNow={setFetchDataNow}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ProductDetail;
