import React from "react";

const ProfileSkeleton = () => {
  return (
    <>
        <div className="profileContainerBox">
          <div className="basicUserInfoContainer">
            <div className="ProfileNameClass">
              <div className="profileSkeletonInputBoxes"></div>
              <div className="profileSkeletonInputBoxes"></div>
            </div>
            <div className="profilemobileupadtebuttoncontainer">
              <div className="UpdateProfileButton" id="SkeletonUpdateProfileButton"></div>
            </div>
          </div>
          <hr className="HrLineProfileinfoPage skeletonBorderLine" />
          <div  className="AccountDetailsDiv">
            <div className="ChildrensHeaginTag"></div>
            <div className="EmailMobileNoAddContainer">
              <div className="profileSkeletonInputBoxes"></div>
              <div className="profileSkeletonInputBoxes"></div>
            </div>
          </div>
          <hr className="HrLineProfileinfoPage skeletonBorderLine" />
          <div  className="ChildrenBirthDayDiv">
            <div className="ChildrensHeaginTag"></div>
            <div className="ChildAddBirthContainer skeletonBorderLine"></div>
          </div>
          <hr className="HrLineProfileinfoPage skeletonBorderLine" />
          <div  className="ContainerButtonforaccountandDeactivate">
            <div className="ReferAndEarnContainer skeletonBorderLine"></div>
            <div className="ChangePasswordContainer skeletonBorderLine"></div>
            <div className="DeactivateAccountContainer skeletonBorderLine"></div>
          </div>
        </div>
     
    </>
  );
};

export default ProfileSkeleton;
