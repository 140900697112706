

const setDataToCookies = (cookieName, cookieValue, accessPath) => {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 1 * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${encodeURIComponent(
    JSON.stringify(cookieValue)
  )}; expires=${expiryDate.toUTCString()}; path=${accessPath}`;
};

const removeDataFromCookie = (cookieName, accessPath) => {
  const expiryDate = new Date(0);
  document.cookie = `${cookieName}=; expires=${expiryDate.toUTCString()}; path=${accessPath}`;
};

const setDataToCookiesWithExpirationTime = (
  cookieName,
  cookieValue,
  expiryDate,
  accessPath
) => {
  document.cookie = `${cookieName}=${encodeURIComponent(
    JSON.stringify(cookieValue)
  )}; expires=${expiryDate.toUTCString()}; path=${accessPath}`;
};

const getDataFromCookies = (cookieName) => {
  const cookies = document.cookie.split("; ");
  let cookieValue = null;
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === cookieName) {
      cookieValue = decodeURIComponent(cookie[1]);
      break;
    }
  }
  let returnValue = null;
  if (cookieValue) {
    try {
      returnValue = JSON.parse(cookieValue);
    } catch (error) {
      console.error("Error parsing JSON from cookie:", error);
      
    }
  }
  return returnValue;
};

const setProductIdsArrayToCookies = (product_id) => {
  const cookieName = "t-a3%)/`$o";
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 365);
  const cookies = document.cookie.split("; ");
  let cookieValue = null;
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === cookieName) {
      cookieValue = decodeURIComponent(cookie[1]);
      break;
    }
  }
  let cookieArray = [];
  if (cookieValue) {
    try {
      cookieArray = JSON.parse(cookieValue);
    } catch (error) {
      console.error("Error parsing JSON from cookie:", error);
      // if (error.message.includes("Failed to fetch")) {
      //   navigate("/serviceUnavailable");
      // }
    }
  }
  const index = cookieArray.indexOf(product_id);
  if (index !== -1) {
    cookieArray.splice(index, 1);
  } else {
    if (cookieArray.length >= 30) {
      cookieArray.pop();
    }
  }
  cookieArray.unshift(product_id);
  document.cookie = `${cookieName}=${encodeURIComponent(
    JSON.stringify(cookieArray)
  )}; expires=${expiryDate.toUTCString()}; path=/`;
};

export {
  setDataToCookies,
  getDataFromCookies,
  setProductIdsArrayToCookies,
  setDataToCookiesWithExpirationTime,
  removeDataFromCookie,
};
