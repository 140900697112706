import React from "react";
import "./CheckoutPage.css";
const CheckoutPageRadioBtn = (props) => {
  return (
    <div
      className="CheckoutPageRadioBtnOuterDiv"
      style={{
        border: props.isChecked ? "2px solid #3366CC" : "2px solid #9B9B9B",
      }}
    >
      {props.isChecked && <div className="CheckoutPageRadioBtnInnerDiv"></div>}
    </div>
  );
};
export default CheckoutPageRadioBtn;
