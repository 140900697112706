import React from "react";

const BlogSkeletonCard = () => {
  return (
    <div className=" blogSkeletonCard">
      <div className="image-wrapper skeleton-box"></div>
      <h3 className="blogSkeleton-title skeleton-box"></h3>
      <p className="blogSkeleton-description skeleton-box"></p>
      <div className="blogSkeleton-read-more skeleton-box"></div>
    </div>
  );
};
export default BlogSkeletonCard;
