import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../config/Navigation.json";
import Endpoints from "../../config/APIEndpoints.json";
import ParamsContext from "../useContext/paramContext";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faUser } from "@fortawesome/free-regular-svg-icons";
import Description from "../Description/Description";
import BlogCard from "./BlogCard";
import Toast from "../ReactToastify/Toast";
import LazyImage from "../LazyImage/LazyImage";
import { Helmet } from "react-helmet";
import BlogDetailsPageSkeleton from "./BlogDetailsPageSkeleton";
import "./blog.css";

const API_HOST = process.env.REACT_APP_API_HOST;

const BlogDetailsPage = () => {
  const infra = process.env.REACT_APP_INFRA;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const { showToast } = Toast();
  const contextObj = useContext(ParamsContext);
  const [blog, setBlog] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // open blog details from related blog
  const openBlogDetails = (slug, id) => {
    sessionStorage.setItem("blogDetailsId", id);
    getBlogDetails(id);
  };

  // open blog details from next and previous
  const fetchBlogDetails = (id) => {
    getBlogDetails(id);
  };

  const getBlogDetails = async (blogId) => {
    window.scrollTo(0, 0);
    sessionStorage.setItem("blogDetailsId", blogId);
    setLoading(true);

    const response = await fetch(
      `${API_HOST}/${Endpoints.getBlogList}/${blogId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();

    if (res.status === 200) {
      setBlog(res.data);
    } else if (res.status >= 503) {
      if (res.status == 503) setMaintainanceMode(true);
      else contextObj.setInput("serviceUnavailable", true);
    }
    setLoading(false);
  };

  useEffect(() => {
    const blogId = sessionStorage.getItem("blogDetailsId");
    if (blogId) getBlogDetails(blogId);
    else {
      showToast("info", "Something went wrong");
      navigate("/blogs");
    }
  }, []);

  const getFormatedDate = (value) => {
    const date = new Date(value);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleString(undefined, options);
    return formattedDate;
  };

  return (
    <>
      {infra == "production" && (
        <Helmet>
          {blog.meta_title && <title>{blog.meta_title}</title>}
          {blog.meta_description && (
            <meta name="description" content={blog.meta_description} />
          )}
          {blog.meta_keywords && (
            <meta name="keywords" content={blog.meta_keywords} />
          )}
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      <div className="pageParentDiv">
        <div className="body-container">
          <div className="blog-details-container">
            <div className="breadcrumb-area">
              <h1>Blog & News</h1>
              <ol className="breadcrumb">
                <li
                  className="breadcrumb-item"
                  onClick={() => {
                    navigate(navigation.navigateToHomepage);
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Home
                  </a>
                </li>
                <li
                  className="breadcrumb-item"
                  onClick={() => {
                    navigate(navigation.navigateToBlogs);
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Blogs
                  </a>
                </li>
                <li className="breadcrumb-item active">Details</li>
              </ol>
            </div>
            {isLoading && <BlogDetailsPageSkeleton />}
            {!isLoading && (
              <>
                <div className="blog-details-content">
                  <LazyImage
                    className="blog-details-thumbnail"
                    alt="thumbnail"
                    src={`${imgHost}/${blog.thumbnail}`}
                  />

                  <h1 className="blog-details-title">{blog.title}</h1>
                  <p className="blog-details-author-line">
                    <span>
                      <FontAwesomeIcon icon={faUser} className="author-icon" />
                      {blog.author}
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faClock} className="time-icon" />
                      {getFormatedDate(blog.created_at)}
                    </span>
                  </p>

                  <div className="blog-details-description">
                    <p style={{ color: "#757575" }}>{blog.short_description}</p>
                    <Description htmlContent={blog.description} />
                  </div>
                </div>

                {/* Blog navigation */}
                {(blog.prev || blog.next) && (
                  <div className="blog-details-navigation">
                    <hr />
                    <div className="navigation-links">
                      <div>
                        {blog.prev && (
                          <>
                            <p>PREVIOUS</p>
                            <p
                              className="navigation-title"
                              onClick={() => {
                                if (blog.prev) fetchBlogDetails(blog.prev.id);
                              }}
                            >
                              {blog.prev.title}
                            </p>
                          </>
                        )}
                      </div>
                      <div>
                        {blog.next && (
                          <>
                            <p>NEXT</p>
                            <p
                              className="navigation-title"
                              onClick={() => {
                                if (blog.next) fetchBlogDetails(blog.next.id);
                              }}
                            >
                              {blog.next.title}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                )}
              </>
            )}
          </div>
          {!isLoading && blog && blog.related_blogs && (
            <>
              {blog.related_blogs && (
                <h1 className="related-blogs-title">Related Post</h1>
              )}
              <div className="related-blogs">
                {blog.related_blogs && (
                  <>
                    {blog.related_blogs.map((blog, index) => (
                      <BlogCard
                        key={index}
                        blog={blog}
                        navigateToDetails={openBlogDetails}
                      />
                    ))}
                  </>
                )}
              </div>
              {blog.related_blogs && (
                <div className="blog-list-btn">
                  <button
                    type="button"
                    className="all-blogs-btn"
                    onClick={() => {
                      navigate(navigation.navigateToBlogs);
                    }}
                    onMouseDown={(e) => {
                      if (e.button == 1) {
                        e.preventDefault();
                        window.scrollTo(0, 0);
                        window.open(`${navigation.navigateToBlogs}`, "_blank");
                      }
                    }}
                  >
                    See All Blogs & News
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogDetailsPage;
