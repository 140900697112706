import React from "react";
import { useLocation } from "react-router-dom";
import PolicyComponent from "./policy/privacypolicy";
import { useState, useEffect, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import { Helmet } from "react-helmet";

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const infra = process.env.REACT_APP_INFRA;

const ReturnPolicy = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
    buyerPoliciesRetrievedviaApi: false,
  });
  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
        buyerPoliciesRetrievedviaApi:
          featureFlags.buyer_policies_retrieved_via_api,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;

  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const Name = "Return Policy";
  const PolicyId = 4;

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Your Return Ticket to Joy - JOYREJOY</title>
          <meta
            name="description"
            content="Our return policy at JOYREJOY is your passport to happiness! Discover our easy and hassle-free return process with a sprinkle of joy. Let's ensure every purchase is a joyful experience!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {showFeatures.buyerPoliciesRetrievedviaApi ? (
        <PolicyComponent PolicyName={Name} policyId={PolicyId} />
      ) : (
        <div
          className="pageParentDiv"
          style={{
            marginTop: contextObj.params.topNavbarHeight,
          }}
        >
          <div className="body-container">
            <div className="policy-container" style={conditionalStyle}>
              <ResourcesSidebar activeResource={"Return Policy"} />
              <div className="policy-content">
                <h1
                  className={`policy-content-heading ${
                    isSticky ? "sticky-heading" : ""
                  }`}
                >
                  Return Policy
                </h1>
                <p>
                  <span>
                    Please get in touch with us within 48 hours of receiving the
                    goods (along with a picture of the damaged item) if you
                    receive one that is damaged when it is delivered. We'll work
                    out a solution that works for both of us. We are a
                    trustworthy group, and our main goal is to provide you with
                    the best service possible.
                  </span>
                </p>
                <p>
                  <span>
                    We apologize that we are unable to allow refunds just
                    because you changed your mind because we want to keep these
                    previously owned, PreHugged things cheap for everyone. We
                    hope you'll comprehend.
                  </span>
                  <span>
                    There may be pieces missing because these are previously
                    owned, PreHugged items. All of these specifics are
                    highlighted in the product description. Prior to placing
                    your order, kindly read it thoroughly.
                  </span>
                </p>
                <p>
                  <span>
                    Contact us at{" "}
                    <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a> if you
                    have any questions.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReturnPolicy;
