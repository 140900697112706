import React, { useEffect, useState, useRef, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import { useNavigate } from "react-router-dom";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode } from "swiper/modules";
import Navigate from "../../config/Navigation.json";
import { getAllCategoriesData } from "../../resuableFunctions/resuableFunctions";
import "swiper/css";
import "swiper/css/pagination";
import "./home.css";
import SkeletonComponent from "../skeleton/Skeleton";
import ShopByCategoryElement from "./shopByCategoryElement";
const ShopByCategory = (props) => {
  const queryParams = new URLSearchParams(location.search);
  let filterParamsUpdated = [
    {
      columnName: "brand_id",
      values: [],
    },
    {
      columnName: "category_id",
      values: [],
    },
    {
      columnName: "age_group",
      values: [],
    },
    {
      columnName: "condition",
      values: [],
    },
    {
      columnName: "price_range",
      values: [],
    },
    {
      columnName: "sort",
      values: [],
    },
    {
      columnName: "selected_category_id",
      value: [],
    },
  ];
  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    buyer_homepage_categoryview: true,
  });
  function frameQueryString() {
    let queryString = "";
    let filterCount = 0;
    let tempArray = [];

    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (
        filterParamsUpdated[i].values.length != 0 &&
        filterParamsUpdated[i].columnName != "sort"
      ) {
        tempArray = filterParamsUpdated[i].values;
        if (tempArray.length > 0) {
          for (let j = 0; j < tempArray.length; j++) {
            if (
              filterParamsUpdated[i].columnName == "price_range" ||
              filterParamsUpdated[i].columnName == "age_group" ||
              filterParamsUpdated[i].columnName == "brand_id" ||
              filterParamsUpdated[i].columnName == "condition" ||
              filterParamsUpdated[i].columnName == "category_id" ||
              filterParamsUpdated[i].columnName == "selected_category_id"
            ) {
              {
                queryString +=
                  j == 0
                    ? `&${filterParamsUpdated[i].columnName}=${tempArray[j]}`
                    : `,${tempArray[j]}`;
              }
              if (tempArray[j] !== "") {
                filterCount++;
              }
            }
          }
        }
      }
    }

    const sortColumnIndex = getIndexOfColumn("sort");

    // if (showFeatures.showSortBy) {
    if (filterParamsUpdated[sortColumnIndex].values[0]) {
      queryString += filterParamsUpdated[sortColumnIndex].values[0];
    }
    // }
    if (queryString) {
      const base64EncodedQuery = btoa(queryString);
      navigate(`/products?query=${base64EncodedQuery}`);
    } else {
      navigate(`/products`);
    }
  }

  function pushToArray(columnName, value) {
    // Ensure that updateFilterArray returns a Promise
    return updateFilterArray()
      .then(() => {
        const filterElement = filterParamsUpdated.find(
          (element) => element.columnName === columnName
        );

        if (columnName == "selected_category_id") {
          filterElement.values = [];
          filterElement.values.push(value);
          return;
        }

        if (filterElement && !filterElement.values.includes(value)) {
          filterElement.values.push(value);
        }
      })
      .catch((error) => {
        console.error(
          "An error occurred while updating the filter array:",
          error
        );
      });
  }

  function getIndexOfColumn(columnName) {
    let columnIndex = -1;
    for (let i = 0; i <= filterParamsUpdated.length; i++) {
      if (filterParamsUpdated[i].columnName === columnName) {
        columnIndex = i;
        break;
      }
    }
    return columnIndex;
  }
  function processQuery(arr) {
    const result = {};
    let orderSort = "";
    let valueSort = "";
    arr.forEach((item) => {
      if (item) {
        // Check if the string is not empty
        const [key, value] = item.split("="); // Split by '='
        if (key !== "sort" && key !== "order") {
          result[key] = value ? value.split(",") : "";
        } // Assign key and value to the result object

        if (key == "order") {
          orderSort = value;
        }
        if (key == "sort") {
          valueSort = value;
        }
      }
    });

    if (orderSort && valueSort) {
      const indexOfSortColumn = getIndexOfColumn("sort");
      filterParamsUpdated[indexOfSortColumn].values = [];
      if (indexOfSortColumn >= 0) {
        if (orderSort == "asc") {
          if (valueSort == "selling_price") {
            setSelectedOption((prev) => ({
              ...prev,
              name: "Low-Priced First",
              value: "&order=asc&sort=selling_price",
            }));

            result.sort = ["&order=asc&sort=selling_price"];
          } else {
            setSelectedOption((prev) => ({
              ...prev,
              name: "Oldest First",
              value: "&order=asc&sort=created_at",
            }));

            result.sort = ["&order=asc&sort=created_at"];
          }
        } else {
          if (valueSort == "selling_price") {
            setSelectedOption((prev) => ({
              ...prev,
              name: "High-Priced First",
              value: "&order=desc&sort=selling_price",
            }));

            result.sort = ["&order=desc&sort=selling_price"];
          } else {
            setSelectedOption((prev) => ({
              ...prev,
              name: "Newest First",
              value: "&order=desc&sort=created_at",
            }));

            result.sort = ["&order=desc&sort=created_at"];
          }
        }
      }
    }
    return result;
  }
  const navigateToParentCategory = async (categories, parentCategoryId) => {
    const filterElement = filterParamsUpdated.find(
      (element) => element.columnName === "category_id"
    );
    if (filterElement) {
      filterElement.values = [];
      await pushToArray("category_id", parentCategoryId);
    }
    Promise.all(
      categories.map(async (category) => {
        await pushToArray("category_id", category.id);
      })
    ).then(() => {
      frameQueryString();
    });
  };
  const navigateToCategory = async (category) => {
    const filterElement = filterParamsUpdated.find(
      (element) => element.columnName === "category_id"
    );
    if (filterElement) {
      filterElement.values = [];
    }
    pushToArray("selected_category_id", category.id);
    pushToArray("category_id", category.id)
      .then(() => {
        frameQueryString();
      })
      .catch((error) => {
        console.error("An error occurred while pushing to the array:", error);
      });
  };
  const updateFilterArray = () => {
    return new Promise((resolve, reject) => {
      try {
        if (queryParams) {
          const queryString = atob(queryParams.get("query")).split("&");

          if (queryString) {
            const queryObj = processQuery(queryString);

            filterParamsUpdated = [
              { columnName: "brand_id", values: [] },
              { columnName: "category_id", values: [] },
              { columnName: "age_group", values: [] },
              { columnName: "condition", values: [] },
              { columnName: "price_range", values: [] },
              { columnName: "sort", values: [] },
              {
                columnName: "selected_category_id",
                values: [],
              },
            ];

            filterParamsUpdated = filterParamsUpdated.map((element) => {
              const value = queryObj?.[element.columnName];

              if (
                element.columnName !== "category_id" &&
                element.columnName !== "selected_category_id" &&
                value &&
                value.length > 0
              ) {
                element = { ...element, values: value };
              } else {
                element = { ...element, values: [] };
              }

              return element;
            });
          }
        }

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
  useEffect(() => {
    let featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showCategoryFilter: featureFlags.buyer_products_categories,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const ShopByCategoryRef = useRef(null);

  const [categoriesData, setCategoriesData] = useState([]);
  const fetchAllCategoriesData = async () => {
    let categoryInSession = sessionStorage.getItem("parentCategoryListData");
    if (categoryInSession == null || categoryInSession == undefined) {
      let serviceUnavailable = await getAllCategoriesData();
      if (serviceUnavailable) {
        contextObj.setInput("serviceUnavailable", true);
        return;
      }
      categoryInSession = sessionStorage.getItem("parentCategoryListData");
      if (categoryInSession) {
        let categoryData = JSON.parse(categoryInSession);
        if (categoryData.data) {
          setCategoriesData(categoryData.data);
        }
      }
    } else {
      if (categoryInSession) {
        let categoryData = JSON.parse(categoryInSession);
        if (categoryData.data) {
          setCategoriesData(categoryData.data);
        }
      }
    }
  };

  useEffect(() => {
    let categoryInSession = sessionStorage.getItem("parentCategoryListData");

    if (categoryInSession == null || categoryInSession == undefined) {
      setTimeout(() => {
        fetchAllCategoriesData(); // Recursive call
      }, 2000);
    } else {
      let categoryData = JSON.parse(categoryInSession);

      setCategoriesData(categoryData.data);
    }
  }, [contextObj.params.featureFlags]);
  const [loadingCategory, setLoadingCategory] = useState(true);

  return (
    <>
      {showFeatures.showCategoryFilter && (
        <div className="category-collection" ref={ShopByCategoryRef}>
          {loadingCategory && categoriesData.length == 0 && (
            <SkeletonComponent className="SkeletonSection categorySkeleton" />
          )}
          {categoriesData.length > 0 && (
            <div className="shop-by-category">
              <span>
                {!loadingCategory && (
                  <h1 className="collection-heading category-heading">
                    Shop by Category
                  </h1>
                )}

                <SwiperComponent
                  className={
                    loadingCategory
                      ? "category_swiper loadingCategory_swiper"
                      : "category_swiper"
                  }
                  slidesPerView={1}
                  spaceBetween={20}
                  freeMode={true}
                  navigation={{ clickable: true }}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  scrollbar={{ draggable: true }}
                  modules={[Navigation, Pagination, FreeMode]}
                  breakpoints={{
                    220: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                      // loop: true,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                      // loop: true,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    425: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                      // loop: true,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    500: {
                      slidesPerView: 4,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    600: {
                      slidesPerView: 5,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    768: {
                      slidesPerView: 5,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  {categoriesData.map((category, index) => (
                    <SwiperSlide
                      key={index}
                      tabIndex={0}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        if (category.parent_category_id !== 0) {
                          navigateToParentCategory(
                            category.childCategory,
                            category.id
                          );
                        } else {
                          navigateToCategory(category);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          // Trigger action on Enter or Space key press
                          window.scrollTo(0, 0);
                          setSelectedCategoryId(item.id);
                          if (category.parent_category_id !== 0) {
                            sessionStorage.setItem(
                              "selectedCategoryId",
                              category.id
                            );
                            navigateToParentCategory(category.childCategory);
                          } else {
                            navigateToCategory(category);
                          }
                        }
                      }}
                      onMouseDown={(e) => {
                        if (e.button == 1) {
                          e.preventDefault();
                          window.scrollTo(0, 0);
                          setSelectedCategoryId(item.id);
                          if (category.parent_category_id !== 0) {
                            sessionStorage.setItem(
                              "selectedCategoryId",
                              category.id
                            );
                            navigateToParentCategory(category.childCategory);
                          } else {
                            navigateToCategory(category);
                          }
                        }
                      }}
                    >
                      <ShopByCategoryElement
                        category={category}
                        loadingCategory={setLoadingCategory}
                      />
                    </SwiperSlide>
                  ))}
                </SwiperComponent>
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShopByCategory;
