// This jsx element is not used anywhere in code

import React, { useContext } from "react";

import "./loginUpdatedUI.css";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ParamsContext from "../useContext/paramContext";

function ContinueAs({ handleCloseModal }) {
  const contextObj = useContext(ParamsContext);
  function continueAsGuest() {
    alert("Continue as guest.");
    const cartProductsData = {
      data: [],
    };
    sessionStorage.setItem("isGuestUser", true);
    sessionStorage.setItem("cartProducts", JSON.stringify(cartProductsData));
    window.history.back();
  }

  return (
    <>
      <div className="BuyerLoginPage-container">
        <div className="buyercross-container-div">
          <FontAwesomeIcon
            icon={faCircleXmark}
            style={{ color: "#44cac8" }}
            onClick={handleCloseModal}
          />
        </div>
        <div className="popupitem">
          <div className="BuyerLoginPage-innerBox">
            <h2 className="BuyerLoginPage-heading"> Have an account? </h2>
            <div className="BuyerLoginPage-footer">
              <button
                onClick={() => contextObj.setInput("showLoginModel", true)}
              >
                Login{" "}
              </button>
              {/* <p className="or-text">OR</p> */}
            </div>
          </div>
          <div className="BuyerLoginPage-innerBox">
            <h2 className="BuyerLoginPage-heading"> Checkout as guest?</h2>

            <div className="BuyerLoginPage-footer">
              <button
                className="BuyerLogin-logInWithGoogle"
                onClick={continueAsGuest}
              >
                Continue as guest
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContinueAs;
