import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import LazyImage from "../LazyImage/LazyImage";
import { imgHost as IMG_HOST } from "../../resuableFunctions/resuableFunctions";
import Endpoints from "../../config/APIEndpoints.json";
import "./home.css";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import SkeletonComponent from "../skeleton/Skeleton";
const API_HOST = process.env.REACT_APP_API_HOST;
const APP_NAME = process.env.REACT_APP_NAME;

const Testimonials = (props) => {
  const testimonialsRef = useRef(null);
  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  const [stories, setStories] = useState([]);
  let [loadingImg, setLoadingImg] = useState(true);

  const getAllTestimonials = async () => {
    try {
      const response = await fetch(
        `${API_HOST}/${Endpoints.sellerTestimonials}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (
        res.status >= 500 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        contextObj.setInput("serviceUnavailable", true);
      } else if (res.status == 200) {
        setStories(res.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  useEffect(() => {
    // const options = {
    //   root: null,
    //   rootMargin: "0px",
    //   threshold: 0.01,
    // };

    // const callback = (entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    getAllTestimonials();
    //       observer.unobserve(entry.target);
    //     }
    //   });
    // };

    // const observer = new IntersectionObserver(callback, options);

    // if (testimonialsRef.current) {
    //   observer.observe(testimonialsRef.current);
    // }

    // // Clean up
    // return () => {
    //   if (testimonialsRef.current) {
    //     observer.unobserve(testimonialsRef.current);
    //   }
    // };
  }, []);

  // Testimonial Autoplay
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMouseEnter = () => {
    if (swiperInstance) {
      swiperInstance.autoplay.stop();
    }
  };
  const handleMouseLeave = () => {
    if (swiperInstance) {
      swiperInstance.autoplay.start();
    }
  };
  const handleSwiperInit = (swiper) => {
    setSwiperInstance(swiper);
  };

  return (
    <div
      ref={testimonialsRef}
      className="testimonialContainer"
      id="testimonials"
    >
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <>
          {loadingImg && stories.length < 1 && (
            <SkeletonComponent className={"testimonialsSkeleton "} />
          )}
          {stories.length > 0 && (
            <div className="seller-story-container">
              <div className="story-heading">
                <h2>
                  <span style={{ color: "#EA5E3D" }}>Testimonials</span>
                </h2>
                <p>{APP_NAME} is trusted choice for many users</p>
              </div>
              <div className="story-slider">
                <SwiperComponent
                  onSwiper={handleSwiperInit}
                  className="category_swiper story-swiper"
                  slidesPerView={1}
                  spaceBetween={20}
                  freeMode={false}
                  grabCursor={true}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  scrollbar={{ draggable: true }}
                  modules={[Navigation, Pagination, FreeMode, Autoplay]}
                  navigation={{ clickable: true }}
                  breakpoints={{
                    280: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    425: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    600: {
                      slidesPerView: 1,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    768: {
                      slidesPerView: 2,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    1024: {
                      slidesPerView: 2,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    1440: {
                      slidesPerView: 3,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                  }}
                >
                  {stories &&
                    stories.map((story, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className="seller-story"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <p className="seller-story-line">{story.message}</p>
                          <div className="seller-story-image">
                            <LazyImage
                              src={`${IMG_HOST}/${story.image}`}
                              alt="image"
                              loadingImg={setLoadingImg}
                            />
                            <div>
                              <p className="seller-name">{story.name}</p>
                              {story.shop_name && (
                                <p className="seller-shop-name">
                                  {story.shop_name}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </SwiperComponent>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Testimonials;
