import React, { useEffect, useState, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import Endpoints from "../../config/APIEndpoints.json";
import { getDataFromCookies } from "../Cookies/Cookies";
import Swiper from "./Swiper";
import { useNavigate } from "react-router-dom";
import SkeletonComponent from "../skeleton/Skeleton";
const API_HOST = process.env.REACT_APP_API_HOST;

const RecentlyViewedProducts = (props) => {
  const navigate = useNavigate();
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    recentlyViewedProducts: true,
  });
  const [showRecent, setShowRecent] = useState(false);

  useEffect(() => {
    if (contextObj.params.isLoggedIn == true) {
      setShowRecent(true);
      getRecentlyViewedProductsData();
    } else {
      setRecentlyViewedProducts([]);
      let guestUserAcceptedCookie = getDataFromCookies(
        "guestUserAcceptedCookie"
      );
      if (guestUserAcceptedCookie) {
        getGuestRecentlyViewedProductsData();
      } else {
        setDataLoading(false);
        setShowRecent(false);
      }
    }

    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showWishList: featureFlags.buyer_products_wishlist,
        recentlyViewedProducts: featureFlags.buyer_common_recentlyvisiteditems,
      }));
    }
  }, [
    props.filterProductId,
    contextObj.params.featureFlags,
    contextObj.params.reFetchRecentlyViewProducts,
    contextObj.params.isLoggedIn,
  ]);

  function updateRecentlyViewedData(id) {
    const index = recentlyViewedProducts.findIndex(
      (product) => product.id === id
    );
    if (
      index !== -1 &&
      recentlyViewedProducts[index].is_in_wishlist === false
    ) {
      recentlyViewedProducts[index].is_in_wishlist = true;
    }
  }
  function updateRecentlyViewedDataRemove(id) {
    const index = recentlyViewedProducts.findIndex(
      (product) => product.id === id
    );
    if (index !== -1 && recentlyViewedProducts[index].is_in_wishlist === true) {
      recentlyViewedProducts[index].is_in_wishlist = false;
    }
  }
  useEffect(() => {
    if (contextObj.params.productAddedToWishlistId) {
      let productId = contextObj.params.productAddedToWishlistId;
      const timeoutId = setTimeout(() => {
        if (contextObj.params.productAddedToWishlistId !== "") {
          updateRecentlyViewedData(productId);
        }
      }, 10);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productAddedToWishlistId]);
  useEffect(() => {
    if (contextObj.params.productRemovedFromWishlistId) {
      let productToRemove = contextObj.params.productRemovedFromWishlistId;
      const timeoutId = setTimeout(() => {
        if (contextObj.params.productRemovedFromWishlistId !== "") {
          updateRecentlyViewedDataRemove(productToRemove);
        }
      }, 10);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productRemovedFromWishlistId]);
  useEffect(() => {
    if (contextObj.params.refetchAfterLoginAddWishList) {
      const token = getDataFromCookies("7H3K9R5P2Q");
      if (token) {
        getRecentlyViewedProductsData();
      } else {
        setDataLoading(true);
      }
      contextObj.setInput("refetchAfterLoginAddWishList", false);
    }
  }, [contextObj.params.refetchAfterLoginAddWishList]);
  const getRecentlyViewedProductsData = async () => {
    try {
      const token = getDataFromCookies("7H3K9R5P2Q");
      if (token) {
        const response = await fetch(
          `${API_HOST}/${Endpoints.getRecentlyViewedProducts}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok && response.status !== 404) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const res = await response.json();
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status == 404) {
          console.error("No products found in recently viewed items");
        }
        if (res.status == 200) {
          let productsData = res.data.products;

          if (props.filterProductId) {
            productsData = productsData.filter(
              (data) => data.id !== props.filterProductId
            );
          }
          setRecentlyViewedProducts(productsData);
        }
        setDataLoading(true);
      } else {
        setDataLoading(true);
      }
    } catch (error) {
      setDataLoading(true);
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  const getProductsFromCookies = () => {
    const cookieName = "t-a3%)/`$o";
    const cookies = document.cookie.split("; ");
    let cookieValue = null;
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === cookieName) {
        cookieValue = decodeURIComponent(cookie[1]);
        break;
      }
    }
    let cookieArray = [];
    if (cookieValue) {
      try {
        cookieArray = JSON.parse(cookieValue);
      } catch (error) {
        console.error("Error parsing JSON from cookie:", error);
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      }
    }
    return cookieArray;
  };

  const getGuestRecentlyViewedProductsData = async () => {
    try {
      const productsIdArray = getProductsFromCookies();
      if (
        productsIdArray !== undefined &&
        productsIdArray !== null &&
        productsIdArray.length < 1
      ) {
        setDataLoading(false);

        return;
      }
      const payload = {
        product_ids: productsIdArray,
      };

      const response = await fetch(
        `${API_HOST}/${Endpoints.getGuestRecentlyViewedProducts}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();

      if (
        res.status == 500 ||
        res.status == 501 ||
        res.status == 502 ||
        res.status == 503 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        if (res.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      let productsData = res.data.products;
      if (props.filterProductId) {
        productsData = productsData.filter(
          (data) => data.id !== props.filterProductId
        );
      }

      setRecentlyViewedProducts(productsData);
      setShowRecent(true);
      setDataLoading(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  return (
    <>
      {showRecent && (
        <>
          {dataLoading && showFeatures.recentlyViewedProducts && (
            <div
              className="recentlyViewedContainer"
              style={{
                padding: recentlyViewedProducts.length > 0 ? "20px 0px" : "0px",
              }}
            >
              <div className="collection-heading-row">
                {recentlyViewedProducts.length > 0 && (
                  <>
                    <h1 className="collection-heading">Recently Viewed</h1>
                    <div className="collection-hr" />
                  </>
                )}
              </div>
              <Swiper
                products={recentlyViewedProducts}
                resetFetchDataNow={props.resetFetchDataNow}
              />
            </div>
          )}

          {!dataLoading &&
            showFeatures.recentlyViewedProducts &&
            recentlyViewedProducts.length == 0 && (
              <SkeletonComponent className="SkeletonSection categorySkeleton" />
            )}
        </>
      )}
    </>
  );
};

export default RecentlyViewedProducts;
