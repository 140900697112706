import React from "react";
import { imgHost as IMG_HOST } from "../../resuableFunctions/resuableFunctions";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./blog.css";
import LazyImage from "../LazyImage/LazyImage";
const BlogCard = (props) => {
  const { blog, navigateToDetails, loadingImg } = props;

  return (
    <>
      {blog && (
        <div className="blog-card">
          <div
            className="image-wrapper"
            onClick={() => {
              navigateToDetails(blog.slug, blog.id);
            }}
          >
            <LazyImage
              src={`${IMG_HOST}/${blog.thumbnail}`}
              alt="image"
              loadingImg={loadingImg}
            />
          </div>
          <h3
            className="blog-title"
            onClick={() => {
              navigateToDetails(blog.slug, blog.id);
            }}
          >
            {blog.title}
          </h3>
          <p className="blog-description">{blog.short_description}</p>
          <a
            className="blog-read-more"
            tabIndex={0}
            onClick={() => {
              navigateToDetails(blog.slug, blog.id);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigateToDetails(blog.slug, blog.id);
              }
            }}
            onMouseDown={(e) => {
              if (e.button == 1) {
                e.preventDefault();
                window.scrollTo(0, 0);
                navigateToDetails(blog.slug, blog.id);
              }
            }}
          >
            Read more
            <FontAwesomeIcon
              icon={faChevronRight}
              className="read-more-arrow"
            />
          </a>
        </div>
      )}
    </>
  );
};

export default BlogCard;
