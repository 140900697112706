import React, { useEffect, useState, useContext } from "react";
import "./CategoryDropDown.css";
import ParamsContext from "../useContext/paramContext";
import Navigation from "../../config/Navigation.json";
import { useNavigate } from "react-router-dom";
const CategoryDropDown = (props) => {
  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  let filterParamsUpdated = [
    {
      columnName: "brand_id",
      values: [],
    },
    {
      columnName: "category_id",
      values: [],
    },
    {
      columnName: "age_group",
      values: [],
    },
    {
      columnName: "condition",
      values: [],
    },
    {
      columnName: "price_range",
      values: [],
    },
    {
      columnName: "sort",
      values: [],
    },
  ];
  function frameQueryString() {
    let queryString = "";

    let tempArray = [];
    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (
        filterParamsUpdated[i].values.length != 0 &&
        filterParamsUpdated[i].columnName != "sort"
      ) {
        tempArray = filterParamsUpdated[i].values;
        if (tempArray.length > 0) {
          for (let j = 0; j < tempArray.length; j++) {
            if (
              filterParamsUpdated[i].columnName == "price_range" ||
              filterParamsUpdated[i].columnName == "age_group" ||
              filterParamsUpdated[i].columnName == "brand_id" ||
              filterParamsUpdated[i].columnName == "condition" ||
              filterParamsUpdated[i].columnName == "category_id" ||
              filterParamsUpdated[i].columnName == "selected_category_id"
            ) {
              {
                queryString +=
                  j == 0
                    ? `&${filterParamsUpdated[i].columnName}=${tempArray[j]}`
                    : `,${tempArray[j]}`;
              }
            }
          }
        }
      }
    }

    if (queryString) {
      const base64EncodedQuery = btoa(queryString);
      navigate(`/products?query=${base64EncodedQuery}`);
    } else {
      navigate(`/products`);
    }
  }
  const navigateToCategory = async (categoryId, parentCategoryId) => {
    props.setSelectedCategoryId(parentCategoryId);
    const session_filterParamsUpdated = sessionStorage.getItem(
      "filterParamsUpdated"
    );
    if (session_filterParamsUpdated !== null) {
      filterParamsUpdated = await JSON.parse(session_filterParamsUpdated);
    }
    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (filterParamsUpdated[i].columnName == "category_id") {
        filterParamsUpdated[i].values == [];
        filterParamsUpdated[i].values = [categoryId];
        break;
      }
    }
    sessionStorage.setItem("selectedCategoryId", parentCategoryId);
    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    frameQueryString();
  };
  let [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (props.childCategories) {
      setCategoryList(props.childCategories);
    }
  }, [props]);

  return (
    <div
      className="childCategoriesDropDown"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="childCategoriesList">
        {categoryList &&
          categoryList.map((category) => {
            return (
              <div
                className="childCategoryName"
                onClick={() => {
                  navigateToCategory(category.id, category.parent_category_id);
                  props.closeCategoryDropDown(props.item.id);
                }}
              >
                {category.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoryDropDown;
