import { useNavigate } from "react-router-dom";
import "./NotFound.css";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import React from "react";

import pageNotFound from "../../Assets/PageNotFound.webp";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
function NoRoutesMatched() {
  const navigate = useNavigate();

  return (
    <>
      <div className="invalidRouteDivContainer">
        <div className="invalidRoutePageContainer">
          <p className="ErrorMsgForInvalidRoutePage">
            {Constants.HeadlinesMessages.messageForPageNotFound}
          </p>
          <p className="invalidRouteP">
            {Constants.HeadlinesMessages.messageForValidURL_first}
          </p>
          <p className="invalidRouteP invalidRoutePageDescriptionTxt-second">
            {Constants.HeadlinesMessages.messageForValidURL_second}
          </p>
          <div className="invalidRoutePageImageContainer">
            <img
              className="imgForInvaildRoutePage"
              src={pageNotFound}
              onError={(e) => {
                e.target.src = placeHolderImage;
              }}
              alt="Page Not Found."
            />
          </div>
          <div
            onClick={() => {
              navigate(`${navigation.navigateToHomepage}`);
            }}
            className="invalidRouteBtn"
          >
            Go Back To Home Screen
          </div>
        </div>
      </div>
    </>
  );
}
export default NoRoutesMatched;
