import { React, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../config/Navigation.json";
import "./contact-us.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const SUPPORT_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const SUPPORT_PHONE = process.env.REACT_APP_ADMIN_PHONE;
const APP_NAME = process.env.REACT_APP_NAME;

const ContactUs = () => {
  const infra = process.env.REACT_APP_INFRA;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Let's Play Together! Get in Touch with JOYREJOY</title>
          <meta
            name="description"
            content="At JOYREJOY, we're here to make playtime joyful and hassle-free! Explore our range of kids' preloved and new items. Contact us for any queries or assistance - email, phone, or visit us in Gandhinagar, India."
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}

      <div className="pageParentDiv">
        <div className="body-container">
          <div className="contact-us">
            <div className="breadcrumb-area">
              <h1>Contact Us</h1>
              <ol className="breadcrumb">
                <li
                  className="breadcrumb-item"
                  onClick={() => {
                    navigate(navigation.navigateToHomepage);
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">Contact Us</li>
              </ol>
            </div>
            <p className="heading-description">
              Feel free to reaching out to us, We are here to help you.
            </p>
            <div className="contact-description">
              <p>
                You can drop us an email -
                <span>
                  <FontAwesomeIcon icon={faEnvelope} className="icons" />{" "}
                  {SUPPORT_EMAIL}
                </span>
              </p>
              <p>
                You can call us -{" "}
                <span>
                  <FontAwesomeIcon icon={faPhone} className="icons" /> +91{" "}
                  {SUPPORT_PHONE}
                </span>
              </p>
              <p>
                We are operating from -
                <span>
                  <FontAwesomeIcon icon={faHome} className="icons" /> A-323,
                  Siddhraj Zori, Sargasan Cross Road, Gandhinagar-382421,
                  Gujarat, India.
                </span>
              </p>
              <p>
                {APP_NAME}: Affordable Online Shopping of Kids preloved and new
                items at Your Fingertips
                <span>
                  There are many benefits of shopping online. You can take your
                  time and look at different options to find exactly what you
                  want. It's easy to compare prices online and find exactly what
                  you are looking for. And now with {APP_NAME}, you can shop for
                  anything related to kids preloved and new items you want at
                  the affordable price.
                </span>
              </p>
              <p>
                <span>
                  All kids related products listed on our portal are mostly
                  verified, supervised, examined for hygiene and quality. We
                  list range or products helpful to kids including but not
                  limited to all type of toys, sports goods, kids related
                  furniture, accessories etc. Have Joy and spread Rejoy by
                  buying and selling here at {APP_NAME}.
                </span>
              </p>
              {/* <p>
              A Huge Selection of Products Across All Categories
              <span>
                We have a vast inventory of products ranging of toys. {APP_NAME}{" "}
                is sure to have everything you need. In our latest collections,
                you will find all the popular items at an affordable price, so
                you can be confident you're getting the best deal. Whether
                you're in the market for new clothes, accessories, or just some
                daily-use items for home, {APP_NAME} has what you need.
              </span>
            </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
