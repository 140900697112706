import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../config/Navigation.json";
import Endpoints from "../../config/APIEndpoints.json";
import ParamsContext from "../useContext/paramContext";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faUser } from "@fortawesome/free-regular-svg-icons";
import Description from "../Description/Description";
import "./blog.css";
import BlogCard from "./BlogCard";
import Toast from "../ReactToastify/Toast";
import LazyImage from "../LazyImage/LazyImage";
import { Helmet } from "react-helmet";
const API_HOST = process.env.REACT_APP_API_HOST;

const BlogDetailsPageSkeleton = () => {
  return (
    <>
      <div className="blog-details-content blog-details-content-skeleton">
        <div className="blog-details-thumbnail-skeleton skeleton-box"></div>

        <h3 className="blog-details-title skeleton-box"></h3>
        <p className="blog-details-author-line skeleton-box"></p>

        <div className="blog-details-description-skeleton skeleton-box">
          {/* <p style={{ color: "#757575" }}>{blog.short_description}</p>
          <Description htmlContent={blog.description} /> */}
        </div>
      </div>
    </>
  );
};

export default BlogDetailsPageSkeleton;
