import React from "react";
import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import {
  generalFAQData,
  SafetyFAQData,
  CustomerServiceData,
  AdditionalFAQs,
} from "./questionAnswerList";
import Endpoints from "../../config/APIEndpoints.json";
import { Helmet } from "react-helmet";
const API_HOST = process.env.REACT_APP_API_HOST;
const infra = process.env.REACT_APP_INFRA;

const FAQs = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
    buyerfaqretrieveviaapicall: false,
  });
  let [dataUpdated, setDataUpdated] = useState(false);
  const [generalFAQDataList, setgeneralFAQDataList] = useState([]);
  const [safetyFAQDataList, setSafetyFAQDataList] = useState([]);
  const [customerServiceList, setCustomerServiceList] = useState([]);
  const [additionalFAQsList, setAdditionalFAQsList] = useState([]);
  const [data, setData] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (generalFAQData) {
      setgeneralFAQDataList(generalFAQData);
    }
    if (SafetyFAQData) {
      setSafetyFAQDataList(SafetyFAQData);
    }
    if (CustomerServiceData) {
      setCustomerServiceList(CustomerServiceData);
    }
    if (AdditionalFAQs) {
      setAdditionalFAQsList(AdditionalFAQs);
    }
  }, [generalFAQData, SafetyFAQData, AdditionalFAQs]);

  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
        buyerPoliciesRetrievedviaApi:
          featureFlags.buyer_policies_retrieved_via_api,
        buyerfaqretrieveviaapicall:
          featureFlags.buyer_faq_retrieve_via_api_call,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;

  // Get FAQ Data
  const getFaqData = async () => {
    try {
      const response = await fetch(`${API_HOST}/${Endpoints.getFaqData}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      setData(res.data.data);
      setLoading(false);
      setDataUpdated(true);
    } catch (error) {
      setDataUpdated(true);
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      } else {
      }
    }
  };

  useEffect(() => {
    getFaqData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Find Joyful Answers - JOYREJOY FAQs</title>
          <meta
            name="description"
            content="Explore our FAQs at JOYREJOY and find joyful answers to your questions! Dive into our playful and informative FAQs section to discover everything you need to know. Let's make finding answers as joyful as playtime itself!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      <div
        className="pageParentDiv"
        style={{
          marginTop: contextObj.params.topNavbarHeight,
        }}
      >
        <div className="body-container">
          <div className="policy-container" style={conditionalStyle}>
            <ResourcesSidebar activeResource={"FAQ's"} />
            {dataUpdated && (
              <>
                {showFeatures.buyerfaqretrieveviaapicall ? (
                  <>
                    <div className="policy-content">
                      <h1
                        className={`policy-content-heading ${
                          isSticky ? "sticky-heading" : ""
                        }`}
                      >
                        FAQ's
                      </h1>
                      {generalFAQDataList && (
                        <>
                          {data.map((faq) => {
                            return (
                              <p>
                                {faq.question}
                                <span>{faq.answer}</span>
                              </p>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="policy-content">
                      <h1
                        className={`policy-content-heading ${
                          isSticky ? "sticky-heading" : ""
                        }`}
                      >
                        FAQ's
                      </h1>
                      {generalFAQDataList && (
                        <>
                          {generalFAQDataList.map((faq) => {
                            return (
                              <p>
                                {faq.question}
                                <span>{faq.answer}</span>
                              </p>
                            );
                          })}
                        </>
                      )}
                      <h2>Safety</h2>
                      {safetyFAQDataList && (
                        <>
                          {safetyFAQDataList.map((faq) => {
                            return (
                              <p>
                                {faq.question}
                                <span>{faq.answer}</span>
                              </p>
                            );
                          })}
                        </>
                      )}
                      <h2>Customer Service</h2>
                      {customerServiceList && (
                        <>
                          {customerServiceList.map((faq) => {
                            return (
                              <p>
                                {faq.question}
                                <span>{faq.answer}</span>
                              </p>
                            );
                          })}
                        </>
                      )}
                      <h2>Additional FAQ's</h2>
                      {additionalFAQsList && (
                        <>
                          {additionalFAQsList.map((faq) => {
                            return (
                              <p>
                                {faq.question}
                                <span>{faq.answer}</span>
                              </p>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
