import endpoints from "../config/APIEndpoints.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../Components/Cookies/Cookies";

import Constants from "../config/ProjectConstants.json";
import Toast from "../Components/ReactToastify/Toast";
const API_HOST = process.env.REACT_APP_API_HOST;

const S3BucketImgHost = process.env.REACT_APP_S3BUCKET_IMG_HOST;
let currentInfra = process.env.REACT_APP_INFRA.toLowerCase();
let imgHost =
  currentInfra == "staging" || currentInfra == "production"
    ? S3BucketImgHost
    : API_HOST;
const { showToast } = Toast();
const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const logout = async () => {
  sessionStorage.removeItem("multiFilters");
  sessionStorage.removeItem("filterParamsUpdated");
  sessionStorage.removeItem("categoryListData");
  sessionStorage.removeItem("conditionDataInSession");
  sessionStorage.removeItem("ageGroupDataInSession");
  sessionStorage.removeItem("priceDataInSession");
  let token = getDataFromCookies("7H3K9R5P2Q");
  const API_HOST = process.env.REACT_APP_API_HOST;

  if (token !== null) {
    let response = await fetch(`${API_HOST}/${endpoints.buyerLogOut}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    removeDataFromCookie("7H3K9R5P2Q", "/");
    removeDataFromCookie("isLoggedIn", "/");

    if (response.status == 200) {
      showToast("success", Constants.alertMessages.alertForLogout);
      handleScrollToTop();
    }
  }
};

// price
const getAllPriceData = async (contextObj) => {
  const API_HOST = process.env.REACT_APP_API_HOST;
  await fetch(`${API_HOST}/${endpoints.getPriceCollections}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      if (res.status == 200) {
        sessionStorage.setItem("getAllPriceData", JSON.stringify(res.data));
        return false;
      }
    })
    .catch((err) => {
      console.error(err);
      if (err.message.includes("Failed to fetch")) {
        return true;
      }
    });
};

const getAllCategoriesData = async () => {
  const API_HOST = process.env.REACT_APP_API_HOST;
  await fetch(`${API_HOST}/${endpoints.getCategories}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      const dataArray = data.data;
      let allCategories = dataArray;
      if (Array.isArray(dataArray)) {
        const newdataArray = dataArray
          .filter((obj) => obj.parent_category_id == 0)
          .map((obj) => ({
            ...obj,
            showList: false,
            childCategory: [],
          }));

        allCategories.forEach((childObj) => {
          if (childObj.parent_category_id !== "0") {
            newdataArray.forEach((parent) => {
              if (childObj.parent_category_id == parent.id) {
                parent.childCategory.push(childObj);
              }
            });
          }
        });
        sessionStorage.setItem(
          "categoryListData",
          JSON.stringify({ data: allCategories })
        );
        sessionStorage.setItem(
          "parentCategoryListData",
          // JSON.stringify({ data: allCategories })
          JSON.stringify({ data: newdataArray })
        );
      } else {
        console.error("Data is not an array");
      }
    })
    .catch((err) => {
      console.error(err);
      if (err.message.includes("Failed to fetch")) {
        return true;
      }
    });
};

const getAllBrandList = async (contextObj) => {
  const API_HOST = process.env.REACT_APP_API_HOST;
  await fetch(`${API_HOST}/${endpoints.getBrands}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      const dataArray = data.data;
      dataArray.forEach((element) => {
        element.checked = false;
      });
      if (Array.isArray(dataArray)) {
        const setBrandInSession = {
          data: dataArray,
        };
        sessionStorage.setItem(
          "brandsListData",
          JSON.stringify(setBrandInSession)
        );
      }
    })
    .catch((err) => {
      console.error(err);
      if (err.message.includes("Failed to fetch")) {
        return true;
      }
    });
};

function moveIsDefaultToFirstIndex(array) {
  const indexWithIsDefault = array.findIndex((obj) => obj.is_default === 1);

  if (indexWithIsDefault !== -1) {
    const objWithIsDefault = array[indexWithIsDefault];

    // Remove the object with is_default from its original position
    array.splice(indexWithIsDefault, 1);

    // Insert the object at the beginning of the array
    array.unshift(objWithIsDefault);
  }

  return array;
}
function capitalizeFirstChar(str) {
  if (str.length === 0) return str; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export {
  imgHost,
  logout,
  getAllBrandList,
  getAllPriceData,
  getAllCategoriesData,
  handleScrollToTop,
  moveIsDefaultToFirstIndex,
  capitalizeFirstChar,
};
