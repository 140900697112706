import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./resources.css";

const ResourcesSidebar = (props) => {
  const navigate = useNavigate();
  const [activeResource, setActiveResource] = useState("");
  useEffect(() => {
    setActiveResource(props.activeResource);
  }, [props]);

  const handleKeyDown = (e, path) => {
    if (e.key === "Enter") {
      navigate(path);
    }
  };

  const resources = [
    { name: "Privacy Policy", path: "/privacy-policy" },
    { name: "Cookie Policy", path: "/cookie-policy" },
    { name: "Return Policy", path: "/return-policy" },
    { name: "Refund Policy", path: "/refund-policy" },
    { name: "Shipping Policy", path: "/shipping-policy" },
    { name: "Terms & Conditions", path: "/terms-and-condition" },
    { name: "Terms Of Use", path: "/terms-of-use" },
    { name: "FAQ's", path: "/faq" },
    { name: "Seller's Guideline", path: "/seller-guideline" },
  ];

  return (
    <div className="policy-sidebar">
      <div className="policy-sidebar-heading">RESOURCES</div>
      <div className="policy-sidebar-container">
        {resources.map((resource) => (
          <div
            key={resource.name}
            tabIndex="0"
            className={
              activeResource === resource.name
                ? "policy-sidebar-list-item active-policy"
                : "policy-sidebar-list-item"
            }
            onClick={() => navigate(resource.path)}
            onKeyDown={(e) => handleKeyDown(e, resource.path)}
          >
            {resource.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourcesSidebar;
