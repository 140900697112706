import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import InputControl from "../InputControl/InputControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import Toast from "../ReactToastify/Toast";
import loadingImgPath from "../../Assets/rolling.svg";
import "./address.css";

const API_KEY = process.env.REACT_APP_POSTOFFICE_API_KEY;
const API_HOST = process.env.REACT_APP_API_HOST;

const UpdateAddressPage = ({
  handleCloseEditModal,
  address,
  reFetchAddress,
}) => {
  const { showToast } = Toast();
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const navigate = useNavigate();
  const validIdFormat = (phone) => {
    const regex = /^[0-9]+$/;
    return regex.test(phone);
  };
  const contextObj = useContext(ParamsContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [checkIsDefaultAddress, setCheckIsDefaultAddress] = useState(null);
  const [disabledUpdateAddress, setDisabledUpdateAddress] = useState(false);
  const [BuyerAddress, setBuyerAddress] = useState({
    address_type: null,
    apartment_number: null,
    city: null,
    state: null,
    contact_person_name: null,
    contact_person_alt_phone: null,
    address_2: null,
    country: "India",
    house_number: null,
    is_default: 1,
    landmark: null,
    contact_person_phone: null,
    address_1: null,
    pincode: null,
    contact_person_email: null,
  });
  const [isVerified, setIsVerified] = useState(false);

  // let [pincode, setPincode] = useState("");
  let pincode = "";
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const { addressId } = useParams();

  const sanitizeInput = (inputValue) => {
    // Split the input into words
    const words = inputValue.split(" ");

    // Filter out any empty words (resulting from multiple spaces)
    const filteredWords = words.filter((word) => word.trim() !== "");

    // Join the filtered words with a single space
    let sanitizedValue = filteredWords.join(" ");

    // If the original input ends with a space, add it back
    if (inputValue.endsWith(" ")) {
      sanitizedValue += " ";
    }

    return sanitizedValue;
  };

  function validateAddress(value) {
    const regex = /^[a-zA-Z0-9\s\-\/.,()[\]]+$/;
    return regex.test(value);
  }

  const [selectedValue, setSelectedValue] = useState(null);
  const [addressType, SetAddressType] = useState({
    home: false,
    work: false,
  });
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [charCount, setCharCount] = useState({
    name: 0,
    phone: 0,
    alt_phone: 0,
    email: 0,
    pincode: 0,
    landmark: 0,
    address_1: 0,
  });
  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone: "",
    alt_phone: "",
    email: "",
    pincode: "",
    address_1: "",
    address_2: "",
    landmark: "",
    city: "",
    state: "",
  });
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [informationButton, setInformationButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [informationButtonState, setInformationButtonState] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // Initial state for the checkbox
  const handleMouseEnterOnIbutton = () => {
    setInformationButton(true);
  };
  const handleMouseLeaveOnIbutton = () => {
    setInformationButton(false);
  };
  const handleMouseEnterOnIbuttonState = () => {
    setInformationButtonState(true);
  };
  const handleMouseLeaveOnIbuttonState = () => {
    setInformationButtonState(false);
  };
  const isPhoneNumberFormat = (phone) => {
    const regex = /^[0-9]+$/.test(phone);
    return regex;
  };
  const isPhoneNumber = (phone) => {
    const regex = /^(\+?91[\-\s]?)?[6-9]\d{9}$/.test(phone);
    return regex;
  };
  const areAllNumbers = (num) => {
    const regex = /^[0-9]+$/.test(num);
    return regex;
  };

  // the code for implementing name place will only takes name starts from here.

  const handleSelect = (value) => {
    setErrorMessages((prev) => ({
      ...prev,
      addressType: "",
    }));
    setSelectedValue(value);
    setBuyerAddress((prev) => ({
      ...prev,
      address_type: value,
    }));
  };

  const handleCheckboxChange = () => {
    setBuyerAddress((prev) => ({
      ...prev,
      is_default: isChecked ? 0 : 1,
    }));
    setIsChecked(!isChecked);
  };

  const handleCancelAddress = () => {
    reFetchAddress(false);
    handleCloseEditModal(true);
  };

  const toCamelCase = (text) => {
    const words = text.split(" ");
    const result = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return result.join(" ");
  };

  useEffect(() => {
    const validId = validIdFormat(address.id);

    if (!validId) {
      navigate(`${navigation.navigateToNoRoutesMatched}`);
    } else {
      const loggedIn = getDataFromCookies("isLoggedIn");
      if (loggedIn == true) {
        setCheckIsDefaultAddress(address.is_default);
        setBuyerAddress((prev) => ({
          ...prev,
          contact_person_name: address.contact_person_name,
          contact_person_phone: address.contact_person_phone,
          contact_person_alt_phone: address.contact_person_alt_phone,
          contact_person_email: address.contact_person_email,
          pincode: address.pincode,
          address_1: address.address_1,
          address_2: address.address_2,
          city: address.city,
          state: address.state,
          landmark: address.landmark,
          address_type: address.address_type,
          is_default: address.is_default,
          // country: add.country,
        }));
        setIsDataLoaded(true);
        setDataLoaded(true);
        setIsVerified(true);
        if (address.address_type == "Home") {
          SetAddressType({ work: false, home: true });
          handleSelect("Home");
        } else {
          SetAddressType({ work: true, home: false });
          handleSelect("Work");
        }
        if (address.is_default == 0) {
          setIsChecked(false);
        } else {
          setIsChecked(true);
        }
      } else {
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        contextObj.setInput("isLoggedIn", false);
        navigate(`${navigation.navigateToHomepage}`);
      }
    }

    const handleResize = () => {
      if (window.innerWidth > 600) {
        document.body.style.overflow = "hidden"; // Disable scroll when the screen width is greater than 600px
      } else {
        document.body.style.overflow = "auto"; // Enable scroll when the screen width is less than or equal to 600px
      }
    };

    // Initial call to set overflow based on the initial screen size
    handleResize();

    // Add a resize event listener to update overflow when the screen size changes
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      // document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
    };
  }, []);

  async function handleUpdateAddress() {
    let errorFound = false;
    setErrorMessages((prev) => ({
      name: "",
      phone: "",
      pincode: "",
      address_1: "",
      address_2: "",
      landmark: "",
      email: "",
    }));

    if (BuyerAddress.contact_person_name === null) {
      setErrorMessages((prev) => ({
        ...prev,
        name: Constants.alertMessages.alertForName,
      }));
      errorFound = true;
    } else if (BuyerAddress.contact_person_name.length < 2) {
      setErrorMessages((prev) => ({
        ...prev,
        name: Constants.alertMessages.alertForMinName,
      }));
      errorFound = true;
    }

    if (BuyerAddress.contact_person_phone.length < 10) {
      setErrorMessages((prev) => ({
        ...prev,
        phone:
          BuyerAddress.contact_person_phone.length === 0
            ? Constants.alertMessages.alertForMobileNo
            : Constants.alertMessages.alertForValidMobile,
      }));
      errorFound = true;
    }

    if (
      BuyerAddress.contact_person_alt_phone !== "" &&
      BuyerAddress.contact_person_alt_phone !== null
    ) {
      if (BuyerAddress.contact_person_alt_phone.length < 10) {
        setErrorMessages((prev) => ({
          ...prev,
          alt_phone:
            BuyerAddress.contact_person_alt_phone.length === 0
              ? Constants.alertMessages.alertForMobileNo
              : Constants.alertMessages.alertForValidMobile,
        }));
        errorFound = true;
      }
    }

    if (BuyerAddress.pincode.length === "") {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForPincode,
      }));
      errorFound = true;
    } else if (BuyerAddress.pincode.length < 6) {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForValidPincode,
      }));
      errorFound = true;
    }

    setErrorMessages((prev) => ({
      ...prev,
      address_2: "",
    }));

    // this piece of code is for the validating the (Address), Street and HouseNo.
    setErrorMessages((prev) => ({
      ...prev,
      address_1: "",
    }));

    if (BuyerAddress.address_1 !== null || BuyerAddress.address_1 !== "") {
      if (
        BuyerAddress.address_1.length < 2 ||
        BuyerAddress.address_1.length > 150
      ) {
        setErrorMessages((prev) => ({
          ...prev,
          address_1: Constants.alertMessages.alertForAddressGuideline,
        }));
        errorFound = true;
      }
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        address_1: Constants.alertMessages.alertForAddress,
      }));
      errorFound = true;
    }

    //// this piece of code is for the validating the (Landmark).
    setErrorMessages((prev) => ({
      ...prev,
      landmark: "",
    }));
    if (BuyerAddress.landmark !== null && BuyerAddress.landmark !== "") {
      if (
        BuyerAddress.landmark.length < 2 ||
        BuyerAddress.landmark.length > 150
      ) {
        setErrorMessages((prev) => ({
          ...prev,
          landmark: Constants.alertMessages.alertForLandmarkLength,
        }));
        errorFound = true;
      }
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        landmark: "",
      }));
    }

    if (
      !isVerified &&
      BuyerAddress.pincode !== null &&
      BuyerAddress.pincode !== "" &&
      BuyerAddress.pincode !== undefined &&
      BuyerAddress.pincode.length > 0 &&
      BuyerAddress.pincode.length < 6
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForValidPincode,
      }));
      errorFound = true;
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: isVerified ? "" : Constants.alertMessages.alertForPincode,
      }));
    }
    if (errorFound == true) {
      setDisabledUpdateAddress(false);
      setIsDataChanged(false);
      return;
    }

    const token = getDataFromCookies("7H3K9R5P2Q");

    if (isVerified) {
      if (BuyerAddress.contact_person_phone.length === 10) {
        const payload = JSON.stringify(BuyerAddress);

        const response = await fetch(
          `${API_HOST}/${endpoints.buyerShippingAddress}/${address.id}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: payload,
          }
        );
        const updateAddJson = await response.json();
        setDisabledUpdateAddress(false);
        if (
          updateAddJson.status == 500 ||
          updateAddJson.status == 501 ||
          updateAddJson.status == 502 ||
          updateAddJson.status == 503 ||
          (updateAddJson.message &&
            updateAddJson.message.toLowerCase().includes("server error"))
        ) {
          if (updateAddJson.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }

        if (updateAddJson.status == 200) {
          reFetchAddress(true);
          handleCloseEditModal(true);
        }
        if (updateAddJson.status == 401) {
          removeDataFromCookie("7H3K9R5P2Q", "/");
          removeDataFromCookie("isLoggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          contextObj.setInput("isLoggedIn", false);
          navigate(`${navigation.navigateToHomepage}`);
        }
        if (updateAddJson.status == 400) {
          if (updateAddJson.errors.address_1) {
            setErrorMessages((prev) => ({
              ...prev,
              address_1: Constants.alertMessages.alertForAddressGuide,
            }));
          }
          if (updateAddJson.errors.address_2) {
            setErrorMessages((prev) => ({
              ...prev,
              address_2:
                updateAddJson.errors.address_2[0] ==
                "The address 2 must be between 3 and 256 characters."
                  ? Constants.alertMessages.alertForStreetLength
                  : updateAddJson.errors.address_2[0],
            }));
          }
          if (updateAddJson.errors.contact_person_email) {
            setErrorMessages((prev) => ({
              ...prev,
              email: updateAddJson.errors.contact_person_email[0],
            }));
          }
          if (updateAddJson.errors.contact_person_phone) {
            setErrorMessages((prev) => ({
              ...prev,
              phone: updateAddJson.errors.contact_person_phone[0],
            }));
          }
          if (updateAddJson.errors.contact_person_alt_phone) {
            setErrorMessages((prev) => ({
              ...prev,
              alt_phone: updateAddJson.errors.contact_person_alt_phone[0],
            }));
          }
          return;
        }
        if (updateAddJson.status == 409) {
          setErrorMessages((prev) => ({
            ...prev,
            address_1: updateAddJson.message,
          }));
        }
      } else {
        setDisabledUpdateAddress(false);
        return setErrorMessages((prev) => ({
          ...prev,
          phone:
            BuyerAddress.contact_person_phone.length === 0
              ? Constants.alertMessages.alertForMobileNo
              : Constants.alertMessages.alertForValidMobile,
        }));
      }
    } else {
      setDisabledUpdateAddress(false);
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForValidPincode,
      }));
      return;
    }
  }

  async function getPincodeData(pincode) {
    const response = await fetch(
      `https://api.data.gov.in/resource/7eca2fa3-d6f5-444e-b3d6-faa441e35294?api-key=${API_KEY}&format=json&filters[pincode]=${pincode}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  }

  const handleSubmitData = async (e) => {
    const data = await getPincodeData(pincode);
    if (pincode.length > 0 && pincode.length === 6) {
      if (data.records.length === 0) {
        setIsVerified(false);
        setSelectedState(null);
        setSelectedCity(null);
        setBuyerAddress((prev) => ({
          ...prev,
          city: null,
          state: null,
        }));
        setErrorMessages((prev) => ({
          ...prev,
          pincode: Constants.alertMessages.alertForValidPincode,
        }));
      } else {
        const state = toCamelCase(data.records[0]._statename);
        const city = toCamelCase(data.records[0].districtname);
        setSelectedState(state);
        setSelectedCity(city);
        setBuyerAddress((prev) => ({
          ...prev,
          city: city,
          state: state,
          pincode: pincode,
        }));

        setIsVerified(true);
      }
    } else {
      setIsVerified(false);
      setSelectedState(null);
      setSelectedCity(null);
      setBuyerAddress((prev) => ({
        ...prev,
        city: null,
        state: null,
      }));
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForValidPincode,
      }));
    }
  };

  return (
    <>
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div>
          {isDataLoaded && (
            <div className="buyerHeadingMainAddressPage">
              <div
                className="buyerMainDivForAddressDetailsSection"
                id="buyerMainDivForAddressDetailsPopUp"
              >
                <div className="mobileUIDiv">
                  <h1 className="MobileUIHeading">JOYREJOY</h1>
                  <button
                    onClick={handleCancelAddress}
                    className="cancleaddressaddbuttonMobile"
                  >
                    <FontAwesomeIcon icon={faTimes} color="#ffffff" />
                  </button>
                </div>
                <div className="addaddressheadingcontainer">
                  <h1 className="addNewAddressHeading">Update Address</h1>
                  <button
                    onClick={handleCancelAddress}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleCancelAddress();
                      }
                    }}
                    className="cancleaddressaddbutton"
                    id="CloseAddressaddButton"
                  >
                    <FontAwesomeIcon icon={faTimes} color="#a59d9d " />
                  </button>
                </div>
                <hr className="addaddressHrLine" />

                <>
                  <div className="buyerMainDivForNewAddressFormSection">
                    <div className="buyerMainDivForNewAddressFormSectionDetails">
                      <div className="popupContainer">
                        <div>
                          {" "}
                          <h2
                            className="subheadinginaddaddresspage"
                            id="addressModalAddressTypeHeading"
                          >
                            {" "}
                            Contact Details
                          </h2>
                          <div className="buyerMainDivForAddressMainflexbox">
                            <div className="buyerMainDivForProfileName">
                              <InputControl
                                label={<span>Name</span>}
                                inputTagId={"updateAddressName"}
                                type="text"
                                autoFocus
                                // placeholder=""
                                value={BuyerAddress.contact_person_name}
                                maxLength="50"
                                maxLimit="50"
                                minLength="2"
                                autoComplete="off"
                                characterCount={charCount.name}
                                onChange={(event) => {
                                  if (!isDataChanged) {
                                    setIsDataChanged(true);
                                  }
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    name: "",
                                  }));
                                  setCharCount((prev) => ({
                                    ...prev,
                                    name: event.target.value.length,
                                  }));

                                  // Get the input value without trimming
                                  const inputValue = event.target.value;
                                  const sanitizedValue =
                                    sanitizeInput(inputValue);

                                  // Check if the input is empty and update the state accordingly
                                  setBuyerAddress((prev) => ({
                                    ...prev,
                                    contact_person_name: sanitizedValue,
                                  }));
                                }}
                                error={errorMessages.name}
                              />
                            </div>
                            <div className="buyerMainDivForProfileLastName">
                              <InputControl
                                label={<span>Phone Number</span>}
                                inputTagId={"updateAddressPhoneNumber"}
                                type="text"
                                maxLength="10"
                                maxLimit="10"
                                characterCount={charCount.phone}
                                value={BuyerAddress.contact_person_phone}
                                onChange={(event) => {
                                  if (!isDataChanged) {
                                    setIsDataChanged(true);
                                  }
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    phone: "",
                                  }));
                                  setCharCount((prev) => ({
                                    ...prev,
                                    phone: event.target.value.length,
                                  }));

                                  const phone = event.target.value.length;
                                  setCharCount((prev) => ({
                                    ...prev,
                                    phone: phone,
                                  }));
                                  const inputValue = event.target.value;
                                  const sanitizedValue =
                                    sanitizeInput(inputValue);

                                  if (
                                    sanitizedValue === inputValue &&
                                    inputValue !== ""
                                  ) {
                                    const validnumber = isPhoneNumberFormat(
                                      event.target.value.trim()
                                    );
                                    if (
                                      validnumber ||
                                      event.target.value.trim() == ""
                                    ) {
                                      setBuyerAddress((prev) => ({
                                        ...prev,
                                        contact_person_phone:
                                          event.target.value.trim(),
                                      }));
                                    } else {
                                      setErrorMessages((prev) => ({
                                        ...prev,
                                        phone:
                                          inputValue === ""
                                            ? Constants.alertMessages
                                                .alertForMobileNo
                                            : Constants.alertMessages
                                                .alertForValidMobile,
                                      }));
                                    }
                                  } else {
                                    if (sanitizedValue !== "") {
                                      setErrorMessages((prev) => ({
                                        ...prev,
                                        phone:
                                          Constants.alertMessages
                                            .alertForSpecialChar,
                                      }));
                                    } else {
                                      setErrorMessages((prev) => ({
                                        ...prev,
                                        phone:
                                          Constants.alertMessages
                                            .alertForMobileNo,
                                      }));
                                    }
                                    setBuyerAddress((prev) => ({
                                      ...prev,
                                      contact_person_phone: sanitizedValue,
                                    }));
                                  }
                                }}
                                error={errorMessages.phone}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <h2
                            className="subheadinginaddaddresspage"
                            id="addressModalAddressHeading"
                          >
                            {" "}
                            Address
                          </h2>{" "}
                        </div>
                        <div className="buyerMainDivForAddressMainflexbox">
                          <div className="buyerMainDivForProfileName buyerMainDivForProfileNamePincode">
                            <InputControl
                              label={<span>Pincode</span>}
                              inputTagId={"updateAddressPincode"}
                              maxLength="6"
                              maxLimit="6"
                              type="text"
                              value={BuyerAddress.pincode}
                              characterCount={charCount.pincode}
                              onChange={(e) => {
                                if (!isDataChanged) {
                                  setIsDataChanged(true);
                                }
                                const inputValue = e.target.value;
                                const sanitizedValue =
                                  sanitizeInput(inputValue);

                                if (sanitizedValue === inputValue) {
                                  setIsVerified(false);
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    pincode: "",
                                    city: "",
                                    state: "",
                                  }));
                                  setCharCount((prev) => ({
                                    ...prev,
                                    pincode: e.target.value.length,
                                  }));

                                  setBuyerAddress((prev) => ({
                                    ...prev,
                                    city: "",
                                    state: "",
                                    // country: "",
                                  }));

                                  setSelectedState("");
                                  setSelectedCity("");
                                  // setSelectedCountry("");
                                  const pin = e.target.value.trim();
                                  const verifyAllNumber = areAllNumbers(pin);

                                  if (verifyAllNumber) {
                                    pincode = pin;

                                    if (e.target.value.trim().length === 6) {
                                      handleSubmitData();
                                    }
                                    setBuyerAddress((prev) => ({
                                      ...prev,
                                      pincode: e.target.value,
                                    }));
                                  } else {
                                    setErrorMessages((prev) => ({
                                      ...prev,
                                      pincode:
                                        pin.length > 0
                                          ? Constants.alertMessages
                                              .alertForOnlyNo
                                          : Constants.alertMessages
                                              .alertForPincode,
                                    }));
                                    setBuyerAddress((prev) => ({
                                      ...prev,
                                      pincode: "",
                                    }));
                                  }
                                } else {
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    pincode:
                                      Constants.alertMessages
                                        .alertForSpecialChar,
                                  }));
                                  setBuyerAddress((prev) => ({
                                    ...prev,
                                    pincode: sanitizedValue,
                                  }));
                                }
                              }}
                              error={errorMessages.pincode}
                            />
                          </div>
                          <div className="buyerMainDivForProfileLastName">
                            <InputControl
                              label={<span>Street address(Optional)</span>}
                              inputTagId={"updateAddressLocality"}
                              type="text"
                              maxLength="150"
                              maxLimit="150"
                              value={BuyerAddress.address_2}
                              minLength="2"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const sanitizedValue =
                                  sanitizeInput(inputValue);

                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  address_2: sanitizedValue,
                                }));
                                setErrorMessages((prev) => ({
                                  ...prev,
                                  address_2: "",
                                }));
                                // If valid data is entered, you may choose to set isDataChanged to true here as well.
                                setIsDataChanged(true);
                              }}
                              error={errorMessages.address_2}
                            />
                          </div>
                        </div>
                        <div className="buyerMainDivForAddressMainTextareaflexbox">
                          <label className="BuyerASddresspageInfoLable">
                            Address (House No, Building, Street)
                          </label>

                          <textarea
                            spellCheck="false"
                            id={"updateAddress_Address"}
                            className="BuyerAddressPageTextareaForaddress"
                            maxLength="150"
                            value={BuyerAddress.address_1}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedValue = sanitizeInput(inputValue);
                              setErrorMessages((prev) => ({
                                ...prev,
                                address_1: "",
                              }));
                              setBuyerAddress((prev) => ({
                                ...prev,
                                address_1: sanitizedValue,
                              }));

                              setIsDataChanged(true);
                            }}
                            error={errorMessages.address_1}
                          ></textarea>
                          <div className="errorMessageDivAddress">
                            <span
                              className="errorinputboxAddress"
                              id="errorinputboxAddress_error"
                              style={{ height: "10px" }}
                            >
                              {" "}
                              {errorMessages.address_1}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="buyerMainDivForAddressMainflexbox">
                          <div className="buyerMainDivForProfileName">
                            <InputControl
                              inputTagId={"updateAddressCity"}
                              label="City / District/ Town"
                              // value={selectedCity}
                              value={BuyerAddress.city}
                              readOnly
                              tabIndex="-1"
                              // error={errorMessages.city}
                              onMouseEnter={handleMouseEnterOnIbutton}
                              onMouseLeave={handleMouseLeaveOnIbutton}
                            />
                          </div>
                          <div className="buyerMainDivForProfileLastName">
                            <InputControl
                              label="State"
                              inputTagId={"updateAddressState"}
                              // value={selectedState}
                              value={BuyerAddress.state}
                              tabIndex="-1"
                              readOnly
                              onMouseEnter={handleMouseEnterOnIbuttonState}
                              onMouseLeave={handleMouseLeaveOnIbuttonState}
                            />
                          </div>
                        </div>
                        <div className="buyerMainDivForAddressMainflexbox">
                          <div className="buyerMainDivForProfileName buyerMainDivForProfileNamePincode">
                            <InputControl
                              label={<span>Landmark(Optional)</span>}
                              inputTagId={"updateAddressLandmark"}
                              type="text"
                              maxLength="150"
                              maxLimit="150"
                              minLength="2"
                              //placeholder=""
                              value={BuyerAddress.landmark}
                              characterCount={charCount.landmark}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const sanitizedValue =
                                  sanitizeInput(inputValue);
                                setErrorMessages((prev) => ({
                                  ...prev,
                                  landmark: "",
                                }));
                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  landmark: sanitizedValue,
                                }));

                                // If valid data is entered, you may choose to set isDataChanged to true here as well.
                                setIsDataChanged(true);
                              }}
                              error={errorMessages.landmark}
                            />
                          </div>
                          <div className="buyerMainDivForProfileLastName">
                            <InputControl
                              label={
                                <span>Alternate Phone Number (Optional)</span>
                              }
                              type="text"
                              inputTagId={"updateAddressAltPhone"}
                              value={BuyerAddress.contact_person_alt_phone}
                              maxLength="10"
                              maxLimit="10"
                              characterCount={charCount.alt_phone}
                              onChange={(event) => {
                                if (!isDataChanged) setIsDataChanged(true);
                                setErrorMessages((prev) => ({
                                  ...prev,
                                  alt_phone: "",
                                }));
                                setCharCount((prev) => ({
                                  ...prev,
                                  alt_phone: event.target.value.length,
                                }));
                                const inputValue = event.target.value;
                                const sanitizedValue =
                                  sanitizeInput(inputValue);
                                if (
                                  sanitizedValue === inputValue &&
                                  inputValue !== ""
                                ) {
                                  const validnumber = isPhoneNumberFormat(
                                    event.target.value.trim()
                                  );
                                  if (validnumber) {
                                    setBuyerAddress((prev) => ({
                                      ...prev,
                                      contact_person_alt_phone:
                                        event.target.value.trim(),
                                    }));
                                  } else {
                                    setErrorMessages((prev) => ({
                                      ...prev,
                                      alt_phone:
                                        Constants.alertMessages
                                          .alertForValidMobile,
                                    }));
                                    setBuyerAddress((prev) => ({
                                      ...prev,
                                      contact_person_alt_phone: "",
                                    }));
                                  }
                                } else {
                                  if (sanitizedValue !== "") {
                                    setErrorMessages((prev) => ({
                                      ...prev,
                                      alt_phone:
                                        Constants.alertMessages
                                          .alertForSpecialChar,
                                    }));
                                  }
                                  setBuyerAddress((prev) => ({
                                    ...prev,
                                    contact_person_alt_phone: sanitizedValue,
                                  }));
                                }
                              }}
                              error={errorMessages.alt_phone}
                            />
                          </div>
                        </div>
                        <div>
                          {" "}
                          <h2
                            className="subheadinginaddaddresspage"
                            id="addressModalAddressTypeHeading"
                          >
                            {" "}
                            Address Type
                          </h2>{" "}
                        </div>
                        <div className="buyerMainDivForAddressMainflexbox">
                          <div className="buyerMainDivForProfileNamess">
                            <div className="buyerAddressPageRadioSelection">
                              <button
                                id="updateAddressTypeHome"
                                className={
                                  selectedValue === "Home"
                                    ? "selectedbutton"
                                    : "butttonforselectaddressType"
                                }
                                onClick={() => {
                                  if (!isDataChanged) {
                                    setIsDataChanged(true);
                                  }
                                  handleSelect("Home");
                                }}
                              >
                                Home{" "}
                              </button>
                              <button
                                id="updateAddressTypeWork"
                                className={
                                  selectedValue === "Work"
                                    ? "selectedbutton1"
                                    : "butttonforselectaddressType2"
                                }
                                onClick={() => {
                                  if (!isDataChanged) {
                                    setIsDataChanged(true);
                                  }
                                  handleSelect("Work");
                                }}
                              >
                                {" "}
                                Work
                              </button>
                            </div>
                            <span
                              className="inputboxforerrormessageaddress"
                              id="addressModelType_error"
                              style={{ height: "10px" }}
                            >
                              {errorMessages.addressType}
                            </span>
                          </div>
                        </div>
                        <div className="DefaultCheckBoxDiv">
                          {dataLoaded && checkIsDefaultAddress == 0 && (
                            <label className="lableformakemakeDefault">
                              <input
                                className="InputCheckboxaddressPage"
                                type="checkbox"
                                id="updateAddressMakeDefault"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                onClick={() => {
                                  if (!isDataChanged) {
                                    setIsDataChanged(true);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setIsDataChanged(true);
                                    handleCheckboxChange();
                                  }
                                }}
                              />
                              {"   "}
                              <span className="addressmarkdefault">
                                Make this my default address
                              </span>
                            </label>
                          )}
                        </div>
                      </div>

                      <div className="addresspagebuttonDivAddCancelUpdate">
                        <button
                          id="updateAddressUpdate"
                          className={
                            isDataChanged
                              ? "BuyerAddressPageMainSubmitButton"
                              : "BuyerAddressPageMainSubmitButton disabledElement"
                          }
                          onClick={() => {
                            if (isDataChanged !== false) {
                              setDisabledUpdateAddress(true);
                              handleUpdateAddress();
                            }
                          }}
                          style={{
                            cursor: isDataChanged ? "pointer" : "not-allowed",
                          }}
                        >
                          {disabledUpdateAddress ? (
                            <img
                              src={loadingImgPath}
                              onError={(e) => {
                                e.target.src = placeHolderImage;
                              }}
                              alt="Loading"
                              className="disabledBtn-Loading"
                            />
                          ) : (
                            "UPDATE"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UpdateAddressPage;
