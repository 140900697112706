import React from "react";
import CacheBuster from "react-cache-buster";

const CacheBusterComponent = ({ children }) => {
  const currentVersion = "v1";

  return (
    <CacheBuster isEnabled={true} currentVersion={currentVersion}>
      {({ loading, isLatestVersion, handleUpdate }) => {
        if (loading) return null;
        if (!isLatestVersion) {
          handleUpdate();
          return null;
        }
        return React.isValidElement(children) ? children : null;
      }}
    </CacheBuster>
  );
};

export default CacheBusterComponent;
