import React, { useState, useContext, useEffect, useRef } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartFill } from "@fortawesome/free-solid-svg-icons";
import { getDataFromCookies } from "../Cookies/Cookies";
import { ReactComponent as RollingSvg } from "../../Assets/rolling.svg";
import ParamsContext from "../useContext/paramContext";
import Navigation from "../../config/Navigation.json";
import Endpoints from "../../config/APIEndpoints.json";
import Constants from "../../config/ProjectConstants.json";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import Toast from "../ReactToastify/Toast";
import "./card.css";
import LazyImage from "../LazyImage/LazyImage";
import SkeletonComponent from "../skeleton/Skeleton";
const API_HOST = process.env.REACT_APP_API_HOST;

const Card = (props) => {
  let id = props.idParameret;
  const location = useLocation();
  const [imageLoading, setImageLoading] = useState(true);
  const { showToast } = Toast();
  const { item, fetchCartDetails } = props;

  const [cardProd, setCardProd] = useState(null);

  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  const [isInWishlist, setIsInWishlist] = useState(item.is_in_wishlist);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, seIsLoggedIn] = useState(getDataFromCookies("isLoggedIn"));
  const [enableAddToCart, setEnableAddToCart] = useState(true);
  const [showFeatures, setShowFeatures] = useState({
    enableHover: true,
    showWishList: true,
  });
  useEffect(() => {
    if (item.stocks) {
      setCardProd(item.stocks[0]);
    } else if (item.default_stock) {
      setCardProd(item.default_stock);
    } else {
      setCardProd(null);
    }
    if (item.default_stock) {
      setEnableAddToCart(item.default_stock.quantity > 0 ? true : false);
      if (item.default_stock.is_in_wishlist) {
        setIsInWishlist(item.default_stock.is_in_wishlist);
      }
    } else {
      setIsInWishlist(item.is_in_wishlist);
    }
  }, [item.is_in_wishlist, item.default_stock]);

  useEffect(() => {
    if (props.loadingImg && !imageLoading) {
      props.loadingImg(imageLoading);
    }
  }, [imageLoading]);
  useEffect(() => {
    if (contextObj.params.productAddedToWishlistId) {
      const timeoutId = setTimeout(() => {
        contextObj.setInput("productAddedToWishlistId", "");
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productAddedToWishlistId]);
  useEffect(() => {
    if (contextObj.params.productAddedToWishlistId) {
      const timeoutId = setTimeout(() => {
        contextObj.setInput("productRemovedFromWishlistId", "");
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productRemovedFromWishlistId]);
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        enableHover: featureFlags.buyer_common_productcardhover,
        showWishList: featureFlags.buyer_products_wishlist,
      }));
    }
  }, []);

  const getOfferPercentage = (mrp, sellingPrice) => {
    if (mrp !== null && sellingPrice !== null) {
      const percentage = Math.round(((mrp - sellingPrice) / mrp) * 100);

      if (mrp <= 0 || sellingPrice <= 0 || percentage <= 0) return 0;
      return percentage;
    }
  };

  const formatNumberWithCommas = (number) => {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  };

  const handleAddToWishlist = async () => {
    let isLoggedIn = getDataFromCookies("isLoggedIn");
    if (!isLoggedIn) {
      contextObj.setInput("actionAfterLogin", "addToWishlist");
      contextObj.setInput(
        "productId",
        cardProd ? cardProd.id : item.default_stock.id
      );
      contextObj.setInput("showLoginModel", true);
      return;
    }

    const access_token = getDataFromCookies("7H3K9R5P2Q");
    const payload = {
      stock_id: cardProd ? cardProd.id : item.id,
    };
    const addToWishlist = await fetch(
      `${API_HOST}/${Endpoints.addToWishList}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const addToWishlistResJson = await addToWishlist.json();

    if (addToWishlistResJson.status === 201) {
      setIsLoading(false);
      setIsInWishlist(true);
      showToast("success", addToWishlistResJson.message);

      if (location.pathname == Navigation.navigateToWishlist) {
        contextObj.setInput(
          "reFetchWishList",
          !contextObj.params.reFetchWishList
        );
      }
      contextObj.setInput(
        "productAddedToWishlistId",
        cardProd ? cardProd.id : item.id
      );
    } else if (
      addToWishlistResJson.status === 403 ||
      addToWishlistResJson.status === 404
    ) {
      showToast("error", addToWishlistResJson.message);
      setIsLoading(false);
    } else if (addToWishlistResJson.status === 409) {
      showToast("info", addToWishlistResJson.message);
      setIsLoading(false);
    } else if (addToWishlistResJson.status === 401) {
      setIsLoading(false);
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${Navigation.navigateToNoRoutesMatched}`);
    } else {
      setIsLoading(false);
    }
  };

  const handleAddToCart = async () => {
    const checkLoggedIn = getDataFromCookies("isLoggedIn");
    if (checkLoggedIn !== true) {
      contextObj.setInput("actionAfterLogin", "addToCart");
      contextObj.setInput(
        "productId",
        cardProd ? cardProd.id : item.default_stock.id
      );
      contextObj.setInput("showLoginModel", true);

      setEnableAddToCart(true);
    } else {
      const payload = {
        stock_id: cardProd ? cardProd.id : item.id,
      };

      const authenticationToken = getDataFromCookies("7H3K9R5P2Q");
      const addToCart = await fetch(`${API_HOST}/${Endpoints.addToCart}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationToken}`,
        },
        body: JSON.stringify(payload),
      });
      const addToCartResJson = await addToCart.json();
      setEnableAddToCart(true);

      if (addToCartResJson.status == 201 || addToCartResJson.status == 409) {
        if (fetchCartDetails !== undefined && fetchCartDetails !== null) {
          fetchCartDetails(true);
        }
        if (addToCartResJson.status === 201) {
          contextObj.setInput("cartLength", addToCartResJson.data.total_items);
        }

        if (location.pathname == Navigation.navigateToNewCartPage) {
          contextObj.setInput("reFetchCart", !contextObj.params.reFetchCart);
        }
        if (props.resetFetchDataNow !== undefined) {
          props.resetFetchDataNow(true);
        }
        showToast("success", Constants.alertMessages.alertForAddingInCart);
        if (window.innerWidth > 600) {
          contextObj.setInput("showCartSidebar", true);
        }
      } else if (
        addToCartResJson.status == 422 &&
        addToCartResJson.message ==
          Constants.alertMessages.alertForMaxLimitOfCart
      ) {
        showToast("info", Constants.alertMessages.alertForMaxLimitOfCart);
        if (window.innerWidth > 600) {
          if (fetchCartDetails !== undefined && fetchCartDetails !== null) {
            fetchCartDetails(true);
          }
          contextObj.setInput("showCartSidebar", true);
        }
      } else if (addToCartResJson.status === 404) {
        contextObj.setInput("showCartSidebar", false);
        showToast("error", Constants.alertMessages.alertForStockNotAvailable);
      } else if (addToCartResJson.status === 401) {
        sessionStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("7H3K9R5P2Q");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        contextObj.setInput("isLoggedIn", false);
        navigate(`${Navigation.navigateToHomepage}`);
      }
    }
  };

  const navigateToDetails = (slug) => {
    navigate(`${Navigation.navigateToProductList}/${slug}`);
  };

  const getBackgroundColor = (condition) => {
    if (condition) {
      switch (condition.toUpperCase()) {
        case "NEW":
          return "#379D75";
        case "ALMOST LIKE NEW":
          return "#F7BC3A";
        case "GENTLY LOVED":
          return "#9AC2E6";
        case "LOVED":
          return "#F4ABA4";
        default:
          return "";
      }
    }
  };

  const getBorderColor = (condition) => {
    switch (condition.toUpperCase()) {
      case "NEW":
        return "rgba(55, 157, 117, 0.5) transparent rgba(55, 157, 117, 0.5) rgba(55, 157, 117, 1)";
      case "ALMOST LIKE NEW":
        return "rgba(247, 188, 58, 0.5) transparent rgba(247, 188, 58, 0.5) rgba(247, 188, 58, 1)";
      case "GENTLY LOVED":
        return "rgba(154, 194, 230, 0.5) transparent rgba(154, 194, 230, 0.5) rgba(154, 194, 230, 1)";
      case "LOVED":
        return "rgba(244, 171, 164, 0.5) transparent rgba(244, 171, 164, 0.5) rgba(244, 171, 164, 1)";
      default:
        return "";
    }
  };

  return (
    <div className="prod-card-container">
      {imageLoading && (
        <SkeletonComponent
          className="SkeletonSection prod-card "
          style={{
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            margin: "0px",
          }}
        />
      )}
      <div
        className="prod-card"
        tabIndex={0}
        onClick={(e) => {
          if (props.openInNewTab) {
            window.open(
              `${Navigation.navigateToProductList}/${item.slug}`,
              "_blank"
            );
          } else {
            navigateToDetails(item.slug);
          }
        }}
        onMouseDown={(e) => {
          if (e.button === 1) {
            e.preventDefault();
            e.stopPropagation();
            window.open(
              `${Navigation.navigateToProductList}/${item.slug}`,
              "_blank"
            );
          }
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Enter") {
            navigateToDetails(item.slug);
          }
        }}
      >
        <div className="prod-image">
          <LazyImage
            className="prod-thumbnail"
            src={`${imgHost}/${
              cardProd ? cardProd.thumb_image : item.thumb_image
            }`}
            alt="image"
            loadingImg={setImageLoading}
          />

          <label
            className="prod-condition-ribbon"
            id={`prodConditionLabelForIndexId_${id}`}
            style={{
              backgroundColor: getBackgroundColor(item.condition),
              "--border-color": getBorderColor(item.condition),
            }}
          >
            {item.condition.toUpperCase()}
          </label>
        </div>
        <div className="prod-desc">
          <p
            className="prod-selling-price"
            id={`prodSellingPriceForIndexId_${id}`}
          >
            <span className="productCardRupeeSymbol">₹</span>{" "}
            {cardProd ? (
              <>
                {formatNumberWithCommas(cardProd.selling_price)}{" "}
                {getOfferPercentage(
                  cardProd.current_market_price,
                  cardProd.selling_price
                ) > 0 && (
                  <span className="offer-discount">
                    {getOfferPercentage(
                      cardProd.current_market_price,
                      cardProd.selling_price
                    )}
                    % off
                  </span>
                )}
              </>
            ) : (
              <>
                {formatNumberWithCommas(item.selling_price)}{" "}
                {getOfferPercentage(
                  item.current_market_price,
                  item.selling_price
                ) > 0 && (
                  <span className="offer-discount">
                    {getOfferPercentage(
                      item.current_market_price,
                      item.selling_price
                    )}
                    % off
                  </span>
                )}
              </>
            )}
          </p>
          <p
            className="prod-estimated-price"
            id={`prodEstimatedPriceForIndexId_${id}`}
          >
            (Est. Original Price:{" "}
            <span>
              ₹{" "}
              {formatNumberWithCommas(
                cardProd
                  ? cardProd.current_market_price
                  : item.current_market_price
              )}
            </span>
            )
          </p>
          <p className="prod-title" id={`prodTitleForIndexId_${id}`}>
            {item.name}
          </p>
        </div>
        <div
          className={`cta-button ${
            showFeatures.enableHover ? "card-hover" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {/* Add to cart btn */}

          {/* Add to Wishlist btn */}
          {showFeatures.showWishList && (
            <button
              tabIndex={0}
              type="button"
              className="wishlist-button"
              aria-label={`prodAddToWishlistButtonForIndexId_${id}`}
              id={`prodAddToWishlistButtonForIndexId_${id}`}
              style={{ cursor: isInWishlist ? "default" : "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!isInWishlist) {
                  handleAddToWishlist();
                  setIsLoading(true);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  if (!isInWishlist) {
                    handleAddToWishlist();
                    setIsLoading(true);
                    e.preventDefault();
                  }
                }
              }}
            >
              {isLoggedIn && isLoading ? (
                <RollingSvg className="rolling-svg-icon" />
              ) : (
                <>
                  {isInWishlist || item.is_in_wishlist ? (
                    <FontAwesomeIcon icon={faHeartFill} color="#ea5e3d" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faHeart}
                      color="#ea5e3d"
                      className="empty-heart"
                    />
                  )}
                </>
              )}
            </button>
          )}

          <button
            type="button"
            tabIndex={0}
            className={
              enableAddToCart
                ? "add-to-cart-button"
                : "add-to-cart-button disabledElement"
            }
            id={`prodAddToCartButtonForIndexId_${id}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (enableAddToCart) {
                const checkLoggedIn = getDataFromCookies("isLoggedIn");
                if (checkLoggedIn && window.innerWidth > 600) {
                  contextObj.setInput("showCartSidebar", true);
                }
                setEnableAddToCart(false);
                handleAddToCart();
              }
              if (item.default_stock.quantity < 1) {
                showToast(
                  "error",
                  Constants.alertMessages.alertForStockNotAvailable
                );
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                if (enableAddToCart) {
                  const checkLoggedIn = getDataFromCookies("isLoggedIn");
                  if (checkLoggedIn && window.innerWidth > 600) {
                    contextObj.setInput("showCartSidebar", true);
                  }
                  setEnableAddToCart(false);
                  handleAddToCart();
                }
              }
            }}
            style={{
              width: showFeatures.showWishList ? "70%" : "100%",
            }}
          >
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
