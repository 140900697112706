import React, { useEffect, useState, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHome,
  faPhone,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import endpoints from "../../config/APIEndpoints.json";
import Constants from "../../config/ProjectConstants.json";
import Toast from "../ReactToastify/Toast";
import navigation from "../../config/Navigation.json";
import ReactStars from "react-rating-stars-component";
import { handleScrollToTop } from "../../resuableFunctions/resuableFunctions";
import "./footer1.css";
import loadingImgPath from "../../Assets/rolling.svg";

// import constants
const APP_NAME = process.env.REACT_APP_NAME;
const SUPPORT_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const SUPPORT_PHONE = process.env.REACT_APP_ADMIN_PHONE;
const API_HOST = process.env.REACT_APP_API_HOST;
const SELLER_URL = process.env.REACT_APP_SELLER_DOMAIN;
const APP_SLOGAN = process.env.REACT_APP_SLOGAN;
const COMPANY_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;

const Footer1 = () => {
  // alert("Footer1");
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = Toast();
  const contextObj = useContext(ParamsContext);
  const [currYear, setCurrYear] = useState("");
  const [emailValues, setEmailValues] = useState("");
  const [errorForEmail, setErrorForEmail] = useState("");
  const [enableFooter, setEnableFooter] = useState(true);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isSubmitButtonDisable, setIsSubmitButtonDisable] = useState(false);
  const [ratingDataDetails, setRatingDataDetails] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    rating: "",
    environment: "Buyer",
    is_anonymous: null,
  });
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setIsChecked(event.target.checked);
  };
  const [showFeatures, setShowFeatures] = useState({
    showFeedBack: false,
  });
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setCurrYear(currentYear.toString());

    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setEnableFooter(featureFlags.buyer_common_footer);
      setShowFeatures((prev) => ({
        ...prev,
        showFeedBack: featureFlags.buyer_feedback,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const isValidEmail = (email) => {
    const regex =
      /^[0-9|a-z|A-Z]+([\.-]?[0-9|a-z|A-Z]+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(
        email
      );
    return regex;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\s/g, "");
    setEmailValues(sanitizedValue);
    setErrorForEmail("");
  };

  useEffect(() => {
    setEmailValues("");
    setErrorForEmail("");
  }, [location]);
  const ClickSumbitButton = () => {
    if (emailValues === "") {
      setErrorForEmail(Constants.alertMessages.alertForEmail);
    } else if (isValidEmail(emailValues) === false) {
      setErrorForEmail(Constants.alertMessages.alertForValidEmail);
    } else if (isValidEmail(emailValues) === true) {
      const data = {
        email: emailValues,
      };
      const payload = JSON.stringify(data);
      fetch(`${API_HOST}/${endpoints.newsLetter}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: payload,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (
            data.status == 500 ||
            data.status == 501 ||
            data.status == 502 ||
            data.status == 503 ||
            (data.message &&
              data.message.toLowerCase().includes("server error"))
          ) {
            contextObj.setInput("serviceUnavailable", false);
            showToast(
              "error",
              Constants.alertMessages.alertForInternalServerError
            );
            setEmailValues("");
          } else if (data.status === 201) {
            showToast("success", Constants.alertMessages.alertForContact);
            setEmailValues("");
          } else if (data.status === 409) {
            showToast("info", Constants.alertMessages.alertFoExistingEmail);
            setEmailValues("");
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.message.includes("Failed to fetch")) {
            contextObj.setInput("serviceUnavailable", true);
          }
        });
    }
  };

  const ratingChanged = (newRating) => {
    setRatingError("");
    setRatingDataDetails((prevData) => ({
      ...prevData,
      rating: newRating,
    }));
  };

  const handleCloseFeedback = () => {
    setShowFeedbackModal(false);
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setDescriptionError("");
    setRatingError("");
    setIsChecked(false);
    setRatingDataDetails((prevData) => ({
      ...prevData,
      name: "",
      phone: "",
      email: "",
      message: "",
      rating: "",
      environment: "Buyer",
      is_anonymous: null,
    }));
  };
  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "email") {
      setEmailError("");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "message") {
      setDescriptionError("");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "phone") {
      setPhoneError("");
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      }
    } else if (name === "name") {
      setNameError("");
      if (/^[A-Za-z\s]+$/.test(value)) {
        if (value.trim() !== "" || value == "") {
          setRatingDataDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      if (value.trim() == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value.trim(),
        }));
      }
    }
  };

  const createFeedback = async () => {
    setIsSubmitButtonDisable(true);
    let errorHas = false;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (isChecked) {
      if (ratingDataDetails.name != "" && ratingDataDetails.name != null) {
        if (
          ratingDataDetails.name.length < 2 ||
          ratingDataDetails.name.length > 50
        ) {
          setNameError("Name should be between 2 and 50 characters.");
          errorHas = true;
        } else {
          setNameError("");
        }
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        if (!isPhoneNumber) {
          setPhoneError("Please enter a valid Phone.");
          errorHas = true;
        } else {
          setPhoneError("");
        }
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        if (!isEmail) {
          setEmailError("Please enter a valid Email.");
          errorHas = true;
        } else if (
          ratingDataDetails.email.length < 5 ||
          ratingDataDetails.email.length > 100
        ) {
          setEmailError("Email should be between 3 and 150 characters.");
          errorHas = true;
        } else {
          setEmailError("");
        }
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    } else {
      if (ratingDataDetails.name === "" || ratingDataDetails.name === null) {
        setNameError("Name is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.name.length < 2 ||
        ratingDataDetails.name.length > 50
      ) {
        setNameError("Name should be between 2 and 50 characters.");
        errorHas = true;
      } else {
        setNameError("");
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone === "" || ratingDataDetails.phone === null) {
        setPhoneError("Phone is required.");
        errorHas = true;
      } else if (!isPhoneNumber) {
        setPhoneError("Please enter a valid Phone.");
        errorHas = true;
      } else {
        setPhoneError("");
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email === "" || ratingDataDetails.email === null) {
        setEmailError("Email is required.");
        errorHas = true;
      } else if (!isEmail) {
        setEmailError("Please enter a valid Email.");
        errorHas = true;
      } else if (
        ratingDataDetails.email.length < 5 ||
        ratingDataDetails.email.length > 100
      ) {
        setEmailError("Email should be between 3 and 150 characters.");
        errorHas = true;
      } else {
        setEmailError("");
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        errorHas = true;
        setDescriptionError("Description is required.");
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    }
    if (errorHas == true) {
      setIsSubmitButtonDisable(false);

      return;
    }
    const payload = {};
    if (isChecked) {
      if (ratingDataDetails.name != "" && ratingDataDetails.name != null) {
        payload.name = ratingDataDetails.name;
      }
      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        payload.phone = ratingDataDetails.phone;
      }
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        payload.email = ratingDataDetails.email;
      }
      payload.is_anonymous = true;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
    } else {
      payload.is_anonymous = false;
      payload.name = ratingDataDetails.name;
      payload.phone = ratingDataDetails.phone;
      payload.email = ratingDataDetails.email;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
    }
    const response = await fetch(`${API_HOST}/api/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setIsSubmitButtonDisable(false);
    if (res.status === 201) {
      showToast(
        "success",
        "Thank you for your feedback. Your Feedback is valued and appreciated by JOYREJOY"
      );
      handleCloseFeedback();
    } else if (res.status === 409) {
    } else if (res.status === 404) {
    } else if (res.status === 400) {
    } else if (res.status === 403) {
    } else {
    }
  };

  return (
    <>
      {enableFooter && (
        <section id="footer">
          <div className="footer-container">
            <div className="main-footer">
              <div className="org-info">
                <h1>{APP_NAME}</h1>
                <p className="tag-line">{APP_SLOGAN}</p>
                <div className="contact-details">
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faEnvelope} className="icons" />{" "}
                      <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faPhone} className="icons" />{" "}
                      <a href={`tel:${SUPPORT_PHONE}`}>+91 {SUPPORT_PHONE}</a>
                    </li>
                    <li style={{ maxWidth: "250px", whiteSpace: "pre-wrap" }}>
                      <FontAwesomeIcon icon={faHome} className="icons" />{" "}
                      <a
                        href="https://maps.app.goo.gl/3k8zLqdmA8yNoULJ6"
                        target="_blank"
                      >
                        {COMPANY_ADDRESS}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h2>For All</h2>
                <ul>
                  <li
                    tabIndex="0"
                    onClick={async () => {
                      let filterParamsUpdated = [
                        { columnName: "brand_id", values: [] },
                        { columnName: "category_id", values: [] },
                        { columnName: "age_group", values: [] },
                        { columnName: "condition", values: [] },
                        { columnName: "price_range", values: [] },
                        { columnName: "sort", values: [] },
                      ];
                      sessionStorage.setItem(
                        "filterParamsUpdated",
                        JSON.stringify(filterParamsUpdated)
                      );

                      let currentRoute = location.pathname;
                      if (currentRoute == navigation.navigateToProductList) {
                        contextObj.setInput("resetSelectedCategoryId", true);
                        window.location.reload();
                      } else {
                        contextObj.setInput("resetSelectedCategoryId", true);
                        navigate(navigation.navigateToProductList);
                      }
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    All Products
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate(navigation.navigateToAboutUs);
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    About Us
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate(navigation.navigateToContact);
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Contact Us
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate(navigation.navigateToBlogs);
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Blogs
                  </li>
                  {showFeatures.showFeedBack ? (
                    <li
                      tabIndex="0"
                      onClick={() => {
                        setShowFeedbackModal(true);
                      }}
                      id="FooterFeedbackOption"
                      onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                    >
                      Feedback
                    </li>
                  ) : (
                    <>
                      <span />
                    </>
                  )}
                </ul>
              </div>
              <div>
                <h2>For Seller</h2>
                <ul>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      window.open(SELLER_URL, "_blank");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Join As Seller
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/seller-guideline");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Seller's Guidelines
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/faq");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    FAQs
                  </li>
                </ul>
              </div>
              <div>
                <h2>Quick Links</h2>
                <ul>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/return-policy");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Return Policy
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/refund-policy");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Refund Policy
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/shipping-policy");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Shipping Policy
                  </li>
                </ul>
              </div>
              <div>
                <h2>News Letter</h2>
                <p>Subscribe for the latest updates.</p>
                <div className="newsletter-line">
                  <input
                    autoComplete="off"
                    required
                    type="email"
                    name="email"
                    className="newsletter-input"
                    placeholder="Enter email address"
                    value={emailValues}
                    onChange={handleInputChange}
                  />
                  <button
                    type="submit"
                    className="newsletter-submit-btn"
                    onClick={ClickSumbitButton}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    Submit
                  </button>
                </div>
                <li
                  className="errorMessage-Email"
                  style={{
                    cursor: "default",
                    textDecoration: "none",
                  }}
                >
                  {errorForEmail}
                </li>
              </div>
            </div>
          </div>
          <div className="footer-last-line">
            <div className="footer-copyright">
              <p className="right-reserved">
                &copy; {currYear} {APP_NAME} by Mplify Tech Services Pvt Ltd.
                All Rights Reserved.
              </p>
              <p>
                <span
                  className="openPolicyDocs"
                  onClick={() => {
                    if (location.pathname == "/privacy-policy") {
                      handleScrollToTop();
                    } else {
                      // window.open("/cookie-policy", "_blank");
                      navigate("/privacy-policy");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </span>{" "}
                |{" "}
                <span
                  className="openPolicyDocs"
                  onClick={() => {
                    if (location.pathname == "/cookie-policy") {
                      handleScrollToTop();
                    } else {
                      // window.open("/cookie-policy", "_blank");
                      navigate("/cookie-policy");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Cookie Policy
                </span>{" "}
                |{" "}
                <span
                  className="openPolicyDocs"
                  onClick={() => {
                    if (location.pathname == "/terms-and-condition") {
                      handleScrollToTop();
                    } else {
                      // window.open("/cookie-policy", "_blank");
                      navigate("/terms-and-condition");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Terms And Conditions
                </span>
              </p>
            </div>
          </div>
          {showFeedbackModal && (
            <div className="Feedback_modal_container">
              <div className="Feedback_modal_c2">
                <div className="cross_button_container">
                  <button
                    className="cross_button"
                    id="closeFeedBackModelBtn"
                    onClick={handleCloseFeedback}
                  >
                    <FontAwesomeIcon style={{ color: "#595959" }} icon={faX} />
                  </button>
                </div>
                <h1 id="Feedback_Heading"> Feedback Form</h1>
                <div>
                  <label className="login-label-tag">
                    <input
                      type="text"
                      className="email-phone-input login_input_tag"
                      onChange={handleChange}
                      name="name"
                      id="feedBackName"
                      placeholder={`Name ${isChecked ? "(optional)" : ""}`}
                      maxLength={50}
                      value={ratingDataDetails.name}
                    />
                    <span className="login-Span-tags">
                      {" "}
                      Name {isChecked ? "(optional)" : " "}
                    </span>
                  </label>
                  <li className="fotter-error" id="feedBackNameError">
                    {nameError}
                  </li>
                </div>
                <div>
                  <label className="login-label-tag">
                    <input
                      type="text"
                      className="email-phone-input login_input_tag"
                      onChange={handleChange}
                      name="phone"
                      placeholder={`Phone ${isChecked ? "(optional)" : ""}`}
                      maxLength={10}
                      id="feedBackPhone"
                      value={ratingDataDetails.phone}
                    />
                    <span className="login-Span-tags">
                      {" "}
                      Phone {isChecked ? "(optional)" : " "}
                    </span>
                  </label>
                  <li className="fotter-error" id="feedBackPhoneError">
                    {phoneError}
                  </li>
                </div>
                <div>
                  <label className="login-label-tag">
                    <input
                      type="text"
                      className="email-phone-input login_input_tag"
                      onChange={handleChange}
                      id="feedBackEmail"
                      name="email"
                      placeholder={`Email ${isChecked ? "(optional)" : ""}`}
                      maxLength={50}
                      value={ratingDataDetails.email}
                    />
                    <span className="login-Span-tags">
                      {" "}
                      Email {isChecked ? "(optional)" : " "}
                    </span>
                  </label>
                  <li className="fotter-error" id="feedBackEmailError">
                    {emailError}
                  </li>
                </div>
                <div>
                  <label className="login-label-tag">
                    <textarea
                      type="text"
                      className="email-phone-input login_input_tag footer-textarea"
                      onChange={handleChange}
                      id="feedBackDescription"
                      maxLength={500}
                      minLength={10}
                      name="message"
                      placeholder="Tell us more about your experience."
                      value={ratingDataDetails.message}
                    />
                    <span className="login-Span-tags">
                      {" "}
                      Tell us more about your experience.
                    </span>
                  </label>
                  <li className="fotter-error" id="feedBackDescriptionError">
                    {descriptionError}
                  </li>
                </div>
                <div>
                  <p className="rate_your_experience">
                    How would you rate your experience?
                  </p>
                  <div id="feedbackRatingContainer">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={40}
                      isHalf={false}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ff9f00"
                    />
                  </div>
                  <li className="fotter-error" id="feedBackRatingError">
                    {ratingError}
                  </li>
                </div>
                <div className="ckeckbox-container">
                  <label className="checkbox-label">
                    <input
                      className="checkbox-input"
                      id="SendAnonymousFeedbackInput"
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    {"  "}
                    Send Anonymous Feedback
                  </label>
                </div>
                <button
                  onClick={createFeedback}
                  className="submit-button"
                  id="submitFeedbackBtn"
                  disabled={isSubmitButtonDisable}
                >
                  {isSubmitButtonDisable ? (
                    <img
                      src={loadingImgPath}
                      alt="Loading"
                      className="disabledBtn-Loading"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default Footer1;
