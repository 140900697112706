import React from "react";

const HomePageSkeleton = () => {
  return (
    <div
      className="skeleton-loading-forHome"
      style={{
        position: "relative",
      }}
    >
      <div className="skeleton-HomePageColumn1 loaderEffect"></div>
      <div className="skeleton-HomePageColumn2 ">
        <div className="skeleton-circleRow loaderEffect"></div>
        <div className="skeleton-circleRow loaderEffect"></div>
        <div className="skeleton-circleRow loaderEffect"></div>
        <div className="skeleton-circleRow loaderEffect"></div>
        <div className="skeleton-circleRow loaderEffect"></div>
        <div className="skeleton-circleRow loaderEffect"></div>
      </div>
      <div className="skeleton-HomePageColumn2ForMobile">
        <div className="skeleton-circleRow loaderEffect"></div>
        <div className="skeleton-circleRow loaderEffect"></div>
        <div className="skeleton-circleRow loaderEffect"></div>
      </div>
      <div className="skeleton-HomePageColumn3">
        {/* <div className="skeleton-HomePageColumn3-InnerContainer">
            // <div className="skeleton-HomePageColumn3-Heading"></div> 
            <div className="skeleton-HomePageColumn3-InnerContainer-GridImplimentation">
              <div className="skeleton-HomePageColumn3-InnerBox"></div>
              <div className="skeleton-HomePageColumn3-InnerBox"></div>
              <div className="skeleton-HomePageColumn3-InnerBox"></div>
              <div className="skeleton-HomePageColumn3-InnerBox"></div>
            </div>
          </div> */}
        <div className="skeleton-HomePageColumn3-InnerContainer">
          {/* <div className="skeleton-HomePageColumn3-Heading"></div> */}
          <div className="skeleton-HomePageColumn3-InnerContainer-GridImplimentation">
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
          </div>
        </div>
        <div className="skeleton-HomePageColumn3-InnerContainer">
          {/* <div className="skeleton-HomePageColumn3-Heading"></div> */}
          <div className="skeleton-HomePageColumn3-InnerContainer-GridImplimentation">
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
          </div>
        </div>
        <div className="skeleton-HomePageColumn3-InnerContainer">
          {/* <div className="skeleton-HomePageColumn3-Heading"></div> */}
          <div className="skeleton-HomePageColumn3-InnerContainer-GridImplimentation">
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
            <div className="skeleton-HomePageColumn3-InnerBox loaderEffect"></div>
          </div>
        </div>
      </div>

      {/*  */}

      <div className="sk-product-row">
        <div className="sk-product-card loaderEffect">
          <div className="sk-prod-img loaderEffect"></div>
          <div className="sk-prod-selling-price loaderEffect"></div>
          <div className="sk-prod-original-price loaderEffect"></div>
          <div className="sk-prod-name loaderEffect"></div>
        </div>
        <div className="sk-product-card loaderEffect">
          <div className="sk-prod-img loaderEffect"></div>
          <div className="sk-prod-selling-price loaderEffect"></div>
          <div className="sk-prod-original-price loaderEffect"></div>
          <div className="sk-prod-name loaderEffect"></div>
        </div>
        <div className="sk-product-card loaderEffect">
          <div className="sk-prod-img loaderEffect"></div>
          <div className="sk-prod-selling-price loaderEffect"></div>
          <div className="sk-prod-original-price loaderEffect"></div>
          <div className="sk-prod-name loaderEffect"></div>
        </div>
        <div className="sk-product-card loaderEffect">
          <div className="sk-prod-img loaderEffect"></div>
          <div className="sk-prod-selling-price loaderEffect"></div>
          <div className="sk-prod-original-price loaderEffect"></div>
          <div className="sk-prod-name loaderEffect"></div>
        </div>
      </div>

      {/*  */}
    </div>
  );
};
export default HomePageSkeleton;
