import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import navigation from "../../config/Navigation.json";
import ProfileImage from "../../Assets/Profile.webp";
import UpdatedNavbar from "../Navbar/TopNavbar";
import { logout } from "../../resuableFunctions/resuableFunctions";
import { getDataFromCookies } from "../Cookies/Cookies";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import Toast from "../ReactToastify/Toast";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
export const UserSideNavbar = () => {
  const { showToast } = Toast();
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const contextObj = useContext(ParamsContext);
  useEffect(() => {
    const isLoggedIn = getDataFromCookies("isLoggedIn");
    if (isLoggedIn !== true) {
      navigate(`${navigation.navigateToHomepage}`);
    }
  }, []);
  const [CharacterToShow, setCharacterToShow] = useState(0);
  const [showFeatures, setShowFeatures] = useState({
    showWishList: true,
    myOrders: true,

    showMyWallet: true,
    searchbar: true,
  });
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    setCharacterToShow(window.outerWidth > 600 ? 18 : 12);
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showWishList: featureFlags.buyer_products_wishlist,
        myOrders: featureFlags.buyer_profile_myorders,
        showMyWallet: featureFlags.buyer_profile_mywallet,
        searchbar: featureFlags.buyer_products_search,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const location = useLocation();
  const navigate = useNavigate();

  const isActiveLink = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <>
          <div className="ShowforDesktopView">
            <div className="buyerUserTopProfileSideNavbarsection">
              <div className="userProfileImageContainer">
                <img
                  className="profileImage"
                  src={ProfileImage}
                  onError={(e) => {
                    e.target.src = placeHolderImage;
                  }}
                  alt="Profile.webp"
                />
              </div>

              <div className="namedetailsDiv">
                <p className="profileHelloName">Hello,</p>
                <p className="profileNameofusers">
                  {`${
                    contextObj.params.userProfile &&
                    contextObj.params.userProfile.f_name
                      ? contextObj.params.userProfile.f_name
                      : ""
                  } ${
                    contextObj.params.userProfile &&
                    contextObj.params.userProfile.l_name
                      ? contextObj.params.userProfile.l_name
                      : ""
                  }`}
                </p>
              </div>
            </div>
            <div className="buyerUserSideNavbar">
              <p
                className={`buyerUserSideNavbarAnchor ${isActiveLink(
                  "/profile-info"
                )}`}
                id="buyerUserSideNavbar_ProfileInfo"
                onClick={() => {
                  let currentRoute = location.pathname;
                  if (currentRoute == navigation.navigateToProfileInfo) {
                    window.location.reload();
                  } else {
                    navigate(`${navigation.navigateToProfileInfo}`);
                  }
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    stroke: isActiveLink("/profile-info")
                      ? "#ea5e3d"
                      : "#979797",
                  }}
                >
                  <path
                    d="M7.92143 2.6C7.92143 3.68375 7.05446 4.55 6 4.55C4.94558 4.55 4.07857 3.68374 4.07857 2.6C4.07857 1.51626 4.94558 0.65 6 0.65C7.05446 0.65 7.92143 1.51625 7.92143 2.6ZM0.688074 12.35C1.00548 9.65975 3.26837 7.58333 6 7.58333C8.73164 7.58333 10.9945 9.65975 11.3119 12.35H0.688074Z"
                    stroke-width="1.3"
                  />
                </svg>{" "}
                <button
                  className={`myorderline ${isActiveLink("/profile-info")}`}
                >
                  Profile Information
                </button>
              </p>
              <p
                id="buyerUserSideNavbar_AddressList"
                className={`buyerUserSideNavbarAnchor ${isActiveLink(
                  "/address-list"
                )}`}
                onClick={() => {
                  let currentRoute = location.pathname;
                  if (currentRoute == navigation.navigateToAddressList) {
                    window.location.reload();
                  } else {
                    navigate(`${navigation.navigateToAddressList}`);
                  }
                }}
              >
                <svg
                  style={{
                    stroke: isActiveLink("/address-list")
                      ? "#ea5e3d"
                      : "#979797",
                  }}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 7L2.33333 5.66667M2.33333 5.66667L7 1L11.6667 5.66667M2.33333 5.66667V12.3333C2.33333 12.7015 2.63181 13 3 13H5M11.6667 5.66667L13 7M11.6667 5.66667V12.3333C11.6667 12.7015 11.3682 13 11 13H9M5 13C5.36819 13 5.66667 12.7015 5.66667 12.3333V9.66667C5.66667 9.29847 5.96513 9 6.33333 9H7.66667C8.03487 9 8.33333 9.29847 8.33333 9.66667V12.3333C8.33333 12.7015 8.6318 13 9 13M5 13H9"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
                <button
                  className={`myorderline ${isActiveLink("/address-list")}`}
                >
                  Manage Addresses
                </button>
              </p>
              {showFeatures.myOrders && (
                <p
                  id="buyerUserSideNavbar_MyOrders"
                  className={`buyerUserSideNavbarAnchor ${isActiveLink(
                    "/my-order"
                  )}`}
                  onClick={() => {
                    let currentRoute = location.pathname;
                    if (currentRoute == navigation.navigateToOrder) {
                      window.location.reload();
                    } else {
                      navigate(`${navigation.navigateToOrder}`);
                    }
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    style={{
                      stroke: isActiveLink("/my-order") ? "#ea5e3d" : "#979797",
                    }}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.2 0.7H1.8C1.19248 0.7 0.7 1.19249 0.7 1.8V10.2C0.7 10.8075 1.1925 11.3 1.8 11.3H10.2C10.8075 11.3 11.3 10.8075 11.3 10.2V1.8C11.3 1.19249 10.8075 0.7 10.2 0.7ZM0.5 1.8C0.5 1.08203 1.08203 0.5 1.8 0.5H10.2C10.918 0.5 11.5 1.08203 11.5 1.8V10.2C11.5 10.918 10.918 11.5 10.2 11.5H1.8C1.08203 11.5 0.5 10.918 0.5 10.2V1.8ZM6 5.5C4.82444 5.5 3.72947 4.43677 3.53168 2.9H3.73293C3.9291 4.36003 4.9701 5.3 6 5.3C7.0299 5.3 8.0709 4.36003 8.26707 2.9H8.46832C8.27053 4.43676 7.17555 5.5 6 5.5Z" />
                  </svg>{" "}
                  <button
                    className={`myorderline ${isActiveLink("/my-order")}`}
                  >
                    My Orders
                  </button>
                </p>
              )}
              {showFeatures.showWishList && (
                <p
                  id="buyerUserSideNavbar_WishList"
                  className={`buyerUserSideNavbarAnchor ${isActiveLink(
                    "/wish-list"
                  )}`}
                  onClick={() => {
                    let currentRoute = location.pathname;
                    if (currentRoute == navigation.navigateToWishlist) {
                      window.location.reload();
                    } else {
                      navigate(`${navigation.navigateToWishlist}`);
                    }
                  }}
                >
                  <svg
                    style={{
                      stroke: isActiveLink("/wish-list")
                        ? "#ea5e3d"
                        : "#979797",
                    }}
                    width="14"
                    height="14"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.003 6.89689L7.00555 12L2.00814 6.89689M2.00814 6.89689C1.67852 6.56617 1.41888 6.16867 1.24557 5.72941C1.07226 5.29015 0.989044 4.81865 1.00116 4.3446C1.01327 3.87055 1.12044 3.40422 1.31594 2.97498C1.51143 2.54574 1.79101 2.16288 2.13707 1.85051C2.48312 1.53814 2.88816 1.30304 3.32667 1.16C3.76518 1.01696 4.22766 0.969078 4.685 1.01938C5.14234 1.06968 5.58462 1.21707 5.984 1.45227C6.38338 1.68747 6.73119 2.00538 7.00555 2.38598C7.2811 2.00814 7.62932 1.69301 8.02842 1.46031C8.42752 1.2276 8.86892 1.08234 9.32497 1.03361C9.78103 0.984884 10.2419 1.03374 10.6788 1.17711C11.1157 1.32049 11.5192 1.5553 11.8641 1.86685C12.2089 2.1784 12.4877 2.55999 12.6829 2.98773C12.8781 3.41546 12.9856 3.88015 12.9987 4.35269C13.0117 4.82523 12.93 5.29546 12.7587 5.73395C12.5873 6.17244 12.3301 6.56975 12.003 6.90101"
                      fill="white"
                    />
                    <path
                      d="M12.003 6.89689L7.00555 12L2.00814 6.89689C1.67852 6.56617 1.41888 6.16867 1.24557 5.72941C1.07226 5.29015 0.989044 4.81865 1.00116 4.3446C1.01327 3.87055 1.12044 3.40422 1.31594 2.97498C1.51143 2.54574 1.79101 2.16288 2.13707 1.85051C2.48312 1.53814 2.88816 1.30304 3.32667 1.16C3.76518 1.01696 4.22766 0.969078 4.685 1.01938C5.14234 1.06968 5.58462 1.21707 5.984 1.45227C6.38338 1.68747 6.73119 2.00538 7.00555 2.38598C7.2811 2.00814 7.62932 1.69301 8.02842 1.46031C8.42752 1.2276 8.86892 1.08234 9.32497 1.03361C9.78103 0.984884 10.2419 1.03374 10.6788 1.17711C11.1157 1.32049 11.5192 1.5553 11.8641 1.86685C12.2089 2.1784 12.4877 2.55999 12.6829 2.98773C12.8781 3.41546 12.9856 3.88015 12.9987 4.35269C13.0117 4.82523 12.93 5.29546 12.7587 5.73395C12.5873 6.17244 12.3301 6.56975 12.003 6.90101"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  <button
                    className={`myorderline ${isActiveLink("/wish-list")}`}
                  >
                    My Wishlist
                  </button>
                </p>
              )}
              {showFeatures.showMyWallet && (
                <p
                  id="buyerUserSideNavbar_MyWallet"
                  className={`buyerUserSideNavbarAnchor ${isActiveLink(
                    "/mywallet"
                  )}`}
                  onClick={() => {
                    let currentRoute = location.pathname;
                    if (currentRoute == navigation.navigateToMyWallet) {
                      window.location.reload();
                    } else {
                      navigate(`${navigation.navigateToMyWallet}`);
                    }
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      stroke: isActiveLink("/mywallet") ? "#ea5e3d" : "#979797",
                    }}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.9008 11.0016V9.00156C21.9008 7.39956 20.6031 6.10156 19.0014 6.10156C15.3515 6.10156 7.65086 6.10156 4.00096 6.10156C2.39926 6.10156 1.10156 7.39956 1.10156 9.00156C1.10156 11.6626 1.10156 16.3406 1.10156 19.0016C1.10156 20.6036 2.39926 21.9016 4.00096 21.9016C7.65086 21.9016 15.3515 21.9016 19.0014 21.9016C20.6031 21.9016 21.9008 20.6036 21.9008 19.0016C21.9008 18.0016 21.9008 17.0016 21.9008 17.0016C21.9008 16.5046 21.4981 16.1016 21.0012 16.1016C20.5044 16.1016 20.1016 16.5046 20.1016 17.0016C20.1016 17.0016 20.1016 18.0016 20.1016 19.0016C20.1016 19.6096 19.6085 20.1016 19.0014 20.1016C15.3515 20.1016 7.65086 20.1016 4.00096 20.1016C3.39391 20.1016 2.90085 19.6096 2.90085 19.0016C2.90085 16.3406 2.90085 11.6626 2.90085 9.00156C2.90085 8.39356 3.39391 7.90155 4.00096 7.90155C7.65086 7.90155 15.3515 7.90155 19.0014 7.90155C19.6085 7.90155 20.1016 8.39356 20.1016 9.00156V11.0016C20.1016 11.4986 20.5044 11.9016 21.0012 11.9016C21.4981 11.9016 21.9008 11.4986 21.9008 11.0016Z"
                      fill="#979797"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.40167 7.80474C4.40167 7.80474 9.95666 5.02775 13.5077 3.25175C13.8487 3.08175 14.2537 3.09975 14.5777 3.29975C14.9027 3.50075 15.0997 3.85474 15.0997 4.23574C15.0997 5.65274 15.0997 6.99975 15.0997 6.99975H16.8997C16.8997 6.99975 16.8997 5.65274 16.8997 4.23574C16.8997 3.23074 16.3797 2.29775 15.5247 1.76875C14.6697 1.24075 13.6017 1.19275 12.7027 1.64175C9.15167 3.41775 3.59766 6.19476 3.59766 6.19476L4.40167 7.80474Z"
                      fill="#979797"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.9016 13.0016C22.9016 11.3996 21.6036 10.1016 20.0016 10.1016C19.0946 10.1016 18.0036 10.1016 17.0016 10.1016C14.8476 10.1016 13.1016 11.8476 13.1016 14.0016C13.1016 16.1556 14.8476 17.9016 17.0016 17.9016C18.0036 17.9016 19.0946 17.9016 20.0016 17.9016C21.6036 17.9016 22.9016 16.6036 22.9016 15.0016V13.0016ZM21.1016 13.0016C21.1016 12.3936 20.6096 11.9016 20.0016 11.9016C19.0946 11.9016 18.0036 11.9016 17.0016 11.9016C15.8416 11.9016 14.9016 12.8416 14.9016 14.0016C14.9016 15.1616 15.8416 16.1016 17.0016 16.1016C18.0036 16.1016 19.0946 16.1016 20.0016 16.1016C20.6096 16.1016 21.1016 15.6096 21.1016 15.0016V13.0016Z"
                      fill="#979797"
                    />
                    <path
                      d="M17.008 15.0013C17.5604 15.0013 18.0082 14.5533 18.0082 14.0007C18.0082 13.448 17.5604 13 17.008 13C16.4556 13 16.0078 13.448 16.0078 14.0007C16.0078 14.5533 16.4556 15.0013 17.008 15.0013Z"
                      fill="#979797"
                    />
                  </svg>

                  <button
                    className={`myorderline ${isActiveLink("/mywallet")}`}
                  >
                    My Wallet
                  </button>
                </p>
              )}

              <p
                id="secondcartstyle"
                className={`buyerUserSideNavbarAnchor ${isActiveLink("/cart")}`}
                onClick={() => {
                  let currentRoute = location.pathname;
                  if (currentRoute == navigation.navigateToNewCartPage) {
                    window.location.reload();
                  } else {
                    navigate(`${navigation.navigateToNewCartPage}`);
                  }
                }}
              >
                <div>
                  <svg
                    style={{
                      stroke: isActiveLink("/cart") ? "#ea5e3d" : "#979797",
                    }}
                    width="15"
                    height="16"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1H2.22222L2.46667 2.33333M2.46667 2.33333H12L9.55556 7.66667H3.44444M2.46667 2.33333L3.44444 7.66667M3.44444 7.66667L2.04323 9.19527C1.65825 9.61527 1.93091 10.3333 2.47535 10.3333H9.55556M9.55556 10.3333C8.88052 10.3333 8.33333 10.9303 8.33333 11.6667C8.33333 12.4031 8.88052 13 9.55556 13C10.2306 13 10.7778 12.4031 10.7778 11.6667C10.7778 10.9303 10.2306 10.3333 9.55556 10.3333ZM4.66667 11.6667C4.66667 12.4031 4.11946 13 3.44444 13C2.76943 13 2.22222 12.4031 2.22222 11.6667C2.22222 10.9303 2.76943 10.3333 3.44444 10.3333C4.11946 10.3333 4.66667 10.9303 4.66667 11.6667Z"
                      stroke="#979797"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <button className={`myorderline ${isActiveLink("/cart")}`}>
                    My Cart
                  </button>
                </div>
                <div className="arrowSVG">
                  <svg
                    width="9"
                    height="13"
                    viewBox="0 0 7 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                      fill="#8C8C8C"
                    />
                  </svg>
                </div>
              </p>
              <hr className="mediasidenavhrline" />
              <p
                style={{
                  marginBottom: "7px",
                }}
                id="buyerUserSideNavbar_Logout"
                className="buyerUserSideNavbarAnchor"
                onClick={async () => {
                  contextObj.setInput("userProfile", null);
                  await logout();
                  // await contextObj.setInput("isLoggedIn", false);
                  // await contextObj.setInput("cartLength", 0);
                  await contextObj.setInput(
                    "triggerReSetContextObj",
                    true
                  );
                  let currentRoute = location.pathname;
                  if (currentRoute == navigation.navigateToHomepage) {
                    window.location.reload();
                  } else {
                    navigate(`${navigation.navigateToHomepage}`);
                  }
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.55263 13.3V11.7H2.92105V12.6V13.1H3.42105H11.6316H12.1316V12.6V1.4V0.9H11.6316H3.42105H2.92105V1.4V2.3H2.55263V0.7C2.55263 0.643323 2.57475 0.5912 2.6106 0.554523L2.25303 0.205025L2.6106 0.554523C2.64607 0.518234 2.69172 0.5 2.73684 0.5H12.3158C12.3609 0.5 12.4066 0.518234 12.442 0.554524C12.4779 0.591198 12.5 0.643321 12.5 0.7V13.3C12.5 13.3567 12.4779 13.4088 12.442 13.4455C12.4066 13.4818 12.3609 13.5 12.3158 13.5H2.73684C2.69171 13.5 2.64607 13.4818 2.6106 13.4455C2.57475 13.4088 2.55263 13.3567 2.55263 13.3ZM2.92105 8.74465L0.789431 7L2.92105 5.25535V6.3V6.8H3.42105H7.71053V7.2H3.42105H2.92105V7.7V8.74465Z"
                    stroke="#979797"
                  />
                </svg>{" "}
                <button className="myorderlineLogout">Logout</button>
              </p>
            </div>
          </div>

          <div className="mainMobileContainer">
            {/* <div className="mobileDivContainerNavi">
            <UpdatedNavbar /> 
            </div> */}

            <div
              className="ShowforMobileView"
              style={{
                paddingTop: !showFeatures.searchbar && "40px",
                marginBottom: !showFeatures.searchbar && "0px",
              }}
            >
              <div className="buyerUserTopProfileSideNavbarsection">
                <div className="userProfileImageContainer">
                  <img
                    className="profileImage"
                    src={ProfileImage}
                    onError={(e) => {
                      e.target.src = placeHolderImage;
                    }}
                    alt="Profile.webp"
                  />
                </div>

                <div className="namedetailsDiv">
                  <p className="profileHelloName">Hello,</p>
                  <p className="profileNameofusers">
                    {contextObj.params.displayName} {contextObj.params.l_name}{" "}
                  </p>
                </div>
              </div>
              <div className="buyerUserSideNavbar">
                <p
                  className={`buyerUserSideNavbarAnchorMobile ${isActiveLink(
                    "/profile-info"
                  )}`}
                  onClick={() => {
                    let currentRoute = location.pathname;
                    if (currentRoute == navigation.navigateToProfileInfo) {
                      window.location.reload();
                    } else {
                      navigate(`${navigation.navigateToProfileInfo}`);
                    }
                  }}
                >
                  <div>
                    <svg
                      className="iconsForMobile"
                      width="14"
                      height="14"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        stroke: isActiveLink("/profile-info")
                          ? "#ea5e3d"
                          : "#979797",
                      }}
                    >
                      <path
                        d="M7.92143 2.6C7.92143 3.68375 7.05446 4.55 6 4.55C4.94558 4.55 4.07857 3.68374 4.07857 2.6C4.07857 1.51626 4.94558 0.65 6 0.65C7.05446 0.65 7.92143 1.51625 7.92143 2.6ZM0.688074 12.35C1.00548 9.65975 3.26837 7.58333 6 7.58333C8.73164 7.58333 10.9945 9.65975 11.3119 12.35H0.688074Z"
                        stroke-width="1.3"
                      />
                    </svg>{" "}
                    <button
                      className={`myorderline ${isActiveLink("/profile-info")}`}
                    >
                      Profile Information
                    </button>
                  </div>
                  <div className="arrowSVG">
                    <svg
                      width="9"
                      height="13"
                      viewBox="0 0 7 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                  </div>
                </p>
                <p
                  className={`buyerUserSideNavbarAnchorMobile ${isActiveLink(
                    "/address-list"
                  )}`}
                  onClick={() => {
                    let currentRoute = location.pathname;
                    if (currentRoute == navigation.navigateToAddressList) {
                      window.location.reload();
                    } else {
                      navigate(`${navigation.navigateToAddressList}`);
                    }
                  }}
                >
                  <div>
                    <svg
                      className="iconsForMobile"
                      style={{
                        stroke: isActiveLink("/address-list")
                          ? "#ea5e3d"
                          : "#979797",
                      }}
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7L2.33333 5.66667M2.33333 5.66667L7 1L11.6667 5.66667M2.33333 5.66667V12.3333C2.33333 12.7015 2.63181 13 3 13H5M11.6667 5.66667L13 7M11.6667 5.66667V12.3333C11.6667 12.7015 11.3682 13 11 13H9M5 13C5.36819 13 5.66667 12.7015 5.66667 12.3333V9.66667C5.66667 9.29847 5.96513 9 6.33333 9H7.66667C8.03487 9 8.33333 9.29847 8.33333 9.66667V12.3333C8.33333 12.7015 8.6318 13 9 13M5 13H9"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>{" "}
                    <button
                      className={`myorderline ${isActiveLink("/address-list")}`}
                    >
                      Manage Addresses
                    </button>
                  </div>
                  <div className="arrowSVG">
                    <svg
                      width="9"
                      height="13"
                      viewBox="0 0 7 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                  </div>
                </p>
                {showFeatures.myOrders && (
                  <p
                    className={`buyerUserSideNavbarAnchorMobile ${isActiveLink(
                      "/my-order"
                    )}`}
                    onClick={() => {
                      let currentRoute = location.pathname;
                      if (currentRoute == navigation.navigateToOrder) {
                        window.location.reload();
                      } else {
                        navigate(`${navigation.navigateToOrder}`);
                      }
                    }}
                  >
                    <div>
                      <svg
                        className="iconsForMobile"
                        width="14"
                        height="14"
                        style={{
                          stroke: isActiveLink("/my-order")
                            ? "#ea5e3d"
                            : "#979797",
                        }}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.2 0.7H1.8C1.19248 0.7 0.7 1.19249 0.7 1.8V10.2C0.7 10.8075 1.1925 11.3 1.8 11.3H10.2C10.8075 11.3 11.3 10.8075 11.3 10.2V1.8C11.3 1.19249 10.8075 0.7 10.2 0.7ZM0.5 1.8C0.5 1.08203 1.08203 0.5 1.8 0.5H10.2C10.918 0.5 11.5 1.08203 11.5 1.8V10.2C11.5 10.918 10.918 11.5 10.2 11.5H1.8C1.08203 11.5 0.5 10.918 0.5 10.2V1.8ZM6 5.5C4.82444 5.5 3.72947 4.43677 3.53168 2.9H3.73293C3.9291 4.36003 4.9701 5.3 6 5.3C7.0299 5.3 8.0709 4.36003 8.26707 2.9H8.46832C8.27053 4.43676 7.17555 5.5 6 5.5Z" />
                      </svg>{" "}
                      <button
                        className={`myorderline ${isActiveLink("/my-order")}`}
                      >
                        My Orders
                      </button>
                    </div>
                    <div className="arrowSVG">
                      <svg
                        width="9"
                        height="13"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                          fill="#8C8C8C"
                        />
                      </svg>
                    </div>
                  </p>
                )}

                {showFeatures.showWishList && (
                  <p
                    className={`buyerUserSideNavbarAnchorMobile ${isActiveLink(
                      "/wish-list"
                    )}`}
                    onClick={() => {
                      let currentRoute = location.pathname;
                      if (currentRoute == navigation.navigateToWishlist) {
                        window.location.reload();
                      } else {
                        navigate(`${navigation.navigateToWishlist}`);
                      }
                    }}
                  >
                    <div>
                      <svg
                        className="iconsForMobile"
                        style={{
                          stroke: isActiveLink("/wish-list")
                            ? "#ea5e3d"
                            : "#979797",
                        }}
                        width="14"
                        height="14"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.003 6.89689L7.00555 12L2.00814 6.89689M2.00814 6.89689C1.67852 6.56617 1.41888 6.16867 1.24557 5.72941C1.07226 5.29015 0.989044 4.81865 1.00116 4.3446C1.01327 3.87055 1.12044 3.40422 1.31594 2.97498C1.51143 2.54574 1.79101 2.16288 2.13707 1.85051C2.48312 1.53814 2.88816 1.30304 3.32667 1.16C3.76518 1.01696 4.22766 0.969078 4.685 1.01938C5.14234 1.06968 5.58462 1.21707 5.984 1.45227C6.38338 1.68747 6.73119 2.00538 7.00555 2.38598C7.2811 2.00814 7.62932 1.69301 8.02842 1.46031C8.42752 1.2276 8.86892 1.08234 9.32497 1.03361C9.78103 0.984884 10.2419 1.03374 10.6788 1.17711C11.1157 1.32049 11.5192 1.5553 11.8641 1.86685C12.2089 2.1784 12.4877 2.55999 12.6829 2.98773C12.8781 3.41546 12.9856 3.88015 12.9987 4.35269C13.0117 4.82523 12.93 5.29546 12.7587 5.73395C12.5873 6.17244 12.3301 6.56975 12.003 6.90101"
                          fill="white"
                        />
                        <path
                          d="M12.003 6.89689L7.00555 12L2.00814 6.89689C1.67852 6.56617 1.41888 6.16867 1.24557 5.72941C1.07226 5.29015 0.989044 4.81865 1.00116 4.3446C1.01327 3.87055 1.12044 3.40422 1.31594 2.97498C1.51143 2.54574 1.79101 2.16288 2.13707 1.85051C2.48312 1.53814 2.88816 1.30304 3.32667 1.16C3.76518 1.01696 4.22766 0.969078 4.685 1.01938C5.14234 1.06968 5.58462 1.21707 5.984 1.45227C6.38338 1.68747 6.73119 2.00538 7.00555 2.38598C7.2811 2.00814 7.62932 1.69301 8.02842 1.46031C8.42752 1.2276 8.86892 1.08234 9.32497 1.03361C9.78103 0.984884 10.2419 1.03374 10.6788 1.17711C11.1157 1.32049 11.5192 1.5553 11.8641 1.86685C12.2089 2.1784 12.4877 2.55999 12.6829 2.98773C12.8781 3.41546 12.9856 3.88015 12.9987 4.35269C13.0117 4.82523 12.93 5.29546 12.7587 5.73395C12.5873 6.17244 12.3301 6.56975 12.003 6.90101"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      <button
                        className={`myorderline ${isActiveLink("/wish-list")}`}
                      >
                        My Wishlist
                      </button>
                    </div>
                    <div className="arrowSVG">
                      <svg
                        width="9"
                        height="13"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                          fill="#8C8C8C"
                        />
                      </svg>
                    </div>
                  </p>
                )}
                {showFeatures.showMyWallet && (
                  <p
                    className={`buyerUserSideNavbarAnchorMobile ${isActiveLink(
                      "/mywallet"
                    )}`}
                    onClick={() => {
                      let currentRoute = location.pathname;
                      if (currentRoute == navigation.navigateToMyWallet) {
                        window.location.reload();
                      } else {
                        navigate(`${navigation.navigateToMyWallet}`);
                      }
                    }}
                  >
                    <div>
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          stroke: isActiveLink("/mywallet")
                            ? "#ea5e3d"
                            : "#979797",
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M21.9008 11.0016V9.00156C21.9008 7.39956 20.6031 6.10156 19.0014 6.10156C15.3515 6.10156 7.65086 6.10156 4.00096 6.10156C2.39926 6.10156 1.10156 7.39956 1.10156 9.00156C1.10156 11.6626 1.10156 16.3406 1.10156 19.0016C1.10156 20.6036 2.39926 21.9016 4.00096 21.9016C7.65086 21.9016 15.3515 21.9016 19.0014 21.9016C20.6031 21.9016 21.9008 20.6036 21.9008 19.0016C21.9008 18.0016 21.9008 17.0016 21.9008 17.0016C21.9008 16.5046 21.4981 16.1016 21.0012 16.1016C20.5044 16.1016 20.1016 16.5046 20.1016 17.0016C20.1016 17.0016 20.1016 18.0016 20.1016 19.0016C20.1016 19.6096 19.6085 20.1016 19.0014 20.1016C15.3515 20.1016 7.65086 20.1016 4.00096 20.1016C3.39391 20.1016 2.90085 19.6096 2.90085 19.0016C2.90085 16.3406 2.90085 11.6626 2.90085 9.00156C2.90085 8.39356 3.39391 7.90155 4.00096 7.90155C7.65086 7.90155 15.3515 7.90155 19.0014 7.90155C19.6085 7.90155 20.1016 8.39356 20.1016 9.00156V11.0016C20.1016 11.4986 20.5044 11.9016 21.0012 11.9016C21.4981 11.9016 21.9008 11.4986 21.9008 11.0016Z"
                          fill="#979797"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.40167 7.80474C4.40167 7.80474 9.95666 5.02775 13.5077 3.25175C13.8487 3.08175 14.2537 3.09975 14.5777 3.29975C14.9027 3.50075 15.0997 3.85474 15.0997 4.23574C15.0997 5.65274 15.0997 6.99975 15.0997 6.99975H16.8997C16.8997 6.99975 16.8997 5.65274 16.8997 4.23574C16.8997 3.23074 16.3797 2.29775 15.5247 1.76875C14.6697 1.24075 13.6017 1.19275 12.7027 1.64175C9.15167 3.41775 3.59766 6.19476 3.59766 6.19476L4.40167 7.80474Z"
                          fill="#979797"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.9016 13.0016C22.9016 11.3996 21.6036 10.1016 20.0016 10.1016C19.0946 10.1016 18.0036 10.1016 17.0016 10.1016C14.8476 10.1016 13.1016 11.8476 13.1016 14.0016C13.1016 16.1556 14.8476 17.9016 17.0016 17.9016C18.0036 17.9016 19.0946 17.9016 20.0016 17.9016C21.6036 17.9016 22.9016 16.6036 22.9016 15.0016V13.0016ZM21.1016 13.0016C21.1016 12.3936 20.6096 11.9016 20.0016 11.9016C19.0946 11.9016 18.0036 11.9016 17.0016 11.9016C15.8416 11.9016 14.9016 12.8416 14.9016 14.0016C14.9016 15.1616 15.8416 16.1016 17.0016 16.1016C18.0036 16.1016 19.0946 16.1016 20.0016 16.1016C20.6096 16.1016 21.1016 15.6096 21.1016 15.0016V13.0016Z"
                          fill="#979797"
                        />
                        <path
                          d="M17.008 15.0013C17.5604 15.0013 18.0082 14.5533 18.0082 14.0007C18.0082 13.448 17.5604 13 17.008 13C16.4556 13 16.0078 13.448 16.0078 14.0007C16.0078 14.5533 16.4556 15.0013 17.008 15.0013Z"
                          fill="#979797"
                        />
                      </svg>

                      <button
                        className={`myorderline ${isActiveLink("/mywallet")}`}
                      >
                        My Wallet
                      </button>
                    </div>
                    <div className="arrowSVG">
                      <svg
                        width="9"
                        height="13"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                          fill="#8C8C8C"
                        />
                      </svg>
                    </div>
                  </p>
                )}
                <p
                  className={`buyerUserSideNavbarAnchorMobile ${isActiveLink(
                    "/cart"
                  )}`}
                  onClick={() => {
                    let currentRoute = location.pathname;
                    if (currentRoute == navigation.navigateToNewCartPage) {
                      window.location.reload();
                    } else {
                      navigate(`${navigation.navigateToNewCartPage}`);
                    }
                  }}
                >
                  <div>
                    <svg
                      className="iconsForMobile"
                      style={{
                        stroke: isActiveLink("/cart") ? "#ea5e3d" : "#979797",
                      }}
                      width="15"
                      height="16"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1H2.22222L2.46667 2.33333M2.46667 2.33333H12L9.55556 7.66667H3.44444M2.46667 2.33333L3.44444 7.66667M3.44444 7.66667L2.04323 9.19527C1.65825 9.61527 1.93091 10.3333 2.47535 10.3333H9.55556M9.55556 10.3333C8.88052 10.3333 8.33333 10.9303 8.33333 11.6667C8.33333 12.4031 8.88052 13 9.55556 13C10.2306 13 10.7778 12.4031 10.7778 11.6667C10.7778 10.9303 10.2306 10.3333 9.55556 10.3333ZM4.66667 11.6667C4.66667 12.4031 4.11946 13 3.44444 13C2.76943 13 2.22222 12.4031 2.22222 11.6667C2.22222 10.9303 2.76943 10.3333 3.44444 10.3333C4.11946 10.3333 4.66667 10.9303 4.66667 11.6667Z"
                        stroke="#979797"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {"   "}
                    <button className={`myorderline ${isActiveLink("/cart")}`}>
                      My Cart
                    </button>
                  </div>
                  <div className="arrowSVG">
                    <svg
                      width="9"
                      height="13"
                      viewBox="0 0 7 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                  </div>
                </p>
                <hr className="mediasidenavhrline" />
                <p
                  className="buyerUserSideNavbarAnchor"
                  onClick={async () => {
                    contextObj.setInput("userProfile", null);
                    await logout();
                    // await contextObj.setInput("isLoggedIn", false);
                    // await contextObj.setInput("cartLength", 0);
                    await contextObj.setInput(
                      "triggerReSetContextObj",
                      true
                    );
                    let currentRoute = location.pathname;
                    if (currentRoute == navigation.navigateToHomepage) {
                      window.location.reload();
                    } else {
                      navigate(`${navigation.navigateToHomepage}`);
                    }
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.55263 13.3V11.7H2.92105V12.6V13.1H3.42105H11.6316H12.1316V12.6V1.4V0.9H11.6316H3.42105H2.92105V1.4V2.3H2.55263V0.7C2.55263 0.643323 2.57475 0.5912 2.6106 0.554523L2.25303 0.205025L2.6106 0.554523C2.64607 0.518234 2.69172 0.5 2.73684 0.5H12.3158C12.3609 0.5 12.4066 0.518234 12.442 0.554524C12.4779 0.591198 12.5 0.643321 12.5 0.7V13.3C12.5 13.3567 12.4779 13.4088 12.442 13.4455C12.4066 13.4818 12.3609 13.5 12.3158 13.5H2.73684C2.69171 13.5 2.64607 13.4818 2.6106 13.4455C2.57475 13.4088 2.55263 13.3567 2.55263 13.3ZM2.92105 8.74465L0.789431 7L2.92105 5.25535V6.3V6.8H3.42105H7.71053V7.2H3.42105H2.92105V7.7V8.74465Z"
                      stroke="#979797"
                    />
                  </svg>{" "}
                  <button className="myorderlineLogout">Logout</button>
                </p>
              </div>
              <div className="MobileNavigateLinkBox">
                <p className="FrequentlyVisitLink">Frequently Visited: </p>
                <p className="TrackOrderLink">Track Order</p>
                <p className="helpCenterLink"> Help Center</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
