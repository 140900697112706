import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import ParamsContext from "../useContext/paramContext";
import { useNavigate } from "react-router-dom";
import { getDataFromCookies } from "../Cookies/Cookies";
import Swiper from "./Swiper";
import SkeletonComponent from "../skeleton/Skeleton";
const API_HOST = process.env.REACT_APP_API_HOST;

const SimilarProductsSection = (props) => {
  const navigate = useNavigate();
  const [similarProducts, setSimilarProducts] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const contextObj = useContext(ParamsContext);

  const [showFeatures, setShowFeatures] = useState({
    similarProducts: true,
  });

  const showSimilarProducts = useMemo(() => {
    const featureFlags = contextObj.params.featureFlags;
    return featureFlags && featureFlags.buyer_productdetails_similarproducts;
  }, [contextObj.params.featureFlags]);
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        similarProducts: featureFlags.buyer_productdetails_similarproducts,
      }));
    }
  }, [contextObj.params.featureFlags]);

  useEffect(() => {
    if (!showSimilarProducts) return;
    getSimilarProductsData();
  }, [
    props.currentProductCondition,
    contextObj.params.isLoggedIn,
    showSimilarProducts,
  ]);

  useEffect(() => {
    if (contextObj.params.refetchAfterLoginAddWishList) {
      getSimilarProductsData();
      contextObj.setInput("refetchAfterLoginAddWishList", false);
    }
  }, [contextObj.params.refetchAfterLoginAddWishList]);

  const getSimilarProductsData = useCallback(async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    let apiHeader;

    if (token == null) {
      apiHeader = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      apiHeader = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    try {
      const response = await fetch(
        `${API_HOST}/api/products?&condition=${props.currentProductCondition}&page_no=1&per_page=10`,
        {
          method: "GET",
          headers: apiHeader,
        }
      );
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      if (
        res.status == 500 ||
        res.status == 501 ||
        res.status == 502 ||
        res.status == 503 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      if (res.status == 404) {
      }

      let sameConditionProducts = res.data.data;
      sameConditionProducts = sameConditionProducts.filter(
        (data) => data.id !== props.filterProductId
      );
      setSimilarProducts(sameConditionProducts);
      setDataLoading(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  }, [props.currentProductCondition, contextObj.params.isLoggedIn]);

  function updateSimmilarProductsData(id) {
    const index = similarProducts.findIndex((product) => product.id === id);
    if (index !== -1 && similarProducts[index].is_in_wishlist === false) {
      similarProducts[index].is_in_wishlist = true;
    }
  }
  useEffect(() => {
    if (contextObj.params.productAddedToWishlistId) {
      let productId = contextObj.params.productAddedToWishlistId;
      const timeoutId = setTimeout(() => {
        if (contextObj.params.productAddedToWishlistId !== "") {
          updateSimmilarProductsData(productId);
        }
      }, 10);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productAddedToWishlistId]);

  return (
    <div className="similarProductsContainer">
      {dataLoading && showFeatures.similarProducts && (
        <div className="collection-row">
          {similarProducts.length > 0 && (
            <div className="collection-heading-row">
              <h1 className="collection-heading">Similar Products</h1>
              <div className="collection-hr" />
            </div>
          )}
          <Swiper
            products={similarProducts}
            resetFetchDataNow={props.resetFetchDataNow}
          />
        </div>
      )}
      {!dataLoading && (
        <SkeletonComponent className="SkeletonSection categorySkeleton" />
      )}
    </div>
  );
};

export default SimilarProductsSection;
