import React, { useEffect, useState, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHome,
  faMessage,
  faPaperPlane,
  faPhone,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedinIn,
  faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import endpoints from "../../config/APIEndpoints.json";
import Constants from "../../config/ProjectConstants.json";
import Toast from "../ReactToastify/Toast";
import navigation from "../../config/Navigation.json";
import ReactStars from "react-rating-stars-component";
import { handleScrollToTop } from "../../resuableFunctions/resuableFunctions";
import loadingImgPath from "../../Assets/rolling.svg";
import "./footer2.css";
import NewFeedBack from "../Feedback/NewFeedBack";

// import constants
const APP_NAME = process.env.REACT_APP_NAME;
const SUPPORT_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const SUPPORT_PHONE = process.env.REACT_APP_ADMIN_PHONE;
const API_HOST = process.env.REACT_APP_API_HOST;
const APP_SLOGAN = process.env.REACT_APP_SLOGAN;
const COMPANY_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;

const Footer2 = () => {
  const { wrightToUs } = useParams();

  const { showToast } = Toast();
  const navigate = useNavigate();
  const location = useLocation();

  const contextObj = useContext(ParamsContext);
  const [currYear, setCurrYear] = useState("");
  const [emailValues, setEmailValues] = useState("");
  const [enableFooter, setEnableFooter] = useState(true);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isSubmitButtonDisable, setIsSubmitButtonDisable] = useState(false);
  const [ratingDataDetails, setRatingDataDetails] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    rating: "",
    environment: "Buyer",
    is_anonymous: null,
  });
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showFeedbackSurveyModal, setShowFeedbackSurveyModal] = useState(false);
  const handleCheckboxChange = (event) => {
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setIsChecked(event.target.checked);
  };
  const [showFeatures, setShowFeatures] = useState({
    buyerWriteToUs: false,
    buyerFeedbackSurvey: false,
  });

  const closeFeedBackSurvey = () => {
    setShowFeedbackSurveyModal(false);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setCurrYear(currentYear.toString());

    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setEnableFooter(featureFlags.buyer_common_footer);
      setShowFeatures((prev) => ({
        ...prev,
        buyerWriteToUs: featureFlags.buyer_write_to_us,
        buyerFeedbackSurvey: featureFlags.buyer_feedback_survey,
      }));

      if (location.pathname === "/write-to-us") {
        if (featureFlags.buyer_write_to_us) {
          setShowFeedbackModal(true);
        } else {
          navigate("/");
        }
      }
    }
  }, [contextObj.params.featureFlags]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\s/g, "");
    setEmailValues(sanitizedValue);
  };

  useEffect(() => {
    setEmailValues("");
  }, [location]);

  // News Letter Submit
  const [newsLetterLoading, setNewsLetterLoading] = useState(false);
  const newsLetterSumbit = async () => {
    const payload = {
      email: emailValues,
    };

    setNewsLetterLoading(true);
    const response = await fetch(`${API_HOST}/${endpoints.newsLetter}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const res = await response.json();

    if (res.status == 400) {
      showToast("error", res.errors.email[0]);
    }
    if (res.status === 201) {
      showToast("success", Constants.alertMessages.alertForContact);
      setEmailValues("");
    } else if (res.status === 409) {
      showToast("info", Constants.alertMessages.alertFoExistingEmail);
      setEmailValues("");
    } else if (
      res.status == 500 ||
      res.status == 501 ||
      res.status == 502 ||
      res.status == 503 ||
      (res.message && res.message.toLowerCase().includes("server error"))
    ) {
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setNewsLetterLoading(false);
  };

  const ratingChanged = (newRating) => {
    setRatingError("");
    setRatingDataDetails((prevData) => ({
      ...prevData,
      rating: newRating,
    }));
  };

  const handleCloseFeedback = () => {
    setShowFeedbackModal(false);
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setDescriptionError("");
    setRatingError("");
    setIsChecked(false);
    setRatingDataDetails((prevData) => ({
      ...prevData,
      name: "",
      phone: "",
      email: "",
      message: "",
      rating: "",
      environment: "Buyer",
      is_anonymous: null,
    }));

    if (location.pathname == "/write-to-us") {
      navigate("/");
    }
  };
  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "email") {
      setEmailError("");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "message") {
      setDescriptionError("");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "phone") {
      setPhoneError("");
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      }
    } else if (name === "name") {
      setNameError("");
      if (/^[A-Za-z\s]+$/.test(value)) {
        if (value.trim() !== "" || value == "") {
          setRatingDataDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      if (value.trim() == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value.trim(),
        }));
      }
    }
  };

  const createFeedback = async () => {
    setIsSubmitButtonDisable(true);
    let errorHas = false;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (isChecked) {
      if (ratingDataDetails.name != "" && ratingDataDetails.name != null) {
        if (
          ratingDataDetails.name.length < 2 ||
          ratingDataDetails.name.length > 50
        ) {
          setNameError("Name should be between 2 and 50 characters.");
          errorHas = true;
        } else {
          setNameError("");
        }
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        if (!isPhoneNumber) {
          setPhoneError("Please enter a valid Phone.");
          errorHas = true;
        } else {
          setPhoneError("");
        }
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        if (!isEmail) {
          setEmailError("Please enter a valid Email.");
          errorHas = true;
        } else if (
          ratingDataDetails.email.length < 5 ||
          ratingDataDetails.email.length > 100
        ) {
          setEmailError("Email should be between 3 and 150 characters.");
          errorHas = true;
        } else {
          setEmailError("");
        }
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    } else {
      if (ratingDataDetails.name === "" || ratingDataDetails.name === null) {
        setNameError("Name is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.name.length < 2 ||
        ratingDataDetails.name.length > 50
      ) {
        setNameError("Name should be between 2 and 50 characters.");
        errorHas = true;
      } else {
        setNameError("");
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone === "" || ratingDataDetails.phone === null) {
        setPhoneError("Phone is required.");
        errorHas = true;
      } else if (!isPhoneNumber) {
        setPhoneError("Please enter a valid Phone.");
        errorHas = true;
      } else {
        setPhoneError("");
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email === "" || ratingDataDetails.email === null) {
        setEmailError("Email is required.");
        errorHas = true;
      } else if (!isEmail) {
        setEmailError("Please enter a valid Email.");
        errorHas = true;
      } else if (
        ratingDataDetails.email.length < 5 ||
        ratingDataDetails.email.length > 100
      ) {
        setEmailError("Email should be between 3 and 150 characters.");
        errorHas = true;
      } else {
        setEmailError("");
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    }
    if (errorHas == true) {
      setIsSubmitButtonDisable(false);

      return;
    }
    const payload = {};
    if (isChecked) {
      if (ratingDataDetails.name != "" && ratingDataDetails.name != null) {
        payload.name = ratingDataDetails.name;
      }
      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        payload.phone = ratingDataDetails.phone;
      }
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        payload.email = ratingDataDetails.email;
      }
      payload.is_anonymous = true;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
    } else {
      payload.is_anonymous = false;
      payload.name = ratingDataDetails.name;
      payload.phone = ratingDataDetails.phone;
      payload.email = ratingDataDetails.email;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
    }
    const response = await fetch(`${API_HOST}/api/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setIsSubmitButtonDisable(false);
    if (res.status === 201) {
      showToast("success", "Message submitted successfully");
      handleCloseFeedback();
    } else if (res.status === 409) {
    } else if (res.status === 404) {
    } else if (res.status === 400) {
    } else if (res.status === 403) {
    } else {
    }
  };

  useEffect(() => {
    if (showFeedbackSurveyModal) {
      document.body.style.overflow = "hidden"; // Disable scroll when the modal is open
      return () => {
        document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
      };
    }
  }, [showFeedbackSurveyModal]);

  return (
    <>
      {enableFooter && (
        <section id="footer-section">
          <div className="footer-1st-section">
            <div className="footer-1st-section-container">
              <div>
                <div>
                  <h1>{APP_NAME}</h1>
                  <p className="app-slogan">{APP_SLOGAN}</p>
                </div>
              </div>
              <div>
                <ul>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate(navigation.navigateToBlogs);
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    <span>Blogs</span>
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/faq");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    <span>FAQs</span>
                  </li>
                  {showFeatures.buyerFeedbackSurvey && (
                    <li
                      tabIndex="0"
                      onClick={() => {
                        setShowFeedbackSurveyModal(true);
                      }}
                      id="FooterFeedbackOption"
                      onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                    >
                      <span>Feedback</span>
                    </li>
                  )}
                </ul>
              </div>
              <div>
                <ul>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/return-policy");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    <span>Return Policy</span>
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/refund-policy");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    <span>Refund Policy</span>
                  </li>
                  <li
                    tabIndex="0"
                    onClick={() => {
                      navigate("/shipping-policy");
                    }}
                    onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  >
                    <span>Shipping Policy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-2nd-section">
            <div className="footer-2nd-section-container">
              <div className="footer-a-tag footer-address">
                <span>
                  <FontAwesomeIcon icon={faHome} />{" "}
                  <a
                    tabIndex={0}
                    href="https://maps.app.goo.gl/3k8zLqdmA8yNoULJ6"
                    target="_blank"
                  >
                    A-323, Siddhraj Zori,
                    <br />
                    Sargasan Cross Road,
                    <br />
                    Sargasan, Gandhinagar-382421,
                    <br />
                    Gujarat-INDIA
                  </a>
                </span>
              </div>
              <div className="footer-a-tag" id={"footer-contacts"}>
                <span>Let Us Connect</span>
                {showFeatures.buyerWriteToUs && (
                  <li
                    onClick={() => {
                      setShowFeedbackModal(true);
                    }}
                    id="WriteToUs"
                    style={{ marginTop: "5px" }}
                  >
                    <FontAwesomeIcon icon={faMessage} />
                    &nbsp;&nbsp;&nbsp;
                    <span tabIndex={0}>Write to us</span>
                  </li>
                )}
                <div className="footer-icons">
                  <a
                    href={`mailto:${SUPPORT_EMAIL}`}
                    tabIndex={0}
                    id="email-icon"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="footer-icon"
                      style={{ fontSize: "18px" }}
                    />
                  </a>
                  <a href={`tel:${SUPPORT_PHONE}`} tabIndex={0} id="phone-icon">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="footer-icon"
                      style={{ fontSize: "18px" }}
                    />
                  </a>
                  <a
                    tabIndex={0}
                    href="https://www.linkedin.com/company/joyrejoy/"
                    target="_blank"
                    id="linkedin-icon"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="footer-icon"
                    />
                  </a>
                  <a
                    tabIndex={0}
                    href="https://www.facebook.com/people/JoyRejoy/61558971548726/"
                    target="_blank"
                    id="facebook-icon"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="footer-icon"
                    />
                  </a>
                  <a
                    tabIndex={0}
                    href="https://www.instagram.com/joyrejoy__/"
                    target="_blank"
                    id="instagram-icon"
                  >
                    <FontAwesomeIcon
                      icon={faSquareInstagram}
                      className="footer-icon"
                      style={{ fontSize: "21px" }}
                    />
                  </a>
                </div>
              </div>
              <div>
                <p className="newsletter-para">
                  Subscribe news-letter to get latest update.
                </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    newsLetterSumbit();
                  }}
                  className="newsletter-input-line"
                >
                  <input
                    autoComplete="off"
                    required
                    type="email"
                    name="email"
                    className="footer-newsletter-input"
                    placeholder="Enter email address"
                    value={emailValues}
                    onChange={handleInputChange}
                  />
                  <button
                    type="submit"
                    aria-label="newsletter-btn"
                    className="newsletter-btn"
                    disabled={newsLetterLoading}
                  >
                    {newsLetterLoading ? (
                      <img
                        src={loadingImgPath}
                        alt="Loading"
                        className="news-letter-loading"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className="paper-plane"
                      />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-3rd-section">
            <div className="footer-3rd-section-container">
              <p>
                &copy; {currYear} {APP_NAME} by Mplify Tech Services Pvt Ltd.
                All Rights Reserved.
              </p>
              <p className="footer-policy-link">
                <span
                  tabIndex="0"
                  onClick={() => {
                    handleScrollToTop();
                    navigate("/privacy-policy");
                  }}
                  onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  id="footerPolicyLink_0"
                >
                  Privacy Policy
                </span>{" "}
                |{" "}
                <span
                  tabIndex="0"
                  className="openPolicyDocs"
                  onClick={() => {
                    handleScrollToTop();
                    navigate("/cookie-policy");
                  }}
                  onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  id="footerPolicyLink_1"
                >
                  Cookie Policy
                </span>{" "}
                |{" "}
                <span
                  tabIndex="0"
                  className="openPolicyDocs"
                  onClick={() => {
                    handleScrollToTop();
                    navigate("/terms-and-condition");
                  }}
                  onKeyDown={(e) => e.key === "Enter" && e.target.click()}
                  id="footerPolicyLink_2"
                >
                  Terms And Conditions
                </span>
              </p>
            </div>
          </div>
        </section>
      )}

      {/* Feedback submit modal */}
      {showFeedbackModal && (
        <div className="Feedback_modal_container">
          <div className="Feedback_modal_c2" id="Feedback_modal">
            <div className="cross_button_container">
              <button
                className="cross_button"
                onClick={handleCloseFeedback}
                id="closeFeedBackModelBtn"
              >
                <FontAwesomeIcon style={{ color: "#595959" }} icon={faX} />
              </button>
            </div>
            <h1 id="Feedback_Heading">Write To Us</h1>
            <div>
              <label className="login-label-tag">
                <input
                  type="text"
                  id="feedBackName"
                  className="email-phone-input login_input_tag"
                  onChange={handleChange}
                  name="name"
                  placeholder={`Name ${isChecked ? "(optional)" : ""}`}
                  maxLength={50}
                  value={ratingDataDetails.name}
                />
                <span className="login-Span-tags">
                  {" "}
                  Name {isChecked ? "(optional)" : " "}
                </span>
              </label>
              <li className="fotter-error" id="feedBackNameError">
                {nameError}
              </li>
            </div>
            <div>
              <label className="login-label-tag">
                <input
                  type="text"
                  className="email-phone-input login_input_tag"
                  onChange={handleChange}
                  name="phone"
                  id="feedBackPhone"
                  placeholder={`Phone ${isChecked ? "(optional)" : ""}`}
                  maxLength={10}
                  value={ratingDataDetails.phone}
                />
                <span className="login-Span-tags">
                  {" "}
                  Phone {isChecked ? "(optional)" : " "}
                </span>
              </label>
              <li className="fotter-error" id="feedBackPhoneError">
                {phoneError}
              </li>
            </div>
            <div>
              <label className="login-label-tag">
                <input
                  type="text"
                  className="email-phone-input login_input_tag"
                  onChange={handleChange}
                  name="email"
                  placeholder={`Email ${isChecked ? "(optional)" : ""}`}
                  maxLength={50}
                  id="feedBackEmail"
                  value={ratingDataDetails.email}
                />
                <span className="login-Span-tags">
                  {" "}
                  Email {isChecked ? "(optional)" : " "}
                </span>
              </label>
              <li className="fotter-error" id="feedBackEmailError">
                {emailError}
              </li>
            </div>
            <div>
              <label className="login-label-tag">
                <textarea
                  type="text"
                  className="email-phone-input login_input_tag footer-textarea"
                  onChange={handleChange}
                  maxLength={500}
                  id="feedBackDescription"
                  minLength={10}
                  name="message"
                  placeholder="Message"
                  value={ratingDataDetails.message}
                />
                <span className="login-Span-tags"> Message</span>
              </label>
              <li className="fotter-error" id="feedBackDescriptionError">
                {descriptionError}
              </li>
            </div>
            <div>
              <p className="rate_your_experience">
                How would you rate our website?
              </p>
              <div id="feedbackRatingContainer">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  isHalf={false}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ff9f00"
                />
              </div>
              <li className="fotter-error" id="feedBackRatingError">
                {ratingError}
              </li>
            </div>
            <div className="ckeckbox-container">
              <label className="checkbox-label">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  id="SendAnonymousFeedbackInput"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                {"  "}
                Send Anonymous
              </label>
            </div>
            <button
              onClick={createFeedback}
              className="submit-button"
              id="submitFeedbackBtn"
              disabled={isSubmitButtonDisable}
            >
              {isSubmitButtonDisable ? (
                <img
                  src={loadingImgPath}
                  alt="Loading"
                  className="disabledBtn-Loading"
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      )}
      {/* Feedback submit modal */}
      {showFeedbackSurveyModal && (
        <NewFeedBack closeNewFeedBack={closeFeedBackSurvey} />
      )}
    </>
  );
};

export default Footer2;
