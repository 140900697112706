import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserSideNavbar } from "../userSideNavbar/usersidenavbarMQ";
import "./myWallet.css";
import myWallet from "../../Assets/MyWallet.webp";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import WalletSkeleton from "./WalletSkeleton";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import { Helmet } from "react-helmet";
const MemoizedUserSideNavbar = React.memo(UserSideNavbar);
const API_HOST = process.env.REACT_APP_API_HOST;

const infra = process.env.REACT_APP_INFRA;
const MyWallet = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const [loading, setLoading] = useState({ fetchedWalletBalance: false });
  const [walletBalance, setWalletBalance] = useState();
  const [transactionDetails, setTransactionDetails] = useState([]);
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    showFooter: true,
    showWallet: true,
    searchbar: true,
  });

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showFooter: featureFlags.buyer_common_footer,
        showWallet: featureFlags.buyer_profile_mywallet,
        searchbar: featureFlags.buyer_products_search,
      }));
    }
  }, [contextObj.params.featureFlags]);

  useEffect(() => {
    if (!showFeatures.showWallet) {
      window.history.back();
    }
  }, [showFeatures.showWallet]);

  useEffect(() => {
    if (showFeatures.showWallet) {
      GetWalletDetails();
    }
  }, [showFeatures.showWallet]);

  const GetWalletDetails = async () => {
    const authenticationToken = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(`${API_HOST}/${endpoints.getWalletBalanced}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticationToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const { status, message, data } = await response.json();
    setLoading({ fetchedWalletBalance: true });

    if (
      status >= 500 ||
      (message && message.toLowerCase().includes("server error"))
    ) {
      setMaintainanceMode(status === 503);
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }

    if (status === 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", true);
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(navigation.navigateToHomepage);
    } else if (status === 200) {
      setWalletBalance(data.wallet_balance);
      setTransactionDetails(data.wallet_transaction);
    }
  };

  const TimestampConverter = ({ timestamp }) => {
    const dateObject = new Date(timestamp);
    const day = dateObject.getUTCDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return <>{formattedDate}</>;
  };

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Your Playful Wallet - JOYREJOY</title>
          <meta
            name="description"
            content="Your wallet at JOYREJOY is where your joy multiplies! Monitor your balance, track transactions, and enjoy exclusive rewards and discounts. Let's keep the fun rolling in!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      <div className="pageParentDiv pageParentDivColoredBackground">
        {showFeatures.showWallet && (
          <div className="allBoxContainer">
            <div className="mywallet_Main_Container">
              <div className="myWallet_Flexbox_Container">
                <div className="mywallet_Navigation_Container">
                  <MemoizedUserSideNavbar />
                </div>
                <div className="mywallet_Products_Container">
                  <div className="pageListHeaderContainer">
                    <p className="PageListTitle">My Wallet</p>
                  </div>
                  {!loading.fetchedWalletBalance ? (
                    <WalletSkeleton />
                  ) : (
                    <>
                      <div className="mywallet_Box">
                        <div className="mywallet_Image_div">
                          <img
                            className="mywallet_Image_Container"
                            src={myWallet}
                            onError={(e) => {
                              e.target.src = placeHolderImage;
                            }}
                            alt="wallet.webp"
                          />
                        </div>
                        <div className="MyWallet_Tabel_div_Container">
                          <h1 className="Wallet_Heading_transaction">
                            Wallet Transaction Summary
                          </h1>
                          <div className="MyWallet_Tabel_div">
                            <table className="mywallet_table_div">
                              <thead>
                                <tr className="mywallet_tr_main">
                                  <th className="myWallet_Heading_Tag">
                                    Sr.No
                                  </th>
                                  <th className="myWallet_Heading_Tag">Date</th>
                                  <th className="myWallet_Heading_Tag">
                                    Transaction Note
                                  </th>
                                  <th className="myWallet_Heading_Tag">
                                    Referee Name
                                  </th>
                                  <th className="myWallet_Heading_Tag">
                                    Coins
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactionDetails.map((data, index) => (
                                  <tr
                                    key={data.id}
                                    className="mywallet_tr_main"
                                  >
                                    <td>{index + 1}</td>
                                    <td>
                                      <TimestampConverter
                                        timestamp={data.created_at}
                                      />
                                    </td>
                                    <td>{data.comment}</td>
                                    <td>
                                      {data.referee_name === null
                                        ? "-"
                                        : data.referee_name}
                                    </td>
                                    <td
                                      className={
                                        data.transaction_type === "Deposit"
                                          ? "mywalletcolorToCoinsAdd"
                                          : "mywalletcolorToCoinsAsubst"
                                      }
                                    >
                                      {data.transaction_type === "Deposit"
                                        ? `+${data.amount}`
                                        : `-${data.amount}`}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="myWallet_EarnedCoin_Main">
                        <div className="mywallet_EarnedCoins_Container">
                          <p className="Mywallet_Earned_Paragraph">
                            Your Wallet Balance is
                          </p>
                          <li className="myWallet_Line_coins">
                            {walletBalance}{" "}
                            <span className="MyWallet_Earned_Coins">Coins</span>
                          </li>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyWallet;
