import React from "react";
import "./pagination.css";

const Paginate = (props) => {
  const { pagination, pageChange } = props;

  return (
    <ul className="pagination">
      {/* Previous Button */}
      <li
        className={`page-item ${
          pagination.prev_page_url === null ? "disabled" : ""
        }`}
        onClick={() => {
          if (pagination.prev_page_url !== null) {
            pageChange(pagination.prev_page_url);
          }
        }}
      >
        <a
          className="page-link"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault;
          }}
        >
          Previous
        </a>
      </li>

      {/* Pages */}
      {pagination &&
        pagination.links.map((link, index) => {
          if (index !== 0 && index !== pagination.links.length - 1) {
            return (
              <>
                {/* Current Page */}
                {link.active ? (
                  <li key={index} className="page-item active">
                    <span className="page-link">{link.label}</span>
                  </li>
                ) : (
                  <>
                    {/* Others Page */}
                    {link.url ? (
                      <li
                        key={index}
                        className="page-item"
                        onClick={() => {
                          if (link.url !== null) {
                            pageChange(link.url);
                          }
                        }}
                      >
                        <a
                          className="page-link"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault;
                          }}
                        >
                          {link.label}
                        </a>
                      </li>
                    ) : (
                      // Dots
                      <li key={index} className="page-item">
                        {link.label}
                      </li>
                    )}
                  </>
                )}
              </>
            );
          }
        })}

      {/* Next Button */}
      <li
        className={`page-item ${
          pagination.next_page_url === null ? "disabled" : ""
        }`}
        onClick={() => {
          if (pagination.next_page_url !== null) {
            pageChange(pagination.next_page_url);
          }
        }}
      >
        <a
          className="page-link"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault;
          }}
        >
          Next
        </a>
      </li>
    </ul>
  );
};

export default Paginate;
