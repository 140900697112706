import React, { useState } from "react";
import ParamsContext from "./paramContext";

const ParamsContextState = (props) => {
  const [params, setParams] = useState({
    serviceUnavailable: false,
    multiFilters: "",
    navigation: "false",
    navigationFrom: "",    
    cartLength: 0,
    wishlistLength: 0,
    reFetchWishList: false,
    reFetchCart: false,
    protectedRoutes: false,
    isLoggedIn: false,
    showLoginModel: false,
    paymentStatus: "",
    displayName: "",
    lastName: "",
    getListLength: true,
    featureFlagsAvailable: false,
    featureFlags: null,
    imgData: null,
    checkoutProducts: [],
    showAddressModal: false,
    productAddedToWishlistId: "",
    productRemovedFromWishlistId: "",
    policiesData: [],
    userProfile: null,
    reFetchRecentlyViewProducts: false,
    recentlyViewProducts: null,
    navigateToOrderList: false,
    reDirectToPage: "",
    reSetFilters: false,
    categoryFilterApplied: false,
    resetSelectedCategoryId: false,
    reFetchCartDetails: false,
    reFetchAddressList: false,
    updateProfileFromCheckout: false,
    actionAfterLogin: "",
    productId: "",
    refetchAfterLoginAddWishList: false, 
    triggerReSetContextObj: false,
    
  });
  const setInput = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <ParamsContext.Provider value={{ params, setInput }}>
      {props.children}
    </ParamsContext.Provider>
  );
};

export default ParamsContextState;
