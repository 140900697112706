import React from "react";
import Paginate from "../Pagination/Paginate";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../config/Navigation.json";
import Endpoints from "../../config/APIEndpoints.json";
import ParamsContext from "../useContext/paramContext";
import BlogCard from "./BlogCard";
import "./blog.css";
import { Helmet } from "react-helmet";
import BlogsSkeleton from "./blogsSkeleton";
import NoBlog from "../../Assets/NoProductsAvailable.webp";

const API_HOST = process.env.REACT_APP_API_HOST;

const BlogList = () => {
  const infra = process.env.REACT_APP_INFRA;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [pagination, setPagination] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [blogs]);
  const handleURLChange = (data) => {
    getBlogList(data);
  };

  const openBlogDetails = (slug, id) => {
    navigate(`/blogs/${slug}`);
    sessionStorage.setItem("blogDetailsId", id);
  };

  // get all blog list
  const getBlogList = async (url) => {
    const response = await fetch(`${url}&per_page=12`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();

    if (res.status === 200) {
      setBlogs(res.data.data);
      setPagination(res.data);
      // Error handling
    } else if (res.status >= 500) {
      if (res.status == 503) setMaintainanceMode(true);
      else contextObj.setInput("serviceUnavailable", true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBlogList(`${API_HOST}/${Endpoints.getBlogList}?page_no=1`);
  }, []);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Discover Joyful Reads - JOYREJOY Blog</title>
          <meta
            name="description"
            content="Explore the world of joy and learning with our playful blog at JOYREJOY! Dive into insightful articles, fun DIYs, and parenting tips that'll make your heart smile. Let's embark on a journey of discovery together!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}

      <div className="pageParentDiv">
        <div className="body-container">
          <div className="blog-container">
            <div className="breadcrumb-area">
              <h1>Blog & News</h1>
              <ol className="breadcrumb">
                <li
                  className="breadcrumb-item"
                  onClick={() => {
                    navigate(navigation.navigateToHomepage);
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">Blogs</li>
              </ol>
            </div>

            {loading ? (
              <BlogsSkeleton />
            ) : (
              <>
                {blogs && blogs.length > 0 && (
                  <div className="blog-list">
                    {blogs.map((blog, index) => (
                      <BlogCard
                        key={index}
                        blog={blog}
                        navigateToDetails={openBlogDetails}
                      />
                    ))}
                  </div>
                )}

                {blogs.length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    <img src={NoBlog} alt="No Blogs" width={300} />
                    <p>No Blogs Available</p>
                  </div>
                )}

                <div className="pagination-bar">
                  {pagination && (
                    <Paginate
                      pagination={pagination}
                      pageChange={handleURLChange}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
