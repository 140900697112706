import React from "react";
import SkeletonComponent from "../skeleton/Skeleton";
const ProductDetailsSkeleton = () => {
  return (
    <div>
      <div className="productContainer ">
        <div className="skeleton-loading-forProductDetailsOfImage">
          <div className="skeleton-loading-forSmallImages">
            <SkeletonComponent className="skeleton-loading-forSmallImages1" />
            <SkeletonComponent className="skeleton-loading-forSmallImages1" />
            <SkeletonComponent className="skeleton-loading-forSmallImages1" />
            <SkeletonComponent className="skeleton-loading-forSmallImages1" />
            <SkeletonComponent className="skeleton-loading-forSmallImages1" />
            <SkeletonComponent className="skeleton-loading-forSmallImages1" />
          </div>
          <SkeletonComponent className="skeleton-loading-forLargeImages" />
        </div>
        <SkeletonComponent className="skeleton-loading-forMobileImage" />
        <div className="skeleton-loading-forProductDetailsOfDetails">
          <div className="skeleton-loading-forProductDetailsOfDetails1"></div>
          <div className="skeleton-loading-forProductDetailsOfDetails2"></div>
          <div className="skeleton-loading-forProductDetailsOfDetails3"></div>
          <div className="skeleton-loading-forProductDetailsOfDetails4"></div>
          <div className="skeleton-loading-forProductDetailsOfDetails5"></div>
          <div className="skeleton-loading-forProductDetailsOfDetails6"></div>
          <div className="skeleton-loading-forProductDetailsOfDetails7">
            <SkeletonComponent className="skeleton-loading-Button1" />
            <SkeletonComponent className="skeleton-loading-Button2" />
          </div>
          <SkeletonComponent className="skeleton-loading-forProductDetailsOfDetails8" />
        </div>
      </div>

      <div className="skeleton-prdouctpage22">
        <div className="sk-product-row">
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>

          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
        </div>
      </div>

      {/* these divs were visible only in mobile view */}
      <div className="onlyVisible-inMobileView-div">
        <div className="sk-product-row">
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
          <div className="sk-product-card">
            <SkeletonComponent className="sk-prod-img" />
            <SkeletonComponent className="sk-prod-selling-price" />
            <SkeletonComponent className="sk-prod-original-price" />
            <SkeletonComponent className="sk-prod-name" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetailsSkeleton;
