import React from "react";
import "./orderSkeleton.css";
const OrderSkeleton = () => {
  return (
    <>
      <div>
        <div className="skeleton-loading">
          <div className="orderDetailsHolder">
            <div className="orderPageImageAndNameDiv">
              <div className="ordersListImageContainer">
                <div className="orderSkeletonImageContainer "></div>
              </div>
              <div className="orderNameAndCategoryDiv skeletonOrderNameAndCategoryDiv">
                <div className="orderPageProductName skeletonElement" id="orderSkeletonProductName"></div>
                <div className="orderPageMobileViewOrderStatus"></div>
              </div>
            </div>
            <div className="commonDiv">
              <div className="buyerMainDivForOrderSectionPTag">
                <div className="orderSkeletonPrice skeletonElement"></div>
              </div>
              <div className="orderNumberandDeliveryDiv SkeletonOrderNumberandDeliveryDiv">
                <div className="orderSkeletonBuyerMainDivForOrderSectionPTag   ">
                  <div className="orderSkeletonOrderStatus">
                    <div className="orderSkeletonOrderStatusPrimary skeletonElement"></div>
                    <div className="orderSkeletonOrderStatusSecondary skeletonElement"></div>
                  </div>
                  <div className="orderSkeletonMakePaymentBtn skeletonElement"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="orderPageOuterDiv "
            id="orderPageOuterDivSkelelton"
          ></div>
        </div>
        <hr class="ordersSepratingLine skeletonBorderLine"/>
        <div className="skeleton-loading">
          <div className="orderDetailsHolder">
            <div className="orderPageImageAndNameDiv">
              <div className="ordersListImageContainer">
                <div className="orderSkeletonImageContainer "></div>
              </div>
              <div className="orderNameAndCategoryDiv skeletonOrderNameAndCategoryDiv">
              <div className="orderPageProductName skeletonElement" id="orderSkeletonProductName"></div>
                <div className="orderPageMobileViewOrderStatus"></div>
              </div>
            </div>
            <div className="commonDiv">
              <div className="buyerMainDivForOrderSectionPTag">
                <div className="orderSkeletonPrice skeletonElement"></div>
              </div>
              <div className="orderNumberandDeliveryDiv SkeletonOrderNumberandDeliveryDiv">
                <div className="orderSkeletonBuyerMainDivForOrderSectionPTag   ">
                  <div className="orderSkeletonOrderStatus">
                    <div className="orderSkeletonOrderStatusPrimary skeletonElement"></div>
                    <div className="orderSkeletonOrderStatusSecondary skeletonElement"></div>
                  </div>
                  <div className="orderSkeletonMakePaymentBtn skeletonElement"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="orderPageOuterDiv "
            id="orderPageOuterDivSkelelton"
          ></div>
        </div>
        <hr class="ordersSepratingLine skeletonBorderLine"/>
        <div className="skeleton-loading">
          <div className="orderDetailsHolder">
            <div className="orderPageImageAndNameDiv">
              <div className="ordersListImageContainer">
                <div className="orderSkeletonImageContainer "></div>
              </div>
              <div className="orderNameAndCategoryDiv skeletonOrderNameAndCategoryDiv">
              <div className="orderPageProductName skeletonElement" id="orderSkeletonProductName"></div>
                <div className="orderPageMobileViewOrderStatus"></div>
              </div>
            </div>
            <div className="commonDiv">
              <div className="buyerMainDivForOrderSectionPTag">
                <div className="orderSkeletonPrice skeletonElement"></div>
              </div>
              <div className="orderNumberandDeliveryDiv SkeletonOrderNumberandDeliveryDiv">
                <div className="orderSkeletonBuyerMainDivForOrderSectionPTag   ">
                  <div className="orderSkeletonOrderStatus">
                    <div className="orderSkeletonOrderStatusPrimary skeletonElement"></div>
                    <div className="orderSkeletonOrderStatusSecondary skeletonElement"></div>
                  </div>
                  <div className="orderSkeletonMakePaymentBtn skeletonElement"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="orderPageOuterDiv "
            id="orderPageOuterDivSkelelton"
          ></div>
        </div>
      </div>
    </>
  );
};
export default OrderSkeleton;
