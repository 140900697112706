import React from "react";

const WalletSkeleton = () => {
  return (
    <div className="WalletSkeletonContainer">
      <div className="mywallet_Box mywallet_BoxSkeleton">
        <div className="mywallet_Image_div mywallet_Image_divSkeleton"></div>
        <div className="MyWallet_Tabel_div_Container MyWalletSkeleton_Tabel_div_Container">
          <div className="myWalletSkeletonTableHeading"></div>
          <div className="myWalletSkeletonTable"></div>
        </div>
      </div>
      <div className="myWallet_EarnedCoin_Main myWalletSkeleton_EarnedCoin_Main">
        <div className="myWalletSkeletonWalletBalanceText"></div>
        <div className="myWalletSkeletonWalletBalance"></div>
      </div>
    </div>
  );
};
export default WalletSkeleton;
