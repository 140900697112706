import React from "react";
import { useLocation } from "react-router-dom";
import PolicyComponent from "./policy/privacypolicy";
import { useState, useEffect, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import { Helmet } from "react-helmet";

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;
const infra = process.env.REACT_APP_INFRA;
const RefundPolicy = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;

  const Name = "Refund Policy";
  const PolicyId = 3;
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
    buyerPoliciesRetrievedviaApi: false,
  });
  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
        buyerPoliciesRetrievedviaApi:
          featureFlags.buyer_policies_retrieved_via_api,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;

  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Refund Joyfully - JOYREJOY</title>
          <meta
            name="description"
            content="Our refund policy at JOYREJOY is like a promise of happiness! Explore our easy and transparent refund process with a touch of joy. Let's ensure every refund leaves you smiling!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {showFeatures.buyerPoliciesRetrievedviaApi ? (
        <PolicyComponent PolicyName={Name} policyId={PolicyId} />
      ) : (
        <div
          className="pageParentDiv"
          style={{
            marginTop: contextObj.params.topNavbarHeight,
          }}
        >
          <div className="body-container">
            <div className="policy-container" style={conditionalStyle}>
              <ResourcesSidebar activeResource={"Refund Policy"} />
              <div className="policy-content">
                <h1
                  className={`policy-content-heading ${
                    isSticky ? "sticky-heading" : ""
                  }`}
                >
                  Refund Policy
                </h1>
                <p>Returns</p>

                <p>
                  <span>
                    Our policy lasts 3 days. If 3 days have gone by since your
                    purchase, unfortunately we can't offer you a refund or
                    exchange.
                  </span>
                </p>
                <ul>
                  <li>
                    To be eligible for a return, your item must be unused and in
                    the same condition that you received it. It must also be in
                    the original packaging.
                  </li>
                  <li>
                    To complete your return, we require a receipt or proof of
                    purchase.
                  </li>
                  <li>
                    Please do not send your purchase back to the manufacturer.
                  </li>
                </ul>
                <p>
                  <span>
                    There are certain situations where only partial refunds are
                    granted:
                  </span>
                </p>
                <ul>
                  <li>If item received is different than the item ordered</li>
                  <li>
                    If condition of the item received is not matching the
                    condition at which it was ordered
                  </li>
                  <li>
                    Any item that is returned more than 3 days after delivery
                  </li>
                </ul>
                <p>Refunds</p>
                <p>
                  <span>
                    Once your return is received and inspected, we will send you
                    an email to notify you that we have received your returned
                    item. We will also notify you of the approval or rejection
                    of your refund.
                  </span>
                </p>
                <p>
                  <span>
                    If you are approved, then your refund will be processed, and
                    a credit will automatically be applied to your credit card
                    or original method of payment, within 7-10 working
                    days(excluding Saturdays,Sundays, Bank Holidays and Public
                    Holidays).
                  </span>
                </p>
                <p>Late or missing refunds</p>
                <p>
                  <span>
                    If you haven't received a refund yet, first check your bank
                    account again.
                  </span>
                  <span>
                    Then contact your credit card company, it may take some time
                    before your refund is officially posted.
                  </span>
                  <span>
                    Next contact your bank. There is often some processing time
                    before a refund is posted.
                  </span>
                </p>
                <p>
                  <span>
                    If you've done all of this and you still have not received
                    your refund yet, please contact us at{" "}
                    <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a>
                  </span>
                </p>
                <p>Exchanges</p>

                <p>
                  <span>
                    We only replace the products if they don't match what was
                    agreed upon when the purchase was made. Please email us at
                    <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a> if you
                    need to exchange it for another item. After that mail your
                    product to: {ADMIN_ADDRESS}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RefundPolicy;
