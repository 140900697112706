import React from "react";
import "./Address.css";
const AddressSkeleton = () => {
  return (
    <>
      <div className="BuyerAddressListContainer BuyerAddressListContainerSkeleton">
        <div className="address-list">
          <div className="ButtonDivAddAddress">
            <div className="addressSkeletonAddAddressBtn"></div>
          </div>
          <hr className="skeletonBorderLine" />
          <div className="ListContainer addressSkeletonListContainer">
            <div className="addressListSkeleton">
              <div className="addressListSkeleton-AddressDetails">
                <div className="addressListSkeleton-AddressDetails-Type"></div>
                <div className="addressListSkeleton-AddressDetails-primary"></div>
                <div className="addressListSkeleton-AddressDetails-secondary"></div>
              </div>
              <div className="addressListSkeleton-AddressDetailsEdit">
                <div className="addressListSkeleton-AddressDetailsEdit-a"></div>
                <div className="addressListSkeleton-AddressDetailsEdit-b"></div>
                <div className="addressListSkeleton-AddressDetailsEdit-c"></div>
              </div>
            </div>
            <div className="addressListSkeleton">
              <div className="addressListSkeleton-AddressDetails">
                <div className="addressListSkeleton-AddressDetails-Type"></div>
                <div className="addressListSkeleton-AddressDetails-primary"></div>
                <div className="addressListSkeleton-AddressDetails-secondary"></div>
              </div>
              <div className="addressListSkeleton-AddressDetailsEdit">
                <div className="addressListSkeleton-AddressDetailsEdit-a"></div>
                <div className="addressListSkeleton-AddressDetailsEdit-b"></div>
                <div className="addressListSkeleton-AddressDetailsEdit-c"></div>
              </div>
            </div>
            <div className="addressListSkeleton" style={{
              border: "none"
            }}>
              <div className="addressListSkeleton-AddressDetails">
                <div className="addressListSkeleton-AddressDetails-Type"></div>
                <div className="addressListSkeleton-AddressDetails-primary"></div>
                <div className="addressListSkeleton-AddressDetails-secondary"></div>
              </div>
              <div className="addressListSkeleton-AddressDetailsEdit">
                <div className="addressListSkeleton-AddressDetailsEdit-a"></div>
                <div className="addressListSkeleton-AddressDetailsEdit-b"></div>
                <div className="addressListSkeleton-AddressDetailsEdit-c"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressSkeleton;
