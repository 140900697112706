import React, { useState } from "react";
import { useEffect } from "react";
import "./orderStatus.css";

const OrderStatus = (props) => {
  const [orderDetails, setOrderDetails] = useState({
    primaryText: "",
    secondaryText: "",
    productName: "",
    color: "",
    type: "",
  });

  useEffect(() => {
    let order = props.orderDetails;

    setOrderDetails((prev) => ({
      ...prev,
      productName: order.product.name,
      primaryText: order.order_status.primary_status,
      secondaryText: order.order_status.status_description,
      color: order.order_status.color,
      type: order.order_status.type,
    }));
  }, [props]);

  return (
    <div className="orderStatusContainer">
      <div className="orderStatusPrimaryContainer">
        <div className="orderStatusRadioBtnContainer">
          <div
            className="orderStatusRadioBtnOuter"
            style={{
              backgroundColor:
                orderDetails.color == "yellow"
                  ? "#FFAF00"
                  : orderDetails.color == "red"
                  ? "#FF6161"
                  : "#0C8200",
            }}
          >
            <div
              className="orderStatusRadioBtnInner"
              style={{
                backgroundColor:
                  orderDetails.type == "solid" ? "transparent  " : "#fff ",
              }}
            ></div>
          </div>
        </div>
        <div className="orderStatusPrimaryContainer">
          <p className="orderStatusPrimaryText">{orderDetails.primaryText}</p>
        </div>
      </div>
      <div className="orderStatusSecondaryContainer">
        <p className="orderStatusSecondaryText orderStatusSecondaryTextWeb">
          {orderDetails.secondaryText}
        </p>
        <p className="orderStatusSecondaryText orderStatusSecondaryTextMobile">
          {orderDetails.productName}
        </p>
      </div>
    </div>
  );
};
export default OrderStatus;
