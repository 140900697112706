import React from "react";

const WishlistSkeleton = () => {
  return (
    <>
      <div className="skeleton-loading-forWishlist">
        <div className="skeleton-Wishlistrow1">
          <div className="skeleton-Wishlistrow11"></div>
          <div className="skeleton-Wishlistrow12">
            <div className="skeleton-WishlistColumn1"></div>
            <div className="skeleton-WishlistColumn2"></div>
            <div className="skeleton-WishlistColumn3"></div>
            <div className="skeleton-WishlistColumn4"></div>
          </div>
        </div>
        <div className="skeleton-Wishlistrow2">
          <div className="skeleton-WishlistColumn21"></div>
          <div className="skeleton-WishlistColumn22"></div>
        </div>
      </div>
      <hr className="skeleton-hr-forWishlist" />

      <div className="skeleton-loading-forWishlist">
        <div className="skeleton-Wishlistrow1">
          <div className="skeleton-Wishlistrow11"></div>
          <div className="skeleton-Wishlistrow12">
            <div className="skeleton-WishlistColumn1"></div>
            <div className="skeleton-WishlistColumn2"></div>
            <div className="skeleton-WishlistColumn3"></div>
            <div className="skeleton-WishlistColumn4"></div>
          </div>
        </div>
        <div className="skeleton-Wishlistrow2">
          <div className="skeleton-WishlistColumn21"></div>
          <div className="skeleton-WishlistColumn22"></div>
        </div>
      </div>
      <hr className="skeleton-hr-forWishlist" />
      <div className="skeleton-loading-forWishlist">
        <div className="skeleton-Wishlistrow1">
          <div className="skeleton-Wishlistrow11"></div>
          <div className="skeleton-Wishlistrow12">
            <div className="skeleton-WishlistColumn1"></div>
            <div className="skeleton-WishlistColumn2"></div>
            <div className="skeleton-WishlistColumn3"></div>
            <div className="skeleton-WishlistColumn4"></div>
          </div>
        </div>
        <div className="skeleton-Wishlistrow2">
          <div className="skeleton-WishlistColumn21"></div>
          <div className="skeleton-WishlistColumn22"></div>
        </div>
      </div>
    </>
  );
};
export default WishlistSkeleton;
