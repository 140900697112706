import React from "react";

const CheckoutSkeleton = () => {
  return (
    <>
      <div className="skeleton-loading-forCheckout">
        <div className="skeleton-CheckoutRow1">
          <div className="skeleton-CheckoutRow1Column1">
            <div className="skeleton-CheckoutRowProductSummary"></div>
            <div className="skeleton-CheckoutRowProductDropdown"></div>
          </div>
          <div className="skeleton-CheckoutRow1Column2">
            <div className="skeleton-CheckoutRowProductSummary2"></div>
            <div className="skeleton-CheckoutRowProductDropdown"></div>
          </div>
          <div className="skeleton-CheckoutRow1Column3">
            <div className="skeleton-CheckoutRowEditButtonDiv">
              <div className="skeleton-CheckoutRow1Data1"></div>
              <div className="skeleton-CheckoutRow1Data2"></div>
            </div>
            <div className="skeleton-CheckoutRow1Data3"></div>
            <div className="skeleton-CheckoutRow1Data4"></div>
            <div className="skeleton-CheckoutRow1Data5"></div>
          </div>
        </div>
        <div className="skeleton-CheckoutRow2">
          <div className="skeleton-CheckoutRow2Div1"></div>
          <div className="skeleton-CheckoutRow2Div2">
            <div className="skeleton-CheckoutRow2Div21"></div>
            <div className="skeleton-CheckoutRow2Div22"></div>
          </div>
          <div className="skeleton-CheckoutRow2Div3">
            <div className="skeleton-CheckoutRow2Div21"></div>
            <div className="skeleton-CheckoutRow2Div22"></div>
          </div>
          <div className="skeleton-CheckoutRow2Div3">
            <div className="skeleton-CheckoutRow2Div21"></div>
            <div className="skeleton-CheckoutRow2Div22"></div>
          </div>
          <hr className="skeleton-checkoutHr" />
          <div className="skeleton-CheckoutRow2Div3">
            <div className="skeleton-CheckoutRow2Div21"></div>
            <div className="skeleton-CheckoutRow2Div22"></div>
          </div>
          <div className="skeleton-CheckoutRow2Div6"></div>
        </div>
      </div>
    </>
  );
};
export default CheckoutSkeleton;
