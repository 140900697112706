import React, { useEffect, useState } from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode } from "swiper/modules";
import Card from "../Card/Card";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Swiper = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props.products);
  }, [props.products]);

  return (
    <>
      {items && (
        <SwiperComponent
          className="my_swiper"
          autoHeight={true}
          slidesPerView={1}
          spaceBetween={10}
          freeMode={true}
          scrollbar={{ draggable: true }}
          navigation={{ clickable: true }}
          loopAdditionalSlides={1}
          modules={[Navigation, Pagination, FreeMode]}
          breakpoints={{
            250: {
              slidesPerView: 1.25,
              spaceBetween: 10,
              centeredSlides: false,
            },
            350: {
              slidesPerView: 1.75,
              spaceBetween: 10,
              centeredSlides: false,
            },
            425: {
              slidesPerView: 2.25,
              spaceBetween: 10,
              centeredSlides: false,
            },
            600: {
              slidesPerView: 2.75,
            },
            768: {
              slidesPerView: 3.75,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
        >
          {items &&
            items.map((item, index) => (
              <SwiperSlide key={index}>
                <Card
                  item={item}
                  resetFetchDataNow={props.resetFetchDataNow}
                  idParameret={index}
                  loadingImg={props.loadingImg}
                />
              </SwiperSlide>
            ))}
        </SwiperComponent>
      )}
    </>
  );
};

export default Swiper;
