import React, { useState, useEffect, useContext, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserSideNavbar } from "../userSideNavbar/usersidenavbarMQ";
import ParamsContext from "../useContext/paramContext";
import { useNavigate, useLocation } from "react-router-dom";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import noOrderFound from "../../Assets/order.webp";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import StatusTracker from "./statusTracker";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Wright from "../../Assets/Wright.webp";
import RazorpayButton from "../razorpayPaymentButton/razorpayBtn";
import OrderSkeleton from "./Skeleton/orderSkeleton";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import OrderStatus from "./orderStatus";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import loadingImgPath from "../../Assets/rolling.svg";
import "./ordersMQ.css";
import Toast from "../ReactToastify/Toast";
import LazyImage from "../LazyImage/LazyImage";
import { Helmet } from "react-helmet";
const MemoizedUserSideNavbar = React.memo(UserSideNavbar);
const API_HOST = process.env.REACT_APP_API_HOST;
const infra = process.env.REACT_APP_INFRA;
const Order = () => {
  const { showToast } = Toast();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const [seconds, setSeconds] = useState(5);
  const [isActive, setIsActive] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = async () => {
    setLoading(true);
    setOrders([]);
    setShowPaymentSuccess(false);
    setIsActive(false);
    setSeconds(5);
    fetchOrders();
  };
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const [scroll, setScroll] = useState(false);
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [loadingPayNowBtn, setLoadingPayNowBtn] = useState(false);
  const [PayOrderId, setPayOrderId] = useState("");
  const [makePaymentDetails, setMakePaymentDetails] = useState({
    order_amount: "",
    shipping_cost: "",
    joyrejoy_coins: "",
    total_payable_amount: "",
    saved: "",
  });
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rerenderOrders, setreRenderOrders] = useState(false);
  const [defaultRender, setDefaultRender] = useState(true);
  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "numeric", month: "short", year: "numeric" };

    if (inputDate.length === 10) {
      // If the date string is in "2024-01-26" format
      return new Intl.DateTimeFormat("en-US", options).format(dateObject);
    } else if (inputDate.length >= 24) {
      // If the date string is in "2024-01-26T07:17:30.845449Z" format
      return new Intl.DateTimeFormat("en-US", options).format(dateObject);
    }

    // Return the original input if the format is not recognized
    return inputDate;
  }
  function getTimestampByStatus(status, arrayOfObjects) {
    const matchingObject = arrayOfObjects.find((obj) => obj.status === status);

    // Check if an object with the specified status was found
    if (matchingObject) {
      return formatDate(matchingObject.timestamp);
    } else {
      // Handle the case when no matching object is found
      return null; // or any other appropriate value
    }
  }

  const [showFeatures, setShowFeatures] = useState({
    showFooter: true,
    showOrder: true,
    searchbar: true,
  });
  const [updatedValue, setUpdatedValue] = useState(false);
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showFooter: featureFlags.buyer_common_footer,
        showOrder: featureFlags.buyer_profile_myorders,
        searchbar: featureFlags.buyer_products_search,
      }));
      setUpdatedValue(true);
    }
  }, [contextObj.params.featureFlags]);
  useEffect(() => {
    if (showFeatures.showOrder == false && updatedValue) {
      window.history.back();
    }
  }, [updatedValue]);
  const toggleDivVisibility = (orderId) => {
    let updatedOrders = orders.map((obj) => {
      if (obj.id === orderId) {
        const updatedObj = { ...obj, isDivVisible: !obj.isDivVisible };
        return updatedObj;
      } else {
        return obj;
      }
    });
    setOrders(updatedOrders);
  };

  const getOrderDetails = async (orderId) => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    let orderDetails = await fetch(
      `${API_HOST}/api/customer/orders/${orderId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (orderDetails.status == 200) {
      let orderDetailsJson = await orderDetails.json();
      return orderDetailsJson.data;
    }
    return null;
  };
  const [razorpay, setRazorpay] = useState({
    notes: "",
    jRjOrderId: "",
    coins: "",
    r_order_id: "",
    amount: "",
    currency: "",
    key: "rzp_test_BT2r3Udwz71PXL",
    bussiness_name: "",
    name: "",
    email: "",
    contact: "",
    theme: "#fd7e14",
  });
  const getPaymentSummary = async (id) => {
    setMakePaymentDetails((prev) => ({
      ...prev,
      order_amount: "",
      shipping_cost: "",
      joyrejoy_coins: "",
      total_payable_amount: "",
      saved: "",
    }));
    setShowMakePaymentModal(false);
    const token = getDataFromCookies("7H3K9R5P2Q");
    await fetch(`${API_HOST}/${endpoints.getBuyerPaymentSummary}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status === 200) {
          setMakePaymentDetails((prev) => ({
            ...prev,
            order_amount: res.data.order_amount,
            shipping_cost: res.data.shipping_cost,
            joyrejoy_coins: res.data.joyrejoy_coins,
            total_payable_amount: res.data.total_payable_amount,
            saved: res.data.you_saved,
          }));

          setShowMakePaymentModal(true);
          setLoadingPayNowBtn(false);
          setRazorpay((prev) => ({
            ...prev,
            jRjOrderId: id,
            coins: res.data.joyrejoy_coins,
          }));
          if (res.data.razorpay.payment_details) {
            let razorPayResponse = res.data.razorpay;

            setRazorpay((prev) => ({
              ...prev,
              r_order_id: razorPayResponse.payment_details.r_order_id,
              amount: razorPayResponse.payment_details.amount,
              currency: razorPayResponse.payment_details.currency,
              key: razorPayResponse.payment_details.key,
              bussiness_name: razorPayResponse.payment_details.bussiness_name,
            }));
          }
          if (res.data.razorpay.prefill) {
            let prefillResponse = res.data.razorpay.prefill;
            setRazorpay((prev) => ({
              ...prev,
              // name: prefillResponse.name,
              email: prefillResponse.email,
              contact: prefillResponse.contact,
            }));
          }
        }
        if (res.status == 409) {
          window.location.reload();
        }
        if (res.status == 422) {
          // alert(res.message);
          showToast("info", res.message);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
    setLoading(false);
  };

  const [paymentVeriFication, setPaymentVerification] = useState(false);
  async function reFetchOrderList() {
    setScroll(true);
    setShowPaymentSuccess(true);
    setUrl(API_HOST + "/" + endpoints.buyerOrders);
    startTimer();
    setPaymentVerification(false);
  }
  let [url, setUrl] = useState(API_HOST + "/" + endpoints.buyerOrders);
  const fetchOrders = async () => {
    setShowMakePaymentModal(false);
    setLoadingPayNowBtn(false);
    const token = getDataFromCookies("7H3K9R5P2Q");

    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status === 200) {
          if (res.data.next_page_url) {
            setUrl(res.data.next_page_url);
          }
          res.data.data.map((obj) => {
            {
              obj.isDivVisible = false;
              obj.showSkeleton = false;
            }
          });
          // setOrders(res.data.data);
          setOrders((prevOrders) => [...prevOrders, ...res.data.data]);
          if (res.data.current_page < res.data.last_page) {
            setScroll(true);
          } else {
            setScroll(false);
          }
        }
        if (res.status == 401) {
          removeDataFromCookie("7H3K9R5P2Q", "/");
          removeDataFromCookie("isLoggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          contextObj.setInput("isLoggedIn", false);

          navigate(`${navigation.navigateToHomepage}`);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    const loggedIn = getDataFromCookies("isLoggedIn");

    if (loggedIn == true) {
      contextObj.setInput("navigateToOrderList", false);
      fetchOrders();
    } else {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("navigateToOrderList", true);
      contextObj.setInput("isLoggedIn", false);

      navigate(`${navigation.navigateToHomepage}`);
    }
  }, []);

  useEffect(() => {
    if (!defaultRender) {
      setDefaultRender(false);
      fetchOrders();
    }
  }, [rerenderOrders]);

  function formatNumberWithCommas(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  }
  const getOfferPercentage = (mrp, sellingPrice) => {
    // const percentage = Math.floor(((mrp - sellingPrice) / mrp) * 100);
    const percentage = Math.abs(Math.round(((mrp - sellingPrice) / mrp) * 100));
    if (mrp <= 0 || sellingPrice <= 0 || percentage <= 0) return 0;

    return percentage;
  };
  useEffect(() => {
    if (showMakePaymentModal) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [showMakePaymentModal]);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Your Exciting Order History - JOYREJOY</title>
          <meta
            name="description"
            content="Your order history at JOYREJOY is a journey of joy-filled surprises! Track your past purchases, manage returns, and stay updated on your latest deliveries. Let's keep the excitement flowing!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {showFeatures.showOrder && (
        <>
          {contextObj.params.serviceUnavailable == true ? (
            <ServiceUnavailable maintainanceMode={maintainanceMode} />
          ) : (
            <div className="pageParentDiv pageParentDivColoredBackground">
              <div className="allBoxContainer">
                {showPaymentSuccess ? (
                  <div className="Account-Container">
                    <div>
                      <div className="paymentSuccessModal">
                        <div className="paymentSuccessModal-SVG-box">
                          <img
                            src={Wright}
                            onError={(e) => {
                              e.target.src = placeHolderImage;
                            }}
                            alt="wright.webp"
                          />
                        </div>
                        <div className="">
                          <h1 className="paymentSuccessModal-Heading-Popup">
                            Payment Successful
                          </h1>
                        </div>
                        <div>
                          <p className="paymentSuccessModal-List-tag">
                            Thank You For Shopping With JOYREJOY
                          </p>
                        </div>

                        <div className="closePaymentSuccessModal">
                          {/* Return To Orders Page */}
                          Redirecting to orders page in {seconds}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="buyerMainOrderPage">
                    <div className="buyerMainDivForOrderSection">
                      <div className="buyerMainDivForNavSectionOrderPage">
                        <MemoizedUserSideNavbar />
                      </div>

                      <div className="AllOrderList">
                        <>
                          <div className="pageListHeaderContainer">
                            <p className="PageListTitle">My Orders</p>
                          </div>
                        </>

                        {loading ? (
                          <OrderSkeleton />
                        ) : (
                          <div
                            className={
                              orders.length == 0 ? "loadingTextDiv" : ""
                            }
                          >
                            {showMakePaymentModal && (
                              <div
                                className="ModalContentForCart"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <div
                                  className="makePaymentModalPopupDetails"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <div
                                    className="crossbutton"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      style={{
                                        color: "#a6a6a6",
                                        width: "18px",
                                        height: "18px",
                                        opacity: paymentVeriFication ? 0 : 1,
                                      }}
                                      onClick={() => {
                                        if (!paymentVeriFication) {
                                          setShowMakePaymentModal(false);
                                          setLoadingPayNowBtn(false);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginTop: "-30px",
                                    }}
                                  >
                                    {" "}
                                    {paymentVeriFication ? (
                                      <div
                                        style={{
                                          marginTop: "30%",
                                        }}
                                      >
                                        <img
                                          src={loadingImgPath}
                                          alt="Loading"
                                          className="disabledBtn-Loading"
                                        />
                                        <p
                                          style={{
                                            fontSize: "16px",
                                          }}
                                        >
                                          Payment Verification In Progress.
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="makePaymentModalPopupDetails-container">
                                          <p className="makePaymentModalPopupDetails-Title removeTextMargin">
                                            Payment Summary
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            margin: "15px 0px",
                                          }}
                                        >
                                          <div className="makePaymentModalPopupDetails-container">
                                            <div>
                                              <p className=" removeTextMargin">
                                                Order Amount
                                              </p>
                                            </div>
                                            <div>
                                              <p
                                                className=" removeTextMargin"
                                                style={{
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {" "}
                                                ₹{" "}
                                                {formatNumberWithCommas(
                                                  makePaymentDetails.order_amount
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="makePaymentModalPopupDetails-container">
                                            <div>
                                              <p className=" removeTextMargin">
                                                JOYREJOY Coins Discount
                                              </p>
                                            </div>
                                            <div>
                                              <p
                                                className=" removeTextMargin"
                                                id="JoyReJoyCoinsDiscountCoins"
                                                style={{
                                                  fontWeight: "500",
                                                }}
                                              >
                                                - ₹{" "}
                                                {formatNumberWithCommas(
                                                  makePaymentDetails.joyrejoy_coins
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            borderBottom:
                                              "0.5px solid #59595933",
                                          }}
                                        ></div>
                                        <div className="">
                                          <div className="makePaymentModalPopupDetails-container">
                                            <div>
                                              <p className=" removeTextMargin">
                                                Amount To Pay
                                              </p>
                                            </div>
                                            <div>
                                              <p
                                                className="leftAlignedTxt removeTextMargin"
                                                style={{
                                                  fontWeight: "500",
                                                }}
                                              >
                                                ₹{" "}
                                                {formatNumberWithCommas(
                                                  makePaymentDetails.total_payable_amount
                                                )}
                                              </p>
                                              <p className="leftAlignedTxt allTaxIncludedTxt">
                                                ( All taxes are included. )
                                              </p>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              margin: "20px 0px",
                                              padding: "5px 0px",
                                              backgroundColor: "#d3f4ea69",
                                            }}
                                          >
                                            <p
                                              className=" removeTextMargin"
                                              style={{
                                                color: "#038D63",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Yay! You saved ₹{" "}
                                              {makePaymentDetails.saved} on this
                                              order.
                                            </p>
                                          </div>
                                        </div>

                                        <RazorpayButton
                                          paymentDetails={razorpay}
                                          reFetchOrderList={reFetchOrderList}
                                          setPaymentVerification={
                                            setPaymentVerification
                                          }
                                          showMakePaymentModal={
                                            setShowMakePaymentModal
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                            {orders.length == 0 ? (
                              // Show "No orders available" message when no orders are present

                              <div
                                className="NoItemsOrders"
                                id="zeroOrdersList"
                              >
                                <img
                                  className="orderimage"
                                  src={noOrderFound}
                                  onError={(e) => {
                                    e.target.src = placeHolderImage;
                                  }}
                                  alt="order.webp"
                                />
                                <h4
                                  className="wishlistHeadingElement"
                                  id="EmptyOrderlistHeading"
                                >
                                  My order is empty!
                                </h4>
                                <li className="wishlistEmptyline">
                                  Make order now.
                                </li>
                                <button
                                  onClick={() => {
                                    navigate(
                                      `${navigation.navigateToHomepage}`
                                    );
                                  }}
                                  className="ShopNowButtonOrder"
                                >
                                  {" "}
                                  Shop Now{" "}
                                </button>
                              </div>
                            ) : (
                              <div className="listContainer">
                                <InfiniteScroll
                                  dataLength={orders.length}
                                  next={fetchOrders}
                                  hasMore={scroll}
                                  style={{
                                    width: "95%",
                                    margin: "auto",
                                    // border: "1px solid red",
                                  }}
                                >
                                  {orders &&
                                    orders.map((orderData, index) => (
                                      <>
                                        <div
                                          className="ordersRow"
                                          key={orderData.id}
                                        >
                                          <div className="buyerMainDivForHeadingOrderSectionallelements">
                                            <div className="orderDetailsHolder">
                                              <div className="orderPageImageAndNameDiv">
                                                <div className="ordersListImageContainer">
                                                  {" "}
                                                  {orderData.product &&
                                                  orderData.product
                                                    .thumb_image ? (
                                                    <LazyImage
                                                      className="buyerMainDivForOrderSectionImage"
                                                      src={
                                                        imgHost +
                                                        "/" +
                                                        orderData.product
                                                          .thumb_image
                                                      }
                                                      alt="thumbnail"
                                                    />
                                                  ) : (
                                                    <img
                                                      className="buyerMainDivForOrderSectionImage"
                                                      alt="Loading.."
                                                      loading="lazy"
                                                    />
                                                  )}
                                                </div>
                                                <div className="orderNameAndCategoryDiv">
                                                  <div className="orderPageProductName">
                                                    <p
                                                      className="removeTxtMargin OrderPageProductTitle"
                                                      id={`orderPageProductNameOrderIndex_${index}`}
                                                    >
                                                      {orderData.product.name}
                                                    </p>
                                                    <p className="OrderPageProductCategoryName">
                                                      {
                                                        orderData.product
                                                          .category.name
                                                      }
                                                    </p>
                                                  </div>
                                                  <div className="orderPageMobileViewOrderStatus">
                                                    {!orderData.isDivVisible ? (
                                                      <div>
                                                        <OrderStatus
                                                          orderDetails={
                                                            orderData
                                                          }
                                                        />
                                                        <div className="commonDivForMobile">
                                                          <div className="orderNameAndCategoryDiv1">
                                                            <div
                                                              className={
                                                                orderData
                                                                  .order_status
                                                                  .primary_status ==
                                                                  "Order Confirmation is Pending" &&
                                                                orderData.payment_status ==
                                                                  "Unpaid"
                                                                  ? "ordersPageMakePaymentDisabled ordersPageMakePayment"
                                                                  : orderData
                                                                      .order_status
                                                                      .primary_status ==
                                                                      "Payment is Pending." &&
                                                                    orderData.payment_status ==
                                                                      "Unpaid"
                                                                  ? "ordersPageMakePayment ordersPageMakePaymentEnabled"
                                                                  : "ordersPageMakePaymentHidden"
                                                              }
                                                              onClick={() => {
                                                                if (
                                                                  orderData
                                                                    .order_status
                                                                    .primary_status ==
                                                                    "Payment is Pending." &&
                                                                  orderData.payment_status ==
                                                                    "Unpaid"
                                                                ) {
                                                                  setLoadingPayNowBtn(
                                                                    false
                                                                  );
                                                                  setShowMakePaymentModal(
                                                                    false
                                                                  );
                                                                  setPayOrderId(
                                                                    orderData.id
                                                                  );

                                                                  getPaymentSummary(
                                                                    orderData.id,
                                                                    index
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              Make Payment
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className="prod-desc">
                                                        <p className="removeTxtMargin OrderPageProductTitle">
                                                          {orderData.product
                                                            .name.length > 20
                                                            ? orderData.product.name
                                                                .substring(
                                                                  0,
                                                                  40
                                                                )
                                                                .trim() + ".."
                                                            : orderData.product
                                                                .name}
                                                        </p>
                                                        <p className="OrderPageProductCategoryName">
                                                          {
                                                            orderData.product
                                                              .category.name
                                                          }
                                                        </p>
                                                        <p className="prod-selling-price">
                                                          <span className="productCardRupeeSymbol">
                                                            ₹
                                                          </span>{" "}
                                                          {formatNumberWithCommas(
                                                            orderData.product
                                                              .selling_price
                                                          )}{" "}
                                                          {getOfferPercentage(
                                                            orderData.product
                                                              .current_market_price,
                                                            orderData.product
                                                              .selling_price
                                                          ) > 0 && (
                                                            <span className="offer-discount">
                                                              {getOfferPercentage(
                                                                orderData
                                                                  .product
                                                                  .current_market_price,
                                                                orderData
                                                                  .product
                                                                  .selling_price
                                                              )}
                                                              % off
                                                            </span>
                                                          )}
                                                        </p>
                                                        <p className="prod-estimated-price">
                                                          (Est. Original Price:{" "}
                                                          <span>
                                                            ₹{" "}
                                                            {formatNumberWithCommas(
                                                              orderData.product
                                                                .current_market_price
                                                            )}
                                                          </span>
                                                          )
                                                        </p>
                                                      </div>
                                                    )}
                                                  </div>

                                                  {/* the below div is for mobile view which is not visible in web view */}
                                                </div>
                                              </div>

                                              <div className="commonDiv">
                                                <div className="buyerMainDivForOrderSectionPTag">
                                                  <p
                                                    className="OriginalAmount"
                                                    id={`OriginalAmountOrderIndex_${index}`}
                                                  >
                                                    <span
                                                      className="amountSymbol"
                                                      style={{
                                                        color: "#ACACAC",
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      {" "}
                                                      ₹{" "}
                                                    </span>
                                                    {formatNumberWithCommas(
                                                      Math.round(
                                                        orderData.order_amount
                                                      )
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="orderNumberandDeliveryDiv">
                                                  <div className="buyerMainDivForOrderNumber">
                                                    <OrderStatus
                                                      orderDetails={orderData}
                                                    />
                                                  </div>

                                                  <div className="buyerMainDivForDeliveryDate">
                                                    <div
                                                      id={`orderListMakePaymentOrderIndex_${index}`}
                                                      className={
                                                        orderData.order_status
                                                          .primary_status ==
                                                          "Order Confirmation is Pending" &&
                                                        orderData.payment_status ==
                                                          "Unpaid"
                                                          ? "ordersPageMakePaymentDisabled ordersPageMakePayment"
                                                          : orderData
                                                              .order_status
                                                              .primary_status ==
                                                              "Payment is Pending." &&
                                                            orderData.payment_status ==
                                                              "Unpaid"
                                                          ? "ordersPageMakePayment ordersPageMakePaymentEnabled"
                                                          : "ordersPageMakePaymentHidden"
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          orderData.order_status
                                                            .primary_status ==
                                                            "Payment is Pending." &&
                                                          orderData.payment_status ==
                                                            "Unpaid"
                                                        ) {
                                                          setShowMakePaymentModal(
                                                            true
                                                          );
                                                          setPayOrderId(
                                                            orderData.id
                                                          );
                                                          getPaymentSummary(
                                                            orderData.id
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      Make Payment
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="orderPageOuterDiv">
                                              <div
                                                className="orderPageShowMoreDiv"
                                                onClick={async () => {
                                                  toggleDivVisibility(
                                                    orderData.id
                                                  );
                                                  if (!orderData.isDivVisible) {
                                                    let orderDetails =
                                                      await getOrderDetails(
                                                        orderData.id
                                                      );
                                                    orderData.statusTrackerDetails =
                                                      orderDetails;
                                                    orderData.shipping =
                                                      orderDetails.shipping_address;
                                                    orderData.order_summary =
                                                      orderDetails.order_summary;
                                                    orderData.shipping_cost =
                                                      orderDetails.shipping_cost;

                                                    setOrders(orders);
                                                    toggleDivVisibility(
                                                      orderData.id
                                                    );
                                                  }
                                                }}
                                              >
                                                <button
                                                  id={`orderPageShowOrderDetailsIndex_${index}`}
                                                  className="toggleButtonToShowMore"
                                                >
                                                  <span className="showHideOrderDetails">
                                                    {orderData.isDivVisible
                                                      ? "See Less"
                                                      : "More Details"}
                                                  </span>
                                                  <FontAwesomeIcon
                                                    className="orderPageFontAwsomeIcon"
                                                    icon={
                                                      orderData.isDivVisible
                                                        ? faAngleUp
                                                        : faAngleDown
                                                    }
                                                  />
                                                </button>
                                                <button className="toggleButtonToShowMoreForMobile">
                                                  <FontAwesomeIcon
                                                    className="orderPageFontAwsomeIcon"
                                                    icon={
                                                      orderData.isDivVisible
                                                        ? faAngleUp
                                                        : faAngleDown
                                                    }
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>

                                          {/* mobileview expanded */}
                                          {orderData.isDivVisible && (
                                            <div className="commonDivMobile">
                                              <div className="orderNumberandDeliveryDiv">
                                                <div className="buyerMainDivForOrderNumber">
                                                  <OrderStatus
                                                    orderDetails={orderData}
                                                  />
                                                </div>

                                                <div className="buyerMainDivForDeliveryDate">
                                                  <div
                                                    id={`orderListMakePaymentOrderIndex_${index}`}
                                                    className={
                                                      orderData.order_status
                                                        .primary_status ==
                                                        "Order Confirmation is Pending" &&
                                                      orderData.payment_status ==
                                                        "Unpaid"
                                                        ? "ordersPageMakePaymentDisabled ordersPageMakePayment"
                                                        : orderData.order_status
                                                            .primary_status ==
                                                            "Payment is Pending." &&
                                                          orderData.payment_status ==
                                                            "Unpaid"
                                                        ? "ordersPageMakePayment ordersPageMakePaymentEnabled"
                                                        : "ordersPageMakePaymentHidden"
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        orderData.order_status
                                                          .primary_status ==
                                                          "Payment is Pending." &&
                                                        orderData.payment_status ==
                                                          "Unpaid"
                                                      ) {
                                                        setShowMakePaymentModal(
                                                          true
                                                        );
                                                        setPayOrderId(
                                                          orderData.id
                                                        );
                                                        getPaymentSummary(
                                                          orderData.id
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    Make Payment
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {/* web view */}
                                          {orderData.isDivVisible && (
                                            <>
                                              <div class="hr-container">
                                                <hr class="hr-line" />
                                              </div>
                                              <div className="orderDetailsStatusTrackerHoler">
                                                <div className="orderDetailsContainer">
                                                  <div className="orderPageBuyerOrderIdDiv">
                                                    <div className="DeliveryAddressParaDiv">
                                                      <p
                                                        className="DeliveryAddressPara"
                                                        id={`orderIdFor_${index}`}
                                                      >
                                                        Order ID
                                                      </p>
                                                    </div>
                                                    <div className="orderPageDeliveryAddressDiv">
                                                      <p className="orderPageDeliveryAddress">
                                                        {orderData.order_number}
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div className="orderPageBuyerAddressDiv">
                                                    <div className="DeliveryAddressParaDiv">
                                                      <p
                                                        className="DeliveryAddressPara"
                                                        id={`deliveryAddressIdFor_${index}`}
                                                      >
                                                        Delivery Address
                                                      </p>
                                                    </div>
                                                    <div className="orderPageDeliveryAddressDiv">
                                                      {orderData.shipping ? (
                                                        <p className="orderPageDeliveryAddress">
                                                          {
                                                            orderData.shipping
                                                              .contact_person_name
                                                          }
                                                          <br />
                                                          {
                                                            orderData.shipping
                                                              .address_1
                                                          }{" "}
                                                          {
                                                            orderData.shipping
                                                              .landmark
                                                          }{" "}
                                                          {
                                                            orderData.shipping
                                                              .city
                                                          }{" "}
                                                          {
                                                            orderData.shipping
                                                              .state
                                                          }
                                                          {"-"}
                                                          {
                                                            orderData.shipping
                                                              .pincode
                                                          }
                                                        </p>
                                                      ) : (
                                                        <p
                                                          className="orderPageDeliveryAddress"
                                                          style={{
                                                            color: "#afafaf",
                                                          }}
                                                        >
                                                          {" "}
                                                          Fetching Address...
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="orderPageBuyerOrderSummaryDiv">
                                                    <div className="DeliveryAddressParaDiv">
                                                      <p
                                                        className="DeliveryAddressPara"
                                                        id={`orderSummaryIdFor_${index}`}
                                                      >
                                                        Order Summary
                                                      </p>
                                                    </div>
                                                    <div className="orderPageDeliveryAddressDiv">
                                                      {orderData.order_summary ? (
                                                        <>
                                                          {orderData
                                                            .order_summary
                                                            .total_amount && (
                                                            <p
                                                              className="orderPageDeliveryAddress"
                                                              style={{
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              Order Price : ₹{" "}
                                                              {
                                                                orderData
                                                                  .order_summary
                                                                  .total_amount
                                                              }
                                                            </p>
                                                          )}
                                                          {orderData
                                                            .order_summary
                                                            .coin_discount && (
                                                            <p
                                                              className="orderPageDeliveryAddress"
                                                              style={{
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              JOYREJOY Coins
                                                              Discount : ₹{" "}
                                                              {
                                                                orderData
                                                                  .order_summary
                                                                  .coin_discount
                                                              }
                                                            </p>
                                                          )}
                                                          {orderData
                                                            .order_summary
                                                            .paid_amount && (
                                                            <p className="orderPageDeliveryAddress">
                                                              Amount Paid : ₹{" "}
                                                              {
                                                                orderData
                                                                  .order_summary
                                                                  .paid_amount
                                                              }
                                                            </p>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <p className="orderPageDeliveryAddress">
                                                          Amount Paid : ₹{" "}
                                                          {
                                                            orderData.order_amount
                                                          }
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="orderPageBuyerPaymentSummaryDiv">
                                                    <div className="DeliveryAddressParaDiv">
                                                      <p
                                                        className="DeliveryAddressPara"
                                                        id={`paymentSummaryIdFor_${index}`}
                                                      >
                                                        Payment Summary
                                                      </p>
                                                    </div>
                                                    <div className="orderPageDeliveryAddressDiv">
                                                      {orderData.payment_status && (
                                                        <p
                                                          className="orderPageDeliveryAddress"
                                                          style={{
                                                            color:
                                                              orderData.payment_status ==
                                                              "Unpaid"
                                                                ? "#FF4141"
                                                                : "#0C8200",
                                                          }}
                                                        >
                                                          {
                                                            orderData.payment_status
                                                          }
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>

                                                  {/* shipping Cost */}
                                                  <div className="orderPageBuyerPaymentSummaryDiv">
                                                    <div className="DeliveryAddressParaDiv">
                                                      <p
                                                        className="DeliveryAddressPara"
                                                        id={`shipping_costFor_${index}`}
                                                      >
                                                        Shipping Cost
                                                      </p>
                                                    </div>
                                                    <div className="orderPageDeliveryAddressDiv">
                                                      {orderData.shipping_cost ? (
                                                        <p className="orderPageDeliveryAddress">
                                                          ₹{" "}
                                                          {
                                                            orderData.shipping_cost
                                                          }
                                                        </p>
                                                      ) : (
                                                        <p
                                                          className="orderPageDeliveryAddress"
                                                          style={{
                                                            color: "#afafaf",
                                                          }}
                                                        >
                                                          Fetching cost...
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="orderPageStatusContiner">
                                                  <StatusTracker
                                                    orderDetails={
                                                      orderData.statusTrackerDetails
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        {index < orders.length - 1 && (
                                          <hr className="ordersSepratingLine" />
                                        )}
                                      </>
                                    ))}
                                  {scroll && (
                                    <div className="fetchingMoreOrdersDiv">
                                      {" "}
                                      <p
                                        className="orderStatusPrimaryText"
                                        style={{
                                          color: "#afafaf",
                                        }}
                                      >
                                        Fetching orders ...
                                      </p>{" "}
                                    </div>
                                  )}
                                </InfiniteScroll>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Order;
