import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./InputControl.module.css";

function InputControl(props) {
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <div
      className={styles.container}
      style={{ opacity: props.readOnly ? 0.5 : 1 }}
    >
      {props.label && (
        <label
          style={{ color: "#a3a3a3" }}
          className={`${styles.lableTag}`}
          
        >
          {props.type === "date" ? (
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MM/dd/yyyy"
              className={styles.mymainInputTag}
              id={styles.InputDataType}
              readOnly={props.readOnly}
              autoComplete="off"
              {...props}
            />
          ) : (
            <input
              spellCheck="false"
              id={props.inputTagId}
              className={styles.mymainInputTag}
              style={{
                color: "#808080",
                border: "1px solid #cdcdcd",
              }}
              maxLength={props.maxLength}
              minLength={props.minLength}
              type="text"
              autoComplete="off"
              {...props}
            />
          )}
          <span className={`${styles.SpanlableTag}`} id={props.inputTagId}> {props.label}</span>
          
        </label>
      )}
      <div className={styles.characterCounter}>
        <span
          className={styles.inputboxforerrormessage}
          id={props.inputTagId && `${props.inputTagId}_error`}
          style={{
            fontSize: "10px",
            lineHeight: "12px",
            textAlign: "left",
          }}
        >
          {props.error}
        </span>
      </div>
      <p style={{ color: "black", fontWeight: "500" }} id="modelErrorMsg">{props.message}</p>
    </div>
  );
}

export default InputControl;
