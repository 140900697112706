import React, { useState, useEffect } from "react";
import "./razorpay.css";
import { getDataFromCookies } from "../Cookies/Cookies";
// import { postRazorpayResponse, loadScript } from "./utils";
const razorPayKey = process.env.RAZORPAY_KEY;
const RazorpayButton = (props) => {
  let [razorpayDetails, setRazorpayDetails] = useState();
  useEffect(() => {
    setRazorpayDetails(props.paymentDetails);
  }, [props]);
  const loadScript = (src, callback) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = callback;
    document.body.appendChild(script);
  };
  const postRazorpayResponse = async (
    paymentId,
    signatureId,
    orderId,
    productOrderId,
    coins
  ) => {
    const API_HOST = process.env.REACT_APP_API_HOST;
    let payload = {
      r_payment_id: paymentId,
      r_signature: signatureId,
      r_order_id: orderId,
      order_id: productOrderId,
      coins: coins,
    };
    payload = JSON.stringify(payload);
    const authenticationToken = getDataFromCookies("7H3K9R5P2Q");
    await fetch(`${API_HOST}/api/customer/verify-payment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationToken}`,
      },
      body: payload,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        props.reFetchOrderList();
      })
      .catch((error) => {
        props.reFetchOrderList();
        console.error(error);
      });
  };
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [isPaymentCompleted, setPaymentCompleted] = useState(false);

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js", () => {
      setScriptLoaded(true);
    });
  }, []);

  const handlePayment = () => {
    let razorpayDetailsRes = razorpayDetails;

    if (isScriptLoaded && !isPaymentCompleted) {
      const options = {
        // key: razorpayDetailsRes.key,
        key: razorPayKey,
        amount: razorpayDetailsRes.amount,
        currency: razorpayDetailsRes.currency,
        name: razorpayDetailsRes.bussiness_name,
        order_id: razorpayDetailsRes.r_order_id,
        prefill: {
          name: razorpayDetailsRes.name,
          email: razorpayDetailsRes.email,
          contact: razorpayDetailsRes.contact,
        },
        theme: {
          color: razorpayDetailsRes.theme,
        },
        notes: {
          note: razorpayDetailsRes.notes,
        },

        handler: async (response) => {
          if (
            response.razorpay_order_id &&
            response.razorpay_payment_id &&
            response.razorpay_signature
          ) {
            props.setPaymentVerification(true);
            await postRazorpayResponse(
              response.razorpay_payment_id,
              response.razorpay_signature,
              response.razorpay_order_id,
              razorpayDetails.jRjOrderId,
              razorpayDetails.coins
            );
          }
          setPaymentCompleted(true);
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

      props.setPaymentVerification(false);
    }
  };

  return (
    <div className="razpaydiv">
      <button className="RazorpayPayButton" onClick={handlePayment}>
        Proceed To Payment
      </button>
    </div>
  );
};

export default RazorpayButton;
