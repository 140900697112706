import React, { useEffect, useState, useContext, useMemo } from "react";
import ParamsContext from "./Components/useContext/paramContext";
import { Routes, Route, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { handleScrollToTop } from "./resuableFunctions/resuableFunctions";
import "./App.css";

// Header Footer
import Home from "./Components/LandingPage/Home";
import TopNavbar from "./Components/Navbar/TopNavbar";
import { UserSideNavbar } from "./Components/userSideNavbar/usersidenavbarMQ";
import AppVersion from "./Components/version/AppVersion";
import ProfileInfo from "./Components/ProfileInformation/profileMQ";
import AddressPage from "./Components/Address/addressMQ";
import AddressList from "./Components/addressUpdated/addressListMQ";
import UpdateAddressPage from "./Components/Address/updateAddress";
import ProductList from "./Components/fakestore/ProductList";
import ProductDetails from "./Components/productDetails/ProductView";
import Order from "./Components/OrderPage/orderMQ";
import Cart from "./Components/cartPage/Cart";
import CheckoutPage from "./Components/Checkout/CheckoutPage";
import Wishlist from "./Components/wishlist/wishlistMQ";
import NoRoutesMatched from "./Components/NoRoutes/NotFound";
import ServiceUnavailable from "./Components/serviceUnavailable/serviceUnavailable";
import MyWallet from "./Components/myWallet/myWallet";
import CacheBusterComponent from "./Components/cache/CacheBuster";

import AboutUs from "./Components/aboutUs/AboutUs";
import ContactUs from "./Components/contactUs/ContactUs";

import FAQs from "./Components/Resources/faq";
import CookiePolicy from "./Components/Resources/cookiePolicy";
import PrivacyPolicy from "./Components/Resources/privacyPolicy";
import RefundPolicy from "./Components/Resources/refundPolicy";
import ReturnPolicy from "./Components/Resources/returnPolicy";
import SellersGuideLine from "./Components/Resources/sellersGuideline";
import ShippingPolicy from "./Components/Resources/shippingPolicy";
import TermsOfUse from "./Components/Resources/termsOfUse";
import TermsAndCondition from "./Components/Resources/termsAndCondition";

import Blogs from "./Components/Blogs/BlogList";
import BlogDetails from "./Components/Blogs/BlogDetailsPage";

import Footer1 from "./Components/Footer/Footer1";
import Footer2 from "./Components/Footer/Footer2";
import Privacypolicynew from "./Components/Resources/policy/privacypolicy";
import SkeletonComponent from "./Components/skeleton/Skeleton";
import SearchList from "./Components/fakestore/searchList";
import VendorShop from "./Components/fakestore/VendorShop";
// Default Toast Settings
const defaultToastOptions = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "light",
};

function App() {
  const contextObj = useContext(ParamsContext);
  const { pathname } = useLocation();

  const [showFeatures, setShowFeatures] = useState({
    buyer_common_footer_splitted: false,
    featureUpdated: false,
  });
  useEffect(() => {
    let featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        buyer_common_footer_splitted: featureFlags.buyer_common_footer_splitted,
        featureUpdated: true,
      }));
    }
  }, [contextObj.params.featureFlags]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <CacheBusterComponent />
      <ToastContainer {...defaultToastOptions} />
      <Routes exact path="/" component={App}>
        <Route
          exact
          path="/"
          element={
            <>
              <TopNavbar />
              <Home />
            </>
          }
        />
        <Route
          exact
          path="/write-to-us"
          element={
            <>
              <TopNavbar />
              <Home />
            </>
          }
        />
        <Route exact path="/version" element={<AppVersion />} />
        <Route
          exact
          path="/about"
          element={
            <>
              <TopNavbar />
              <AboutUs />
            </>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <>
              <TopNavbar />
              <ContactUs />
            </>
          }
        />
        <Route
          exact
          path="/products"
          element={
            <>
              <TopNavbar />
              <ProductList />
            </>
          }
        />

        <Route
          exact
          path="/products/:slug"
          element={
            <>
              <TopNavbar />
              <ProductDetails />
              {showFeatures.featureUpdated && (
                <>
                  {showFeatures.buyer_common_footer_splitted ? (
                    <Footer2 />
                  ) : (
                    <Footer1 />
                  )}
                </>
              )}
            </>
          }
        />
        {contextObj.params.featureFlags &&
          contextObj.params.featureFlags.buyer_multivendor && (
            <Route
              exact
              path="/shop/:shopSlug"
              element={
                <>
                  <TopNavbar />
                  <VendorShop />
                  {showFeatures.featureUpdated && (
                    <>
                      {showFeatures.buyer_common_footer_splitted ? (
                        <Footer2 />
                      ) : (
                        <Footer1 />
                      )}
                    </>
                  )}
                </>
              }
            />
          )}
        <Route
          exact
          path="/search"
          element={
            <>
              <TopNavbar />
              <SearchList />
              {showFeatures.featureUpdated && (
                <>
                  {showFeatures.buyer_common_footer_splitted ? (
                    <Footer2 />
                  ) : (
                    <Footer1 />
                  )}
                </>
              )}
            </>
          }
        />

        <Route
          exact
          path="/address-list"
          element={
            <>
              <TopNavbar />
              <AddressList />
            </>
          }
        />
        <Route
          exact
          path="/wish-list"
          element={
            <>
              <TopNavbar />
              <Wishlist />
            </>
          }
        />

        <Route
          exact
          path="/checkout"
          element={
            <>
              <TopNavbar />
              <CheckoutPage />
            </>
          }
        />
        <Route
          exact
          path="/userSideNavbar"
          element={
            <>
              <TopNavbar />
              <UserSideNavbar />
            </>
          }
        />
        <Route
          exact
          path="/address-list/:addressId"
          element={
            <>
              {" "}
              <UpdateAddressPage />
              {showFeatures.featureUpdated && (
                <>
                  {showFeatures.buyer_common_footer_splitted ? (
                    <Footer2 />
                  ) : (
                    <Footer1 />
                  )}
                </>
              )}
            </>
          }
        />
        <Route
          exact
          path="/my-order"
          element={
            <>
              <TopNavbar />
              <Order />
            </>
          }
        />
        <Route
          exact
          path="/profile-info"
          element={
            <>
              <TopNavbar />
              <ProfileInfo />
            </>
          }
        />
        <Route exact path="/address" element={<AddressPage />} />

        <Route exact path="/nav" element={<TopNavbar />} />

        <Route
          exact
          path="/cart"
          element={
            <>
              <TopNavbar />
              <Cart />
            </>
          }
        />
        <Route
          path="/mywallet"
          element={
            <>
              <TopNavbar />
              <MyWallet />
            </>
          }
        />
        <Route path="/serviceUnavailable" element={<ServiceUnavailable />} />
        <Route
          exact
          path="/faq"
          element={
            <>
              <TopNavbar />
              <FAQs />
            </>
          }
        />
        <Route
          exact
          path="/cookie-policy"
          element={
            <>
              <TopNavbar />
              <CookiePolicy />
            </>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <>
              <TopNavbar />
              <PrivacyPolicy />
            </>
          }
        />
        <Route
          exact
          path="/refund-policy"
          element={
            <>
              <TopNavbar />
              <RefundPolicy />
            </>
          }
        />
        <Route
          exact
          path="/return-policy"
          element={
            <>
              <TopNavbar />
              <ReturnPolicy />
            </>
          }
        />
        <Route
          exact
          path="/seller-guideline"
          element={
            <>
              <TopNavbar />
              <SellersGuideLine />
            </>
          }
        />
        <Route
          exact
          path="/shipping-policy"
          element={
            <>
              <TopNavbar />
              <ShippingPolicy />
            </>
          }
        />
        <Route
          exact
          path="/terms-of-use"
          element={
            <>
              <TopNavbar />
              <TermsOfUse />
            </>
          }
        />
        <Route
          exact
          path="/terms-and-condition"
          element={
            <>
              <TopNavbar />
              <TermsAndCondition />
            </>
          }
        />
        <Route
          exact
          path="blogs"
          element={
            <>
              <TopNavbar />
              <Blogs />
            </>
          }
        />
        <Route
          exact
          path="blogs/:slug"
          element={
            <>
              <TopNavbar />
              <BlogDetails />
              {showFeatures.featureUpdated && (
                <>
                  {showFeatures.buyer_common_footer_splitted ? (
                    <Footer2 />
                  ) : (
                    <Footer1 />
                  )}
                </>
              )}
            </>
          }
        />
        <Route
          exact
          path="/privacypolicynew"
          element={
            <>
              <TopNavbar />
              <Privacypolicynew />
            </>
          }
        />

        <Route
          path="*"
          element={
            <>
              <TopNavbar />
              {showFeatures.featureUpdated && <NoRoutesMatched />}
            </>
          }
        />
      </Routes>

      {/* {showFeatures.buyer_common_footer_splitted ? <Footer2 /> : <Footer1 />} */}
      {(location.pathname == "/blogs/:slug" ||
        location.pathname == "/blogs" ||
        location.pathname == "/terms-and-condition" ||
        location.pathname == "/terms-of-use" ||
        location.pathname == "/shipping-policy" ||
        location.pathname == "/seller-guideline" ||
        location.pathname == "/return-policy" ||
        location.pathname == "/refund-policy" ||
        location.pathname == "/privacy-policy" ||
        location.pathname == "/cookie-policy" ||
        location.pathname == "/faq" ||
        location.pathname == "/headlines" ||
        location.pathname == "/mywallet" ||
        location.pathname == "/cart" ||
        location.pathname == "/address" ||
        location.pathname == "/nav" ||
        location.pathname == "/profile-info" ||
        location.pathname == "/my-order" ||
        location.pathname == "/address-list/:addressId" ||
        location.pathname == "/userSideNavbar" ||
        location.pathname == "/wish-list" ||
        location.pathname == "/address-list" ||
        location.pathname == "/products/:slug" ||
        location.pathname == "/products" ||
        location.pathname == "/contact" ||
        location.pathname == "/checkout" ||
        location.pathname == "/about") && (
        // ||
        // location.pathname == "/"
        <>
          {showFeatures.featureUpdated ? (
            <div>
              {showFeatures.buyer_common_footer_splitted ? (
                <Footer2 />
              ) : (
                <Footer1 />
              )}
            </div>
          ) : (
            <SkeletonComponent className="footerSkeleton" />
          )}
        </>
      )}
    </>
  );
}

export default App;
