import { React, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./richTextComponent.css";

const RichTextComponent = (props) => {
  const [richText, setRichText] = useState("");

  useEffect(() => {
    setRichText(props.description);
  }, [props]);

  return (
    <div id="ProductDescription">
      <ReactQuill
        name="description"
        value={richText}
        readOnly
        theme="snow"
        style={{ height: "fitContent" }}
        className="custom-react-quill"
      />
    </div>
  );
};
export default RichTextComponent;
