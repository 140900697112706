import { React, useState, useContext, useEffect } from "react";
import { setDataToCookiesWithExpirationTime } from "./Cookies";
import { useNavigate } from "react-router-dom";

const CookieComponent = (props) => {
  const navigate = useNavigate();
  const [showPrivacyCookies, setShowPrivacyCookies] = useState(true);
  const handleClosePrivacyCookies = () => {
    setShowPrivacyCookies(false);
    props.showPrivacyCookies(false);
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + 0.5 * 60 * 60 * 1000);
    setDataToCookiesWithExpirationTime(
      "guestUserAcceptedCookie",
      false,
      expiryDate,
      "/"
    );
  };
  return (
    <div
      className={
        showPrivacyCookies === true ? "show" : "privacy-cookies-drawer"
      }
    >
      <div className="cookie-content">
        <p className="cookie-text">
          <span className="cookie-heading">We value your privacy</span>
          We use cookies to enhance your browsing experience, serve personalized
          ads or content, and analyze our traffic. By clicking "Accept" you
          consent to our use of cookies.{" "}
          <a
            href="/cookie-policy"
            className="cookie-link"
            onClick={(event) => {
              event.preventDefault();
              navigate("/cookie-policy");
            }}
          >
            Cookie Policy
          </a>
        </p>
        <div className="cookie-btn-area">
          <button
            type="button"
            className="cookie-remind-me-btn"
            onClick={() => {
              props.showPrivacyCookies(false);
              const expiryDate = new Date();
              expiryDate.setTime(expiryDate.getTime() + 12 * 60 * 60 * 1000);
              setDataToCookiesWithExpirationTime(
                "guestUserAcceptedCookie",
                false,
                expiryDate,
                "/"
              );
              setShowPrivacyCookies(false);
            }}
          >
            Remind Later
          </button>
          <button
            type="button"
            className="cookie-accept-btn"
            onClick={() => {
              props.showPrivacyCookies(false);
              const expiryDate = new Date();
              expiryDate.setFullYear(expiryDate.getFullYear() + 1);
              setDataToCookiesWithExpirationTime(
                "guestUserAcceptedCookie",
                true,
                expiryDate,
                "/"
              );
              setShowPrivacyCookies(false);
            }}
          >
            Accept
          </button>
        </div>
        <button
          type="button"
          className="cookie-close"
          onClick={handleClosePrivacyCookies}
        >
          &#x2716;
        </button>
      </div>
    </div>
  );
};

export default CookieComponent;
