import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import { Helmet } from "react-helmet";
const PolicyComponent = React.lazy(() => import("./policy/privacypolicy"));
const infra = process.env.REACT_APP_INFRA;
const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const APP_NAME = process.env.REACT_APP_NAME;

const CookiePolicy = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
    buyerPoliciesRetrievedviaApi: false,
  });
  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
        buyerPoliciesRetrievedviaApi:
          featureFlags.buyer_policies_retrieved_via_api,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;
  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Name = "Cookie Policy";
  const PolicyId = 1;

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Indulge in Joyful Cookies - JOYREJOY</title>
          <meta
            name="description"
            content="Our cookie policy at JOYREJOY is like a recipe for happiness! Learn how we use cookies to enhance your browsing experience with a dash of sweetness. Let's make your online journey even more delightful!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {showFeatures.buyerPoliciesRetrievedviaApi ? (
        <PolicyComponent PolicyName={Name} policyId={PolicyId} />
      ) : (
        <div
          className="pageParentDiv"
          style={{
            marginTop: contextObj.params.topNavbarHeight,
          }}
        >
          <div className="body-container">
            <div className="policy-container" style={conditionalStyle}>
              <ResourcesSidebar activeResource={"Cookie Policy"} />
              <div className="policy-content">
                <h1
                  className={`policy-content-heading ${
                    isSticky ? "sticky-heading" : ""
                  }`}
                >
                  Cookie Policy
                </h1>
                <p>
                  <span>
                    This Cookie Policy ("Policy") describes how {APP_NAME}{" "}
                    ("Company", "we", "us", or "our") uses cookies and similar
                    technologies ("Cookies") on our website and associated
                    services (collectively referred to as the "Platform").
                  </span>
                </p>
                <p>
                  What are Cookies?
                  <span>
                    Cookies are small text files that are placed on your device
                    (computer, phone, tablet) when you visit a website. They
                    allow the website to remember your actions and preferences
                    (such as login, language, font size) over a period of time,
                    so you don't have to keep re-entering them whenever you come
                    back to the site or browse from one page to another.
                  </span>
                </p>
                <p>
                  Types of Cookies We Use
                  <span>
                    We use various types of Cookies on the Platform to serve
                    different purposes. Here are some of the main types:
                  </span>
                </p>
                <ul>
                  <li>
                    Essential Cookies: These Cookies are strictly necessary for
                    the Platform to function properly. They enable you to
                    navigate the Platform and use its features, such as
                    accessing secure areas and remembering shopping cart items.
                    Without these Cookies, some or all of the Platform's
                    functionality may not work as intended.
                  </li>
                  <li>
                    Performance Cookies: These Cookies collect information about
                    how you use the Platform, such as the pages you visit and
                    the links you click. They help us understand how the
                    Platform is performing and identify areas for improvement.
                    This information is anonymous and does not identify you
                    individually.
                  </li>
                  <li>
                    Functionality Cookies: These Cookies allow us to remember
                    your preferences and settings on the Platform, such as your
                    preferred language and location. They also enable us to
                    provide you with personalized features, like product
                    recommendations based on your browsing history.
                  </li>
                </ul>
                <p>
                  How We Use Cookies
                  <span>We use Cookies for various purposes, including:</span>
                </p>
                <ul>
                  <li>To personalize your experience on the Platform</li>
                  <li>To improve the performance of the Platform</li>
                  <li>To analyze how you use the Platform</li>
                  <li>To deliver targeted advertising</li>
                </ul>
                <p>
                  Your Cookie Choices
                  <span>
                    You have control over the Cookies placed on your device. You
                    can set your browser to acceptl Cookies or reject. Each
                    browser has different instructions for managing Cookies.
                    Please consult the "Help" section of your browser for more
                    information.
                  </span>
                </p>
                <p>
                  <span>
                    <i>
                      Please note that disabling certain Cookies may affect your
                      browsing experience on the Platform and your ability to
                      access certain features.
                    </i>
                  </span>
                </p>

                <p>
                  Changes to this Policy
                  <span>
                    We may update this Policy from time to time to reflect
                    changes in our practices or the law. We encourage you to
                    review this Policy periodically for the latest information.
                  </span>
                </p>
                <p>
                  Contact Us
                  <span>
                    If you have any questions about this Policy, please contact
                    us at <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiePolicy;
