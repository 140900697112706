import React from "react";
import "./skeleton.css";

const SkeletonComponent = (props) => {
  return (
    <div
      id={props.id}
      className={props.className ? "skeleton-box " + props.className : "skeleton-box"}
      style={props.style}
    ></div>
  );
};

export default SkeletonComponent;
