import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import PolicyComponent from "./policy/privacypolicy";
import { Helmet } from "react-helmet";

const APP_NAME = process.env.REACT_APP_NAME;
const infra = process.env.REACT_APP_INFRA;
const ShippingPolicy = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
    buyerPoliciesRetrievedviaApi: false,
  });
  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
        buyerPoliciesRetrievedviaApi:
          featureFlags.buyer_policies_retrieved_via_api,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;

  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const Name = "Shipping Policy";
  const PolicyId = 5;

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Sail Away with Joyful Shipping - JOYREJOY</title>
          <meta
            name="description"
            content="Our shipping policy at JOYREJOY is your ticket to a smooth sailing journey! Learn about our reliable and speedy shipping services with a sprinkle of joy. Let's ensure your packages arrive on time and with a smile!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {showFeatures.buyerPoliciesRetrievedviaApi ? (
        <PolicyComponent PolicyName={Name} policyId={PolicyId} />
      ) : (
        <div
          className="pageParentDiv"
          style={{
            marginTop: contextObj.params.topNavbarHeight,
          }}
        >
          <div className="body-container">
            <div className="policy-container" style={conditionalStyle}>
              <ResourcesSidebar activeResource={"Shipping Policy"} />

              <div className="policy-content">
                <h1
                  className={`policy-content-heading ${
                    isSticky ? "sticky-heading" : ""
                  }`}
                >
                  Shipping Policy
                </h1>
                <p>
                  <span>
                    At {APP_NAME}, we believe in delivering quality products and
                    so our team is actively involved at every stage of the
                    process. Product that is shipped from the seller, reaches
                    the buyer via {APP_NAME} warehouse. The shipping cost from
                    seller to warehouse is borne by seller and that of the
                    warehouse to buyer is borne by buyer.
                  </span>
                </p>
                <p>
                  <span>
                    As a 3rd party delivery vendor is involved in the process of
                    shipping, it might take 7-10 working days(excluding
                    Saturdays,Sundays, Bank Holidays and Public Holidays) for
                    the shipment to reach the destination. At times, orders may
                    take more time depending upon the seller's availability and
                    ability to pack and ship the product. We request your
                    patience during these situations.
                  </span>
                </p>
                <p>
                  <span>
                    The cost of return shipping will be incurred by the{" "}
                    {APP_NAME} in the event that the customer returns any items
                    that are due to the products received in damaged condition
                    or not receiving what was ordered.
                  </span>
                </p>
                <p>
                  <span>
                    When your order leaves the warehouse, you'll receive an
                    email with the tracking information.
                  </span>
                </p>
                <p>
                  <span>
                    Please be aware that {APP_NAME} cannot be held liable for
                    delivery delays brought on by natural disasters, holidays,
                    strikes, festivals, or other unforeseen circumstances.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShippingPolicy;
