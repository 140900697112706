import { React, useState, useEffect } from "react";
import "./StatusTracker.css";
const StatusTracker = (props) => {
  const [orderNumber, setOrderNumber] = useState("");
  let [steps, setSteps] = useState([]);

  useEffect(() => {
    if (props.orderDetails) {
      setOrderNumber(props.orderDetails.order_number);

      steps = [];

      props.orderDetails.buyer_status_tracker &&
        props.orderDetails.buyer_status_tracker.forEach((element, index) => {
          element.state =
            element.status == "Payment Pending" ||
            element.status == "Sanitizing & Packaging" ||
            element.status == "Return" ||
            element.status == "Refund"
              ? "inProgress"
              : element.status == "Cancelled" ||
                element.status == "Delivery Failed"
              ? "cancelled"
              : "complete";

          element.leftBorderColor =
            element.status == "Refund" ? "#EE9A00" : "#0C8200";
          element.rightBorderColor =
            element.status == "Cancelled" ||
            element.status == "Return" ||
            element.status == "Refund"
              ? "#EE9A00"
              : "#0C8200";
          element.timestamp = getDateTime(element.timestamp);
        });

      setSteps(props.orderDetails.buyer_status_tracker);
    }
  }, [props.orderDetails]);

  const getDateTime = (timestamp) => {
    let DateData = timestamp.split(" ");
    let OrderTimeStamp = DateData[1].split(":");
    let formatedTimeStamp = `${
      OrderTimeStamp[0] % 12 == 0 ? 12 : OrderTimeStamp[0] % 12
    }:${OrderTimeStamp[1]}:${OrderTimeStamp[2]} ${
      OrderTimeStamp[0] >= 12 ? "PM" : "AM"
    }`;
    let date = DateData[0].split("-");
    let month =
      date[1] == "01"
        ? "Jan"
        : date[1] == "02"
        ? "Feb"
        : date[1] == "03"
        ? "March"
        : date[1] == "04"
        ? "April"
        : date[1] == "05"
        ? "May"
        : date[1] == "06"
        ? "June"
        : date[1] == "07"
        ? "July"
        : date[1] == "08"
        ? "Aug"
        : date[1] == "09"
        ? "Sep"
        : date[1] == "10"
        ? "Oct"
        : date[1] == "11"
        ? "Nov"
        : date[1] == "12"
        ? "Dec"
        : date[1];
    let formatedDate = `${month} ${date[2]}, ${date[0]} ${formatedTimeStamp}`;
    return formatedDate;
  };

  return (
    <div className="statusTrackerContainer">
      {steps &&
        steps.map((step, index) => {
          return (
            <>
              {step && (
                <div key={index} className="stepContainer">
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="stepsHolder"
                  >
                    <div className="stepTxtHolder" id="stepNameWebView">
                      <p
                        className="stepTxt"
                        style={{
                          color:
                            step.status == "complete"
                              ? "#0C8200"
                              : step.status == "inProgress"
                              ? "#EE9A00"
                              : step.status == "cancelled"
                              ? "red"
                              : "#585858",
                        }}
                        id={`${step.status}_${orderNumber}`}
                      >
                        {step.status}
                      </p>
                    </div>
                    <div
                      className="statusUI"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="orderStatusLeft"
                        style={{
                          // width: "45%",
                          border:
                            index == 0
                              ? "none"
                              : `1px solid ${step.leftBorderColor}`,
                        }}
                      ></div>

                      <div
                        style={{
                          backgroundColor:
                            step.state == "complete" ||
                            step.state == "nxtCancelled"
                              ? "#0C8200"
                              : step.state == "inProgress"
                              ? "#EE9A00"
                              : step.state == "cancelled"
                              ? "red"
                              : "white",
                          border:
                            step.state == "inProgress"
                              ? "1px solid #EE9A00"
                              : step.state == "cancelled"
                              ? "1px solid red"
                              : "1px solid #0C8200",
                          borderRadius: "50%",
                          height: "10px",
                          width: "10px",
                        }}
                      ></div>
                      <div
                        className="orderStatusRight"
                        style={{
                          border:
                            index == steps.length - 1
                              ? "none"
                              : `1px solid ${step.rightBorderColor}`,
                        }}
                      ></div>
                    </div>
                    <div className="stepTxtHolderForTimeStamp">
                      <p
                        className="stepTxt"
                        id="stepNameMobileView"
                        style={{
                          color:
                            step.state == "complete"
                              ? "#0C8200"
                              : step.state == "inProgress"
                              ? "#EE9A00"
                              : step.state == "cancelled"
                              ? "red"
                              : "#585858",
                        }}
                      >
                        {step.status}
                      </p>
                      <p
                        className="stepTimeStampTxt"
                        style={{
                          color: "#585858",
                          // step.status == "complete"
                          //   ? "#0C8200"
                          //   : step.status == "inProgress"
                          //   ? "#EE9A00"
                          //   : step.status == "cancelled"
                          //   ? "red"
                          //   : "#585858",
                        }}
                      >
                        {step.timestamp}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
    </div>
  );
};
export default StatusTracker;
