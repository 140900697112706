import React from "react";

const CartSkeleton = () => {
  return (
    <>
      <div className="skeleton-loading-forCart">
        <div className="skeleton-Cartrow1">
          <div className="skeleton-CartColumn1"></div>
          <hr className="skeletonBorderLine"/>
          <div className="skeleton-CartColumn2">
            <div className="skeleton-CartColumn2InnerRow1"></div>
            <div className="skeleton-CartColumn2InnerRow2">
              <div className="skeleton-CartInnerColumn1"></div>
              <div className="skeleton-CartInnerColumn2"></div>
              <div className="skeleton-CartInnerColumn3"></div>
            </div>
            <div className="skeleton-CartColumn2InnerRow3"></div>
          </div>
          <hr className="inner-hrline-forCart skeletonBorderLine" />
          <div className="skeleton-CartColumn2">
            <div className="skeleton-CartColumn2InnerRow1"></div>
            <div className="skeleton-CartColumn2InnerRow2">
              <div className="skeleton-CartInnerColumn1"></div>
              <div className="skeleton-CartInnerColumn2"></div>
              <div className="skeleton-CartInnerColumn3"></div>
            </div>
            <div className="skeleton-CartColumn2InnerRow3"></div>
          </div>
          <hr className="inner-hrline-forCart skeletonBorderLine" />
        </div>

        <div className="skeleton-Cartrow2">
          <div className="skeleton-CartInnerColumn21"></div>
          <div className="skeleton-CartInnerColumn22"></div>
          <div className="skeleton-CartInnerColumn23"></div>
          <div className="skeleton-CartInnerColumn24"></div>
        </div>
      </div>
    </>
  );
};
export default CartSkeleton;
