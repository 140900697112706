import React from "react";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import ResourcesSidebar from "./Sidebar";
import PolicyComponent from "./policy/privacypolicy";
import "./resources.css";
import { Helmet } from "react-helmet";

const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;
const COMPANY_CIN = process.env.REACT_APP_COMPANY_CIN;
const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;
const APP_NAME = process.env.REACT_APP_NAME;
const infra = process.env.REACT_APP_INFRA;
const PrivacyPolicy = () => {
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;

  const Name = "Privacy Policy";
  const PolicyId = 2;
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
    buyerPoliciesRetrievedviaApi: false,
  });
  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
        buyerPoliciesRetrievedviaApi:
          featureFlags.buyer_policies_retrieved_via_api,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;

  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Our Secret to Joyful Privacy - JOYREJOY</title>
          <meta
            name="description"
            content="Our privacy policy at JOYREJOY is like a treasure chest - full of secrets to keeping your information safe and secure! Explore our policy with a sprinkle of joy and peace of mind. Let's guard your privacy like a precious gem!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {showFeatures.buyerPoliciesRetrievedviaApi ? (
        <PolicyComponent PolicyName={Name} policyId={PolicyId} />
      ) : (
        <div
          className="pageParentDiv"
          style={{
            marginTop: contextObj.params.topNavbarHeight,
          }}
        >
          <div className="body-container">
            <div className="policy-container" style={conditionalStyle}>
              <ResourcesSidebar activeResource={"Privacy Policy"} />
              <div className="policy-content">
                <h1
                  className={`policy-content-heading ${
                    isSticky ? "sticky-heading" : ""
                  }`}
                >
                  Privacy policy
                </h1>
                <p>
                  <span>
                    The policies and procedures on the collecting, using,
                    disclosing, and safeguarding of your personal data when you
                    visit and/or utilize the www.joyrejoy.com web portal, along
                    with any associated software, mobile applications, and other
                    apps, and their features, are outlined in this privacy
                    policy (the "policy"). The platform is owned and run by{" "}
                    <i>
                      {COMPANY_NAME} (CIN: {COMPANY_CIN})
                    </i>
                    , which has its registered office at <i>{ADMIN_ADDRESS}</i>.
                  </span>
                </p>
                <p>
                  <span>
                    Any mention of "you," "your," or "user" (if applicable)
                    refers to you as the platform's visitor, subscriber, or
                    user, as well as any features or functions that you may
                    utilize. {APP_NAME}
                    is referred to as "we," "our," and "us" in all instances.
                  </span>
                </p>
                <p>
                  <span>
                    Please read this policy before accessing and/or using the
                    platform or submitting any personal information to the{" "}
                    {APP_NAME}. This policy is a part of and is incorporated
                    within, and is to be read along with the Terms of Use
                    mentioned in the platform which govern the access and use by
                    you of the platform and the activities, services, features,
                    functionalities and / or resources available on the platform
                    ("Terms").
                  </span>
                </p>
                <p>
                  <span>
                    {APP_NAME} values the trust the users place in {APP_NAME}.
                    Therefore, {APP_NAME} insists upon the highest standards for
                    secure communication and user information privacy.
                  </span>
                </p>
                <p>
                  <span>
                    Please read and understand this entire policy carefully to
                    learn about {APP_NAME}'s information gathering, usage,
                    storage, processing and dissemination practices. The policy
                    specified herein is subject to change at any time without
                    notice. To make sure you are aware of any changes, please
                    review this policy periodically.
                  </span>
                </p>
                <p>Your Consent</p>
                <p>
                  <span>
                    You agree and consent to the collection, transfer, use,
                    storage, disclosure, and sharing—in certain limited
                    circumstances—of your information as defined by and
                    collected by us in line with this policy by accessing and/or
                    using the platform and the services. Please do not use or
                    access the platform if you disagree with the policy. You can
                    always elect not to use a service, feature, or resource on
                    the platform in order to withhold information.
                  </span>
                </p>
                <p>Protecting the Privacy</p>
                <p>
                  <span>
                    All information submitted by the users on the platform shall
                    be safeguarded and protected. {APP_NAME} is committed to
                    protecting the privacy, confidentiality, accuracy and
                    security of the personal information that it collects, uses,
                    retains and discloses in the course of conducting business
                    and providing the services. This policy will protect the
                    data provided by you and will be used for the purposes of
                    providing services to you through the platform and any other
                    administration purposes relevant to any products/services
                    provided by {APP_NAME}.
                  </span>
                </p>
                <p>Types of Information Collected</p>
                <p>
                  <span>
                    {APP_NAME} collects several types of information which is
                    primarily used to serve you better and maintain authenticity
                    of services by identifying you. Details of such information
                    (collectively called “Information”) are as follows:
                  </span>
                </p>
                <p>Personal Identification Information</p>
                <p>
                  <span>
                    Personal information is any data gathered that can be used
                    to identify or get in touch with you specifically. In
                    connection with other activities, services, features, or
                    resources we make available on the platform, as well as when
                    you visit the platform, register there, fill out a form, or
                    reply to a survey, among other times, we may obtain personal
                    identification information from you.
                  </span>
                </p>
                <p>
                  <span>
                    We will collect the personal information from you only if
                    you voluntarily submit such information to us. You can
                    always refuse to supply this information, except that it may
                    prevent you from accessing or using any or all of the
                    activities, services, features or resources available on the
                    platform. You may be asked for, as appropriate, any or all
                    of the following personal information:
                  </span>
                </p>
                <p>
                  <span>
                    Contact information, such as name, email address, phone
                    number, address. Identification/ Verification code such as
                    one time pass (OTP) etc.
                  </span>
                  <span>
                    Unique identifiers such as your social media user
                    information.
                  </span>
                  <span>
                    Financial information such as your bank account details,
                    credit card/ debit card number, billing address.
                  </span>
                  <span>
                    Information regarding your interactions or transactions on
                    the platform.
                  </span>
                </p>
                <p>
                  <span>
                    The information from third-party providers or external APIs
                    (such as the Google API) will be collected only if explicit
                    consent and access is granted by users to do the same,
                    during the signup process.
                  </span>
                </p>
                <p>
                  <span>
                    Please note that unless required by law or submitted to us
                    in accordance with a contract, we do not intentionally
                    collect or keep personal data. We shall remove any
                    personally identifiable information from our database that
                    we discover was obtained from a minor without valid consent.
                  </span>
                </p>
                <p>Non-personal Identification Information</p>
                <p>
                  <span>
                    Every time you use our platform, we might gather information
                    that cannot be used to identify you personally. Non-personal
                    identification information can include the name of your
                    browser, the kind of computer or other communication device
                    you use, and technical details about how you connected to
                    our platform, like the operating system and ISP you used,
                    the IP address that is associated with the computer you use,
                    the domain server that you used to access the platform, and
                    other data of a similar nature. We would not be able to
                    personally identify you with the help of this non-personal
                    information.
                  </span>
                </p>
                <p>Customer Service Communications Information</p>
                <p>
                  <span>
                    {APP_NAME} may collect Personal Information, Non-Personal or
                    Other Information when you post or submit comments,
                    questions, complaints or suggestions regarding services.
                  </span>
                </p>
                <p>Other Information</p>
                <p>
                  <span>
                    {APP_NAME} also collects information regarding your usage of
                    the activities, services, features or resources available on
                    the platform.
                  </span>
                </p>
                <p>Mode of Collection of Information</p>
                <p>
                  <span>
                    In general, you can browse the platform without telling us
                    who you are or revealing any Personal Information about
                    yourself. Once you give us your Personal Information, you
                    are not anonymous to us. We may collect your Non-Personal
                    Information, just by browsing/ downloading the platform.
                    When collecting Personal Information from you we indicate
                    which fields are required and which fields are optional. You
                    always have the option to not provide information by
                    choosing not to use a service or feature or resource on the
                    platform. We may automatically track certain information
                    about you based upon your behavior on the platform.
                  </span>
                  <span>
                    We use this information to do internal research on our
                    users' demographics, interests, and behavior to better
                    understand, protect and serve our users. This information is
                    compiled and analyzed on an aggregated basis. This
                    information may include the link/ URL that you just came
                    from (whether this URL is on our platform / app or not),
                    which URL you next go to (whether this URL is on the
                    platform or not), your computer browser information, and
                    your IP address.
                  </span>
                </p>
                <p>
                  <span>
                    We may receive Personal Information about you from third
                    parties, such as external APIs and social media services. If
                    you access the platform through an external API or a social
                    media service or connect a service on the platform to a
                    social media service, the information we collect may include
                    your user name associated with that social media service,
                    any information or content the social media service has the
                    right to share with us, such as your profile picture, email
                    address or friends list, and any information you have made
                    public in connection with that social media service. When
                    you access the platform or otherwise deal with {APP_NAME}{" "}
                    entity through an external APIs or social media services or
                    when you connect the platform to social media services, you
                    are authorizing {APP_NAME} to collect, store, and use and
                    retain such information and content in accordance with this
                    Privacy Policy.
                  </span>
                </p>
                <p>
                  <span>
                    We offer certain features that are only available through
                    the use of a "cookie". We also use cookies to allow you to
                    enter your password less frequently during a session. Most
                    cookies are "session cookies" meaning that they are
                    automatically deleted from your device at the end of a
                    session. You are always free to decline our cookies if your
                    browser permits, although in that case you may not be able
                    to use certain services, features or resources on the
                    platform. Additionally, you may encounter "cookies" or other
                    similar devices on certain pages of the platform that are
                    placed by third parties. We do not control the use of
                    cookies by third parties.
                  </span>
                </p>
                <p>
                  <span>
                    If you transact with us, we collect some additional
                    information, such as a billing address, upi id or net
                    banking details based on method you opt for payment.
                  </span>
                </p>
                <p>Use of Information Collected</p>
                <p>
                  <span>
                    {APP_NAME} undertakes not to disclose (includes selling,
                    sharing and renting) your Personal Information collected by
                    the Company at any time to any third party without your
                    consent except in accordance with this policy. However,{" "}
                    {APP_NAME}
                    reserves the right to disclose Information in good faith:
                  </span>
                  <span>if required by law or any authority;</span>
                  <span>
                    to protect and defend rights and interests of {APP_NAME},
                    employees/ agents of {APP_NAME}, other users or the general
                    public;
                  </span>
                  <span>
                    to enforce Terms of Use and other policies of {APP_NAME};
                  </span>
                  <span>to address concerns raised by a third party.</span>
                </p>
                <p>
                  <span>
                    {APP_NAME} uses Personal Information to provide its
                    services. To the extent {APP_NAME} uses your Personal
                    Information to market to you, we will provide you the
                    ability to opt-out of such uses. We use your Personal
                    Information to identify and authenticate users; understand
                    your needs and confirm your application information and
                    assess your eligibility for services; establish and maintain
                    communication, and to respond to your inquiries; investigate
                    and settle your claims/requests, and determine your
                    eligibility for benefits;, resolve disputes; troubleshoot
                    problems; help promote a safe service; billing and collect
                    money; measure consumer interest in our products and
                    services, inform you about Services, and updates; customize
                    and improve your experience; detect and protect us against
                    error, fraud and other criminal activity; enforce our terms
                    and conditions; meet regulatory requirements; and as
                    otherwise specified in the policy.
                  </span>
                </p>
                <p>
                  <span>
                    We collect passively tracked data primarily for purposes of
                    administering, protecting and improving our platform and our
                    systems, to better understand the preferences of our site
                    visitors, to identify server problems, to compile aggregated
                    statistics about site usage, and to help personalize your
                    experience on our site.
                  </span>
                </p>
                <p></p>
                <p>
                  <span>
                    By using this platform, you hereby authorize {APP_NAME} to
                    contact you via calls, mails and/or text messages or by any
                    other similar means using the contact details so provided,
                    to furnish you with information regarding our various
                    services currently available or as may be added or made
                    available at any future date. In the event you do not wish
                    to receive such information, you may unsubscribe to the
                    facility.
                  </span>
                </p>

                <p>
                  <span>
                    In our efforts to continually improve our product and
                    service offerings, we collect and analyze demographic and
                    profile data about our users' activity on our platform.
                  </span>
                </p>
                <p>
                  <span>
                    We identify and use your IP address to help administer our
                    platform. Your IP address is also used to help identify you
                    and to gather broad demographic information.
                  </span>
                </p>
                <p>
                  <span>
                    We may occasionally ask you to complete optional online
                    surveys. These surveys may ask you for contact information
                    and demographic information (like postal/ zip code). We use
                    this data to tailor your experience at the platform,
                    providing you with content that we think you might be
                    interested in and to display content according to your
                    preferences.
                  </span>
                </p>
                <p>
                  <span>
                    {APP_NAME} may also use all the data and information,
                    generated in the course or on account of provision of
                    services, on an anonymous basis for analytics purposes.
                  </span>
                </p>
                <p>
                  <span>
                    Information collected can be used by {APP_NAME} within the
                    company or by its affiliates/ contractors/ agents for
                    analysis and determination of statistics, demand and supply,
                    pattern of usage etc.
                  </span>
                </p>
                <p>
                  <span>
                    {APP_NAME} may use the user's data collected from external
                    APIs (such as the Google, or other service providers which{" "}
                    {APP_NAME} integrates with the services from time to time)
                    for the purpose of allowing them to access and use the
                    activities, services, features or resources available on the
                    platform.
                  </span>
                </p>
                <p>
                  <span>
                    {APP_NAME} may retain the user's information for as long as
                    their account is active or as needed to provide them the
                    services, comply with {APP_NAME}'s legal obligations,
                    resolve disputes and enforce {APP_NAME}'s agreements. Please
                    note, the user's information may remain stored in archive on{" "}
                    {APP_NAME}'s servers even after the termination of the
                    user's association with {APP_NAME}.
                  </span>
                </p>
                <h2>Sharing of Information</h2>
                <p>
                  With Service Providers
                  <span>
                    We may share your Information, on a need to know basis, with
                    third parties that provide services to help with our
                    business activities. These third parties are authorized to
                    use your Personal Information only as necessary to provide
                    certain services to us, including but not limited to:
                  </span>
                  <span>Providing cloud infrastructure;</span>
                  <span>
                    Sending transactional and service communications and social
                    media advertising services;
                  </span>
                  <span>Sending marketing communications;</span>
                  <span>Providing customer service;</span>
                  <span>Conducting research;</span>
                  <span>Providing data analytics services;</span>
                  <span>Providing safety and security services;</span>
                  <span>Conducting research or performing surveys;</span>
                  <span>Payment processing and facilitation; or</span>
                  <span>
                    Other professionals engaged by {APP_NAME} viz., consultants,
                    lawyers, accountants, and other professional service
                    providers.
                  </span>
                </p>
                <p>
                  With Other Third Parties
                  <span>
                    We may share your Information with other third-party
                    business partners strictly on a need to know basis, for the
                    purpose of enhancing our services or providing additional
                    services on request. If you do not want us to share your
                    Personal Information with third-party business partners,
                    please contact us at the contact information below.
                  </span>
                  <span>
                    Additionally, we may share your Information if we choose to
                    sell, transfer, or merge parts of our business or our assets
                    with third parties. Alternatively, we may seek to acquire
                    other businesses or merge with them. If a change happens to
                    our business, then the new owners may use your personal data
                    in the same way as set out in this policy. We may also
                    share, sell or transfer your Information, only limited to
                    your name, email address, contact information, with other
                    third parties in accordance with the applicable law.
                  </span>
                </p>
                <p>
                  With consent
                  <span>
                    {APP_NAME} may share a user's personal data other than as
                    described in this policy, with prior consent of such user.
                  </span>
                </p>
                <p>
                  Link to other website
                  <span>
                    The platform may contain certain hyperlinks to other
                    websites. While {APP_NAME}'s platform is subject to this
                    policy, we are not responsible for the privacy practices or
                    the content of any other website that are linked to this
                    platform. Any information you provide to these other
                    websites will be subject to their respective privacy
                    policies. We therefore recommend that you read the privacy
                    statement for each third party site you visit.
                  </span>
                </p>
                <p>
                  Rights and Obligations of the Users
                  <span>
                    To help {APP_NAME} protect information, the users are
                    required to maintain the privacy of login credentials and
                    other information required by them to access or use the
                    platform. The users are also prohibited from communicating
                    information provided by {APP_NAME} to a third party unless
                    required by law or in compliance with agreements entered
                    between users and {APP_NAME}.
                  </span>
                  <span>
                    You are always free to review and edit your Personal
                    Information uploaded on the platform. You shall always have
                    an option to withdraw your consent for collection and use by{" "}
                    {APP_NAME} of your Information by intimating {APP_NAME}{" "}
                    about such withdrawal by writing to {APP_NAME} at the below
                    mentioned contact information. In case, you withdraw your
                    consent for collection, storage and use of your information
                    by {APP_NAME}, {APP_NAME}
                    shall be entitled to restrict, control or terminate your
                    access and use of all or any of the activities, services,
                    features or resources available on the platform.
                  </span>
                </p>
                <p>
                  Disclaimer as to Loss
                  <span>
                    Although {APP_NAME} has ensured the security of Personal
                    Information collected, considering the open nature of the
                    internet, such Information may be accessed by unauthorized
                    entities/ software/ codes in a cyber-security incident.{" "}
                    {APP_NAME} assumes no liability whatsoever with respect to
                    access of Information in case of a cyber-security incident.
                  </span>
                </p>
                <p>
                  Security Measures
                  <span>
                    {APP_NAME} adopts generally accepted, reasonable data
                    collection, storage and processing practices and security
                    measures to protect against unauthorized access, alteration,
                    disclosure or destruction of your Information, username,
                    password, transaction information and data stored on the
                    platform.
                  </span>
                </p>
                <p>
                  <span>
                    {APP_NAME} deploys reasonable security measures in place to
                    protect the loss, misuse, and alteration of the information
                    under its control. Once the user's information is in the
                    Company's possession, {APP_NAME} adheres to reasonable
                    security guidelines, protecting it against unauthorized
                    access.
                  </span>
                </p>
                <p>
                  <span>
                    The user's information will primarily be stored in
                    electronic form however certain data can also be stored in
                    physical form.
                  </span>
                </p>

                <p>
                  <span>
                    {APP_NAME} may also use such Information for purposes of
                    safety, security, fraud prevention and detection, and
                    research and development for as long as it retains such
                    information.
                  </span>
                </p>
                <p>
                  <span>
                    Only authorized personnel have access to your Information.
                    Our systems and procedures are designed to prevent the loss,
                    misuse, unauthorized access, disclosure, alteration, and
                    destruction of your information. Our commitment to security
                    extends to the contracts and agreements that we sign with
                    external suppliers and service providers. We will retain
                    your Information only as long as is required for the
                    purposes for which it was collected and/or any legal or
                    regulatory requirements. We shall only make use of, disclose
                    and/or store your Personal Information in a secure and
                    confidential manner.
                  </span>
                </p>
                <p>
                  <span>
                    We follow generally accepted industry standards to protect
                    the personal information submitted to us. However, no method
                    of transmission over the internet, or method of electronic
                    storage, is 100% secure. Therefore, while we strive to use
                    industry best practices to protect your Personal
                    Information, we do not guarantee its absolute security.
                  </span>
                </p>
                <p>
                  <span>
                    The platform stores all data with the Cloud platform
                    provided by [AWS](“Cloud Provider”) which may store this
                    data on its servers located at [Mumbai]. The Cloud Provider
                    has security measures in place to protect the loss, misuse
                    and alteration of the information. You acknowledge that the
                    standards followed by the Cloud Provider are not within
                    control of {APP_NAME} and are liable to change from time to
                    time. You agree that it is in your interest to review from
                    time to time the security standards, practices and policies
                    adopted by the Cloud Provider to confirm that there are no
                    changes that you are not comfortable with.
                  </span>
                </p>
                <p>
                  <span>
                    You acknowledge that the standards followed by the Cloud
                    Provider are not within control of {APP_NAME} and are liable
                    to change from time to time.
                  </span>
                </p>
                <p>
                  1. Changes to this Policy
                  <span>
                    We reserve the right to modify this policy at any time. In
                    the event that we do so, the updated privacy policy shall be
                    posted on our platform and it will be incumbent upon you to
                    review the same periodically. However, if we materially
                    change our privacy practices we will notify you by sending
                    an email or by posting a notice on our platform.
                  </span>
                </p>
                <p>
                  2. Contact Information
                  <span>
                    If you have any questions about this Privacy Policy, the
                    practices of this platform, or your dealings with this
                    platform, please contact us at: {ADMIN_ADDRESS}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicy;
