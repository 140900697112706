import { useContext, useEffect, useState } from "react";
import Endpoints from "../../../config/APIEndpoints.json";
import ParamsContext from "../../useContext/paramContext";
import ResourcesSidebar from "../Sidebar";
import ServiceUnavailable from "../../serviceUnavailable/serviceUnavailable";
const API_HOST = process.env.REACT_APP_API_HOST;

const PolicyComponent = ({ PolicyName, policyId }) => {
  const [poicyData, setPolicyData] = useState([]);
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const contextObj = useContext(ParamsContext);
  const PolicieId = policyId;
  const [showFeatures, setShowFeatures] = useState({
    searchbar: true,
  });
  const [isSticky, setIsSticky] = useState(false);
  const dynamicStyle = {
    paddingTop: showFeatures.searchbar ? "0px" : "0px",
    margin: showFeatures.searchbar ? "50px 0px 0px 0px" : "40px 0px 0px 0px",
  };

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        searchbar: featureFlags.buyer_products_search,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const conditionalStyle = window.innerWidth < 600 ? dynamicStyle : undefined;

  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const GetPolicydata = async () => {
    await fetch(`${API_HOST}/${Endpoints.getPolicyById}/${PolicieId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status == 200) {
          setPolicyData(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };
  useEffect(() => {
    GetPolicydata();
  }, []);

  return (
    <div className="pageParentDiv">
      <div className="body-container">
        {contextObj.params.serviceUnavailable == true ? (
          <ServiceUnavailable maintainanceMode={maintainanceMode} />
        ) : (
          <div className="policy-container-new" style={conditionalStyle}>
            <ResourcesSidebar activeResource={PolicyName} />
            <div className="policy-content-new">
              <h1
                className={`policy-content-heading ${
                  isSticky ? "sticky-heading" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: poicyData.name }}
              ></h1>
              <div
                dangerouslySetInnerHTML={{ __html: poicyData.content }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyComponent;
