import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserSideNavbar } from "../userSideNavbar/usersidenavbarMQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import CartSidebar from "../productDetails/CartSidebar";
import WishlistEmptyImage from "../../Assets/emptyWishlist.webp";
import RecentlyViewedProducts from "../LandingPage/RecentlyViewedProducts";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import Toast from "../ReactToastify/Toast";
import WishlistSkeleton from "./WishlistSkeleton";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import "./wishlistMQ.css";
import LazyImage from "../LazyImage/LazyImage";
import { Helmet } from "react-helmet";
const MemoizedUserSideNavbar = React.memo(UserSideNavbar);
const MemoizedRecentlyViewedProducts = React.memo(RecentlyViewedProducts);
const MemoizedLazyImage = React.memo(LazyImage);
const MemoizedCartSidebar = React.memo(CartSidebar);

const API_HOST = process.env.REACT_APP_API_HOST;
const infra = process.env.REACT_APP_INFRA;
export default function Wishlist() {
  const { showToast } = Toast();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const contextObj = useContext(ParamsContext);

  const [fetchDataNow, setFetchDataNow] = useState(false);
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const [defaultRender, setDefaultRender] = useState(true);
  const [reRenderPage, setReRenderPage] = useState(false);
  const [CharacterToShow, setCharacterToShow] = useState(0);
  const [wishlistProducts, setWishListProducts] = useState([]);

  const [showIcons, setShowIcons] = useState(false);
  const [itemInWishList, setItemInWishlist] = useState(false);
  const [wishlistLength, setWishlistLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [removeWishlistId, setRemoveWishlistId] = useState(null);
  const [removeFromWishList, setRemoveFromWishList] = useState({
    stock_id: "",
    product_name: "",
    slug: "",
    thumb_image: "",
    selling_price: "",
    current_market_price: "",
  });
  const [showFeatures, setShowFeatures] = useState({
    showFooter: true,
    cartSidebar: true,
    showWishList: true,
    searchbar: true,
  });
  const [updatedValue, setUpdatedValue] = useState(false);
  const [wishlistDeletedItem, setWishlistDeletedItem] = useState(null);
  const [deletedItemModal, setDeletedItemModal] = useState(false);

  const filterWishlistData = (productId) => {
    let filteredData = wishlistProducts;

    filteredData = filteredData.filter((obj) => obj.stock_id !== productId);
    setWishListProducts(filteredData);
    if (filteredData.length) {
      setWishlistLength(filteredData.length);
    } else {
      setItemInWishlist(false);
      setWishlistLength(0);
    }
  };
  const updateWishlistData = (productId) => {
    const updatedWishlist = wishlistProducts.map((obj) => {
      // Create a copy of the object and update if the product_id matches
      if (obj.product_id == productId) {
        return {
          ...obj,
          is_in_cart: true,
        };
      }
      return obj; // Return the object as is if it doesn't match
    });
    setWishListProducts(updatedWishlist);
  };
  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showFooter: featureFlags.buyer_common_footer,
        cartSidebar: featureFlags.buyer_common_addtocartbar,
        showWishList: featureFlags.buyer_products_wishlist,
        searchbar: featureFlags.buyer_products_search,
      }));
      setUpdatedValue(true);
    }
  }, [contextObj.params.featureFlags]);

  useEffect(() => {
    if (showFeatures.showWishList == false && updatedValue) {
      window.history.back();
    }
  }, [updatedValue]);

  useEffect(() => {
    if (showRemoveConfirmation) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [showRemoveConfirmation]);

  async function handleAddToCartFromWishlist(e, productId) {
    e.stopPropagation();
    let payload = {
      stock_id: productId,
    };
    payload = JSON.stringify(payload);
    const tooken = getDataFromCookies("7H3K9R5P2Q");
    const addToCart = await fetch(`${API_HOST}/${endpoints.addToCart}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${tooken}`,
      },
      body: payload,
    });
    const addToCartResJson = await addToCart.json();
    if (
      addToCartResJson.status == 500 ||
      addToCartResJson.status == 501 ||
      addToCartResJson.status == 502 ||
      addToCartResJson.status == 503 ||
      (addToCartResJson.message &&
        addToCartResJson.message.toLowerCase().includes("server error"))
    ) {
      if (addToCartResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (addToCartResJson.status === 201) {
      showToast("success", Constants.alertMessages.alertForAddingInCart);
      setFetchDataNow(true);
      contextObj.setInput("cartLength", contextObj.params.cartLength + 1);
      if (window.innerWidth > 600) {
        contextObj.setInput("showCartSidebar", true);
      }

      // setReRenderPage(!reRenderPage);
      updateWishlistData(productId);
    } else if (addToCartResJson.status === 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else {
      showToast("error", addToCartResJson.message);
    }
  }

  const handelDeleteConfirmation = (item) => {
    setRemoveWishlistId(item.id);
    setShowRemoveConfirmation(true);
    setRemoveFromWishList({
      stock_id: item.stock_id || "",
      product_name: item.product?.name || "",
      slug: item.product?.slug || "",
      thumb_image: item.stock?.thumb_image || "",
      selling_price: item.stock?.selling_price || "",
      current_market_price: item.stock?.current_market_price || "",
    });
  };

  const handleRemoveCancel = () => {
    setShowRemoveConfirmation(false);
  };

  useEffect(() => {
    if (contextObj.params.productRemovedFromWishlistId) {
      const timeoutId = setTimeout(() => {
        contextObj.setInput("productRemovedFromWishlistId", "");
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productRemovedFromWishlistId]);

  // Remove item from wishlist
  async function handleRemoveFromWishlist(productId) {
    const tooken = getDataFromCookies("7H3K9R5P2Q");
    const removeItem = await fetch(
      `${API_HOST}/${endpoints.removeFromWishList}${productId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${tooken}`,
        },
      }
    );
    const removeItemResJson = await removeItem.json();
    if (
      removeItemResJson.status == 500 ||
      removeItemResJson.status == 501 ||
      removeItemResJson.status == 502 ||
      removeItemResJson.status == 503 ||
      (removeItemResJson.message &&
        removeItemResJson.message.toLowerCase().includes("server error"))
    ) {
      if (removeItemResJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    if (removeItemResJson.status === 200) {
      showToast("success", Constants.alertMessages.alertForRemovingProduct);
      setShowRemoveConfirmation(false);
      contextObj.setInput("productRemovedFromWishlistId", productId);
      filterWishlistData(productId);
    }

    if (removeItemResJson.status === 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }

    setShowRemoveConfirmation(false);
  }

  const clearedDeletedItems = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.clearedWishlistDeletedItems}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      setShowRemoveConfirmation(false);
    } else if (res.status == 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else if (
      res.status == 500 ||
      res.status == 501 ||
      res.status == 502 ||
      res.status == 503 ||
      (res.message && res.message.toLowerCase().includes("server error"))
    ) {
      if (res.status == 503) setMaintainanceMode(true);
      else setMaintainanceMode(false);
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDeletedItemModal(false);
    fetchWishlistData();
  };

  function calculateOfferPercentage(actualPrice, sellingPrice) {
    if (actualPrice && sellingPrice) {
      if (actualPrice <= 0 || sellingPrice <= 0) {
        throw new Error("Price must be greater than zero.");
      }
      const discount = actualPrice - sellingPrice;
      const offerPercentage = Math.abs(
        Math.round((discount / actualPrice) * 100)
      );

      return offerPercentage;
    }
  }

  // Get all wishlist
  const fetchWishlistData = async () => {
    setDefaultRender(false);
    const tooken = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(`${API_HOST}/${endpoints.buyerWishList}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tooken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      if (res.data.total > 0) {
        setItemInWishlist(true);
        setWishlistLength(res.data.total);
      } else if (res.data.total === 0) {
        setItemInWishlist(false);
        setWishlistLength(0);
      }

      // Open deleted item warning
      if (res.data && res.data.deleted_items > 0) {
        setDeletedItemModal(true);
        setWishlistDeletedItem(res.data.deleted_items);
      }
      setWishListProducts(res.data.wishlist);
    } else if (res.status === 404) {
      setWishlistLength(0);
      setItemInWishlist(false);

      // Open deleted item warning
      if (res.data && res.data.deleted_items > 0) {
        setDeletedItemModal(true);
        setWishlistDeletedItem(res.data.deleted_items);
      }
    } else if (res.status === 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else if (
      res.status == 500 ||
      res.status == 501 ||
      res.status == 502 ||
      res.status == 503 ||
      (res.message && res.message.toLowerCase().includes("server error"))
    ) {
      if (res.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      showToast("info", "Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    contextObj.setInput("cartWishlistMsg", "");
    contextObj.setInput("showCartSidebar", false);
    const loggedIn = getDataFromCookies("isLoggedIn");
    if (loggedIn == true) {
      setCharacterToShow(window.outerWidth > 600 ? 150 : 150);
      setShowIcons(window.outerWidth > 600 ? true : false);
      fetchWishlistData();
    } else {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }
  }, []);
  useEffect(() => {
    if (!defaultRender) {
      fetchWishlistData();
    }
  }, [reRenderPage || contextObj.params.reFetchWishList]);
  function formatNumberWithCommas(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  }
  useEffect(() => {
    if (contextObj.params.showCartSidebar && showFeatures.cartSidebar) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [contextObj.params.showCartSidebar, showFeatures.cartSidebar]);
  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Your Wishlist - JOYREJOY</title>
          <meta
            name="description"
            content="Your wishlist at JOYREJOY is a collection of dreams for your little ones! Keep track of items you love and bring joy into their lives."
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div className="pageParentDiv pageParentDivColoredBackground">
          <div className="allBoxContainer">
            {showFeatures.showWishList && (
              <div className="Wishlist_buyerMainWishlistPage">
                <div className="Wishlist_buyerMainDivForNavWishlistSection">
                  <div className="Wishlist_buyerMainDivForNavSection">
                    <MemoizedUserSideNavbar />
                  </div>

                  <div className="WishListMainDivForWishlistSection">
                    <div className="pageListHeaderContainer">
                      {loading ? (
                        <p className="PageListTitle">Fetching Wishlist...</p>
                      ) : (
                        <p className="PageListTitle">
                          My Wishlist <span>({wishlistLength})</span>
                        </p>
                      )}
                    </div>
                    {loading ? (
                      <WishlistSkeleton />
                    ) : (
                      <>
                        <div className="Wishlist-cart-container listContainer">
                          <div className="Wishlist-cart-container-1">
                            <div className="Wishlist-row">
                              {!itemInWishList ? (
                                <>
                                  <div
                                    className="Wishlist_NoItemsWarning"
                                    id="Wishlist_NoItemsContainer"
                                  >
                                    <img
                                      src={WishlistEmptyImage}
                                      alt="Wishlist.webp"
                                    />
                                    <h4
                                      className="wishlistHeadingElement"
                                      id="EmptyWishlistHeading"
                                    >
                                      Your wishlist is empty!
                                    </h4>

                                    <p
                                      className="wishlistEmptyline"
                                      id="wishlistEmptyline"
                                    >
                                      Add items to it now.
                                    </p>
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `${navigation.navigateToHomepage}`
                                        );
                                      }}
                                      className="Wishlist_ShopNowButtonWishlist"
                                      id="Wishlist_ShopNowButtonWishlist"
                                    >
                                      {" "}
                                      Shop Now{" "}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="Wishlist_allwishComponents">
                                    {wishlistProducts.map((product, index) => {
                                      return (
                                        <div key={index}>
                                          {index !== 0 && (
                                            <hr className="wishlishHrline" />
                                          )}

                                          <div>
                                            {product.stock && product.product && (
                                              <>
                                                <div
                                                  key={product.id}
                                                  onClick={() => {
                                                    navigate(
                                                      `${navigation.navigateToProductList}/${product.product.slug}`
                                                    );
                                                  }}
                                                >
                                                  <div className="Wishlist-container-having-productdetails">
                                                    <div className="wishlistimagediv">
                                                      <div className="wishlist-image-details">
                                                        <div className="Wishlist-image-containerdiv">
                                                          {
                                                            <MemoizedLazyImage
                                                              className="Wishlist-image-container"
                                                              src={
                                                                imgHost +
                                                                "/" +
                                                                product.stock
                                                                  .thumb_image
                                                              }
                                                              alt="image"
                                                            />
                                                          }
                                                          {product.stock
                                                            .quantity === 0 && (
                                                            <>
                                                              <div className="Wishlist_soldOutdiv">
                                                                <button className="Wishlist_soldOutLabel">
                                                                  Out of Stock
                                                                </button>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                        <div className="wishlistdetailsdiv">
                                                          <div className="Wishlist-brand-name-description-div">
                                                            <li
                                                              className="Wishlist-brand-name-description"
                                                              id={`wishlistProductDescription_${index}`}
                                                            >
                                                              {product.product.name
                                                                .length >
                                                              CharacterToShow
                                                                ? `${product.stock.product.name.substring(
                                                                    0,
                                                                    CharacterToShow
                                                                  )}...`
                                                                : product.product
                                                                    .name}
                                                            </li>
                                                          </div>

                                                          <li
                                                            className="Wishlist_categoryNameWishlist"
                                                            id={`wishlistProductCategoryName_${index}`}
                                                          >
                                                            {product.brand &&
                                                              (product.brand.name
                                                                .length >
                                                              CharacterToShow
                                                                ? `${product.brand.name.substring(
                                                                    0,
                                                                    CharacterToShow
                                                                  )}...`
                                                                : product.brand
                                                                    .name)}
                                                          </li>

                                                          <div className="Wishlist-brand-pice-button-in-a-div">
                                                            <li
                                                              className="Wishlist_price-list-Tag"
                                                              id={`wishlistProductPrice_${index}`}
                                                            >
                                                              ₹{" "}
                                                              {Number(
                                                                product.stock
                                                                  .selling_price
                                                              ).toLocaleString()}
                                                              {"   "}
                                                              <span className="Wishlist_offpricewishlist">
                                                                {calculateOfferPercentage(
                                                                  product.stock
                                                                    .current_market_price,
                                                                    product.stock
                                                                    .selling_price
                                                                )}
                                                                % {"  "}
                                                                off
                                                              </span>
                                                            </li>
                                                            <li
                                                              className="Wishlist_estoriginalpricewishlistlinetag"
                                                              id={`wishlistProductOriginalPrice_${index}`}
                                                            >
                                                              (Est. Original
                                                              Price:{" "}
                                                              <span className="Wishlist_originalpriceline">
                                                                ₹{" "}
                                                                {formatNumberWithCommas(
                                                                  product.stock
                                                                    .current_market_price
                                                                )}
                                                                )
                                                              </span>
                                                            </li>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="wishListRemoveItemMobileDiv">
                                                        <div
                                                          className="removeFromWishListMobile"
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handelDeleteConfirmation(
                                                              product
                                                            );
                                                          }}
                                                        >
                                                          <svg
                                                            width="20"
                                                            height="25"
                                                            viewBox="0 0 15 15"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              d="M1 3.25H10"
                                                              stroke="#B3B3B3"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                            />
                                                            <path
                                                              d="M4.375 5.5V8.875"
                                                              stroke="#B3B3B3"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                            />
                                                            <path
                                                              d="M6.625 5.5V8.875"
                                                              stroke="#B3B3B3"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                            />
                                                            <path
                                                              d="M1.5625 3.25L2.125 10C2.125 10.2984 2.24353 10.5845 2.4545 10.7955C2.66548 11.0065 2.95163 11.125 3.25 11.125H7.75C8.04837 11.125 8.33452 11.0065 8.54549 10.7955C8.75647 10.5845 8.875 10.2984 8.875 10L9.4375 3.25"
                                                              stroke="#B3B3B3"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                            />
                                                            <path
                                                              d="M3.8125 3.25V1.5625C3.8125 1.41332 3.87176 1.27024 3.97725 1.16475C4.08274 1.05926 4.22582 1 4.375 1H6.625C6.77418 1 6.91726 1.05926 7.02275 1.16475C7.12824 1.27024 7.1875 1.41332 7.1875 1.5625V3.25"
                                                              stroke="#B3B3B3"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                            />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="Wishlist-addTocart-removefromcart-btn-container">
                                                      <div
                                                        className="Wishlist-checkout-button-Container WebViewOnly"
                                                        id={`Wishlist-checkout-button-Container-removeFormWishlist_${index}`}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handelDeleteConfirmation(
                                                            product
                                                          );
                                                        }}
                                                      >
                                                        <button className="button-profile-tabindex">
                                                          {showIcons ? (
                                                            <div
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              <svg
                                                                width="15"
                                                                height="15"
                                                                viewBox="0 0 15 15"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <path
                                                                  d="M3.25 1.3C3.25 0.955218 3.38696 0.624558 3.63076 0.380761C3.87456 0.136964 4.20522 0 4.55 0H8.45C8.79478 0 9.12544 0.136964 9.36924 0.380761C9.61304 0.624558 9.75 0.955218 9.75 1.3V2.6H12.35C12.5224 2.6 12.6877 2.66848 12.8096 2.79038C12.9315 2.91228 13 3.07761 13 3.25C13 3.42239 12.9315 3.58772 12.8096 3.70962C12.6877 3.83152 12.5224 3.9 12.35 3.9H11.6551L11.0916 11.7923C11.0683 12.1203 10.9215 12.4272 10.6809 12.6513C10.4403 12.8754 10.1237 13 9.79485 13H3.2045C2.87569 13 2.55909 12.8754 2.31848 12.6513C2.07786 12.4272 1.9311 12.1203 1.90775 11.7923L1.3455 3.9H0.65C0.477609 3.9 0.312279 3.83152 0.190381 3.70962C0.0684819 3.58772 0 3.42239 0 3.25C0 3.07761 0.0684819 2.91228 0.190381 2.79038C0.312279 2.66848 0.477609 2.6 0.65 2.6H3.25V1.3ZM4.55 2.6H8.45V1.3H4.55V2.6ZM2.6481 3.9L3.20515 11.7H9.7955L10.3525 3.9H2.6481ZM5.2 5.2C5.37239 5.2 5.53772 5.26848 5.65962 5.39038C5.78152 5.51228 5.85 5.67761 5.85 5.85V9.75C5.85 9.92239 5.78152 10.0877 5.65962 10.2096C5.53772 10.3315 5.37239 10.4 5.2 10.4C5.02761 10.4 4.86228 10.3315 4.74038 10.2096C4.61848 10.0877 4.55 9.92239 4.55 9.75V5.85C4.55 5.67761 4.61848 5.51228 4.74038 5.39038C4.86228 5.26848 5.02761 5.2 5.2 5.2ZM7.8 5.2C7.97239 5.2 8.13772 5.26848 8.25962 5.39038C8.38152 5.51228 8.45 5.67761 8.45 5.85V9.75C8.45 9.92239 8.38152 10.0877 8.25962 10.2096C8.13772 10.3315 7.97239 10.4 7.8 10.4C7.62761 10.4 7.46228 10.3315 7.34038 10.2096C7.21848 10.0877 7.15 9.92239 7.15 9.75V5.85C7.15 5.67761 7.21848 5.51228 7.34038 5.39038C7.46228 5.26848 7.62761 5.2 7.8 5.2Z"
                                                                  fill="#797979"
                                                                />
                                                              </svg>
                                                            </div>
                                                          ) : (
                                                            <>
                                                              <svg
                                                                width="15"
                                                                height="15"
                                                                viewBox="0 0 15 15"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <path
                                                                  d="M1 3.25H10"
                                                                  stroke="#B3B3B3"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                />
                                                                <path
                                                                  d="M4.375 5.5V8.875"
                                                                  stroke="#B3B3B3"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                />
                                                                <path
                                                                  d="M6.625 5.5V8.875"
                                                                  stroke="#B3B3B3"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                />
                                                                <path
                                                                  d="M1.5625 3.25L2.125 10C2.125 10.2984 2.24353 10.5845 2.4545 10.7955C2.66548 11.0065 2.95163 11.125 3.25 11.125H7.75C8.04837 11.125 8.33452 11.0065 8.54549 10.7955C8.75647 10.5845 8.875 10.2984 8.875 10L9.4375 3.25"
                                                                  stroke="#B3B3B3"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                />
                                                                <path
                                                                  d="M3.8125 3.25V1.5625C3.8125 1.41332 3.87176 1.27024 3.97725 1.16475C4.08274 1.05926 4.22582 1 4.375 1H6.625C6.77418 1 6.91726 1.05926 7.02275 1.16475C7.12824 1.27024 7.1875 1.41332 7.1875 1.5625V3.25"
                                                                  stroke="#B3B3B3"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                />
                                                              </svg>
                                                              Remove
                                                            </>
                                                          )}
                                                        </button>
                                                      </div>
                                                      <div
                                                        className={
                                                          product.is_in_cart ==
                                                            true ||
                                                            product.stock.quantity ==
                                                            0
                                                            ? "Wishlist-checkout-button-Container disabledBtn"
                                                            : "Wishlist-checkout-button-Container"
                                                        }
                                                        id="Wishlist-checkout-button-Container-AddToCart"
                                                        onClick={(e) => {
                                                          if (
                                                            product.stock
                                                              .quantity > 0 &&
                                                              product.is_in_cart !==
                                                              true
                                                          ) {
                                                            handleAddToCartFromWishlist(
                                                              e,
                                                              product.stock_id
                                                            );
                                                          }

                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                        }}
                                                      >
                                                        <button
                                                          className={
                                                            product.is_in_cart ==
                                                            true
                                                              ? "Wishlist_addtocardLogodisable"
                                                              : "Wishlist_addtocardLogo"
                                                          }
                                                          style={{
                                                            cursor:
                                                            product.is_in_cart ==
                                                                true ||
                                                                product.stock
                                                                .quantity == 0
                                                                ? "not-allowed"
                                                                : "pointer",
                                                          }}
                                                        >
                                                          {product.is_in_cart ==
                                                          true
                                                            ? "Added To Cart"
                                                            : "Add To Cart"}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="Wishlist_additionallineforWishlistMobile"></div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {showRemoveConfirmation && removeWishlistId && (
            <div
              className="ModalContainer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="ModalContent">
                <div
                  className=" modal-container"
                  id="removeFromWishlistModal"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <div className="popupContainer-RemoveFromList">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <h5 className="headingMessageOfAlert">Remove Item?</h5>
                      </div>
                      <div
                        className="crossbuttonForCart"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <button
                          onClick={handleRemoveCancel}
                          className="fA-cross-icons-button"
                        >
                          <FontAwesomeIcon
                            icon={faXmark}
                            id={"cartPageCloseRemoveProductModal"}
                            className="CartPage_CrossMarkButton"
                          />
                        </button>
                      </div>
                    </div>

                    <p className="WebViewOnly wishlist-pera-tag">
                      Are you sure you want to remove{" "}
                      <span
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {removeFromWishList.product_name}{" "}
                      </span>
                      from wishlist?
                    </p>

                    <div
                      className="imageAndDetailsMainDivForDeleteButton mobileViewOnly"
                      style={{
                        marginTop: "15px",
                      }}
                    >
                      <div
                        onClick={() => {
                          navigate(
                            `${navigation.navigateToProductList}/${removeFromWishList.slug}`
                          );
                        }}
                      >
                        <LazyImage
                          className="imageContainerForRemove"
                          src={imgHost + "/" + removeFromWishList.thumb_image}
                          alt="image"
                        />
                      </div>
                      <div className="deleteModalForMobileDiv">
                        <div>
                          <p className="cartPageProductNameForMobileDelete">
                            {removeFromWishList.product_name.length > 20
                              ? `${removeFromWishList.product_name.substring(
                                  0,
                                  33
                                )}`
                              : removeFromWishList.product_name}
                          </p>
                        </div>

                        <div className="cartPagePriceDiv">
                          <p className="cartPageProductPrice">
                            <span className="cartPageProductPriceSymbol">
                              ₹
                            </span>
                            {removeFromWishList.selling_price &&
                              formatNumberWithCommas(
                                removeFromWishList.selling_price
                              )}
                          </p>
                          <p className="cartPageDiscountPercentage">
                            {" "}
                            {removeFromWishList.current_market_price &&
                              removeFromWishList.selling_price &&
                              calculateOfferPercentage(
                                removeFromWishList.current_market_price,
                                removeFromWishList.selling_price
                              )}
                            % off
                          </p>
                        </div>
                        <div className="estimatedPriceDivForDeleteButton">
                          <p>
                            (Est. Original Price:{" "}
                            <span className="estPriceSpanTag">
                              ₹{" "}
                              {removeFromWishList.current_market_price &&
                                formatNumberWithCommas(
                                  removeFromWishList.current_market_price
                                )}
                            </span>
                            )
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="lineInDeleteBox" />
                  </div>
                  <div className="buttonDiv">
                    <button
                      className=" button2"
                      id="confirmRemoveFromWishlistModal"
                      onClick={() => {
                        handleRemoveFromWishlist(removeFromWishList.stock_id);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="wishlist_button_remove"
                      id="cancelRemoveFromWishlistModal"
                      onClick={handleRemoveCancel}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!loading && (
            <div
              className="bg-white "
              style={{
                padding: "0 5%",
              }}
            >
              <MemoizedRecentlyViewedProducts
                resetFetchDataNow={setFetchDataNow}
              />
            </div>
          )}

          {contextObj.params.showCartSidebar && showFeatures.cartSidebar && (
            <div className="WebViewOnly">
              <MemoizedCartSidebar
                fetchDataNow={fetchDataNow}
                resetFetchDataNow={setFetchDataNow}
              />
            </div>
          )}
        </div>
      )}

      {/* Deleted Item Modal */}
      {deletedItemModal && (
        <div className="warning-modal ModalContentForCart">
          <div className="modal-container warning-modal-container">
            <h1>Warning</h1>
            <p className="message">
              Oops, {wishlistDeletedItem} items in your wishlist no longer
              available.
            </p>

            <div className="modal-buttons">
              <button
                type="button"
                className="warning-rm-btn"
                onClick={() => {
                  clearedDeletedItems();
                }}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
