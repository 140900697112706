import { React } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../config/Navigation.json";
import { Helmet } from "react-helmet";
import "./about.css";

const AboutUs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const infra = process.env.REACT_APP_INFRA;
  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Bringing Playtime Back to Life: The JOYREJOY Journey</title>
          <meta
            name="description"
            content="Welcome to JOYREJOY! Learn more about our mission to bring joy back to pre-loved toys - one sale at a time. Discover how we strive to provide a wide range of high-quality second-hand toys to our customers. Join us in our journey to make playtime more joyful and sustainable."
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      <div className="pageParentDiv">
        <div className="body-container">
          <div className="about-us">
            <div className="breadcrumb-area">
              <h1>About Us</h1>
              <ol className="breadcrumb">
                <li
                  className="breadcrumb-item"
                  onClick={() => {
                    navigate(navigation.navigateToHomepage);
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">About Us</li>
              </ol>
            </div>
            <p className="heading-description">
              Giving toys a second life: Sparking joy through sustainable play
            </p>
            <div className="about-description">
              <p>
                <span>
                  We believe every child deserves the magic of playtime. That's
                  why we created JOYREJOY, an online marketplace dedicated to
                  bringing pre-loved toys back to life and into the hands of
                  eager little ones.
                </span>
              </p>
              <p>
                Our Mission
                <span>
                  We're passionate about sustainability and reducing the
                  environmental impact of the toy industry. By purchasing
                  refurbished toys, you're not just giving these treasures a
                  second chance, you're also making a conscious choice for the
                  planet.
                </span>
              </p>
              <p>
                The Refurbishment Process
                <span>
                  Each toy on our platform goes through a rigorous multi-step
                  process:
                </span>
              </p>
              <ul>
                <li>
                  Thorough Inspection: We carefully assess each toy for its
                  condition, safety, and functionality.
                </li>
                <li>
                  Cleaning and Sanitizing: Every toy gets a sparkling clean
                  using child-safe and eco-friendly products.
                </li>
                <li>
                  Quality Control: Before being listed, each toy undergoes a
                  final check to ensure it meets our high standards.
                </li>
              </ul>
              <p>
                We believe play is essential for a child's development.
                <span>Beyond providing quality toys, we offer:</span>
              </p>
              <ul>
                <li>
                  Curated selections: Our team of toy experts handpicks toys
                  based on age, developmental stage, and interests.
                </li>
                <li>
                  Educational resources: We offer helpful guides and tips to
                  parents on choosing the right toys for their children.
                </li>
                <li>
                  Community engagement: We host online playgroups and events to
                  connect families and foster a love for learning through play.
                </li>
              </ul>
              <p>
                Why JOYREJOY?
                <span>
                  By choosing refurbished toys, you're not just saving money,
                  you're joining a movement for a more sustainable and playful
                  future. Together, we can make a difference, one toy at a time.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
