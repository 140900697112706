import React, { useState, useEffect, useContext } from "react";
import "./address.css";
import InputControl from "../InputControl/InputControl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import {
  getDataFromCookies,
  setDataToCookies,
  removeDataFromCookie,
} from "../Cookies/Cookies";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import Toast from "../ReactToastify/Toast";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import loadingImgPath from "../../Assets/rolling.svg";

const API_HOST = process.env.REACT_APP_API_HOST;
const API_KEY = process.env.REACT_APP_POSTOFFICE_API_KEY;
let pincode = "";

const AddressPage = ({ handleCloseModal, reFetchAddress }) => {
  const { showToast } = Toast();
  const [isFirstAddress, setIsFirstAddress] = useState(false);

  useEffect(() => {
    let firstAdd = getDataFromCookies("hideIsdefaultInAddAddress");
    setIsFirstAddress(firstAdd);
  }, []);

  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);

  const [disabledAddAddressBtn, setDisabledAddAddressBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // Initial state for the checkbox

  const handleCheckboxChange = (event) => {
    // Update the state when the checkbox value changes
    setBuyerAddress((prev) => ({
      ...prev,
      is_default: isChecked ? 0 : 1,
    }));
    setIsChecked(!isChecked);
  };

  const [charCount, setCharCount] = useState({
    name: 0,
    email: 0,
    phone: 0,
    alt_phone: 0,
    pincode: 0,
    landmark: 0,
    address_1: 0,
  });

  const [selectedValue, setSelectedValue] = useState(null);

  const [maintainanceMode, setMaintainanceMode] = useState(false);
  useEffect(() => {
    const loggedIn = getDataFromCookies("isLoggedIn");
    if (loggedIn !== true) {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }

    const handleResize = () => {
      if (window.innerWidth > 600) {
        document.body.style.overflow = "hidden"; // Disable scroll when the screen width is greater than 600px
      } else {
        document.body.style.overflow = "auto"; // Enable scroll when the screen width is less than or equal to 600px
      }
    };

    // Initial call to set overflow based on the initial screen size
    handleResize();

    // Add a resize event listener to update overflow when the screen size changes
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      // document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
    };
  }, []);

  const isPhoneNumberFormat = (phone) => {
    const regex = /^[0-9]+$/;
    return regex.test(phone);
  };
  const isPhoneNumber = (phone) => {
    const regex = /^(\+?91[\-\s]?)?[6-9]\d{9}$/.test(phone);
    return regex;
  };
  const areAllNumbers = (num) => {
    const regex = /^[0-9]+$/.test(num);
    return regex;
  };

  const sanitizeInput = (inputValue) => {
    const words = inputValue.split(" ");

    const filteredWords = words.filter((word) => word.trim() !== "");

    let sanitizedValue = filteredWords.join(" ");

    if (inputValue.endsWith(" ")) {
      sanitizedValue += " ";
    }

    return sanitizedValue;
  };

  const [isVerified, setIsVerified] = useState(null);
  const [errorMessages, setErrorMessages] = useState({
    name: "",
    phone: "",
    alt_phone: "",
    email: "",
    pincode: "",
    address_1: "",
    address_2: "",
    landmark: "",
    city: "",
    state: "",
    addressType: "",
  });
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [BuyerAddress, setBuyerAddress] = useState({
    address_type: null,
    apartment_number: null,
    city: null,
    contact_person_name: null,
    contact_person_email: null,
    house_number: null,
    is_default: 0,
    landmark: "",
    contact_person_phone: null,
    contact_person_alt_phone: null,
    address_1: null,
    address_2: null,
    pincode: null,
    state: null,
    country: "India",
  });
  const handleSelect = (value) => {
    setErrorMessages((prev) => ({
      ...prev,
      addressType: "",
    }));
    setSelectedValue(value);
    setBuyerAddress((prev) => ({
      ...prev,
      address_type: value,
    }));
  };

  const toCamelCase = (text) => {
    const words = text.split(" ");
    const result = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return result.join(" ");
  };

  async function handleAddAddress() {
    let errorFound = false;
    setErrorMessages((prev) => ({
      ...prev,
      name: "",
      phone: "",
      pincode: "",
      address_1: "",
      address_2: "",
      landmark: "",
      email: "",
    }));
    if (
      BuyerAddress.contact_person_phone !== null &&
      BuyerAddress.contact_person_phone !== ""
    ) {
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        phone: Constants.alertMessages.alertForMobileNo,
      }));
      errorFound = true;
    }

    if (
      BuyerAddress.contact_person_name === null ||
      BuyerAddress.contact_person_name === ""
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        name: Constants.alertMessages.alertForName,
      }));
      errorFound = true;
    } else if (BuyerAddress.contact_person_name.length < 2) {
      setErrorMessages((prev) => ({
        ...prev,
        name: Constants.alertMessages.alertForMinName,
      }));
      errorFound = true;
    }
    if (BuyerAddress.pincode === null || BuyerAddress.pincode.length === 0) {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForPincode,
        city: Constants.alertMessages.alertForCity,
        state: Constants.alertMessages.alertForState,
      }));
      errorFound = true;
    } else if (
      BuyerAddress.pincode.length < 6 &&
      BuyerAddress.pincode.length >= 0
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForValidPincode,
        city: "",
        state: "",
      }));
      errorFound = true;
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: "",
        city: "",
        state: "",
      }));
      //  errorFound = false;
    }

    setErrorMessages((prev) => ({
      ...prev,
      address_2: "",
    }));

    // this piece of code is for  the validating the (Address), Street and HouseNo.
    let addressValidationError = null;
    if (BuyerAddress.address_1 === null || BuyerAddress.address_1 === "") {
      addressValidationError = Constants.alertMessages.alertForAddress;
    } else if (
      BuyerAddress.address_1.length < 2 ||
      BuyerAddress.address_1.length > 150
    ) {
      addressValidationError = Constants.alertMessages.alertForStreetLength;
    }

    setErrorMessages((prev) => ({
      ...prev,
      address_1: "",
    }));
    if (BuyerAddress.address_1 !== null && BuyerAddress.address_1 !== "") {
      if (
        BuyerAddress.address_1.length < 2 ||
        BuyerAddress.address_1.length > 150
      ) {
        setErrorMessages((prev) => ({
          ...prev,
          address_1: Constants.alertMessages.alertForStreetLength,
        }));
        errorFound = true;
      }
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        address_1: Constants.alertMessages.alertForAddress,
      }));
      errorFound = true;
    }
    // this logic is for the landMark
    let landmarkValidationError = null;
    if (
      BuyerAddress.landmark !== null &&
      BuyerAddress.landmark !== "" &&
      BuyerAddress.landmark.length < 3
    ) {
      landmarkValidationError = Constants.alertMessages.alertForLandMark;
      // Check if the landmark length is less than 3
    } else {
      landmarkValidationError = "";
      //"Only numbers are not allowed";
      //Constants.alertMessages.alertForLandmarkLength;
    }

    // Set the error message for the "landmark" field
    if (landmarkValidationError) {
      setErrorMessages((prev) => ({
        ...prev,
        landmark: landmarkValidationError,
      }));
      errorFound = true;
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        landmark: "",
      }));
      //errorFound = false;
    }
    if (BuyerAddress.address_type === null) {
      setErrorMessages((prev) => ({
        ...prev,
        addressType: Constants.alertMessages.alertForAddressType,
      }));
      errorFound = true;
    }
    if (
      !isVerified &&
      errorMessages.pincode === "" &&
      BuyerAddress.pincode !== null &&
      BuyerAddress.pincode !== ""
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        pincode: Constants.alertMessages.alertForValidPincode,
      }));
      errorFound = true;
    }

    if (errorFound) {
      setDisabledAddAddressBtn(false);
      return;
    }

    if (isVerified) {
      const payload = JSON.stringify(BuyerAddress);
      const authenticationToken = getDataFromCookies("7H3K9R5P2Q");
      const response = await fetch(
        `${API_HOST}/${endpoints.buyerShippingAddress}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticationToken}`,
          },
          body: payload,
        }
      );
      const addAddress = await response.json();
      setDisabledAddAddressBtn(false);

      if (
        addAddress.status == 500 ||
        addAddress.status == 501 ||
        addAddress.status == 502 ||
        addAddress.status == 503 ||
        (addAddress.message &&
          addAddress.message.toLowerCase().includes("server error"))
      ) {
        if (addAddress.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      if (addAddress.status === 201) {
        reFetchAddress(true);
        setDataToCookies("hideIsdefaultInAddAddress", false, "/");
        showToast("success", Constants.alertMessages.alertForNewAddress);
        handleCloseModal();
      } else if (addAddress.status === 409) {
        showToast("error", addAddress.message);
      } else if (addAddress.status === 400) {
        if (addAddress.errors.landmark) {
          setErrorMessages((prev) => ({
            ...prev,
            landmark: addAddress.errors.landmark[0],
          }));
        }
        if (addAddress.errors.address_1) {
          setErrorMessages((prev) => ({
            ...prev,
            address_1: addAddress.errors.address_1[0],
          }));
        }
        if (addAddress.errors.address_2) {
          setErrorMessages((prev) => ({
            ...prev,
            address_2:
              addAddress.errors.address_2[0] ==
              "The address 2 must be between 3 and 256 characters."
                ? Constants.alertMessages.alertForStreetLength
                : addAddress.errors.address_2[0],
          }));
        }
        if (addAddress.errors.contact_person_phone) {
          setErrorMessages((prev) => ({
            ...prev,
            phone: addAddress.errors.contact_person_phone[0],
          }));
        }
        if (addAddress.errors.contact_person_alt_phone) {
          setErrorMessages((prev) => ({
            ...prev,
            alt_phone: addAddress.errors.contact_person_alt_phone[0],
          }));
        }
      } else if (addAddress.status == 401) {
        removeDataFromCookie("7H3K9R5P2Q", "/");
        removeDataFromCookie("isLoggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        contextObj.setInput("isLoggedIn", false);
        navigate(`${navigation.navigateToHomepage}`);
      }
    } else {
      setDisabledAddAddressBtn(false);
      // return setErrorMessages((prev) => ({
      //   ...prev,
      //   pincode: 'Enter Valid pincode',
      // }));
    }
  }
  const handleSubmitData = async (e) => {
    try {
      const response = await fetch(
        `https://api.data.gov.in/resource/7eca2fa3-d6f5-444e-b3d6-faa441e35294?api-key=${API_KEY}&format=json&filters[pincode]=${pincode}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (pincode.length >= 0 && pincode.length === 6) {
        if (data.records.length === 0) {
          setIsVerified(false);

          setSelectedState(null);
          setSelectedCity(null);
          // setSelectedCountry(null);
          setErrorMessages((prev) => ({
            ...prev,
            pincode: Constants.alertMessages.alertForValidPincode,
          }));
          setBuyerAddress((prev) => ({
            ...prev,
            city: null,
            state: null,
            pincode: null,
            // country: null,
          }));
        } else {
          const pinCodeDetails = data.records;
          // let country = pinCodeDetails[0].Country;
          const state = toCamelCase(data.records[0]._statename);
          const city = toCamelCase(data.records[0].districtname);
          setBuyerAddress((prev) => ({
            ...prev,
            pincode: pincode,
            city: city,
            state: state,
            // country: country,
          }));

          setSelectedState(state);
          setSelectedCity(city);
          // setSelectedCountry(country);
          setIsVerified(true);
        }
      } else {
        setIsVerified(false);

        setSelectedState(null);
        setSelectedCity(null);
        // setSelectedCountry(null);
        setBuyerAddress((prev) => ({
          ...prev,
          city: null,
        }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    // contextObj.setInput("navigation", "true");
    // contextObj.setInput("navigationFrom", "addUpdateAddress");
    // showToast("success", Constants.alertMessages.alertForCheckout);
    handleCloseModal(true);
  };

  return (
    <>
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <>
          <div className="buyerHeadingMainAddressPage">
            <div
              className="buyerMainDivForAddressDetailsSection"
              id="buyerMainDivForAddressDetailsPopUp"
            >
              <div
                className="mobileUIDiv"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <h1 className="MobileUIHeading">JOYREJOY</h1>
                <button
                  onClick={(e) => {
                    reFetchAddress(false);
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                  }}
                  className="cancleaddressaddbuttonMobile"
                >
                  <FontAwesomeIcon icon={faTimes} color="#FFFFFF" />
                </button>
              </div>

              <div className="addaddressheadingcontainer">
                <h1 className="addNewAddressHeading">Add New Address</h1>
                <button
                  onClick={(e) => {
                    reFetchAddress(false);
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                  }}
                  className="cancleaddressaddbutton"
                  id="CloseAddressaddButton"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      reFetchAddress(false);
                      handleClose();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} color="#A1A1A1" />
                </button>
              </div>
              <hr className="addaddressHrLine" />
              <div className="buyerMainDivForNewAddressFormSection">
                <div className="buyerMainDivForNewAddressFormSectionDetails">
                  <div className="popupContainer">
                    <div>
                      {" "}
                      <h2
                        className="subheadinginaddaddresspage"
                        id="addressModalContactDetailsHeading"
                      >
                        {" "}
                        Contact Details
                      </h2>
                      <div className="buyerMainDivForAddressMainflexbox">
                        <div className="buyerMainDivForProfileName">
                          <InputControl
                            label={<span>Name</span>}
                            inputTagId={"newAddressName"}
                            type="text"
                            autoFocus
                            //placeholder=""
                            value={BuyerAddress.contact_person_name}
                            maxLength="50"
                            maxLimit="50"
                            minLength="2"
                            autoComplete="off"
                            characterCount={charCount.name}
                            onChange={(event) => {
                              setErrorMessages((prev) => ({
                                ...prev,
                                name: "",
                              }));
                              setCharCount((prev) => ({
                                ...prev,
                                name: event.target.value.length,
                              }));

                              // Get the input value without trimming
                              const inputValue = event.target.value;
                              const sanitizedValue = sanitizeInput(inputValue);

                              // Check if the input is empty and update the state accordingly
                              if (inputValue.trim() === "") {
                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  contact_person_name: "",
                                }));
                              } else {
                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  contact_person_name: sanitizedValue,
                                }));
                              }
                            }}
                            error={errorMessages.name}
                          />
                        </div>
                        <div className="buyerMainDivForProfileLastName">
                          <InputControl
                            label={<span>Phone</span>}
                            inputTagId={"newAddressPhoneNumber"}
                            type="text"
                            value={BuyerAddress.contact_person_phone}
                            maxLength="10"
                            maxLimit="10"
                            characterCount={charCount.phone}
                            //placeholder=""
                            onChange={(event) => {
                              setErrorMessages((prev) => ({
                                ...prev,
                                phone: "",
                              }));
                              const inputValue = event.target.value;
                              const sanitizedValue = sanitizeInput(inputValue);
                              const validnumber = areAllNumbers(
                                event.target.value.trim()
                              );
                              if (
                                sanitizedValue === inputValue &&
                                inputValue !== ""
                              ) {
                                if (validnumber) {
                                  setBuyerAddress((prev) => ({
                                    ...prev,
                                    contact_person_phone:
                                      event.target.value.trim(),
                                  }));
                                } else {
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    phone:
                                      inputValue === ""
                                        ? Constants.alertMessages
                                            .alertForMobileNo
                                        : Constants.alertMessages
                                            .alertForValidMobile,
                                  }));
                                  setBuyerAddress((prev) => ({
                                    ...prev,
                                    contact_person_phone: "",
                                  }));
                                }
                              } else {
                                if (sanitizedValue !== "") {
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    phone:
                                      Constants.alertMessages
                                        .alertForSpecialChar,
                                  }));
                                } else {
                                  setErrorMessages((prev) => ({
                                    ...prev,
                                    phone:
                                      Constants.alertMessages.alertForMobileNo,
                                  }));
                                }
                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  contact_person_phone: sanitizedValue,
                                }));
                              }
                            }}
                            error={errorMessages.phone}
                          />
                        </div>
                      </div>
                    </div>
                    {/* country and alt phone number */}
                    <div>
                      {" "}
                      <h2
                        className="subheadinginaddaddresspage"
                        id="addressModalAddressHeading"
                      >
                        {" "}
                        Address
                      </h2>{" "}
                    </div>

                    <div className="buyerMainDivForAddressMainflexbox">
                      <div className="buyerMainDivForProfileName buyerMainDivForProfileNamePincode">
                        <InputControl
                          label={<span>Pincode</span>}
                          inputTagId={"newAddressPincode"}
                          type="text"
                          value={BuyerAddress.pincode}
                          maxLength="6"
                          maxLimit="6"
                          // placeholder=""
                          characterCount={charCount.pincode}
                          onKeyPress={(e) => {
                            const char = String.fromCharCode(e.charCode);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={async (e) => {
                            setErrorMessages((prev) => ({
                              ...prev,
                              pincode: "",
                            }));
                            const inputValue = e.target.value.trim();
                            pincode = inputValue;
                            const verifyAllNumber = areAllNumbers(inputValue);
                            const sanitizedValue = sanitizeInput(inputValue);
                            if (
                              sanitizedValue === inputValue &&
                              verifyAllNumber
                            ) {
                              setCharCount((prev) => ({
                                ...prev,
                                pincode: e.target.value.length,
                              }));
                              const pin = e.target.value.trim();

                              const verifyAllNumber = areAllNumbers(pin);
                              if (verifyAllNumber || pin == "") {
                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  pincode: pin,
                                }));
                                setIsVerified(false);

                                setErrorMessages((prev) => ({
                                  ...prev,
                                  pincode: "",
                                  city: "",
                                  state: "",
                                }));

                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  city: "",
                                  state: "",
                                  // country: "",
                                }));

                                setSelectedState("");
                                setSelectedCity("");
                                // setSelectedCountry("");

                                if (e.target.value.trim().length === 6) {
                                  handleSubmitData();
                                }
                              } else {
                                setErrorMessages((prev) => ({
                                  ...prev,
                                  pincode:
                                    Constants.alertMessages.alertForOnlyNo,
                                }));
                              }
                            } else if (inputValue === "") {
                              setCharCount((prev) => ({
                                ...prev,
                                pincode: e.target.value.length,
                              }));
                              setErrorMessages((prev) => ({
                                ...prev,
                                pincode:
                                  Constants.alertMessages.alertForPincode,
                              }));
                              setBuyerAddress((prev) => ({
                                ...prev,
                                pincode: inputValue,
                              }));
                            } else {
                              setErrorMessages((prev) => ({
                                ...prev,
                                pincode: "Enter Valid Pincode",
                                //Constants.alertMessages.alertForSpecialChar,
                              }));
                            }
                          }}
                          error={errorMessages.pincode}
                        />
                      </div>
                      <div className="buyerMainDivForProfileLastName">
                        <InputControl
                          label={<span>Street address (Optional)</span>}
                          inputTagId={"newAddressLocality"}
                          type="text"
                          maxLength="150"
                          maxLimit="150"
                          value={BuyerAddress.address_2}
                          minLength="2"
                          //placeholder=""

                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = sanitizeInput(inputValue);
                            setErrorMessages((prev) => ({
                              ...prev,
                              address_2: "",
                            }));
                            if (inputValue.trim() === "") {
                              setBuyerAddress((prev) => ({
                                ...prev,
                                address_2: "",
                              }));
                            } else {
                              setBuyerAddress((prev) => ({
                                ...prev,
                                address_2: sanitizedValue,
                              }));
                            }
                          }}
                          error={errorMessages.address_2}
                        />
                      </div>
                    </div>
                    <div className="buyerMainDivForAddressMainTextareaflexbox">
                      <div className="is-floating-label">
                        <label className="BuyerASddresspageInfoLable">
                          Address (House No, Building, Street)
                        </label>
                        <textarea
                          id={"newAddress_Address"}
                          spellCheck="false"
                          className="BuyerAddressPageTextareaForaddress"
                          maxLength="150"
                          value={BuyerAddress.address_1}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = sanitizeInput(inputValue);
                            setBuyerAddress((prev) => ({
                              ...prev,
                              address_1: "",
                            }));
                            setBuyerAddress((prev) => ({
                              ...prev,
                              address_1: sanitizedValue,
                            }));
                          }}
                          error={errorMessages.address_1}
                        ></textarea>
                        <div className="errorMessageDivAddress">
                          <span
                            className="errorinputboxAddress"
                            id="errorinputboxAddress_error"
                            style={{ height: "10px" }}
                          >
                            {" "}
                            {errorMessages.address_1}{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="buyerMainDivForAddressMainflexbox">
                      <div className="buyerMainDivForProfileName">
                        <InputControl
                          inputTagId={"newAddressCity"}
                          label={<span>City / District/ Town </span>}
                          // placeholder=""
                          readOnly
                          type="text"
                          // placeholder="The City field is automatically fetched using the pincode."
                          tabIndex="-1"
                          value={selectedCity}
                        />
                      </div>
                      <div className="buyerMainDivForProfileLastName">
                        <InputControl
                          label={<span>State</span>}
                          inputTagId={"newAddressState"}
                          // placeholder=""
                          readOnly
                          type="text"
                          // placeholder="The State field is automatically fetched using the pincode."
                          tabIndex="-1"
                          value={selectedState}
                          // onMouseEnter={handleMouseEnterOnIbuttonState}
                          // onMouseLeave={handleMouseLeaveOnIbuttonState}
                        />
                      </div>
                    </div>
                    <div className="buyerMainDivForAddressMainflexbox">
                      <div className="buyerMainDivForProfileName">
                        {" "}
                        <InputControl
                          label={<span>Landmark(Optional)</span>}
                          inputTagId={"newAddressLandmark"}
                          type="text"
                          maxLength="150"
                          maxLimit="150"
                          value={BuyerAddress.landmark}
                          minLength="2"
                          //placeholder=""
                          characterCount={charCount.landmark}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = sanitizeInput(inputValue);
                            setBuyerAddress((prev) => ({
                              ...prev,
                              landmark: "",
                            }));
                            setBuyerAddress((prev) => ({
                              ...prev,
                              landmark: sanitizedValue,
                            }));
                          }}
                          error={errorMessages.landmark}
                        />
                      </div>
                      <div className="buyerMainDivForProfileLastName">
                        <InputControl
                          label={<span>Alternate Phone Number (Optional)</span>}
                          type="text"
                          inputTagId={"newAddressAltPhone"}
                          value={BuyerAddress.contact_person_alt_phone}
                          maxLength="10"
                          maxLimit="10"
                          characterCount={charCount.alt_phone}
                          // placeholder=""
                          onChange={(event) => {
                            setErrorMessages((prev) => ({
                              ...prev,
                              alt_phone: "",
                            }));

                            const inputValue = event.target.value;
                            const sanitizedValue = sanitizeInput(inputValue);
                            if (
                              sanitizedValue === inputValue &&
                              inputValue !== ""
                            ) {
                              const validnumber = isPhoneNumberFormat(
                                event.target.value.trim()
                              );
                              if (validnumber) {
                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  contact_person_alt_phone:
                                    event.target.value.trim(),
                                }));
                              } else {
                                setErrorMessages((prev) => ({
                                  ...prev,
                                  alt_phone:
                                    Constants.alertMessages.alertForValidMobile,
                                }));
                                setBuyerAddress((prev) => ({
                                  ...prev,
                                  contact_person_alt_phone: "",
                                }));
                              }
                            } else {
                              if (sanitizedValue !== "") {
                                setErrorMessages((prev) => ({
                                  ...prev,
                                  alt_phone:
                                    Constants.alertMessages.alertForSpecialChar,
                                }));
                              }
                              setBuyerAddress((prev) => ({
                                ...prev,
                                contact_person_alt_phone: sanitizedValue,
                              }));
                            }
                          }}
                          error={errorMessages.alt_phone}
                        />
                      </div>
                    </div>
                    <div>
                      {" "}
                      <h2
                        className="subheadinginaddaddresspage"
                        id="addressModalAddressTypeHeading"
                      >
                        {" "}
                        Address Type
                      </h2>{" "}
                    </div>
                    <div className="buyerMainDivForAddressMainflexbox">
                      <div className="buyerMainDivForProfileNamess">
                        <label className="BuyerASddresspageInfoLable">
                          {/* Address Type <span className="required">*</span> */}
                        </label>
                        <div className="buyerAddressPageRadioSelection">
                          <button
                            id="newAddressTypeHome"
                            className={
                              selectedValue === "Home"
                                ? "selectedbutton"
                                : "butttonforselectaddressType"
                            }
                            onClick={() => {
                              handleSelect("Home");
                            }}
                          >
                            HOME{" "}
                          </button>
                          <button
                            id="newAddressTypeWork"
                            className={
                              selectedValue === "Work"
                                ? "selectedbutton1"
                                : "butttonforselectaddressType2"
                            }
                            onClick={() => {
                              handleSelect("Work");
                            }}
                          >
                            {" "}
                            WORK
                          </button>
                        </div>

                        <span
                          //style={{ display: isFirstAddress ? "none" : "block" }}
                          className="inputboxforerrormessageaddress"
                          id="addressModelType_error"
                          // value={errorMessages.addressType}
                          // readOnly
                          // tabIndex={-1}
                          // disabled
                          style={{ height: "10px" }}
                        >
                          {errorMessages.addressType}
                        </span>
                      </div>
                    </div>

                    {!isFirstAddress && (
                      <div className="DefaultCheckBoxDiv">
                        <label className="lableformakemakeDefault">
                          <input
                            className="InputCheckboxaddressPage"
                            id="newAddressMakeDefault"
                            // tabIndex={-1}
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleCheckboxChange();
                              }
                            }}
                          />
                          {"   "}
                          <span className="addressmarkdefault">
                            Make this my default address
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="addresspagebuttonDivAddCancel">
                    <button
                      className="BuyerAddressPageMainSubmitButton"
                      id="newAddressSave"
                      disabled={disabledAddAddressBtn}
                      onClick={() => {
                        setDisabledAddAddressBtn(true);
                        handleAddAddress();
                      }}
                    >
                      {disabledAddAddressBtn ? (
                        <img
                          src={loadingImgPath}
                          onError={(e) => {
                            e.target.src = placeHolderImage;
                          }}
                          alt="Loading"
                          className="disabledBtn-Loading"
                        />
                      ) : (
                        "SAVE ADDRESS"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddressPage;
