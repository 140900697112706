import React from "react";

const VendorShopSkeleton = () => {
  return (
    <>
      <div className="sk-product-list">
        <div className="sk-product-row">
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
          <div className="sk-product-card">
            <div className="sk-prod-img"></div>
            <div className="sk-prod-desc">
              <div className="sk-prod-selling-price"></div>
              <div className="sk-prod-original-price"></div>
              <div className="sk-prod-name"></div>
            </div>
            <div className="sk-prod-buttons"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorShopSkeleton;
