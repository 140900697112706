import React, { useState, useEffect, useContext } from "react";
import { UserSideNavbar } from "../userSideNavbar/usersidenavbarMQ";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ParamsContext from "../useContext/paramContext.js";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import AddressPage from "../Address/addressMQ";
import UpdateAddressPage from "../Address/updateAddress";
import AddressEmptyImage from "../../Assets/location.webp";
import {
  setDataToCookies,
  getDataFromCookies,
  removeDataFromCookie,
} from "../Cookies/Cookies";
import { moveIsDefaultToFirstIndex } from "../../resuableFunctions/resuableFunctions.js";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
const API_HOST = process.env.REACT_APP_API_HOST;
import "./AddressList.css";
import Toast from "../ReactToastify/Toast";
import AddressSkeleton from "./AddressSkeleton.jsx";
import { Helmet } from "react-helmet";
const MemoizedUserSideNavbar = React.memo(UserSideNavbar);
const AddressList = () => {
  const infra = process.env.REACT_APP_INFRA;
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const { showToast } = Toast();
  const [reFetchAddress, setReFetchAddress] = useState(true);
  const contextObj = useContext(ParamsContext);
  const [addressToBeUpdated, setAddressToBeUpdated] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [deleteAddressId, setDeleteAddressId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [showDesktopView, setShowDesktopView] = useState(
    window.innerWidth > 600
  );
  const [isFirstAddress, setIsFirstAddress] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(true);

  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const handleUpdateCancel = () => {
    setShowUpdateProfile(false);
  };

  const MAX_ADDRESS_LIMIT = 10;

  useEffect(() => {
    const handleResize = () => {
      setShowDesktopView(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [updateAddressId, setUpdatedAddressId] = useState("");
  useEffect(() => {
    if (updateAddressId !== "" && updateAddressId.length !== 0) {
      const updatedAddress = addresses.map((add) => {
        if (add.id == updateAddressId) {
          return { ...add, is_default: 1 };
        } else if (add.is_default == 1) {
          return { ...add, is_default: 0 };
        } else {
          return { ...add };
        }
      });
      let updatedAddressList = moveIsDefaultToFirstIndex(updatedAddress);
      setAddresses(updatedAddressList);
      setUpdatedAddressId("");
    }
  }, [updateAddressId]);

  const getAddressList = () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    fetch(`${API_HOST}/${endpoints.buyerShippingAddress}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status === 404 && res.message == "Address list is empty.") {
          setIsFirstAddress(true);
          if (res.data.is_user_profile_complete == false) {
            setProfileCompleted(false);
          }
        }

        // change statua code to 200 once api response is updated.
        if (res.status === 200) {
          let updatedAddressList = moveIsDefaultToFirstIndex(res.data);
          setAddresses(updatedAddressList);
          setIsFirstAddress(false);
        }
        if (res.status == 401) {
          removeDataFromCookie("7H3K9R5P2Q", "/");
          removeDataFromCookie("isLoggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          contextObj.setInput("isLoggedIn", false);
          navigate(`${navigation.navigateToHomepage}`);
        }
        setLoading(false);
      })
      .catch((error) => {
        showToast("error", "error");
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
        console.error(error);
      });
  };

  useEffect(() => {
    const loggedIn = getDataFromCookies("isLoggedIn");
    contextObj.setInput("showAddressModal", false);
    if (loggedIn == true) {
      getAddressList();
    } else {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }
  }, []);
  useEffect(() => {
    if (
      showDeleteConfirmation ||
      showUpdateProfile ||
      showEditAddressModal ||
      contextObj.params.showAddressModal
    ) {
      document.body.style.overflow = "hidden"; // Disable scroll when the modal is open
      return () => {
        document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
      };
    }
  }, [
    showDeleteConfirmation,
    showUpdateProfile,
    showEditAddressModal,
    contextObj.params.showAddressModal,
  ]);

  // Make default
  const onMakeDefault = async (address) => {
    address.is_default = 1;
    const Payload = JSON.stringify(address);
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.buyerShippingAddress}/${address.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: Payload,
      }
    );
    const updateAddJson = await response.json();

    if (
      updateAddJson.status == 500 ||
      updateAddJson.status == 501 ||
      updateAddJson.status == 502 ||
      updateAddJson.status == 503 ||
      (updateAddJson.message &&
        updateAddJson.message.toLowerCase().includes("server error"))
    ) {
      if (updateAddJson.status == 503) {
        setMaintainanceMode(true);
      } else {
        setMaintainanceMode(false);
      }
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
  };

  const handleAddAddress = async () => {
    if (addresses.length >= MAX_ADDRESS_LIMIT) {
      showToast("error", Constants.alertMessages.alertForMaxAddress);
    } else {
      if (profileCompleted) {
        if (isFirstAddress) {
          setDataToCookies("hideIsdefaultInAddAddress", true, "/");
        } else {
          setDataToCookies("hideIsdefaultInAddAddress", false, "/");
        }

        contextObj.setInput("showAddressModal", true);
      } else {
        setShowUpdateProfile(true);
      }
    }
  };

  const handleCloseModal = () => {
    contextObj.setInput("showAddressModal", false);

    if (reFetchAddress) {
      getAddressList();
    }
  };

  const handleCloseEditModal = () => {
    setShowEditAddressModal(false);
    if (reFetchAddress) {
      getAddressList();
    }
  };

  const handleDelete = (id) => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    fetch(`${API_HOST}/${endpoints.buyerShippingAddress}/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (
          response.status == 500 ||
          response.status == 501 ||
          response.status == 502 ||
          response.status == 503 ||
          (response.message &&
            response.message.toLowerCase().includes("server error"))
        ) {
          if (response.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (response.status == 401) {
          removeDataFromCookie("7H3K9R5P2Q", "/");
          removeDataFromCookie("isLoggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          contextObj.setInput("isLoggedIn", false);
          navigate(`${navigation.navigateToHomepage}`);
        }

        if (response.status == 200) {
          setAddresses(addresses.filter((address) => address.id !== id));

          showToast("success", Constants.alertMessages.alertForAddressDelete);
        }
        setShowDeleteConfirmation(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };

  const navigate = useNavigate();

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  const handleDeleteConfirm = (addressId) => {
    setDeleteAddressId(addressId);
    setShowDeleteConfirmation(true);
  };

  const CheckType = (type) => {
    if (type === "Home") {
      return <span className="namefiled">{type}</span>;
    } else {
      return <span className="namefiled">{type}</span>;
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [showToggeelMenu, setShowToggeelMenu] = useState(null);

  const toggleMenu = (id) => {
    setIsOpen(!isOpen);
    setShowToggeelMenu(id);
  };

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Your Cheerful Address List - JOYREJOY</title>
          <meta
            name="description"
            content="Your address list at JOYREJOY is like a map to happiness! Add, edit, and manage your delivery addresses effortlessly. Let's ensure your parcels always find their way to your doorstep!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div className="pageParentDiv pageParentDivColoredBackground">
          <div
            className="allBoxContainer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {contextObj.params.showAddressModal && (
              <AddressPage
                handleCloseModal={handleCloseModal}
                reFetchAddress={setReFetchAddress}
              />
            )}
            {showEditAddressModal && (
              <UpdateAddressPage
                handleCloseEditModal={handleCloseEditModal}
                address={addressToBeUpdated}
                reFetchAddress={setReFetchAddress}
              />
            )}
            <div className="buyerMainAddressPage">
              <div className="buyerMainDivForNavProfileInfoSection">
                <div className="buyerProfileInfoMainDivForNavSection">
                  <MemoizedUserSideNavbar />
                </div>
                <div className="buyerMainDivForAddressSection">
                  <div className="pageListHeaderContainer">
                    <p className="PageListTitle">Manage Addresses</p>
                  </div>
                  {loading ? (
                    <AddressSkeleton />
                  ) : (
                    <>
                      {addresses.length > 0 ? (
                        <>
                          <div className="BuyerAddressListContainer">
                            <>
                              <div className="address-list">
                                <div className="ButtonDivAddAddress">
                                  <button
                                    id="addressPageAddAddressBtn"
                                    className={
                                      addresses.length >= MAX_ADDRESS_LIMIT
                                        ? "add-address-buttonMaindefault"
                                        : "add-address-buttonMain"
                                    }
                                    onClick={() => {
                                      handleAddAddress();
                                    }}
                                  >
                                    <span className="addressplussign"> +</span>{" "}
                                    NEW ADDRESS
                                  </button>
                                  {showUpdateProfile && (
                                    <>
                                      <div className="ModalContainer">
                                        <div
                                          className="ModalContent"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleUpdateCancel();
                                          }}
                                        >
                                          <div
                                            className="Address_update_confirmation_popup"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                          >
                                            <div
                                              className="Address_Profile_crossbutton_Div"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <svg
                                                onClick={handleUpdateCancel}
                                                width="12"
                                                height="12"
                                                viewBox="0 0 8 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M4.93909 3.99943L7.80299 1.14179C7.92841 1.01635 7.99886 0.846232 7.99886 0.668843C7.99886 0.491455 7.92841 0.321332 7.80299 0.1959C7.67758 0.0704673 7.50748 0 7.33012 0C7.15275 0 6.98265 0.0704673 6.85724 0.1959L4 3.06021L1.14276 0.1959C1.01735 0.0704673 0.847248 -1.32164e-09 0.669885 0C0.492522 1.32165e-09 0.322423 0.0704673 0.197008 0.1959C0.0715939 0.321332 0.00113664 0.491455 0.00113664 0.668843C0.00113663 0.846232 0.0715939 1.01635 0.197008 1.14179L3.06091 3.99943L0.197008 6.85708C0.134583 6.919 0.085035 6.99267 0.0512219 7.07385C0.0174088 7.15502 0 7.24208 0 7.33002C0 7.41796 0.0174088 7.50502 0.0512219 7.58619C0.085035 7.66737 0.134583 7.74104 0.197008 7.80296C0.258924 7.8654 0.332587 7.91495 0.413748 7.94877C0.494909 7.98259 0.581962 8 0.669885 8C0.757808 8 0.844861 7.98259 0.926022 7.94877C1.00718 7.91495 1.08085 7.8654 1.14276 7.80296L4 4.93866L6.85724 7.80296C6.91916 7.8654 6.99282 7.91495 7.07398 7.94877C7.15514 7.98259 7.24219 8 7.33012 8C7.41804 8 7.50509 7.98259 7.58625 7.94877C7.66741 7.91495 7.74108 7.8654 7.80299 7.80296C7.86542 7.74104 7.91497 7.66737 7.94878 7.58619C7.98259 7.50502 8 7.41796 8 7.33002C8 7.24208 7.98259 7.15502 7.94878 7.07385C7.91497 6.99267 7.86542 6.919 7.80299 6.85708L4.93909 3.99943Z"
                                                  fill="#A3A3A3"
                                                />
                                              </svg>
                                            </div>
                                            <h4 className="Address_Update_Profile_Heading">
                                              Update Your Profile
                                            </h4>
                                            <p className="Address_page_Update_Profile_Paragraph">
                                              To create new address, first you
                                              have to update your profile with
                                              all the required details.
                                            </p>
                                            <div className="Address_Page_Update_Profile_Button_Div">
                                              <button
                                                className="Address_Page_UpdateProfile_button3"
                                                onClick={() => {
                                                  navigate(
                                                    `${navigation.navigateToProfileInfo}`
                                                  );
                                                }}
                                              >
                                                Update Now
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <>
                                  {addresses.map((address, index) => (
                                    <div key={index}>
                                      <div className="ListContainer">
                                        <li
                                          style={{
                                            borderBottom:
                                              addresses.length - 1 == index
                                                ? ""
                                                : "1px solid #ccc",
                                          }}
                                        >
                                          <div>
                                            <div className="address-info">
                                              <div className="containeritemforAddreeeList">
                                                <div className="addresstypediv">
                                                  <div className="addressHomeDeleteButtonflexBox">
                                                    <p className="HomePtag">
                                                      {" "}
                                                      {CheckType(
                                                        address.address_type
                                                      )}{" "}
                                                      <span className="showisDefaultaddress">
                                                        {" "}
                                                        {}
                                                        {address.is_default ===
                                                        1
                                                          ? "(Default)"
                                                          : ""}
                                                      </span>
                                                    </p>
                                                    {!showDesktopView && (
                                                      <>
                                                        {address.is_default !==
                                                          1 && (
                                                          <>
                                                            <svg
                                                              className="mobileViewOnly"
                                                              onClick={() =>
                                                                handleDeleteConfirm(
                                                                  address.id
                                                                )
                                                              }
                                                              width="14"
                                                              height="15"
                                                              viewBox="0 0 14 15"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M1 3.88867H12.5556"
                                                                stroke="#B3B3B3"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                              <path
                                                                d="M5.33203 6.77734V11.1107"
                                                                stroke="#B3B3B3"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                              <path
                                                                d="M8.22266 6.77734V11.1107"
                                                                stroke="#B3B3B3"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                              <path
                                                                d="M1.72266 3.88867L2.44488 12.5553C2.44488 12.9384 2.59706 13.3058 2.86795 13.5767C3.13883 13.8476 3.50623 13.9998 3.88932 13.9998H9.6671C10.0502 13.9998 10.4176 13.8476 10.6885 13.5767C10.9594 13.3058 11.1115 12.9384 11.1115 12.5553L11.8338 3.88867"
                                                                stroke="#B3B3B3"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                              <path
                                                                d="M4.60938 3.88889V1.72222C4.60938 1.53068 4.68547 1.34698 4.82091 1.21153C4.95635 1.07609 5.14005 1 5.3316 1H8.22049C8.41203 1 8.59573 1.07609 8.73117 1.21153C8.86662 1.34698 8.94271 1.53068 8.94271 1.72222V3.88889"
                                                                stroke="#B3B3B3"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              />
                                                            </svg>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                  <p className="Address_Page_Contact_Person_Name">
                                                    {
                                                      address.contact_person_name
                                                    }
                                                    ,{" "}
                                                    {
                                                      address.contact_person_phone
                                                    }{" "}
                                                  </p>
                                                  <p>
                                                    {" "}
                                                    {
                                                      address.apartment_number
                                                    }{" "}
                                                    {address.address_1},{" "}
                                                    {address.city},{" "}
                                                    {address.state} -{" "}
                                                    {address.pincode}
                                                  </p>
                                                </div>
                                                <div className="actionDivAddressList">
                                                  {showDesktopView ? (
                                                    <>
                                                      <div className="buttondotsdiv WebViewOnly">
                                                        <div
                                                          className="three-dot-menu"
                                                          id={`addressList_${index}`}
                                                        >
                                                          <div
                                                            className="menu-toggle"
                                                            onClick={(e) => {
                                                              toggleMenu(
                                                                address.id
                                                              );
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                            }}
                                                          >
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                          </div>

                                                          {isOpen &&
                                                            showToggeelMenu ==
                                                              address.id && (
                                                              <ul
                                                                className="options"
                                                                id={`addressOptions_${index}`}
                                                              >
                                                                <li
                                                                  id={`addressOptionsEdit_${index}`}
                                                                  onClick={() => {
                                                                    setAddressToBeUpdated(
                                                                      address
                                                                    );
                                                                    setShowEditAddressModal(
                                                                      true
                                                                    );

                                                                    setIsOpen(
                                                                      !isOpen
                                                                    );
                                                                  }}
                                                                >
                                                                  Edit
                                                                </li>
                                                                {address.is_default !==
                                                                  1 && (
                                                                  <li
                                                                    id={`addressOptionsDelete_${index}`}
                                                                    onClick={() => {
                                                                      handleDeleteConfirm(
                                                                        address.id
                                                                      );
                                                                      setIsOpen(
                                                                        !isOpen
                                                                      );
                                                                    }}
                                                                  >
                                                                    Delete
                                                                  </li>
                                                                )}

                                                                {address.is_default !==
                                                                  1 && (
                                                                  <li
                                                                    id={`addressOptionsMakeDefalut_${index}`}
                                                                    onClick={() => {
                                                                      setUpdatedAddressId(
                                                                        address.id
                                                                      );
                                                                      onMakeDefault(
                                                                        address
                                                                      );
                                                                      setIsOpen(
                                                                        !isOpen
                                                                      );
                                                                    }}
                                                                  >
                                                                    Mark Default
                                                                  </li>
                                                                )}
                                                              </ul>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="MobileViewAddressListPage">
                                                        <div className="MainMobileViewPage mobileViewOnly">
                                                          <button
                                                            className="AddressListEditButtonMobile"
                                                            onClick={() => {
                                                              setAddressToBeUpdated(
                                                                address
                                                              );
                                                              setShowEditAddressModal(
                                                                true
                                                              );
                                                              setIsOpen(
                                                                !isOpen
                                                              );
                                                            }}
                                                          >
                                                            {" "}
                                                            Edit{" "}
                                                          </button>
                                                          {address.is_default !==
                                                            1 && (
                                                            <li
                                                              className="markDefaultMobileView"
                                                              onClick={() => {
                                                                setUpdatedAddressId(
                                                                  address.id
                                                                );
                                                                onMakeDefault(
                                                                  address
                                                                );
                                                                setIsOpen(
                                                                  !isOpen
                                                                );
                                                              }}
                                                            >
                                                              Mark Default
                                                            </li>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      </div>
                                      <div className="divForMobileUI"></div>

                                      {showDeleteConfirmation &&
                                        deleteAddressId === address.id && (
                                          <div className="ModalContainer">
                                            <div className="ModalContent">
                                              <div
                                                className="delete-confirmation-popup"
                                                id="deleteAddressConfirmationPopUp"
                                              >
                                                <div
                                                  className="crossbuttondiv"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faXmark}
                                                    style={{
                                                      color: " #A3A3A3",
                                                      width: "18px",
                                                      height: "18px",
                                                    }}
                                                    id="CloseDeleteAddressConfirmationPopUp"
                                                    onClick={handleDeleteCancel}
                                                  />
                                                </div>
                                                <div className="popupContainer-RemoveFromList">
                                                  <h5 className="headingmessageDeleteaddress">
                                                    Delete address?
                                                  </h5>
                                                  <p>
                                                    Are you sure want to delete
                                                    this address? This operation
                                                    cannot be undone!
                                                  </p>
                                                  <div className="buttonDiv">
                                                    <button
                                                      id="cancelDeleteAddress"
                                                      className="button2"
                                                      onClick={
                                                        handleDeleteCancel
                                                      }
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      id="confirmDeleteAddress"
                                                      className="button1"
                                                      onClick={() => {
                                                        handleDelete(
                                                          address.id
                                                        );
                                                      }}
                                                    >
                                                      Yes, Delete
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  ))}

                                  {/* {contextObj.params.showAddressModal && (
                                    <>
                                      <AddressPage
                                        handleCloseModal={handleCloseModal}
                                        reFetchAddress={setReFetchAddress}
                                      />
                                    </>
                                  )} */}

                                  {/* {showEditAddressModal && (
                                    <>
                                      <UpdateAddressPage
                                        handleCloseEditModal={
                                          handleCloseEditModal
                                        }
                                        address={addressToBeUpdated}
                                        reFetchAddress={setReFetchAddress}
                                      />
                                    </>
                                  )} */}
                                </>
                              </div>
                              {/* {contextObj.params.showAddressModal && (
                                <>
                                  <AddressPage
                                    handleCloseModal={handleCloseModal}
                                    reFetchAddress={setReFetchAddress}
                                  />
                                </>
                              )} */}
                            </>
                          </div>
                        </>
                      ) : (
                        <div className="noAddressDivContainer">
                          <div
                            className="No_AddressFoundDiv"
                            id="No_AddressFoundDiv"
                          >
                            <img
                              className=""
                              src={AddressEmptyImage}
                              onError={(e) => {
                                e.target.src = placeHolderImage;
                              }}
                              alt="Wishlist.webp"
                            />
                            <h4
                              className="wishlistHeadingElement"
                              id="EmptyAddresslistHeading"
                            >
                              Your address list is empty!
                            </h4>
                            <li className="wishlistEmptyline">
                              Create new shipping address.
                            </li>
                            <button
                              className="ShopNowButtonWishlist"
                              onClick={() => {
                                handleAddAddress();
                              }}
                            >
                              {" "}
                              + New Address{" "}
                            </button>
                          </div>
                          {/* {contextObj.params.showAddressModal && (
                            <>
                              <AddressPage
                                handleCloseModal={handleCloseModal}
                                reFetchAddress={setReFetchAddress}
                              />
                            </>
                          )} */}
                          {showUpdateProfile && (
                            <div className="ModalContainer">
                              <div
                                className="ModalContent"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleUpdateCancel();
                                }}
                              >
                                <div
                                  className="Address_update_confirmation_popup"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <div className="Address_Profile_crossbutton_Div">
                                    <svg
                                      onClick={handleUpdateCancel}
                                      width="12"
                                      height="12"
                                      viewBox="0 0 8 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.93909 3.99943L7.80299 1.14179C7.92841 1.01635 7.99886 0.846232 7.99886 0.668843C7.99886 0.491455 7.92841 0.321332 7.80299 0.1959C7.67758 0.0704673 7.50748 0 7.33012 0C7.15275 0 6.98265 0.0704673 6.85724 0.1959L4 3.06021L1.14276 0.1959C1.01735 0.0704673 0.847248 -1.32164e-09 0.669885 0C0.492522 1.32165e-09 0.322423 0.0704673 0.197008 0.1959C0.0715939 0.321332 0.00113664 0.491455 0.00113664 0.668843C0.00113663 0.846232 0.0715939 1.01635 0.197008 1.14179L3.06091 3.99943L0.197008 6.85708C0.134583 6.919 0.085035 6.99267 0.0512219 7.07385C0.0174088 7.15502 0 7.24208 0 7.33002C0 7.41796 0.0174088 7.50502 0.0512219 7.58619C0.085035 7.66737 0.134583 7.74104 0.197008 7.80296C0.258924 7.8654 0.332587 7.91495 0.413748 7.94877C0.494909 7.98259 0.581962 8 0.669885 8C0.757808 8 0.844861 7.98259 0.926022 7.94877C1.00718 7.91495 1.08085 7.8654 1.14276 7.80296L4 4.93866L6.85724 7.80296C6.91916 7.8654 6.99282 7.91495 7.07398 7.94877C7.15514 7.98259 7.24219 8 7.33012 8C7.41804 8 7.50509 7.98259 7.58625 7.94877C7.66741 7.91495 7.74108 7.8654 7.80299 7.80296C7.86542 7.74104 7.91497 7.66737 7.94878 7.58619C7.98259 7.50502 8 7.41796 8 7.33002C8 7.24208 7.98259 7.15502 7.94878 7.07385C7.91497 6.99267 7.86542 6.919 7.80299 6.85708L4.93909 3.99943Z"
                                        fill="#A3A3A3"
                                      />
                                    </svg>
                                  </div>
                                  <h4 className="Address_Update_Profile_Heading">
                                    Update Your Profile
                                  </h4>
                                  <p className="Address_page_Update_Profile_Paragraph">
                                    To create new address, first you have to
                                    update your profile with all the required
                                    details.
                                  </p>
                                  <div className="Address_Page_Update_Profile_Button_Div">
                                    <button
                                      className="Address_Page_UpdateProfile_button3"
                                      onClick={() => {
                                        navigate(
                                          `${navigation.navigateToProfileInfo}`
                                        );
                                      }}
                                    >
                                      Update Now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressList;
