import React, { useEffect, useState } from "react";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import LazyImage from "../LazyImage/LazyImage";
const ShopByCategoryElement = (props) => {
  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    if (props.loadingCategory) {
      if (!imgLoading) {
        props.loadingCategory(false);
      }
    }
  }, [imgLoading]);

  return (
    <div>
      <div className="single-category" style={{ cursor: "pointer" }}>
        <LazyImage
          className="categorySliderImages"
          src={`${imgHost}/${props.category.image}`}
          alt="image"
          loadingImg={setImgLoading}
        />
      </div>
    </div>
  );
};

export default ShopByCategoryElement;
